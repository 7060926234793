import Snapshot from "../PlanReach/reordering/Snapshot";

export default class DataFire {

  static bindRef =(that,ref,schema,snapshotChanged)=> {
    const promise = new Promise( (resolve,reject)=> {
      ref.on('value', snapshot => {
        if (that.mounted && typeof snapshot.val() === "object"){
          let previousState = {...that.state}
          let snapshotState = {...snapshot.val()}
  
          Snapshot.purgeRemovedItems(previousState,snapshotState,schema)
          if (snapshotChanged) snapshotChanged(previousState,snapshotState)
          that.setState({...snapshotState})
          
          resolve({...snapshotState})
        }
        else reject()
      })
    })

    return promise
  }
}
import React from 'react'

import '../../../whiteframe/Whiteframe.css'
import ClickOutsideService from '../../../../services/clicked-outside/ClickOutsideService';
import { ClassList, ClassNameDelegate } from '../../../../delegates/ClassNameDelegate';

class Panel extends React.Component {
  handleClick = e => {
    e.stopPropagation();
    if (this.props.onClick) this.props.onClick();
  }

  componentDidMount =()=> {
    this.refs.panel.clickOutsideId = ClickOutsideService.register(this.refs.panel,
      e=>this.clickedOutside(e))

    setTimeout(()=>
      this.refs.panel && ClickOutsideService.setActive(this.refs.panel.clickOutsideId), 500
    )
    this.mounted = true
  }

  componentWillUnmount =()=> {
    this.mounted = false
    ClickOutsideService.unregister(this.refs.panel.clickOutsideId)
  }

  clickedOutside =(e)=> {
    const { close } = this.props
    close && close()
  }

  panelStyle = {...this.props.style}

  render() {
    const classes = ["whiteframe-4dp","popup-panel"]
    ClassNameDelegate(this.props,classes)

    return (
      <div style={this.panelStyle} ref="panel" className={ClassList(classes)} onClick={this.handleClick}>
        {this.props.children}
      </div>
    )
  }

}

export default Panel
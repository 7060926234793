class Routing {

  /**
   * Gets the router params for a router component.
   * 
   * @param {React.Component} component A react router component.
   */
  static getRouterParam(component){
    const { match: { params } } = component.props
    return params
  }

  /**
   * Gets the a query param for a router component.
   * 
   * @param {React.Component} component A react router component.
   * @param {String} param Param in the query string.
   */
  static getQueryParam(component,param){
    const queryParams = new URLSearchParams(component.props.location.search.slice(1))
    queryParams.get(param)
  }
}

export default Routing
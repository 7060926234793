/**
 * Parses a series of **Message** nodes
 * @param {*} nodes
 */
export const NodeParser=(nodes)=>{
  return Array.from(nodes).reduce( messageNodeReducer, [])
}

const parseNode=(node)=> {
  let children = (node.childNodes) 
    ? Array.from(node.childNodes).reduce(messageNodeReducer,[])
    : []

  switch (node.nodeName){
    case "div": return DivNode(node,children)
    case "section": return SectionNode(node,children)
    case "link": return LinkNode(node,children)
    case "br": return BreakNode(node,children)
    case "b": case "bold": return BoldNode(node,children)
    case "i": case "italic": return ItalicNode(node,children)
    case "pre": return PreNode(node,children)
    case "#text": return TextNode(node,children)
    default: return TextNode(node,children)
  }
}

const messageNodeReducer=(accumulator,currentValue)=>{
  const node = parseNode(currentValue)
  if (node !== null) accumulator.push(node)
  return accumulator
}

function hasNodeValue(nodeValue){
  return nodeValue && (nodeValue.trim() || nodeValue === "0")
}

/**
 * Section containing content. Equivalent to a Div with the 'message-section' class. Teh 
 * section should be formatted `<section>{children}</section>`.
 * @param {*} node - the node to process 
 * @param {*} children - the children for the node
 */
const SectionNode =(props, children)=> ({
  type : "section",
  children : children 
})

/**
 * Div containing content. The div should be formatted `<div>{children}</div>`.
 * @param {*} node - the node to process 
 * @param {*} children - the children for the node
 */
const DivNode =(props, children)=> ({
  type : "div",
  children : children
})

/**
 * Link equivalent to a HTML <a> tag. The link should be formatted `<link url="{Link URL}">{Link Label}</link>`.
 * @param {*} node - the node to process. The node needs
 */
const LinkNode =({attributes,childNodes})=> ({
  type : "link", 
  value: {
    url : attributes.url.nodeValue,
    label : childNodes[0].nodeValue
  }
})

/**
 * Break equivalent to a HTML <br> tag. The break should be formatted as `<br>` or `</br>`.
 */
const BreakNode =()=> ({
  type : "break" 
})

/**
 * Break equivalent to a HTML <br> tag. The break should be formatted as `<br>` or `</br>`.
 */
const BoldNode =(props, children)=> {
  return {
    type : "bold",
    children : children
  }
}

const ItalicNode =(props, children)=> {
  return {
    type : "italic",
    children : children
  }
}

const PreNode =(props, children)=> {
  return {
    type : "pre",
    children : children
  }
}

/**
 * Text Node with no children
 */
const TextNode =({nodeValue})=> {
  if (hasNodeValue(nodeValue)){
    return {
      type : "paragraph",
      value : nodeValue
    }
  }
  return null
}
import React from 'react'

import "./Chip.css"
import Icon from '../icons/Icon';
import IconButton from '../buttons/iconButton/IconButton';
import { ColorsDelegate } from '../../delegates/ColorsDelegate';
import { ClassNameDelegate, ClassList } from '../../delegates/ClassNameDelegate';

/**
 * Material Design Chip
 * https://material.io/design/components/chips.html#input-chips
 * 
 * @prop {function} changed - Optional. If set, the chip acts like a button and the function will
 * be called when the chip is pressed changes.
 * @prop {function} removed - Optional. If set, the chip gets a remove icon button and the passed function will
 * be called when the button on the chip is pressed.
 * @prop color - Optional color for chip. Defaults to gray.
 * @prop {String} color - If present, the color is used for the switch.
 * @prop {boolean} primary - If present, will use the primary color.
 * @prop {boolean} accent - If present, will use the accent color.
 * @prop {boolean} warn - If present, will use the warn color.
 * @prop {IconName} icon - The icon to show
 * @prop {boolean} disabled - If true, the switch is disabled.
 * 
 * @todo avatar, cancel function
 */
const Chip =(props)=> {
  let classes = ["chip"]
  let styles = {}
  let iconStyles = { margin : "auto 8px auto -4px", padding : "7px 0px" }

  ClassNameDelegate(props,classes)
  ColorsDelegate(props,classes,styles)
  // if (props.buttonStyles) styles = {...props.buttonStyles}

  if (props.disabled){
    classes.push("disabled")
  }
  if (typeof props.clicked === "function" ){
    classes.push("button")
  }

  const clicked=()=> {
    props.clicked && props.clicked()
  }
  const removed=()=> {
    props.removed && props.removed(props.id)
  }
  
  return (
    <div className={ClassList(classes)} style={styles} onClick={clicked}>
      {props.icon ? <Icon small styles={iconStyles} icon={props.icon}/> : null }
      {props.children}
      {props.removed ? <IconButton small icon="close" clicked={removed}/> : null }
    </div>
  )
}

export default Chip
import React from 'react'

import IconButton from '../../../platform/components/buttons/iconButton/IconButton'
import TextInput from '../../../platform/components/inputs/text/TextInput'

import './AppScreen.css'

const Screen =(props)=> {
  return (
    <div className="app-screen">
      {props.children}
    </div>
  )
}
const Header =(props)=> {
  let classes="screen-header"
  if (props.primary) classes+= " primary colors-dark-background"
  return (
    <div className={classes}>
      {/* {React.cloneElement(props.children, { className: "colors-dark" })} */}
      {props.children}
    </div>
  )
}
const SubHeader =(props)=> {
  if (props.show === undefined) return null

  let classes="screen-sub-header"
  if (props.primary) classes+= " primary colors-dark-background"
  if (props.show !== undefined){
    classes += " toggleable"
    if (!props.show) classes += " hidden"
  }
  return (
    <div className={classes}>
      {props.children}
    </div>
  )
}

const BackButton =(props)=>{
  return (
    <IconButton icon="keyboard_arrow_left" clicked={props.clicked}/>
  )
}
const Title =(props)=>{
  return (
    <div className="text-title flex input-container">
      {(props.value === undefined || props.value === null) ? "" : props.value }
    </div>
  )
}
const EditableTitle =(props)=>{
  return (
    <TextInput className="flex" changed={props.changed} value={props.value} blurred={props.blurred} placeholder={props.placeholder} noLabel/>
  )
}
const Body =(props)=>{
  return (
    <div className="screenBody" style={props.style ? props.style : {}}>
      {props.children}
    </div>
  )
}
const Footer =(props)=>{
  return (
    <div className="screenFooter">{props.children}</div>
  )
}
const FabArea =(props)=>{
  let classes = "screen-fab-area"
  if (props.bottom) classes+= " bottom"
  if (props.right) classes+= " right"
  return (
    <div className={classes}>{props.children}</div>
  )
}
const Right =(props)=>{
  return (<div className="section-right">{props.children}</div>)
}

export {Screen, Header, SubHeader, BackButton, Title, EditableTitle, Body, Footer, FabArea, Right}
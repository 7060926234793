import React from 'react'

import ButtonsPage from './pages/ButtonsPage'
import CardsPage from './pages/CardsPage'
import TypographyPage from './pages/TypographyPage'
import DialogsPage from './pages/DialogsPage'
import PickersPage from './pages/PickersPage'
import InputsPage from './pages/InputsPage'

import { Route } from 'react-router-dom'
import MenusPage from './pages/MenusPage';
import SnackbarsPage from './pages/SnackbarsPage';
import NavigationPage from './pages/NavigationPage';
import MultiAddItemPage from './pages/MultiAddItemPage';
import { Header } from '../../PlanReach/base/screen/AppScreen';
import NavigationButton from '../components/sideNavigation/SideNavigationButton';
import FileUploadPage from './pages/FileUpload';
import CheckBoxPage from './pages/CheckBoxPage';
import ChipsPage from './pages/ChipsPage';
import BadgesPage from './pages/BadgesPage';
import MessageParserPage from './pages/MessageParserPage';

const UiTestPages=(props)=>{
  return (
    <Route path="/uiTest">
      <>
      <Header primary>
        <NavigationButton/>
      </Header>
      <div className="ui-test-pages">
      <Route path="/uiTest/badges" component={BadgesPage}/>
        <Route path="/uiTest/buttons" component={ButtonsPage}/>
        <Route path="/uiTest/cards" component={CardsPage}/>
        <Route path="/uiTest/checkBox" component={CheckBoxPage}/>
        <Route path="/uiTest/chips"  component={ChipsPage}/>
        <Route path="/uiTest/dialogs" component={DialogsPage}/>
        <Route path="/uiTest/fileUpload" component={FileUploadPage}/>
        <Route path="/uiTest/inputs" component={InputsPage}/>
        <Route path="/uiTest/menus" component={MenusPage}/>
        <Route path="/uiTest/pickers" component={PickersPage}/>
        <Route path="/uiTest/toasts" component={SnackbarsPage}/>
        <Route path="/uiTest/typography" component={TypographyPage}/>
        <Route path="/uiTest/navigationPage" component={NavigationPage}/>
        <Route path="/uiTest/multiAddItemPage" component={MultiAddItemPage}/>
        <Route path="/uiTest/messageParser" component={MessageParserPage}/>
      </div>
      </>
    </Route>
  )
}

export default UiTestPages
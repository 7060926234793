import React from 'react'

import './ExpansionPanel.css'
import IconButton from '../buttons/iconButton/IconButton';

class ExpansionPanel extends React.Component {
  mounted = false
  state = {
    expanded : false
  }

  componentDidMount(){
    this.mounted = true
    this.setState({ expanded : !!this.props.expanded })
  }
  componentWillUnmount(){
    this.mounted = false
  }

  expandButton=()=>{
    this.setState({ expanded : !this.state.expanded })
  }

  render(){
    return (
      <div className="expansion-panel">
        <div className="expansion-panel__header">
          <div className="flex">{this.props.summary}</div>
          <div className="expansion-panel__header__button">
            <IconButton icon={this.state.expanded ? "expand_less" : "expand_more"} clicked={this.expandButton}/>
          </div>
        </div>
        { this.state.expanded &&
          <div className="expansion-panel__content">
            {this.props.children}
          </div> 
        }
      </div>
    )
  }
}

export default ExpansionPanel
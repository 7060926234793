import React from 'react'
import * as firebase from 'firebase'

import DetailScreen from '../../../../base/view/detail/DetailScreen';
import Avatar from '../../../../../platform/components/avatar/Avatar';
import TextArea from '../../../../../platform/components/inputs/textarea/TextArea';
import IconButton from '../../../../../platform/components/buttons/iconButton/IconButton';
import { Model, Transaction, databaseRef } from '../../../../../TransactaFire/TransactaFire';
import User from '../../../../User/User';

import './ChatDetail.css'
import { BackButton, Title } from '../../../../base/screen/AppScreen';
import Divider from '../../../../../platform/components/dividers/Divider';
import Menu, { MenuItem } from '../../../../../platform/components/menu/Menu';
import { arrayLast } from '../../../../../GlobalUtils/BrianUtils';
import { elapsedTimeFormat } from '../../../../../GlobalUtils/DateAndTime';
import { CaptionTextLight } from '../../../../../platform/components/typography/Typography';
import Ticker from '../../../../../DateAndTime/Ticker';

class ChatDetail extends DetailScreen {

  state = {
    messages : [],
    isScrolled : false
  }

  chatsRef = databaseRef()
  chatViewsRef = databaseRef('chats')
  contactsRef = databaseRef(`${User.uid}/contacts`)

  shareablePrefix = "chat"
  viewNode = "chats"
  shareableNode = "chat"
  shareableName = "Chats"

  shareableRef = null
  shareableViewRef = null

  /**
   * Marks the component as mounted.
   */
  componentDidMount(){
    super.componentDidMount()
    this.attachRef(this.contactsRef,"contacts")

    this.ticker = Ticker.registerTicker(this.tick)
    this.tick()
  }
  componentWillUnmount(){
    super.componentWillUnmount()
    Ticker.unregisterTicker(this.ticker)
  }
  tick =()=> {
    this.setState({currentTime:Date.now()})
  }

  /**
   * Attaches the Firebase ref to the component with an on value
   * watcher. Whenever the ref changes the state will be updated.
   *
   * @param {*} ref Firebase ref pointing to the data.
   * @param {*} node Name of the node for the ref - e.g ref("user/bars")
   * would have a node of bars.
   */
  attachRef(ref,node){
    ref.$loaded = new Promise( (resolve,reject) => {
      ref.on('value', snapshot => {
        if (this.mounted && typeof snapshot.val() === "object"){

          let snapshotState = {...snapshot.val()}
          if (snapshotState[node]){
            this.setState({...snapshotState})
          } else {
            this.setState({[node] : {}})
          }
          resolve(snapshotState)
        }
        reject()
      })
    })
  }

  componentDidUpdate(){
    if(!this.mounted || this.state.isScrolled) return;

    setTimeout(() => {
      if (this.mounted)
      this.refs.bodyEnd.parentNode.scrollTop = this.refs.bodyEnd.parentNode.scrollHeight
    }, 30);
  }  

  addMessage =(message)=> {
    let now = (new Date()).getTime()

    let chat = new Model(this.shareableRef,{...this.state})
    let transaction = new Transaction()
      transaction.add(chat)

    let messageKey = "message_"+this.shareableRef.push().key
    
    let messages = this.state.messages ? {...this.state.messages} : {}
    let count = Object.keys(this.state.messages).length
    
    messages[messageKey] = count

    chat.change(messageKey, message)
    chat.change("messages", messages)
    
    let usersRef = databaseRef()
    let usersModel = new Model(usersRef,{...this.state})
    transaction.add(usersModel)

    Object.keys(this.state.users).forEach( uid => {
        usersModel.change(`${uid}/chats/chats/${this.shareableId}`, now)
        usersModel.change(`${uid}/chats/${this.shareableId}/lastText`, message.text)
        usersModel.change(`${uid}/chats/${this.shareableId}/lastDate`, now)
      }
    )
    transaction.commit()
  }

  removeChat =()=> {
    if (!this.mounted) return
    this.props.history.replace(`/chats?removeChat=${this.shareableId}`)
  }

  groupMessages =(messages)=> {
    if (!this.state.messages) return null
    
    let results = []
    let previous = null
    let messageGroup = null

    Object.keys(this.state.messages)
    .forEach( messageKey => {
      let message = this.state[messageKey]
      message.id = messageKey

      if (message.sender !== "system" && previous && previous === message.sender){
        messageGroup.messages.push(message)
      } else {
        if (messageGroup) results.push(messageGroup)

        messageGroup = { messages : [], sent : message.sender === User.uid, sender : this.getSenderUser(message.sender) }
        messageGroup.messages.push(message)
        previous = message.sender
      }
    })
    if (messageGroup) results.push(messageGroup)
    return results
  }

  getSenderUser =(sender)=> {
    if (sender === User.uid) return {}
    if (sender === "system") return "system"
    return this.state[`contact_${sender}`]
  }

  get screenBodyStyle(){
    return {backgroundColor: "var(--color-gray-100)",
    overflowY: "auto"}
  }

  getTitle=()=>{
    if (this.state.name) return this.state.name
    if (!this.state.users) return null
    let otherUsers = Object.keys(this.state.users)
      .filter( uid => uid !== User.uid )
      .map( uid => this.state.users[uid] )
    if (otherUsers.length === 1) return otherUsers[0]
    return "Untitled Chat"
  }

  get screenHeader(){
    return (<>
      <BackButton clicked={this.handleBackLink}/>
      <Title value={this.getTitle()} />
      <Menu>
        <MenuItem clicked={this.removeChat}>Delete</MenuItem>
      </Menu>
    </>)
  }
  
  get screenBody(){
    let i = 0

    return (<>
      <div>
        { this.groupMessages(this.state.messages).map( messageGroup => 
          MessageGroup({ messageGroup, id : i++, time: this.state.time })
        )}
      </div>
      <div ref="bodyEnd"/>
    </>)
  }

  get screenFooter(){
    return (
    <div style={{backgroundColor: "var(--color-gray-100)"}}>
      <ChatEditor addMessage={this.addMessage}/>
    </div>
    )
  }
}

const MessageGroup =({ id, messageGroup, time })=> {
  const systemMessage = (messageGroup.sender === "system")
  const showAvatar = (!messageGroup.sent && !systemMessage)

  const lastMessage  = arrayLast(messageGroup.messages)
  const lastMessageTime =  elapsedTimeFormat(lastMessage.created, time)

  return (<div className="message-group" key={id}>
    { showAvatar && <Avatar src={messageGroup.sender.avatarUrl}/> }
    <div className={`message-group__list ${systemMessage ? "system-message" : ""}`}>
      { messageGroup.messages.map( message => 
        Message({...message, sent : messageGroup.sent, systemMessage : systemMessage}) 
      ) }
      <div className="message-group__time-area">
        <CaptionTextLight>{lastMessageTime}</CaptionTextLight>
      </div>
    </div>
  </div>)
}

const Message =({systemMessage,sent,id,text})=> {
  const messageType = (
    systemMessage ? "system" :
    sent ? "sent" :
    "recieved"
  )

  let classes = `chat-message__${messageType}`
  return (<div key={id} className={classes}>{text}</div>)
}

class ChatEditor extends React.Component {
  state = {
    text : "",
  }

  changeText =(value)=> {
    this.setState({text : value})
  }

  sendMessage =()=> {
    let message = {
      text: this.state.text,
      sender : User.uid,
      created : (new Date()).getTime()
    }
    this.setState({ text : "" })
    this.props.addMessage(message)
  }

  render(){
    return (<>
      <Divider/>
      <div style={{backgroundColor: "var(--color-gray-100)", padding : "8px 8px 8px 16px"}}>
        <div className="chat-editor">
          <TextArea
            noLabel
            rows="1"
            placeholder="Text message"
            value={this.state.text}
            changed={this.changeText} />
          <IconButton primary icon="send" clicked={this.sendMessage}/>
        </div>
      </div>
    </>)
  }
}

export default ChatDetail
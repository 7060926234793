import React from 'react'

import UiTestPage, {UiTestSection} from '../UiTestPage';
import { Header } from '../../../PlanReach/base/screen/AppScreen';
import NavigationButton from '../../components/sideNavigation/SideNavigationButton';
import SideNavigationDrawer, {SideNavigationService} from '../../components/sideNavigation/SideNavigationDrawer';

/**
 * Cards page is a demo of the Card component, interactive api documentaton, and a test
 * of the card's behaivor and conformance to the Material Spec.
 */
class NavigationPage extends React.Component {

  showNavigation=()=>{
    SideNavigationService.showNavigation()
  }

  render(){
    return (
      <UiTestPage>
        <Header>
          <NavigationButton/>
        </Header>
        <UiTestSection name="todo">
          <ul>
            <li>Clicked outside close</li>
          </ul>
        </UiTestSection>
        <SideNavigationDrawer>
          Test
        </SideNavigationDrawer>
      </UiTestPage>
    )
  }
}
export default NavigationPage
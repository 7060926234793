import ClickOutsideService from "../../services/clicked-outside/ClickOutsideService";

/**
 * Service for contolling the navigation drawer.
 * 
 * Only one navigation area can exist at a time. Creating a new
 * navigation will unregister the old one.
 */
export default class SideNavigationService {
  
  /**
   * Shows the navigation drawer
   * @returns Promise that resolves when navigation closes.
   */
  static showDrawer(){
    return new Promise( (res,rej) => {
      if (!this.drawer || !this.drawer.mounted) return rej(); // Should throw error, or at least warn
      this.drawer.setState({ shown : (new Date()).getTime()})
      ClickOutsideService.setActive(this.drawer.clickedOutsideId)
    })
  }

  /**
   * Hides the drawer.
   * @note Due to a timing issue, the method is canceled if the drawer was opened in the 
   * last 300ms
   */
  static hideDrawer(){
    if (!this.drawer || !this.drawer.mounted || (new Date()).getTime() - 300
     - this.drawer.state.shown < 0) return null

    this.drawer.setState({ shown : false })
    ClickOutsideService.setInactive(this.drawer.clickedOutsideId)
  }

  /**
   * Handles binding the current screen's navigation/snackbar to the NavigationService
   * @param {*} drawer SideNavigationDrawer component
   */
  static bindNavigation(drawer){
    this.drawer = drawer;
  }
}
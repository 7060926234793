import React from 'react'

import './Typography.css'

const DisplayText4 =(props)=> { return <span className="typography-h4">{props.children}</span> }
const DisplayText3 =(props)=> { return <span className="typography-h3">{props.children}</span> }
const DisplayText2 =(props)=> { return <span className="typography-h2">{props.children}</span> }
const DisplayText1 =(props)=> { return <span className="typography-h1">{props.children}</span> }
const HeadlineText =(props)=> { return <span className="typography-headline">{props.children}</span> }
const TitleText =(props)=> { return <span className="typography-title">{props.children}</span> }
const SubtitleText =(props)=> { return <span className="typography-subtitle">{props.children}</span> }
const SubtitleText2 =(props)=> { return <span className="typography-subtitle2">{props.children}</span> }
const BodyText =(props)=> { return <span className="typography-body">{props.children}</span> } 
const BodyTextBold =(props)=> { return <span className="typography-body-bold">{props.children}</span> } 
const BodyTextLight =(props)=> { return <span className="typography-body-light">{props.children}</span> } 
const CaptionText =(props)=> { return <span className="typography-caption">{props.children}</span> } 
const CaptionTextLight =(props)=> { return <span className="typography-caption-light">{props.children}</span> } 
export {DisplayText4, DisplayText3, DisplayText2, DisplayText1, HeadlineText, TitleText, SubtitleText,
SubtitleText2, BodyText, BodyTextBold, BodyTextLight, CaptionText, CaptionTextLight}
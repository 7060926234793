import React from 'react'

import Menu, {MenuItem} from '../../../platform/components/menu/Menu'
import UiTestPage, {UiTestSection} from '../UiTestPage';

/**
 * Demo page for menu component, interactive api documentaton, and a test
 * of the menu's behaivor and conformance to the Material Spec.
 */
class MenusPage extends React.Component {
    
  state = {
    output : "",
  }

  appendTextHandler =(value)=> { this.setState({ output : this.state.output + " " + value }) }
  updateTextHandler =(value)=> { this.setState({ output : value }) }

  render(){
    return (
      <UiTestPage>
        
        <UiTestSection name="Menu">
          <div>Output: {this.state.output}</div>
          <Menu>
            <MenuItem select={()=> this.updateTextHandler("Foo")}>Set Foo</MenuItem>
            <MenuItem select={()=> this.appendTextHandler("Bar")}>Append Bar</MenuItem>
            <MenuItem closeMenu={false}>Menu Stays Open</MenuItem>
            <MenuItem select={()=> this.updateTextHandler("")}>Clear</MenuItem>
          </Menu>
        </UiTestSection>
        <UiTestSection name="Menu in Flex Component">
          <div>Output: {this.state.output}</div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <div className="flex"/>
            <Menu>
              <MenuItem select={()=> this.updateTextHandler("Foo")}>Set Foo</MenuItem>
              <MenuItem select={()=> this.appendTextHandler("Bar")}>Append Bar</MenuItem>
              <MenuItem closeMenu={false}>Menu Stays Open</MenuItem>
              <MenuItem select={()=> this.updateTextHandler("")}>Clear</MenuItem>
            </Menu>
          </div>
        </UiTestSection>
        <UiTestSection name="Menu with Dots Icon">
          <div>Output: {this.state.output}</div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <div className="flex"/>
            <Menu verticalDots>
              <MenuItem select={()=> this.updateTextHandler("Foo")}>Set Foo</MenuItem>
              <MenuItem select={()=> this.appendTextHandler("Bar")}>Append Bar</MenuItem>
              <MenuItem closeMenu={false}>Menu Stays Open</MenuItem>
              <MenuItem select={()=> this.updateTextHandler("")}>Clear</MenuItem>
            </Menu>
          </div>
        </UiTestSection>
        <UiTestSection name="Menu Bar">
          <div>Output: {this.state.output}</div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <div className="flex"/>
            <Menu verticalDots>
              <MenuItem select={()=> this.updateTextHandler("Foo")}>Set Foo</MenuItem>
              <MenuItem select={()=> this.appendTextHandler("Bar")}>Append Bar</MenuItem>
              <MenuItem closeMenu={false}>Menu Stays Open</MenuItem>
              <MenuItem select={()=> this.updateTextHandler("")}>Clear</MenuItem>
            </Menu>
          </div>
        </UiTestSection>
      </UiTestPage>
    )
  }

}

export default MenusPage
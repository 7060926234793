import React from 'react'

import ViewsList from '../../../../base/view/list/collection/ViewsList';
import Avatar from '../../../../../platform/components/avatar/Avatar';
import { FabArea, Header, Title } from '../../../../base/screen/AppScreen';
import Fab from '../../../../../platform/components/buttons/FAB/FAB';

import { BodyText, BodyTextBold } from '../../../../../platform/components/typography/Typography';
import User from '../../../../User/User';
import { Transaction, Model, Collection, databaseRef } from '../../../../../TransactaFire/TransactaFire';
import UndoableActions from '../../../../undoable/UndoableActions';
import { ToastService } from '../../../../../platform/components/toast/Toast';
import UndoToastButton from '../../../../../platform/components/toast/buttons/undo/UndoToastButton';
import Divider from '../../../../../platform/components/dividers/Divider';
import { informalTimeFormat } from '../../../../../GlobalUtils/DateAndTime';

import "./Messages.css"
import NavigationButton from '../../../../../platform/components/sideNavigation/SideNavigationButton';
import SelectPicker from '../../../../../platform/components/pickers/selectPicker/SelectPicker';

class Messages extends ViewsList {
  mounted = false
  
  static hidden = {}

  updateLastRoute(){
    const mode = this.getReactRouterParam("mode")
    this.setState({ view: mode === "sender" ? "1" : "0" })
  }

  state = {
    hidden : Messages.hidden,
    view : "0",
  }

  get viewsRef(){
    if (!this._viewsRef) this._viewsRef = databaseRef(`${User.uid}/messages`)
    return this._viewsRef
  }

  shareablePrefix = "message"
  viewNode = "messages"
  itemNode = "message"
  shareableName = "Messages"
  newName = "New Message"
  itemName = "Messages"

  lastRoute = "messages"

  emptyItemsMessageHeader =()=> `No ${this.state.view === "0" ? "Recieved" : "Sent"} Messages`
  emptyItemsMessageBody =()=> "Use the button below to send one"

  /**
   * Handles the following query params on page load:
   * * `removeMessage : messageId` - removes the message with the matching id
   * 
   * @override
   */
  handleParams(params){
    let removeMessage = params.get('removeMessage')
      if (removeMessage) this.removeMessage(removeMessage)
  }

  addMessage=()=>{
    this.props.history.push(`/newMessage`)
    this.props.history.from = `messages`
  }

  readMessage=(messageId)=>{
    const {unreadMessages} = this.state

    if (unreadMessages && unreadMessages[messageId] !== undefined){
      const transaction = new Transaction()
      const unreadMessageModel = transaction.add(new Model(this.viewsRef.child("unreadMessages").child(messageId)))
      const unreadMessagesModel = transaction.add(new Model(User.dataRef.child("unreadMessages")))
      
      var count = unreadMessages ? Object.keys(unreadMessages).length-1 : 0

      unreadMessageModel.remove()
      unreadMessagesModel.set(count)
      transaction.commit()
    }
  }

  handleLink =(id)=> {
    if (!this.mounted) return
    this.readMessage(id)
    this.props.history.push(`/${this.itemNode}/${id}`)
    this.props.history.from = `messages`
  }

  getItem(messageId){
    return MessageSummary({
      ...this.state[messageId], id : messageId, sref : this.handleLink, formatDate : this.formatDate, 
      unread : this.state.unreadMessages && this.state.unreadMessages[messageId] !== undefined
    })
  }

  filterViewItems=(message)=> (
    !this.state.hidden[message] &&
    ( this.state.view === "0"
      ? this.state[message].isSender !== true || this.state[message].isRecipient === true
      : this.state[message].isSender === true)
  )

  /**
   * Get's a message from its messageId. Does not use the current view's message
   * in case it has been changed.
   */
  getMessage = async(messageId) => {
    const messageRef = this.viewsRef.child(messageId)
    const snapshot = await messageRef.once('value')
    return snapshot.val()
  }

  /**
   * Removes a message.
   * Waits for viewRef to load into state so that the message can be hidden.
   */
  removeMessage = async(messageId) => {
    this.props.history.replace("/messages")
    const message = await this.getMessage(messageId)

    if (!message || !message.subject) return null
    await this.viewsRef.$loaded

    this.hideMessage(messageId)
    this.showRemoveMessage(message,messageId)
  }

  /**
   * Shows an Undo Toast that unhides the message if pressed and deletes the message otherwise.
   */
  showRemoveMessage(message,messageId){
    const act = UndoableActions.addAction(
       this.deleteMessage, 
       this.showMessage, 
       messageId
    )
    const action =()=> UndoableActions.action(act)
    const undo =()=> UndoableActions.undo(act)

    ToastService.showToast(
      `Message "${message.subject}" was removed`,
      <UndoToastButton action={undo}/>,
      3500
    ).then( action )
  }
  
  /**
   * Deletes a message
   */
  deleteMessage = async (messageId) => {
    let transaction = new Transaction()
    let messages = transaction.add(new Collection(this.viewsRef, this.viewNode, "message_"))

    await messages.deleteItems([messageId])
    transaction.commit()
  }

  /**
   * Hides a message from view
   */
  hideMessage =(messageId)=> {
    Messages.hidden = {...Messages.hidden, [messageId] : true}
    this.setState({ hidden : {...this.state.hidden, [messageId] : true} })
  }

  /**
   * Unhides a message from view
   */
  showMessage =(messageId)=> {
    Messages.hidden = {...Messages.hidden, [messageId] : false}
    this.setState({ hidden : {...this.state.hidden, [messageId] : false } })
    ToastService.hideToast()
  }

  updateView =(value)=> {
    this.setState({view : value})
    this.props.history.replace(`/messages/${ value === "1" ? "sender" : "recipient"}`)
  }

  get header(){
    return (<>
      <Header primary>
        <NavigationButton/>
        <Title value={ this.itemName }/>
        <SelectPicker
          items={[
            {name: "Recipient", id: "0"},
            {name: "Sender", id: "1"},
          ]}
          value={this.state.view}
          changed={this.updateView}
        />
      </Header>
    </>)
  }

  get fabArea(){
    return (<FabArea bottom right>
      <Fab icon="add" accent clicked={this.addMessage}/>
    </FabArea>)
  }

}

export const MessageSummary =({avatarUrl, created, subject, summary, unread, ...props})=> {
  
  const sref=()=>props.sref(props.id)

  return (
    <div key={props.id} className="column" onClick={sref}>
      <div className="message-summary">
        <div>
          <div className="contact-summary__avatar">
            <Avatar src={avatarUrl}/>
          </div>
        </div>
        <div className="flex column">
          <BodyTextBold>{subject}</BodyTextBold>
          <BodyText>{summary}</BodyText>
        </div>
        <div className="column message-summary__end">
          <BodyTextBold>{informalTimeFormat(created)}</BodyTextBold>
          { unread ? <span className="typography-caption colors-primary">Unread</span> : null}
        </div>
      </div>
      <Divider/>
    </div>
  )
}

export default Messages
import firebase from 'firebase'
import Reordering from '../PlanReach/reordering/Reordering';

/**
 * Creates a new firebase key.
 * @param {String} prefix Optional prefix before key.
 */
export const newKey =(prefix)=> (prefix || "") + firebase.database().ref().push().key

/**
 * Base database connection.
 */
export const databaseRef =(path)=> path === undefined 
  ? firebase.database().ref('reactTests')
  : firebase.database().ref(`reactTests/${path}`)

/**
 * Get's the full path for a refrence
 */
firebase.database.Reference.prototype.fullkey = function() {
  return this.toString().substring(this.root.toString().length-1);
}

/**
 * Used to hold a mutatable firebase model that can be used to generate a
 * data fannout object for a firebase update
 */
export class Model {
  _data = {}
  _update = {}

  constructor(ref,data,load){
    this.ref = ref;
    this._path = ref.fullkey()
    if (data) this._data=data
    if (!data && load) {
      this.loaded = new Promise((resolve, reject) =>
        ref.once("value").then( snapshot => { 
          this._hasData = snapshot.val() !== null
          this._data = snapshot.val() || {}
          resolve(this._data)
        })
      )
    }
  }

  /**
   * Create a model at a firebase path
   * @param {*} path to node in firebase
   */
  static newAtPath(path){
    return new Model(
      databaseRef(path),
      null,
      true
    )
  }

  /**
   * Get the specified field of the Model 
   */
  get(field){
    return this._data[field]
  }

  /**
   * Stores the change and updates the Model
   * @param {*} field 
   * @param {*} value 
   */
  change(field,value){
    this._data[field] = value
    this._update[this._path+"/"+field] = value
  }

  /**
   * Stores an object in the Model. Overwrites any previous data.
   * @param {Object} values Object literal
   */
  set(values){
    this._data = values
    this._update[this._path] = values
  }

  /**
   * Deletes the model.
   */
  remove(){
    this.set(null)
  }

  /**
   * Add an item to a collection in the model.
   * 
   * To prevent an item from adding more than once, generate and pass a key
   * for it. As long as the key is the same each time, the value won't
   * be re-added.
   * 
   * @param {String} prefix The item prefix for the collection
   * @param {String} collection The collection field's name.
   * @param {Object} item The object to store at the location. Must be valid
   * to store in Firebase.
   * @param {String} key Optional key. If passed and an item with a matching
   * key is aleady in the the collection, the item will not be added. 
   */
  addToCollection(prefix,collection,item,key = newKey(prefix)){
    this.key = key 
    if (!this._data[collection]) this._data[collection] = {}
    if (!this._data[collection][collection]) this._data[collection][[collection]] = {}

    // Item already in collection
    if (this._data[collection][collection][key] !== undefined) return true

    let count = (this._data[collection][collection]
      ? Object.keys(this._data[collection][collection]).length
      : 0
    )

    this._data[collection][key] = {item}
    this._data[collection][collection][key] = count
    
    this._update[`${this._path}/${collection}/${key}`] = item
    this._update[`${this._path}/${collection}/${collection}/${key}`] = count
  }

  destory(){

  }
}

/**
 * Collection is used to interact with firebase collections. It maintains a 
 * model of the items node and a collection of models for any changed items. 
 */
export class Collection {
  collection = {}
  items = []

  constructor(ref,node,prefix = "item_"){
    this.ref = ref
    this.node = node
    this.prefix = prefix
    this.path = ref.fullkey()

    this.collection = new Model(ref.child(node),null,true)
    this.loaded = this.collection.loaded
  }

  static newAtPath(path, node, prefix ){
    var collection = new Collection( databaseRef(path), node, prefix )
    collection.path = path
    return collection
  }

  async countItems(){
    let collection = await this.collection.loaded
    return collection ? Object.keys(collection).length : 0
  }

  /**
   * Checks if the item already exists in the model.
   */
  async checkForKey(key){
    let collection = await this.collection.loaded
    return collection ? collection[key] !== undefined : false
  }

  /**
   * Adds an item to the collection and returns its model. If the item already exists, return null.
   * 
   * @param {*} item Firbase compatible item (object or primitive) to add to the collection
   * @param {*} prefix prefix for the item's key. Optional. Unused if key is specified.
   * @param {*} key The key to use for adding the item. Optional. If unspecified, key will
   * be generated.
   */
  async addToCollection(item, prefix = this.prefix, key ){
    if (key !== undefined){
      if (await this.checkForKey(key)) return null
    }

    if (key === undefined) key = newKey(prefix)

    let count = await this.countItems()

    if (count){
      this.collection.change(key,count)
    } else {
      this.collection.set({ [key] : count })
    }

    let addedItem = new Model(this.ref.child(key))
    this.items.push(addedItem)
    addedItem.set(item)
    addedItem.key = key

    return addedItem
  }

  /**
   * @param {Array<string>} itemsToDelete 
   * @param {*} prefix 
   */
  async deleteItems(itemsToDelete, prefix = this.prefix ){
    let items = await this.collection.loaded
    let removed = 0

    Object.keys(items)
      .sort( (a,b) => items[a] - items[b] )
      .forEach( itemId => {
        if(itemsToDelete.find( key=> key === itemId)){
          removed++
          this.collection.change(itemId,null)
        } else if (removed){
          this.collection.change(itemId,items[itemId]-removed)
        }
      })

    itemsToDelete.forEach( itemId => {
      let deletedItem = new Model(this.ref.child(itemId))
      this.items.push(deletedItem)
      deletedItem.set(null)
    })
  }

  async reorderItems(from,to){
    let collection = await this.collection.loaded
    let updatedCollection = Reordering.reorderItem(from,to,collection)
    if (updatedCollection) this.collection.set(updatedCollection)

    return updatedCollection
  }

  async reorderItemRange(from,to){
    let collection = await this.collection.loaded
    let updatedCollection = Reordering.reorderItemRange(from,to,collection)
    if (updatedCollection) this.collection.set(updatedCollection)
    return updatedCollection
  }

}

export class Transaction {
  models = []
  collections = []
  
  add(o){
    if (o instanceof Model) return this.addModel(o)
    if (o instanceof Collection) return this.addCollection(o)
  }

  /**
   * Adds a model to the transaction
   */
  addModel(model){
    this.models.push(model)
    return model
  }

  addCollection(collection){
    this.collections.push(collection)
    return collection
  }

  /**
   * Commits the transaction and returns the update's thenable promise that
   * will resolve when the update completes.
   */
  commit(){
    let update = {}
    let modelsInCollections = []

    this.collections.forEach( collection => {
      modelsInCollections.push( collection.collection )
      modelsInCollections.push( ...collection.items )
    })
    modelsInCollections.push( ...this.models )
    modelsInCollections.forEach( model => {
      update = {...update, ...model._update}
    })
    // console.log(update)
    return firebase.database().ref().update(update)
  }
}

if (!window.firebase) window.firebase = firebase;
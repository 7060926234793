import React from 'react'

import BoardColors from '../../BoardColors'
import './BoardPreview.css'

const getLaneCards=(cards)=> {
  if (!cards) return null
  return cards.map( (card,i) => {
    return (<div className="board-preview-card"
      key={i}
      style={{backgroundColor: BoardColors.colors[card.color || 0].color}}>
      {card.name}
    </div>)
  })
}

const BoardPreview =(props)=> {
  let previewLanes = null
  
  if (props && props.lanes){
    previewLanes = props.lanes.map( (lane,i) => {
      return (
        <div className="board-preview-lane"
          key={i}>
          <div className="board-preview-lane-header">
            {lane.name}
          </div>
          <div>
            {getLaneCards(lane.cards)}
          </div>
        </div>)
    })
  }

  return (
    <div className="board-preview">
      {previewLanes}
    </div>)
}

export default BoardPreview
import React from 'react'
import Popup, { PopupHeader, PopupSection } from '../../../../../platform/components/popups/popup/Popup';
import { FabArea } from '../../../../base/screen/AppScreen';
import Fab from '../../../../../platform/components/buttons/FAB/FAB';
import { SubtitleText2, BodyText } from '../../../../../platform/components/typography/Typography';

/**
 * ## Choose Contact Popup
 * Can pick one of a series of passed in contacts.
 * 
 * @todo Instead of passing in Contact Choices, should pass in contacts
 * and the props needed to make thoes contact choices. The mapper used
 * to create those choices, and the choices themselves, should not be 
 * inside this component.
 * 
 * @param {Array[Contact]} contacts
 * @param {Fab} fab - optional fab to show
 */
const ChooseContactPopup =(props)=> {
  const {contacts,fab} = props

  return(
    <Popup>
      <PopupHeader>Choose Contact</PopupHeader>
      <PopupSection flex scrollY>
        {contactOptions(contacts)}
        <div style={{height : "96px"}}>&nbsp;</div>
      </PopupSection>
      { ChooseContactFabArea(fab) }
      {/* Search box */}
      {/* Footer: Search Button, Close Button*/}
    </Popup>
  )
}

const contactOptions =(contacts)=> {

  if (!contacts || Object.keys(contacts).length <1){
    return (
      <div className="column">
        <SubtitleText2>No Contacts Available</SubtitleText2>
        <BodyText>Can't start a chat with users you are chatting with.</BodyText>
      </div>
    )
  }

  return (contacts)
}

const ChooseContactFabArea =(fab)=> {
  if (!fab) return null

  return(
    <FabArea bottom right>
      {fab}
    </FabArea>
  )
}

export default ChooseContactPopup
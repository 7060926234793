import React from 'react'

import ViewsList from '../../../../base/view/list/collection/ViewsList';
import Avatar from '../../../../../platform/components/avatar/Avatar';
import { FabArea } from '../../../../base/screen/AppScreen';
import Fab from '../../../../../platform/components/buttons/FAB/FAB';

import "./Contacts.css"
import Popup, { PopupHeader, PopupSection } from '../../../../../platform/components/popups/popup/Popup';
import { TitleText, BodyText } from '../../../../../platform/components/typography/Typography';
import User from '../../../../User/User';
import { Transaction, Model, Collection, newKey, databaseRef } from '../../../../../TransactaFire/TransactaFire';
import CopyButton from '../../../../../platform/components/buttons/copyButton/CopyButton';
import UndoableActions from '../../../../undoable/UndoableActions';
import { ToastService } from '../../../../../platform/components/toast/Toast';
import UndoToastButton from '../../../../../platform/components/toast/buttons/undo/UndoToastButton';
import { PopupService } from '../../../../../platform/components/popups/PopupArea';

//https://console.firebase.google.com/project/chorequestapp/database/chorequestapp/data/reactTests/boards
class Contacts extends ViewsList {
  mounted = false
  
  state = {
    reorderingItem : false
  }

  get viewsRef(){
    if (!this._viewsRef) this._viewsRef = databaseRef(`${User.uid}/contacts`)
    return this._viewsRef
  }

  shareablePrefix = "contact"
  viewNode = "contacts"
  itemNode = "contact"
  shareableName = "Contacts"
  newName = "New Contact"

  itemName = "Contacts"

  emptyItemsMessageHeader =()=> "No Contacts"

  /**
   * Handles the following query params on page load:
   *  * **action** - action to take on entry
   *    * addContact - goes straight to add contact popup
   * @override
   */
  handleParams(params){
    let action = params.get('action')
      if (action==="addContact") this.showNewContactPopup()
    
    let removeContact = params.get('removeContact')
    if (removeContact){
      this.props.history.replace(`/contacts`)
      this.removeContact(removeContact)
    } 
  }

  newRecordOnlyContact=()=>{
    let key = newKey("contact_")
    this.props.history.push(`/contact/${key}?newContact=recordOnly`)
  }

  getItem(contactId){
    return ContactSummary({
      contact : this.state[contactId],
      id : contactId,
      sref : ()=>this.handleLink(contactId)
    })
  }

  showNewContactPopup =()=> {
    PopupService.showPopup(this.NewContactPopup())
  }

  /**
   * Get's a contact from its contactId. Does not use the current view's contact
   * in case it has been changed.
   */
  getContact = async(contactId) => {
    const contactRef = this.viewsRef.child(contactId)
    const snapshot = await contactRef.once('value')
    return snapshot.val()
  }

  /**
   * Removes a contact.
   * Waits for viewRef to load into state so that the contact can be hidden.
   */
  removeContact = async(contactId) => {
    const contact = await this.getContact(contactId)
    if (!contact || !contact.name) return null
    await this.viewsRef.$loaded

    this.hideContact(contactId)
    this.showRemoveContactToast(contact,contactId)
  }

  /**
   * Shows an Undo Toast that unhides the contact if pressed and deletes the contact otherwise.
   */
  showRemoveContactToast(contact,contactId){
    const act = UndoableActions.addAction(
       this.deleteContact, 
       this.showContact, 
       contactId
    )
    const action =()=> UndoableActions.action(act)
    const undo =()=> UndoableActions.undo(act)

    ToastService.showToast(
      `Contact ${contact.name} was removed`,
      <UndoToastButton action={undo}/> 
    ).then( action )
  }
  
  /**
   * Deletes a contact
   */
  deleteContact = async (contactId) => {
    let transaction = new Transaction()
    let contacts = transaction.add(new Collection(this.viewsRef, this.viewNode, "contact_"))

    await contacts.deleteItems([contactId])
    transaction.commit()
  }

  /**
   * Hides a contact from view
   */
  hideContact =(contactId)=> {
    this.setState({ [contactId] : {...this.state[contactId],  $hidden : true } })
  }

  /**
   * Unhides a contact from view
   */
  showContact =(contactId)=> {
    this.setState({ [contactId] : {...this.state[contactId],  $hidden : false } })
  }
  
  /**
   * If the user doesn't have a Contact Share Link, creates a deeplink and
   * and updates the user's profile to point at it.
   */
  contactShareLink(){
    new Promise( (resolve, reject) => {
      if (User.profile.invite) return resolve(User.profile.invite.url);

      let transaction = new Transaction()
        let deepLinkKey = this.shareContactDeepLink(transaction)
        let deepLinkUrl  = this.addContactDeepLinkToProfile(transaction,deepLinkKey)

      transaction.commit().then( ()=> resolve(deepLinkUrl) )
    })
    .then( url => this.setState({shareUrl : url}) )  
  }

  /**
   * Creates a deeplink and returns the deeplink's key
   * @param {*} transaction
   * @return {String} **deepLinkKey** The key to create the deeplink url
   */
  shareContactDeepLink(transaction){
    let deepLinkKey = newKey(`shareContactLink_`)
    let model = Model.newAtPath(`${deepLinkKey}`)
      model.set({ uid : User.uid, name : User.userName })
      transaction.add(model)

    return deepLinkKey
  }

  /**
   * Adds the deeplink information to the user
   * @param {*} transaction
   * @param {*} deepLinkKey
   * @returns {String} The deeplink url to add this user as a contact
   */
  addContactDeepLinkToProfile(transaction,deepLinkKey){
    let deepLinkUrl  = `deeplink/contactShare/${deepLinkKey}`
    //let deepLinkUrl = `https://www.planreach.com/deeplink/contactShare/${deepLinkKey}`

    let userModel = Model.newAtPath(`${User.uid}`)
    transaction.add(userModel)
    userModel.change("invite", {
      created : (new Date()).getTime(),
      id : deepLinkKey,
      url : deepLinkUrl,
      shareLink : true
    })

    return deepLinkUrl
  }

  /**
   * Handles processing a add contact deeplink.
   * 
   * Redirects to the contact's profile on resolve.
   * @param {*} key
   * 
   * @todo Handle deep linking when logged in
   */
  static addContactDeepLink(key){
    return databaseRef(key).once('value')
    .then( snapshot => {
      return new Promise( (resolve, reject) => {
        if (!snapshot.val()) reject("Invalid deepLink")
        let deepLink = {...snapshot.val()}

        let transaction = new Transaction()
        let userModel = transaction.add(Model.newAtPath(User.uid))
        let contactModel = transaction.add(Model.newAtPath(deepLink.uid))

        Promise.all([userModel.loaded,contactModel.loaded]).then( ()=> {
          console.log(userModel.data)
          userModel.addToCollection("contact_","contacts",{
            name : deepLink.name, uid : deepLink.uid, 
            ...(contactModel._data.avatarUrl && {avatarUrl: contactModel._data.avatarUrl})
          },`contact_${deepLink.uid}`)
          contactModel.addToCollection("contact_","contacts",{
            name : User.userName, uid : User.uid, 
            ...(User.profile.avatarUrl && {avatarUrl: User.profile.avatarUrl})
          },`contact_${User.uid}`)
          transaction.commit().then(
            ()=> resolve(`/contact/${userModel.key}`)
          )
        })
      })
    })
  }

  /**
   * @todo Prevent adding more than once
   * @param {*} model 
   * @param {*} uid 
   * @param {*} name 
   */
  static addContactToUser(model,uid,name){
    model.addToCollection("contact_","contacts",{
      name : name, uid : User.uid
    })
  }

  /**
   * @todo Move to own file
   */
  NewContactPopup =()=> {
    return (
    <Popup>
      <PopupHeader>Contact Type</PopupHeader>
      { ( !this.state.newContactPopupView ||
        this.state.newContactPopupView === "selectType" ) &&
        <div>
          <PopupSection clicked={this.newRecordOnlyContact}>
            <TitleText>Record Only</TitleText>
            <BodyText>Contact that stores a person's information.</BodyText> 
          </PopupSection>
          <PopupSection clicked={()=> {
            this.setState({newContactPopupView : "inviteLink"})
            this.contactShareLink();
          } }>
            <TitleText>User Contact</TitleText>
            <BodyText>Adding a user as a contact lets you interact with them
              in PlanReach. You can also see and add their contact info.</BodyText> 
          </PopupSection>
        </div>
      }
      { this.state.newContactPopupView === "inviteLink" &&
        <div>
          <PopupSection flex>
            <TitleText>Invite Link</TitleText>
            <BodyText>This link lets people add you as contact. You could share it in an email,
            text, or instant message.</BodyText>
            <div style={{borderWidth: "4px 2px 2px 2px", marginTop: "16px", borderStyle: "solid",
              borderColor: "rgba(0,0,0,0.12)"}}>
              <textarea id="itemToShare" readOnly rows="4"
                style={{padding: "8px", width: "100%", border: "none",
                resize: "none"}} value={`localhost:3000/${this.state.shareUrl}`}/>
            </div>
          </PopupSection>
          <div>
            <CopyButton element="itemToShare"/>
          </div>
        </div>
      }

      {/* Search box */}
      {/* Footer: Search Button, Close Button*/}
    </Popup>)
  }

  get fabArea(){
    return (<FabArea bottom right>
      <Fab icon="add" accent clicked={this.showNewContactPopup}/>
    </FabArea>)
  }

}

const ContactSummary =(props)=> {
  return (<div 
    className="contact-summary"
    key={props.id}
    onClick={props.sref}>
    <div className="contact-summary__avatar">
      <Avatar src={props.contact.avatarUrl} gray={props.contact.recordOnly}/>
    </div>
    <div className="contact-summary__content">
      <div>{props.contact.name}</div>
    </div>
  </div>)
}

export default Contacts
import React from 'react'
import ShareableViews from '../../../base/view/list/shareable/ShareableViews';
import NotePreview from './preview/NotePreview';

class Notes extends ShareableViews {
  mounted = false

  shareablePrefix = "note"
  viewNode = "notes"
  shareableNode = "note"
  shareableName = "Notes"
  pluralName = "Notes"
  newName = "New Note"

  lastRoute = "notes"

  preview(props){
    return <NotePreview {...props}/>
  }
}

export default Notes
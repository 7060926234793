import React from 'react'
import './iconfont/material-icons.css'
import { ClassList } from '../../delegates/ClassNameDelegate';
import { ColorsDelegate } from '../../delegates/ColorsDelegate';

/**
 * Simple icon component.
 * @todo deployed version may want to use cdn, though not localy
 * 
 * @param {void} primary If true, the button will be style with the app's primary color
 * @param {string} icon Required. The mdi icon to show.
 * @param {string} color Optional color in hex format string.
 * 
 * @see https://material.io/resources/icons/?style=baseline
 */
const Icon =(props)=> {
  let styles={}
  let classes = ["material-icons"]

  if (props.styles) styles = {...props.styles}
  if (props.small){ classes.push("small") }

  ColorsDelegate(props,classes,styles,{notBackground:true})
  return (
    <i className={ClassList(classes)} style={styles}>{props.icon}</i>
  )
}

export default Icon
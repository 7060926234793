import React from 'react'

import IconButton from '../../../../../platform/components/buttons/iconButton/IconButton';
import ColorDot from '../../../../../platform/components/pickers/colorPicker/colorDot/ColorDot';
import BoardColors from '../../BoardColors';

import ColorPicker from '../../../../../platform/components/pickers/colorPicker/ColorPicker';
import { MenuItem } from '../../../../../platform/components/menu/Menu';

import './Palette.css'

class Palette extends ColorPicker {
  colors = BoardColors.colors;
  
  changeColorHandler =(color)=>{
    if (!this.mounted) return
    this.setState({showMenu : false})
    if (this.props.changed) this.props.changed(color.id)
  }

  get menuHeight(){
    if (!this.colors || !this.colors.length) return 0
    return this.colors.length * 52 + 32
  }

  get menuStyleTweaks(){
    return [ {property : "margin", value : "8px 0px"} ]
  }
  get additionalMenuClasses() {
    return "board-palette"
  }

  createMenuItem = (color) => {
    return (
      <MenuItem key={color.id}
        selected={this.props.value === color.id}
        hideMenu={this.hideMenuHandler}
        clicked={() => this.changeColorHandler(color)}>
          <ColorDot color={color}/>
          <span className="text">{color.name}</span>
      </MenuItem>
    )
  }

  getMenuContents(){
    return this.colors.map( this.createMenuItem )
  }

  getMenu(){
    let color = this.colors[this.props.value || 0].color;
    return (<div 
      className="md-whiteframe-2dp" 
      style={{margin: "auto 0", height: "40px", borderRadius: "6px 0px 0px 6px", backgroundColor: "white"}}>
        <IconButton icon="web_asset" color={color} clicked={()=>this.showMenuHandler()}/>
    </div>)
  }
}
export default Palette
import React from 'react'
import TextInput from '../../../../../platform/components/inputs/text/TextInput';
import CheckBox from '../../../../../platform/components/inputs/checkBox/CheckBox';

/**
 * Used to display a FieldIterator field in Edit Mode
 *
 * @prop {string} type - The type of field to display. Valid options are 'text' or 'checkbox'.
 * @prop {string} prop - The property to pull value from. Used only if value isn't.
 * @prop {Object} parent - The object to get the values from. Usually the parent state.
 * @prop {function} updateProp - Function used to update the parent state.
 * @prop {String} label - Label for the field. If omitted, prop will be used instead.
 * @prop {boolean | function} noLabel - If true, the label won't be shown.
 * @prop {boolean | function} visible - If true, the EditField won't be shown.
 * 
 * @todo Show label.
 * @todo Create a seperate placeholder prop from label.
 */
const EditField=({type = "input", prop, parent, updateProp, label, noLabel, visible, children})=>{
  if (visible === false) return null
  if (typeof visible === "function" && !visible()) return null
  
  if (type === "input") return(
    <TextInput
      autocomplete={Date.now()}
      fullWidth
      placeholder={label || prop} 
      value={parent[prop]}
      changed={ value => updateProp(prop,value) }/>
  )
  if (type === "checkbox") return(
    <CheckBox
      value={parent[prop]}
      changed={ value => updateProp(prop,value) }>
        {label || prop}
    </CheckBox>
  )
  if (type === "custom") return(
    {children}
  )
}

export default EditField
import React from 'react'

import UiTestPage, {UiTestSection, UiTestRow, UiTestRowItem} from '../UiTestPage';
import Chip from '../../components/chips/Chip';
import { ToastService } from '../../components/toast/Toast';
import FlatButton from '../../components/buttons/textButton/FlatButton';

class ChipsPage extends React.Component {

  state = {
    showRemoveChip : true
  }

  showToast=()=> {
    ToastService.showToast("Chip Clicked")
  }

  removeChip=()=> { this.setState({showRemoveChip : false}) }
  showRemoveChip=()=> { this.setState({showRemoveChip : true}) }

  render(){
    return (
      <UiTestPage>
        <UiTestSection name="Chips">
          <UiTestRow name="Normal Chips">
            <UiTestRowItem name="Chip">
              <Chip>Chip</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Primary">
              <Chip primary>Chip</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Accent">
              <Chip accent>Chip</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Warn">
              <Chip warn>Chip</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Colored">
              <Chip color="#9c27b0">Chip</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Icon">
              <Chip icon="place">Place</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Avatar" failed>
              <Chip avatar>John</Chip>
            </UiTestRowItem>
          </UiTestRow>

          <UiTestRow name="Chips Dark" dark>
            <UiTestRowItem name="Chip">
              <Chip>Chip</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Primary">
              <Chip primary>Chip</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Accent">
              <Chip accent>Chip</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Warn">
              <Chip warn>Chip</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Colored">
              <Chip color="#9c27b0">Chip</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Icon">
              <Chip icon="face">Face</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Avatar">
              <Chip avatar>Bob</Chip>
            </UiTestRowItem>
          </UiTestRow>
        
          <UiTestRow name="Chip Buttons">
            <UiTestRowItem name="Button">
              <Chip clicked={this.showToast}>Toast</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Disabled">
              <Chip clicked={this.showToast} disabled={true}>Chip</Chip>
            </UiTestRowItem>
            <UiTestRowItem name="Remove Button">
              { this.state.showRemoveChip
                ? <Chip removed={this.removeChip}>Delete Me</Chip>
                : <FlatButton clicked={this.showRemoveChip}>ReShow Chip</FlatButton>
              }
            </UiTestRowItem>
          </UiTestRow>
        </UiTestSection>
      </UiTestPage>
    )
  }
}
export default ChipsPage
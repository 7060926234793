/**
 * @todo - make this use a single interval and simply check on those that are watched
 */
export default class ScrollingService {
  
  static init() {
    if (!window.ScrollingService){
        window.ScrollingService = new ScrollingService()
    }
    return window.ScrollingService
  }
  
  constructor(){
    this.registrations = {};
    this._registerKey = 0;
  }  

  static watch(registerKey){
    const registered = ScrollingService.registrations[registerKey]
    ScrollingService.stopWatch(registerKey)

    let rect = registered.element.getBoundingClientRect()
      registered.left = rect.left
      registered.top = rect.top
    
    registered.interval = setInterval(ScrollingService.scrollDetection, 100, registerKey)
    registered.watching = true
  }

  static stopWatch(registerKey){
    const registered = ScrollingService.registrations[registerKey]
    if (registered.interval){
      window.clearInterval(registered.interval)
      registered.interval = null
      registered.watching = false
    }
  }

  /**
   * Every 1/10th of a second, the element is checked for movement. If the initial, the callback
   * fires and  closes and the interval is cleared.
   */
  static scrollDetection =registerKey=> {
    const registered = ScrollingService.registrations[registerKey]
    if (!registered) return ScrollingService.unregister(registerKey)
    let rect = registered.element.getBoundingClientRect()

    if (rect.left !== registered.left || rect.top !== registered.top){
      ScrollingService.detectScroll(registered)
    }
  }

  /**
   * Called when the element scrolls. This method should only fire once.
   */
  static detectScroll(registered){
    window.clearInterval(registered.interval)
    registered.watching = false
    registered.callback()  
  }

  /**
   * Registers an element for the scolling service. A registered element can watch 
   * the element. Make sure to unregister the element when it is unmounted.
   * 
   * @param {*} element HTML element
   * @param {*} callback
   * @return the index of element in the ScrollingService
   */
  static register=(element,callback)=>{
    ScrollingService.init()
    const registrationKey = ScrollingService._registerKey
    ScrollingService.registrations[registrationKey] = {
      element : element,
      callback : callback
    }
    return registrationKey;
  }

  /**
   * Removes the registered element for the scolling service and removes its
   * interval if it has one.
   */
  static unregister=(registration)=>{
    if (window.ScrollingService.registrations){
      delete window.ScrollingService.registrations[registration];
    }
  }

  static get registrations(){
    return window.ScrollingService.registrations
  }
  static get _registerKey(){
    return window.ScrollingService._registerKey++
  }
}
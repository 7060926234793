import React from 'react'
import Icon from '../components/icons/Icon';

/**
 * Returns a Icon Component if the props has an icon
 * @param {*} props 
 * @param {*} iconStyles Additional styles to pass to the Icon
 * 
 * @todo `small` shouldn't be hardcoded to true
 */
export const IconDelegate =(props,iconStyles)=> {
  return props.icon ? <Icon small styles={iconStyles} icon={props.icon}/> : null 
}
import React from 'react'

import Icon from '../../icons/Icon'

import './FAB.css'
import { ClassList } from '../../../delegates/ClassNameDelegate'
import { ColorsDelegate } from '../../../delegates/ColorsDelegate'

const Fab =(props)=> {
  let classes = ["floating-action-button"]
  let styles = {}

  ColorsDelegate(props,classes,styles)

  let iconProps = {...props}
    iconProps.color = "black"

  if (props.color || props.darkColor || props.primary || props.warn) {
    iconProps.color = "white"
  }
  if (props.disabled){
    classes.push("disabled")
    iconProps.color = iconProps.color === "black" ? "rgba(0,0,0,0.26)" : "rgba(255,255,255,0.26)"
  }

  if (props.mini) classes.push("mini")
  if (props.className) classes.push(...props.className.split(" "))

  return (
    <button className={ClassList(classes)} style={styles} onClick={()=>props.clicked && props.clicked()}>
      <Icon {...iconProps}/>
    </button>
  )
}

export default Fab
class BoardColors {
 static colors = [
    {id: 0, name: "Green", color : "#00C853", background: "#a4bdfc"},
    {id: 1, name: "Purple", color : "#AB47BC", background: "#7ae7bf"},
    {id: 2, name: "Violet", color : "#9575CD", background: "#dbadff"},
    {id: 3, name: "Pink", color : "#FF5252", background: "#ff887c"},
    {id: 4, name: "Yellow", color : "#FFC400", background: "#fbd75b"},
    {id: 5, name: "Orange", color : "#FF3D00", background: "#ffb878"},
    {id: 6, name: "Blue", color : "#2979FF", background: "#46d6db"},
    {id: 7, name: "Flint", color : "#616161", background: "#e1e1e1"},
    {id: 8, name: "Indigo", color : "#283593", background: "#5484ed"},
    {id: 9, name: "Pine", color : "#388E3C", background: "#51b749"},
    {id: 10, name: "Ruby", color : "#D50000", background: "#dc2127"}
 ]
}

export default BoardColors
import React from 'react'

import Icon from '../../../components/icons/Icon'
import { ColorsDelegate } from '../../../delegates/ColorsDelegate'
import { ClassList } from '../../../delegates/ClassNameDelegate'

const TextButton =(props)=> {
  let classes = [...props.buttonClass.split(" ")]

  let styles = {}
  let iconStyles = { margin : "auto 8px auto -4px" }

  if (props.buttonStyles) styles = {...props.buttonStyles}
  ColorsDelegate(props,classes,styles,{notBackground:props.notBackground})
  
  if (props.flex) classes.push("flex")
  if (props.disabled) classes.push("disabled")
  if (props.className) classes.push(...props.className.split(" "))

  return (
    <button className={ClassList(classes)} style={styles}
      onClick={()=> props.clicked && props.clicked() }>
      {props.icon ? <Icon small styles={iconStyles} icon={props.icon}/> : null }
      {props.children}
    </button>
  )
}

export default TextButton
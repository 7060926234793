import React from 'react'
import Icon from '../../components/icons/Icon'
import { SideNavigationService } from './SideNavigationDrawer';

/**
 * A button to open the Navigation Drawer.
 * @param {string} icon Optional. Mdi icon to show.
 */
const NavigationButton =(props)=> {
  let classes = "icon-button"
  if (props.classes) classes+= " " + props.classes
  
  let styles = {}

  if (!props.accent && !props.warn) classes += " primary"
  if (props.accent) classes += " accent"
  if (props.warn) classes += " warn"
  
  if (props.disabled) { 
    classes += " disabled"
    styles.opacity = ".26"
  }
  
  if (props.className) classes += " " + props.className
  let childProps = {...props}
  let icon = props.icon || "menu"

  return (
    <button className={classes} onClick={()=>SideNavigationService.showDrawer()}>
      <Icon styles={styles} icon={icon} primary={props.primary} props={childProps}/>
    </button>
  )
}

export default NavigationButton
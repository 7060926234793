import '../text/TextInput.css'
import NumberInput from '../number/NumberInput';

/**
 * Date formated TextInput
 */
class DateInput extends NumberInput {

  mask="##/##/####-"
  fomatMask="__/__/____"
  
  validateDate =(oldValue, newValue) => {
    if (newValue !== 0 && newValue !== "0" && !newValue) return ""
    
    const newLength = newValue.length
    const oldLength = oldValue !== undefined ? oldValue.length : 0
    if (newLength < oldLength) return newValue
    if (newLength > 10) return oldValue

    const lastCharacter = newValue.substr(newLength-1,1)
    const lastInMask = this.mask.substr(newLength-1,1)
    const nextInMask = this.mask.substr(newLength,1)

    switch(lastInMask){
      case "#": 
        return isNaN(lastCharacter) ? oldValue 
        : newValue + (nextInMask === "/" ? "/" : "")
      case "/":
        return isNaN(lastCharacter) 
        ? (lastCharacter === "/" ? oldValue : newValue)
        : newValue.substr(0,newLength-1) + "/" + lastCharacter
    }
    return newValue
  }

  validate =(oldValue, newValue) => {
    return this.validateDate(oldValue, newValue)
  }
 
}

export default DateInput
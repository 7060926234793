import React from 'react'

import './Lane.css'
import TextInput from '../../../../../platform/components/inputs/text/TextInput';
import FlatButton from '../../../../../platform/components/buttons/textButton/FlatButton';
import Menu, { MenuItem } from '../../../../../platform/components/menu/Menu';

export default class Lane extends React.Component{

  changeName =(value)=>{
    this.props.changeLaneField(value,"name",this.props.id)
  }
  saveName =(value)=>{
    this.props.saveLaneField(value,"name",this.props.id)
  }

  render(){
    let classes = "board-lane"

    if (this.props.reorderStatus === "target")
      classes += " reorder-target"
    if (this.props.reorderStatus === "cardTarget")
      classes += " reorder-target card-target"

    return (
      <div className={classes}>
        { this.LaneHeader }
        { this.LaneBody }
        { this.LaneFooter }
        { this.ReorderingOverlay }
      </div>
    )
  }

  get ReorderingOverlay(){
    if (this.props.reorderStatus === false) return null
    return (
      <div className="board-lane-reordering-area" onClick={this.props.endReorder}/>
    )
  }

  get LaneHeader(){
    return (
      <div className="board-lane-header">
        <TextInput fullWidth value={this.props.name} changed={this.changeName} blurred={this.saveName} noLabel/>
        <Menu left>{this.MenuItems}</Menu>
      </div>
    )
  }

  get MenuItems(){
    const {laneId,canReorder,reorderLane,removeLane} = this.props
    const menuItems = []

    if (canReorder) menuItems.push(<MenuItem clicked={reorderLane} key={menuItems.length}>Reorder</MenuItem>)
    menuItems.push(<MenuItem clicked={removeLane} clickedArgs={laneId} key={menuItems.length}>Remove</MenuItem>)
    
    return menuItems
  }

  get LaneBody(){
    return (
      <div className="board-lane-body">
        {this.props.cards}
      </div>
    )
  }
  
  get LaneFooter(){
    return (
      <div className="board-lane-footer">
        <FlatButton primary clicked={this.props.addCard}>Add Card</FlatButton>
      </div>
    )
  }
}
import React from 'react'
import FlatButton from '../../../../platform/components/buttons/textButton/FlatButton'
import Icon from '../../../../platform/components/icons/Icon';

import { BodyText, SubtitleText } from '../../../../platform/components/typography/Typography';
import TextInput from '../../../../platform/components/inputs/text/TextInput';
import Menu, { MenuItem } from '../../../../platform/components/menu/Menu';
import IconButton from '../../../../platform/components/buttons/iconButton/IconButton';

import './MultiAddItem.css'

/**
 * @prop {function} available - Optional function that determines if the item can add/edit items.
 * @prop {function} itemAvailable - Optional function to run on each item determines if the item can be editted/deleted. 
 * The item will be passed into function as parameter with its id as a field on the item.
 * 
 * @todo Document
 */
class MultiAddItem extends React.Component{
  
  state = {
    mode : "summary",
    currentItem : null
  }

  /**
   * Gets the items from props and converts them into a
   * sorted array. Due to react not deleting keys, also
   * checks for items without values. 
   */
  get items(){
    if (!this.props.items) return null
    let keys = Object.keys(this.props.items)
    if (keys.length < 1) return null

    return keys.filter( key =>
      this.props.parent[key] !== undefined &&
      this.props.parent[key] !== null)
    .map( key => ({...this.props.parent[key], id: key}) )
  }

  get available(){
    if (this.props.available === undefined || this.props.available === null || this.props.available === "" ||
      this.props.available === true) return true;

    if (typeof this.props.available === "function") return this.props.available()
    return this.props.available
  }

  itemAvailable(item){
    if (typeof this.props.itemAvailable === "function") return this.props.itemAvailable(item)
    if (this.props.itemAvailable === undefined) return true
    throw console.error(`Passing MultiAddItem a prop itemAvailable with type '${typeof this.props.itemAvailable}' is not supported.`);
  }

  /**
   * Determines which view the item is in.
   */
  get currentView(){
    if (!this.available) return this.summaryView
    switch(this.state.mode){
      case "summary" : return this.summaryView
      case "add" : return this.editView
      case "edit" : return this.editView
      default : return this.summaryView
    }
  }
  get currentButtons(){
    if (!this.available) return null
    switch(this.state.mode){
      case "summary" : return this.addButton
      case "add" : return this.addAndCancelButton
      case "edit" : return this.saveAndCancelButton
      default : return null
    }
  }

  /**
   * Summary Mode
   */
  summaryMode=()=>{ 
    this.setState({mode : "summary", currentItem : null}) 
  }
  get summaryView(){
    return ( this.items && this.items.length > 0 ?
      this.summaryList() : this.emptySummary() )
  }
  summaryList=()=> {
    return this.items.map( item => this.summaryItemView(item) )
  }
  summaryItemView=(item)=> {
    const available = this.itemAvailable(item)

    return (
      <div className="multi-add-item_summary" key={item.id}>
        <div className="flex column" onClick={ ()=>available && this.editMode(item.id) }>
          <span style={{lineHeight: "24px"}} className="typography-body">
            {item[this.props.lines[0].prop]} </span>
          <span style={{lineHeight: "24px"}} className="typography-body-medium">
            {item[this.props.lines[1].prop]}</span>
        </div>
        { available && this.props.itemMenu && this.props.itemMenu.length &&
          <Menu dotsVertical>
            { this.props.itemMenu.map( menuItem =>
              <MenuItem primary clicked={()=>menuItem.clicked(item)}>
                {menuItem.label}
              </MenuItem>
            )}
          </Menu>
        }
      { available && this.props.itemButton && !(this.props.itemMenu && this.props.itemMenu.length) &&
        <IconButton clicked={()=>this.props.itemButton.clicked(item)}
          icon={this.props.itemButton.icon}/>
      }
    </div>
  )}
  emptySummary =()=> {
    return (
    <div style={{marginLeft: "38px"}}>
      <BodyText>{this.props.emptyMessage || "No items"}</BodyText>
    </div>)
  }
  get addButton(){
    return (<div className="multi-add-item__actions">
      <FlatButton primary clicked={this.addMode}>{this.props.addLabel}</FlatButton>
      <span className="flex"/>
    { this.props.secondButton && 
      <FlatButton primary clicked={this.props.secondButton.clicked}>
        {this.props.secondButton.label}
      </FlatButton>
    }
    </div>)
  }

  /**
   * Add Mode
   * 
   * This mode is used to handle new items. The save button
   * for add mode will call addItem()
   */
  addMode=()=>{ 
    this.setState({mode : "add", currentItem : {} })
  }
  addItem=()=>{
    this.props.addItem(this.state.currentItem)
    this.summaryMode()
  }
  get addAndCancelButton(){
    return (<div className="multi-add-item__actions">
      <FlatButton primary clicked={this.addItem}>Save</FlatButton>
      <span className="flex"/>
      <FlatButton primary clicked={this.summaryMode}>Cancel</FlatButton>
    </div>)
  }

  /**
   * Edit Mode
   * 
   * This mode is used to handle new items. The save button
   * for add mode will call saveItem()
   */
  editMode=(itemId)=>{
    this.setState({mode : "edit", 
      currentItem : {...this.props.parent[itemId], id : itemId}
    })
  }
  saveItem=()=>{
    this.props.saveItem(this.state.currentItem)
    this.summaryMode()
  }
  get editView(){
    return ( this.props.lines ? this.editLines() : <div/> )
  }
  editLines=()=> {
    return this.props.lines.map( (line,i) =>
      this.editLine(line,i)
    )
  }
  editLine=(line,index)=>{
    return (<div key={index} className="multi-add-item__edit-line">
      <TextInput
        fullWidth
        placeholder={line.label || line.prop} 
        value={this.state.currentItem[line.prop]}
        changed={ value =>this.updateProp(line.prop,value) }/>
    </div>)
  }
  get saveAndCancelButton(){
    return (<div className="multi-add-item__actions">
      <FlatButton primary clicked={this.saveItem}>Save</FlatButton>
      <span className="flex"/>
      <FlatButton primary clicked={this.summaryMode}>Cancel</FlatButton>
    </div>)
  }

  /**
   * Modifies a line in an item
   */
  updateProp=(prop,value)=>{
    this.setState({currentItem : {
        ...this.state.currentItem,
        [prop] : value
      }
    })
  }

  render(){
    return (
      <div className="multi-add-item">
        <div className="row">
          <Icon icon={this.props.icon} color="rgba(0,0,0,.54)" />
          <div style={{lineHeight: "48px", flex : "1", paddingLeft : "14px"}}>
            <SubtitleText>{this.props.label}</SubtitleText>
          </div>
        </div>
        <div className="column flex">
          { this.currentView }
        </div>
        <div className="column flex">
          { this.currentButtons }
        </div>
      </div>
    ) 
  }
}

export default MultiAddItem
import React from 'react'
import Badge from '../../../../platform/components/badges/Badge';
import User from '../../../User/User';

/**
 * Indicator for unread messages.
 */
export default class MessageIndicator extends React.Component {
  state = {
    unreadMessages : null
  }

  get unreadMessagesSource(){
    return this.props.messageSource || "unreadMessages"
  }

  componentDidMount(){
    User.watchDataChanges( data => data && data[this.unreadMessagesSource] &&
      this.setState({unreadMessages : data[this.unreadMessagesSource]})
    )
  }

  render(){
    const {unreadMessages} = this.state

    if (!this.state.unreadMessages) return null

    return (
      <div>
        <Badge primary>{unreadMessages}</Badge>
      </div>
    )
  }

}
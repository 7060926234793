import React from 'react'

import TextInput from '../../../../../../platform/components/inputs/text/TextInput';

import './QuickListSection.css';
import Divider from '../../../../../../platform/components/dividers/Divider';
import QuickListItem from '../item/QuickListItem';

class QuickListSection extends QuickListItem {
  
  render(){
    let itemClasses = "quicklist-section"
    let itemReorderingclasses = "quicklist-section-reordering-area"
    if (this.props.reorderStatus === "target") itemReorderingclasses += " target"
    if (this.state.selected || this.props.item.$new) itemClasses += " selected"

    return (<>
      <div className={itemClasses}
        key={this.props.id}>
        <div style={{display : "flex", flexDirection: "row"}}>
          <div style={{display : "flex", flexDirection: "row", flex: "1", marginLeft: "72px"}}
            ref="item" onClick={()=>this.select()}>
            <TextInput
              noLabel
              fullWidth
              placeholder="Item name"
              value={this.props.item.name}
              blurred={ this.blurName }
              changed={ this.changeName }
              pressed={this.keyDown} />
            {  
              this.props.reorderStatus === false ? null :
              <div className={itemReorderingclasses}
                onClick={this.props.endReorder}/>
            }
          </div>
          <div className="quick-list-section__right-spacer"/>
        </div>
      </div>
      <Divider/>
      </>
    )
  }
}

export default QuickListSection
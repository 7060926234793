import React from 'react'

import './CheckBox.css'
import CheckMark from './CheckMark'
import { ColorsDelegate } from '../../../delegates/DisabledDelegate';
import { ClassList } from '../../../delegates/ClassNameDelegate';

/**
 * @prop {boolean} value - whether the switch is on or off
 * @prop {function} changed - function executed when the switch changes.
 * @prop {String} color - If present, the color is used for the switch.
 * @prop {boolean} primary - If present, will use the primary color.
 * @prop {boolean} accent - If present, will use the accent color.
 * @prop {boolean} warn - If present, will use the warn color.
 * @prop {boolean} disabled - If true, the switch is disabled.
 * 
 * @todo support readOnly
 */
class CheckBox extends React.Component {

  check=()=>{
    if (this.props.changed && !this.props.readonly && !this.props.disabled) this.props.changed(!this.props.value)
  }

  render(){
    var boxClasses = ["checkbox-box"]
    var boxStyles = {}
    var checkClasses = ""

    ColorsDelegate(this.props,boxClasses,boxStyles)

    if (this.props.disabled) boxClasses.push("disabled")
    if (this.props.value) {
      boxClasses.push("checked")
      checkClasses+=" checked"
    }

    // Handle the color prop
    if (this.props.color){
      if (this.props.value){
        boxStyles.borderColor = this.props.color
      } else {
        delete boxStyles.backgroundColor
      }
    }

    return (<div className="checkbox-container">
      <label>
        <input className="checkbox-input" type="checkbox" checked={this.props.value} onChange={this.check}/>
        <div className={ClassList(boxClasses)} style={boxStyles}/>
        <div className={`checkbox-box-check ${checkClasses}`}>
          <CheckMark color="white"/>
        </div>
      </label>
      <div className="checkbox-label">
        {this.props.children}
      </div>
    </div>)
  }
}

export default CheckBox
import React from 'react'
import PopupService from './PopupService';

export default class PopupArea extends React.Component {
  mounted = false
  state = {
    popup : null,
    shown : false,
  }

  componentDidMount(){
    this.mounted = true
    PopupService.bindPopupArea(this)
  }
  componentWillUnmount(){
    PopupService.unbindPopupArea(this)
    this.mounted = false
  }

  render(){
    return(
      <div tabIndex="-1">
        {this.popup}
      </div>
    )
  }
  get popup(){
    if (this.state.shown === false) return null
    return this.state.popup
  }
}
export { PopupService }
import React from 'react'

import FilePicker from '../../../platform/components/file/picker/FilePicker'
import UiTestPage, {UiTestSection} from '../UiTestPage';

import firebase from 'firebase'
import FlatButton from '../../components/buttons/textButton/FlatButton';

/**
 * FileUpload page is a demo of the FilePicker component.
 */
class FileUploadPage extends React.Component {
    
  state = {
    text : undefined
  }

  componentDidMount(){
    this.storageRef = firebase.storage().ref();
  }

  storeFoo=(file)=>{
    this.storageRef.child("foo").put(file).then(function(snapshot) {
      console.log(snapshot);
    });
  }
  
  saveResults=()=>{
    this.storeFoo(this.file)
  }

  changedFile=(files)=>{
    this.setState({foo:"bar"})
    this.file = files[0]

    FilePicker.fileReader(files[0])
    .then( (result) => {
        console.log(this)
        this.setState({text : result})
    })
  }

  render(){
    return (
      <UiTestPage>
        <UiTestSection name="File Storage">
          <div style={{display: "flex", flexDirection: "row", justifyItems: "center"}}>
            <FilePicker changed={this.changedFile}/>
          </div>
          <div>Results: {this.state.text}</div>
          { this.state.text && 
            <FlatButton clicked={this.saveResults}>Save</FlatButton> 
          }
        </UiTestSection>
      </UiTestPage>
    )
  }

}

export default FileUploadPage
import React from 'react'

import ShareableViews from '../../../base/view/list/shareable/ShareableViews';
import BoardPreview from './preview/BoardPreview';

class Boards extends ShareableViews {
  mounted = false

  shareablePrefix = "board"
  viewNode = "boards"
  shareableNode = "board"
  shareableName = "Board"
  pluralName = "Boards"
  newName = "New Board"

  lastRoute = "boards"

  preview(props){
    return <BoardPreview {...props}/>
  }
}

export default Boards
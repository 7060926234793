import React from 'react'
import './Badge.css'
import { ColorsDelegate } from '../../delegates/ColorsDelegate';
import { ClassList } from '../../delegates/ClassNameDelegate';
import { IconDelegate } from '../../delegates/IconDelegate';

/**
 * Material Design Badge
 * https://getmdl.io/components/#badges-section
 * 
 * @prop color - Optional color for chip. Defaults to gray.
 * @prop {String} color - If present, the color is used for the switch.
 * @prop {boolean} primary - If present, will use the primary color.
 * @prop {boolean} accent - If present, will use the accent color.
 * @prop {boolean} warn - If present, will use the warn color.
 * 
 * @prop {IconName} icon - Optional - Icon to show
 */
const Badge =(props)=> {
  let classes = ["badge"]
  let styles = {}
  let icon = IconDelegate(props)

  ColorsDelegate(props,classes,styles)
  
  return (
    <div style={styles} className={ClassList(classes)}>
      <div className="badge__text">{icon || props.children}</div>
    </div>
  )
}
export default Badge
import React from 'react'
import { Screen, Header, BackButton, Body, FabArea, Title } from '../../../screen/AppScreen';
import Menu, { MenuItem } from '../../../../../platform/components/menu/Menu';
import Fab from '../../../../../platform/components/buttons/FAB/FAB';

import { Redirect } from 'react-router';

import { ToastService } from '../../../../../platform/components/toast/Toast';
import FlatButton from '../../../../../platform/components/buttons/textButton/FlatButton';
import Popup, { PopupHeader, PopupSubHeader } from '../../../../../platform/components/popups/popup/Popup';
import Icon from '../../../../../platform/components/icons/Icon';
import NavigationButton from '../../../../../platform/components/sideNavigation/SideNavigationButton';
import { Transaction, Model, Collection, newKey, databaseRef } from '../../../../../TransactaFire/TransactaFire';

import FeatureView from './view/FeatureView';
import FeatureViewFolder from '../../list/folder/FeatureViewFolder';
import User from '../../../../User/User';
import ShareItemPopup from './ShareItemPopup';

import "./ShareableViews.css"
import "./FolderOption.css"
import UndoToastButton from '../../../../../platform/components/toast/buttons/undo/UndoToastButton';
import UndoableActions from '../../../../undoable/UndoableActions';
import Shareable from './ShareableCollection';
import Item from '../../../../../TransactaFire/Item';
import PopupArea, { PopupService } from '../../../../../platform/components/popups/PopupArea';

class ShareableViews extends React.Component {
  mounted = false
  state = {
    views : [],
    folders : [],
    hidden : {},
    reorderingItem : false,
    showMoveToFolderPopup : false,
  }

  shareablesRef = databaseRef()

  get viewsRef(){
    if (!this._viewsRef) this._viewsRef = databaseRef(`${User.uid}/${this.viewNode}`)
    return this._viewsRef
  }

  getReactRouterParam(){
    return this.props.match.params.folderId
  }

  /**
   * Marks the component as unmounted to prevent memory leak from 
   * any async operations.
   * 
   * @todo Remove reordering
   */
  componentWillUnmount =()=> {
    this.mounted = false
    this.viewsRef.off()
  }

  /**
   * Pass component updates to method to check for folder state
   * changes.
   */
  componentDidUpdate(prevProps,prevState) {
    this.updateFolderOnStateChange(prevProps,prevState)
  }

  /**
   * Marks the component as mounted.
   */
  componentDidMount(){
    this.mounted = true
    this.attachViewRef()
    this.updateFolder()
    this.updateLastRoute()
  }

  /**
   * Handles pointing the user at the last route
   */
  updateLastRoute(){
    if (this.lastRoute) User.updateRoute(this.lastRoute)
  }

  /**
   * Attaches the Firebase ref to the component with an on value
   * watcher. Whenever the ref changes the state will be updated.
   */
  attachViewRef(){
    this.viewsRef.on('value', snapshot => {
      if (this.mounted && typeof snapshot.val() === "object"){
        let snapshotState = {...snapshot.val()}
        this.mounted && this.setState({...snapshotState})
      }
    })
    this.contactsRef = new Item(this,`${User.uid}/contacts`,"contacts")
  }

  /**
   * Using a redirect to change the folder causes problems, so instead
   * the state is handled by naivgation directly. This method is used
   * to handle whenever the user navigates within the 
   */
  updateFolderOnStateChange(prevProps,prevState) {
    if (!prevState.folderId) return this.updateFolder()
    if (prevProps.match.params.folderId !== this.props.match.params.folderId)
      this.updateFolder()
  }

  /**
   * Saves the folder url param to state, defaulting to base if its null
   */
  updateFolder(){
    let folderId = this.getReactRouterParam() || "base"
    this.mounted && this.setState({folderId : folderId})
  }

  /**
   * Creates a new shareable of the type specified by the child extending
   * this class.
   * 
   * The shareable gets stored in a type-prefixed GUID. The creator gets a
   * view of the shareable which is stored in their list of shareables of
   * that type.
   */
  newShareable = async() => {
    const viewsKey = `${this.shareablePrefix}_${newKey()}`
    const uid = User.uid
    const user = { name : User.userName }

    const transaction = new Transaction()
    const shareableModel = transaction.add(new Model.newAtPath(viewsKey))
    const viewsCollection = transaction.add(new Collection.newAtPath(`${User.uid}/${this.viewNode}`, this.viewNode, `${this.shareablePrefix}_`))

    const view = { 
      link : viewsKey,
      name : this.newName,
      owner : uid,
      parent : (this.state.folderId && this.state.folderId !== 'base')
        ? this.state.folderId 
        : null
    }
    await viewsCollection.addToCollection(view, null, viewsKey)

    const shareable = { 
      name : this.newName,
      readers : { [uid] : 1},
      writers : { [uid] : 1},
      owner : uid,
      users : { [uid] : user }
    }
    shareableModel.set(shareable)

    transaction.commit()
  }

  /**
   * If the item doesn't have a Share Link, creates a deeplink and
   * and updates the item to point at it.
   * 
   * @todo Move out of the view into own class
   */
  itemShareLink=(id)=>{
    return new Promise( (resolve, reject) => {
      let item = Model.newAtPath(id)

      item.loaded.then( ()=> {
        if (item._data.invite) return resolve(item._data.invite.url);
  
        let type = this.shareableNode
        let typeName = this.shareableName
        let collection = this.viewNode

        let transaction = new Transaction()
        let deepLinkKey = this.shareContactDeepLink(transaction,type,typeName,collection,item,id)
        let deepLinkUrl  = this.addContactDeepLinkToItem(transaction,type,item,id,deepLinkKey)
  
        transaction.commit().then( ()=> resolve(deepLinkUrl) )
      })
    })
  }

  /**
   * Creates a deeplink and returns the deeplink's key
   * @param {*} transaction
   * @return {String} **deepLinkKey** The key to create the deeplink url
   */
  shareContactDeepLink=(transaction,type,typeName,collection,item,id)=>{
    let deepLinkKey = newKey(`shareItemLink_`)
    let model = transaction.add(Model.newAtPath(deepLinkKey))
    
    model.set({ 
      id : id,
      name : item._data.name,
      type : type,
      typeName : typeName,
      collection : collection,
      permission : "edit",
    })
    return deepLinkKey
  }

  /**
   * Adds the deeplink information to the item
   * @param {*} transaction
   * @param {*} deepLinkKey
   * @returns {String} The deeplink url to add this user as a contact
   */
  addContactDeepLinkToItem=(transaction,type,item,id,deepLinkKey)=>{
    let deepLinkUrl  = `deeplink/landing/${type}/${deepLinkKey}`
    //let deepLinkUrl = `https://www.planreach.com/deeplink/contactShare/${deepLinkKey}`

    transaction.add(item)
    item.change("invite", {
      created : (new Date()).getTime(),
      id : deepLinkKey,
      url : deepLinkUrl,
      permission : "edit",
      shareLink : true
    })
    return deepLinkUrl
  }

  /**
   * Marks a view to be reordered.
   */
  startReorderView =(id)=> {
    if (!this.mounted) return
    this.setState({
      reorderingItem : id,
      reorderingFolder : false
    })
  }

  /**
   * Ends reordering. If a different view was clicked, the view is
   * reordered to it.
   */
  endReorderView = async(id) => {
    if (!this.mounted) return
    if (!!id || !!this.state.reorderingItem || id !== this.state.reorderingItem){
      const transaction = new Transaction()
      const viewsModel = transaction.add(new Collection(this.viewsRef, this.viewNode, `${this.shareablePrefix}_`))
      
      await viewsModel.reorderItems(this.state.reorderingItem, id)
        transaction.commit()
    }
    this.setState({ reorderingItem : false})
  }

  /**
   * Marks a folder to be reordered.
   */
  reorderFolder =(id)=> {
    if (!this.mounted) return
    this.setState({
      reorderingItem : false,
      reorderingFolder : id
    })
  }

  /**
   * Ends reordering. If a different folder was clicked, the view is
   * reordered to it.
   */
  endReorderFolder = async(id) => {
    if (!this.mounted) return
    
    if (!!id && !!this.state.reorderingFolder && id !== this.state.reorderingFolder){
      const transaction = new Transaction()
      const foldersModel = transaction.add(new Collection(this.viewsRef, "folders", `folder_`))

      await foldersModel.reorderItems(this.state.reorderingFolder, id)
        transaction.commit()
    }    

    return this.setState({ reorderingFolder : false })
  }

  /**
   * Removes the view and shows an undo toast. If the toast is not clicked,
   * the view is deleted.
   */
  removeView =(viewId, unshare)=> {
    this.hideItem(viewId)

    const msg = `"${this.state[viewId].name}" was removed`
    const removalAction = unshare ? this.unshareView : this.deleteView

    const act = UndoableActions.addAction( removalAction, this.showItem, viewId )
      const action =()=> UndoableActions.action(act)
      const undo =()=> UndoableActions.undo(act)
    
    ToastService.showToast( msg, <UndoToastButton action={undo}/>, 3000 ).then(action)
  }

  /**
   * Deletes a Shareable and its view
   */
  deleteView = async(id) => {
    const transaction = new Transaction()
    const beforeCommit = []

    const shareable = transaction.add(new Model(this.shareablesRef.child(id),null,true))
    const shareableData = await shareable.loaded
    const users = this.getShareableReaders(shareableData)

    users.forEach( uid => {
      const ref = databaseRef(`${uid}/${this.viewNode}`)
      const userShareableViews = transaction.add(new Collection(ref, this.viewNode, `${this.shareablePrefix}_`))
      beforeCommit.push(userShareableViews.deleteItems([id]))
    })

    this.deleteSharelink(shareableData.invite,transaction)
    shareable.remove()

    await Promise.all(beforeCommit)
    transaction.commit()
  }

  /**
   * Deletes a the current user's View for a Shareable
   */
  unshareView = async(id) => {
    const transaction = new Transaction()
    const shareable = transaction.add(new Model(this.shareablesRef.child(id),null,true))

    shareable.change(`readers/${User.uid}`, null)
    shareable.change(`writers/${User.uid}`, null)
    shareable.change(`users/${User.uid}`, null)

    const views = transaction.add(new Collection(this.viewsRef, this.viewNode, `${this.shareablePrefix}_`))
    await views.deleteItems([id])

    transaction.commit()
  }

  getShareableReaders =(shareableData)=> {
    if (!shareableData) return null
    return Object.keys(shareableData.readers).filter(a => shareableData.readers[a] )
  }

  /**
   * Deletes the Shareable's deeplink if it exists
   */
  deleteSharelink =(invite,transaction)=> {
    if (!invite || !invite.id) return

    const shareLink = transaction.add(new Model.newAtPath(invite.id))
    shareLink.remove()
  }

  /**
   * Marks an item to be hidden.
   */
  hideItem =(id)=> {
    this.mounted && this.setState({hidden : {...this.state.hidden, [id] : true} })
  }

  /**
   * Marks an item to be visible.
   */
  showItem =(id)=> {
    this.mounted && this.setState({hidden : {...this.state.hidden, [id] : null} })
    ToastService.hideToast()
  }

  /**
   * Undo button shown in remove item toast
   */
  undoRemoveItemButton = viewId => (
    <FlatButton accent clicked={()=>this.showItem(viewId)}>Undo</FlatButton>
  )

  /**
   * Saves item's name change
   */
  saveNameChange = async(id,value) => {
    const transaction = new Transaction()
    const shareable = transaction.add( Model.newAtPath(id) )
    const users = (await shareable.loaded).readers

    shareable.change("name", value)

    Object.keys(users).forEach( user => {
      const view = transaction.add( Model.newAtPath(`${user}/${this.viewNode}/${id}`) )
      view.change("name",value)
    })

    transaction.commit()
  }

  /**
   * Saves a folder's name change
   */
  saveFolderNameChange =(id,value)=> {
    const transaction = new Transaction()
    const folder = transaction.add( Model.newAtPath(`${User.uid}/${this.viewNode}/${id}`) )
    folder.change("name", value)
    transaction.commit()
  }

  /**
   * @todo implement
   */
  changeName =(id,value)=> {
    this.setState({ [id] : {...this.state[id], name : value} })
  }

  /**
   * Moves view to a shareable
   */
  handleLink =(id)=> {
    if (!this.mounted) return
    this.props.history.push('/'+this.shareableNode+'/'+id)
    this.props.history.from = this.viewNode
  }

  /**
   * Moves view to a folder
   */
  handleFolderLink =(id)=> {
    if (!this.mounted) return
    this.props.history.push('/'+this.viewNode+'/'+id)
    this.setState({hasBack : true})
  }

  /**
   * If the last route was /views, moves back. Otherwise moves
   * to /views route.
   */
  handleFolderBackLink =(id)=> {
    if (!this.mounted) return
    if (this.state.hasBack){
      this.props.history.goBack()
      this.setState({hasBack : false})
    }
    else {
      this.props.history.push('/'+this.viewNode)
    }
  }

  /**
   * Add a new view folder.
   */
  newFolder =()=> {
    let transaction = new Transaction()
    let views = new Model(this.viewsRef,{...this.state})
      transaction.add(views)

    let folderKey = "folder_" + this.viewsRef.push().key
    let count = this.state.folders ? Object.keys(this.state.folders).length : 0
    let folders = {...this.state.folders}
      folders[folderKey] = count

    views.change(folderKey, { link : folderKey, name : "New Folder" })
    views.change("folders", folders)
    transaction.commit()
  }

  /** 
   * Show the MoveToFolderPopup
   */
  showMoveToFolderPopup =(itemId)=> {
    if (!this.mounted) return
    this.setState({showMoveToFolderPopup : itemId}, ()=>{
      PopupService.showPopup(this.MoveToFolderPopup())
    })
  }

  /** 
   * Show the MoveToFolderPopup
   */
  showShareItemPopup = async(itemId) => {
    if (!this.mounted) return
    await this.setState({showShareItemPopup : itemId})
    PopupService.showPopup(this.shareItemPopup())
  }

  hasFoldersToMoveTo =()=> 
    this.state.folders &&
    Object.keys(this.state.folders).filter( folder => !this.state.hidden[folder] ).length

  /**
   * Popup used to move a item into a folder
   */
  MoveToFolderPopup = () => (
    <Popup>
      <PopupHeader>Move to Folder</PopupHeader>
      <PopupSubHeader>{this.getMoveToFolderPopupItemName()}</PopupSubHeader>
      {/* Search box */}
      { this.moveToFolderPopupOptions() }
      {/* Footer: Search Button, Close Button*/}
    </Popup>
  )

  getMoveToFolderPopupItemName=()=>{
    if (!this.state.showMoveToFolderPopup) return null
    return this.state[this.state.showMoveToFolderPopup].name
  }

  shareItemPopup=()=>{
    let itemId = this.state.showShareItemPopup

    return <ShareItemPopup
      contacts={this.state.contacts}
      closePopup={this.closeShareItemPopup}
      itemShareLink={this.itemShareLink}
      type={this.shareableNode}
      typeName={this.shareableName}
      item={this.state[itemId]}
      id={itemId}
    />
  }

  /**
   * Gets a list of Folder View Options
   */
  moveToFolderPopupOptions=()=>{
    if (!this.state.folders) return null;

    const itemId = this.state.showMoveToFolderPopup

    return Object.keys(this.state.folders).map( folderId => {
      return this.viewFolderOption( {
        clicked : ()=>this.moveToFolder(itemId, folderId),
        name : this.state[folderId].name,
      })
    })
  }

  /**
   * View Folder Option react component
   */
  viewFolderOption = (props)=> {
    return (
      <div className="folder-option" onClick={props.clicked}>
        <Icon icon="folder" primary/>
        <div>{props.name}</div>
      </div>
    )
  }

  /**
   * Moves an item into a folder
   */
  moveToFolder =(itemId,folderId)=> {
    var transaction = new Transaction()
    let views = new Model(this.viewsRef,{...this.state})
      transaction.add(views)

    let item = {...this.state[itemId]}
    let folder = {...this.state[folderId]}

    item.parent = folderId
    views.change(itemId,item)

    if (!folder.items) folder.items = {}
    folder.items[itemId] = Object.keys(folder.items).length
    views.change(folderId,folder)

    transaction.commit()
    PopupService.closePopup()
  }

  /**
   * Moves an item up from a folder into the base folder
   * @todo implement fixing folder's item order
   */
  moveUpFromFolder =(itemId)=> {
    var transaction = new Transaction()
    let views = new Model(this.viewsRef,{...this.state})
      transaction.add(views)

    let item = {...this.state[itemId]}
    let folderId = item.parent
    let folder = {...this.state[folderId]}

    item.parent = null
      views.change(itemId,item)

    folder.items[itemId] = null
      views.change(folderId,folder)

    transaction.commit()
    PopupService.closePopup()
  }

  /**
   * Removes the view and shows an undo toast. If the toast is not clicked,
   * the view is deleted.
   * @tested Passed
   */
  removeFolder =(viewId)=> {
    this.hideItem(viewId)
    
    const msg = `Folder "${this.state[viewId].name}" was removed.`

    const act = UndoableActions.addAction( this.deleteFolder, this.showItem, viewId )
      const action =()=> UndoableActions.action(act)
      const undo =()=> UndoableActions.undo(act)
    
    ToastService.showToast( msg, <UndoToastButton action={undo}/>, 3000 ).then(action)
  }

  /**
   * Deletes a view folder
   * @tested Passed
   */
  deleteFolder =(id)=> {
    let transaction = new Transaction()
    let views = new Model(this.viewsRef,{...this.state})
      transaction.add(views)

    let position = this.state.folders[id]

    let itemOrder = Object.keys(this.state.folders).reduce( ( obj, key ) => {
      if (key === id){ obj[key] = null }
      else {
        obj[key] = this.state.folders[key]
        if (obj[key] > position) obj[key]--
      }
      return obj
    }, {})

    views.change(id, null)
    views.change("folders", itemOrder)
    transaction.commit()
  }

  /**
   * @todo Document
   */
  getShareableViews =()=> {
    if (!this.state[this.viewNode]) return this.emptyItemsMessage
    
    const filteredItems = Shareable.filterShareables(this.state[this.viewNode],this.state)
    const items = Shareable.sortItems(filteredItems,this.state[this.viewNode])

    if (!items.length) return this.emptyItemsMessage

    return items.map( (viewId,index) => {

      let reorderStatus = false;
      if (this.state.reorderingItem){
        reorderStatus = this.state.reorderingItem === viewId ? "reordering" : "target"
      }

      let featureProps = {}
      if (this.preview) featureProps.preview = this.preview(this.state[viewId].preview)

      return <FeatureView
        {...featureProps}
        reorderStatus={reorderStatus}
        feature={this.state[viewId]}
        key={viewId}
        id={viewId}
        changeName={(value)=>this.changeName(viewId,value)}
        saveName={(value)=>this.saveNameChange(viewId,value)}
        showShareItemPopup={()=>this.showShareItemPopup(viewId)}
        sref={()=>this.handleLink(this.state[viewId].link)}
        delete={()=>this.removeView(viewId)}
        unshare={()=>this.removeView(viewId,true)}
        reorder={()=>this.startReorderView(viewId)}
        endReorder={()=>this.endReorderView(viewId)}
        hasFolders={this.hasFoldersToMoveTo()}
        moveToFolder={()=>this.showMoveToFolderPopup(viewId)}
        moveUpFolder={()=>this.moveUpFromFolder(viewId)}
      />
    }, this)
  }

  /**
   * @todo Document
   */
  getShareableViewFolders =()=> {
    if (!this.state.folders) return this.emptyFolderMessage

    const items = Object.keys(this.state.folders)
      .filter( a => !this.state.hidden[a] )
      .sort( (a,b) => this.state.folders[a] - this.state.folders[b] )

    if (!items.length) return this.emptyFolderMessage

    return items.map( (folderId,index) => {

      let reorderStatus = false;
      if (this.state.reorderingFolder){
        reorderStatus = this.state.reorderingFolder === folderId ? "reordering" : "target"
      }

      let folder = this.state[folderId]
      return <FeatureViewFolder
        reorderStatus={reorderStatus}
        folder={folder}
        key={folderId}
        id={folderId}
        changeName={(value)=>this.changeName(folderId,value)}
        saveName={(value)=>this.saveFolderNameChange(folderId,value)}
        sref={()=>this.handleFolderLink(this.state[folderId].link)}
        delete={()=>this.removeFolder(folderId)}
        reorder={()=>this.reorderFolder(folderId)}
        endReorder={()=>this.endReorderFolder(folderId)}
      />
    }, this)
  }

  emptyItemsMessageHeader =()=> `No ${this.pluralName || "Items"}`
  emptyItemsMessageBody =()=> "Create one with the button below"

  get emptyItemsMessage(){
    return (
      <div className="empty-item-views-message">
        <div className="empty-item-views-message__header">{this.emptyItemsMessageHeader()}</div>
        <div className="empty-item-views-message__body">{this.emptyItemsMessageBody()}</div>
      </div>
    )
  }

  get emptyFolderMessage(){
    return (
      <div className="empty-item-folders-message">
        <div className="empty-item-folders-message__body">Add folders with the menu</div>
      </div>
    )
  }

  render(){
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }
    return (
      <Screen>
        <Header primary>
          { this.state.folderId !== "base" 
            ? <BackButton clicked={this.handleFolderBackLink}/> 
            : <NavigationButton/>
          }
          <Title value={ this.state.folderId === undefined ||
            this.state.folderId === "base"
            ? this.shareableName
            : this.state[this.state.folderId] && this.state[this.state.folderId].name
          }/>
          <Menu left dotsVertical>
            <MenuItem clicked={this.newFolder}>New Folder</MenuItem>
          </Menu>
        </Header>
        <search-box-seperate/>
        <Body background="#f5f5f5">
          <div className="scrollable-column">
          { this.state.folderId === "base" && <>
              <div flex="100" className="shareable-views-list-header">
                <h2>Folders</h2>
              </div>
              <div className="shareable-views-list">
                {this.getShareableViewFolders()}
              </div>
            </>}
            <div flex="100" className="shareable-views-list-header">
              <h2>{this.shareableName}</h2>
            </div>
            <div className="shareable-views-list">
              {this.getShareableViews()}
            </div>
          </div>
        </Body>
        <FabArea bottom right>
          <Fab icon="add" accent clicked={this.newShareable} />
        </FabArea>
        <PopupArea/>
      </Screen>
    )
  }
}

export default ShareableViews
import React from 'react'
import ChangedTimeOutDelay from '../../PlanReach/features/notes/ChangedTimeOutDelay';
import UndoHistory from '../../PlanReach/features/notes/UndoHistory';

import './RichTextEditor.css'

/**
 * A content editable based text editor with support for Undo Redo and shortcut based formatting.
 */
export default class RichTextEditor extends React.Component {

  state = {
    zoom : 4
  }

  load(content){
    this.setState({content:content})
    this.refs.editor.innerHTML = content
    this.undoHistory = new UndoHistory(this.state, content)
  }

  render (){

    /**
     * The zoom effect is achieved by scaling the content and counter-scaling the content's size
     * by the opposite amount to keep the component's size constant. So, at 50% zoom, the width 
     * and hight are 200% and vice versa.
     */
    const style = {
      transformOrigin: "top left",
      transform: `scale(${this.zoomLevels[this.state.zoom]})`,
      width : `${1/this.zoomLevels[this.state.zoom]*100}%`,
      height : `${1/this.zoomLevels[this.state.zoom]*100}%`
    }

    return (<div className="rich-text-editor--container">
      <div
        style={style}
        contentEditable 
        className="rich-text-editor no-click-border"
        ref="editor"
        onInput={this.updateContent}
        onKeyDown={this.interceptEditorCommands}
        />
      </div>)
  }

  componentDidMount(){
    this.mounted = true
    this.props.service._editor = this
  }

  componentWillUnmount(){
    this.mounted = false
    this.changedTimeOutDelay.clear()
  }

  /**
   * Holds the timing data for the user making changes 
   */
  changedTimeOutDelay = new ChangedTimeOutDelay( args =>this.updateHistory(args))

  zoomLevels = [0.58, 0.72, 0.86, 1.0, 1.14, 1.28, 1.42, 1.56];
  fontSize = ["8px","10px","12px","14px","16px","18px","20px","22px"]; // Unused, default 4
  
  zoomIn =()=> {
    if (this.state.zoom < this.zoomLevels.length -1){
      this.setState({zoom : this.state.zoom + 1})
    }
    this.refocus()
  }

  zoomOut =()=> {
    if (this.state.zoom > 0){
      this.setState({zoom : this.state.zoom - 1})
    }
    this.refocus()
  }

  updateHistory =({content,caret})=> {
    if (this.lastResult !== content){
      this.undoHistory.change(content,caret);
      this.lastResult = content
    }
    this.hasUndoAndRedo()
  }

  /**
   * Calls the formatting command on the editor at the current caret position
   */
  formatDoc=(sCmd, sValue)=> {
    this.refocus()
    document.execCommand(sCmd, false, sValue);
  }

  /**
   * Due to execCommand having different cursor behaivor for being called from a button and from a
   * keyboard shortcut, keyboard based commands are captured and executed as though they were button
   * commands.
   */
  interceptEditorCommands =(e)=> {
    interceptContentEditableCommandKey(e,this.handleEditorCommands)
  }

  /**
   * Adds an indent at the caret.
   * 
   * @note editor ref's value needs explictly updated because setState is async and
   * happens after the focus and cursor update.
   *  
   * @todo support indenting a range
   */
  addIndent =()=> {
    this.formatDoc('insertText',"\t")
    let content = this.refs.editor.innerHTML

    if (this.undoHistory) this.undoHistory.change(content);
    this.hasUndoAndRedo()
  }

  /**
   * @todo handle copy, cut, paste, paste no format
   * https://support.google.com/docs/answer/179738?co=GENIE.Platform%3DDesktop&hl=en
   */
  handleEditorCommands =(command)=> {
    switch(command){
      case "bold": 
      case "italic": 
      case "underline": 
        return this.formatDoc(command)
      
      case "undo": return this.undo()
      case "redo": return this.redo()
    }
  }

  /**
   * Undoes the most recent UndoHistory event
   */
  undo =()=> {
    this.refocus()

    if (this.undoHistory.hasUndo){
      const undoEntry = this.undoHistory.undo()

      this.setState({ content : undoEntry.value })
      this.refs.editor.innerHTML = undoEntry.value
      selectRange(this.refs.editor, undoEntry.caret.start, undoEntry.caret.end)
    }

    this.hasUndoAndRedo()
  }

  /**
   * Redoes the most recent UndoHistory event
   */
  redo =()=> {
    this.refocus()
    if (this.undoHistory.hasRedo){
      const undoEntry = this.undoHistory.redo()

      this.setState({ content : undoEntry.value })
      this.refs.editor.innerHTML = undoEntry.value
      selectRange( this.refs.editor, undoEntry.caret.start, undoEntry.caret.end)
    }

    this.hasUndoAndRedo()
  }

  /**
   * Tracks if undo and redo can be done to prevent array out of bound in UndoHistory. Also calls
   * the RichTextEditorService, if there is one, to let it handle any parent callback watching
   * undo and redo.
   */
  hasUndoAndRedo=()=>{
    this.setState({ 
      canUndo : this.undoHistory.hasUndo,
      canRedo : this.undoHistory.hasRedo
    })
    
    if (this.props.service){
      this.props.service._watchUndoRedo( this.undoHistory.hasUndo, this.undoHistory.hasRedo)
    }
  }

  /**
   * Updates the state content from the editor's innerHTML, calculates the caret, calls a changed timeout delay
   * to add a undo history event, and 
   */
  updateContent =(event)=> {
    if (event && event.target){
      const caret = getCaretPosition(event.target)
      this.setState({ content : event.target.innerHTML})
      this.changedTimeOutDelay.changed({ content : event.target.innerHTML, caret : caret})
    }
    if (this.props.onInput){
      this.props.onInput(event)
    }
  }

  /**
   * Focuses the editor. This is important when calling a formatting function to ensure that the command can execute
   * at the current carat position.
   */
  refocus(){
    this.refs.editor.focus()
  }
}

/**
 * Listens for content editable command key combinations, intercepts them preventing them from firing, and forwards their
 * events to the passed callback to handled.
 * 
 * https://stackoverflow.com/questions/7207291/is-it-possible-to-disable-or-control-commands-in-contenteditable-elements
 * 
 * @param {KeyboardEvent} e - the key event
 * @param {callback} callback - handler for the intercepted command
 *  - "bold" for ctrl+B and ctrl+b
 *  - "italic" for ctrl+I and ctrl+i
 *  - "underline" for ctrl+U and ctrl+u
 *  - "undo" for ctrl+z
 *  - "redo" for ctrl+y
 */
function interceptContentEditableCommandKey(e,callback =v=>v){
  if( e.ctrlKey || e.metaKey ){
    switch(e.keyCode){

      // Bold: ctrl+B ctrl+b
      case 66: 
      case 98: 
        callback("bold")
        e.preventDefault()
        break

      // Italic: ctrl+I or ctrl+i
      case 73: 
      case 105:
        callback("italic")
        e.preventDefault()
        break

      // Underline: ctrl+U or ctrl+u
      case 85: 
      case 117:
        callback("underline")
        e.preventDefault()
        break

      // Undo: ctrl+z
      case 90: 
        callback("undo")
        e.preventDefault()
        break

      // Redo: ctrl+y
      case 89:
        callback("redo")
        e.preventDefault()
        break
    }
  }
}

/**
 * Selects a range within a content editable.
 */
function selectRange(element, start, end){
  element.focus()
  if (start ===  0 && end === 0 && !element.innerHTML ) return

  if (document.selection) {
    const selection = document.selection.createRange()
    selection.moveStart('character', start)
    selection.select()
  }
  else {
    const selection = window.getSelection();
    selection.collapse(element.lastChild, start < element.lastChild.length ? start : element.lastChild.length);
  }
}

/**
 * Finds the current caret in a content editable. This function is needed because content editable's can have
 * multiple child nodes making it difficult to find the caret position.
 */
function getCaretPosition(element) {
  var start = 0;
  var end = 0;
  var doc = element.ownerDocument || element.document;
  var win = doc.defaultView || doc.parentWindow;
  var selection;

  if (typeof win.getSelection !== "undefined") {
      selection = win.getSelection();
      if (selection.rangeCount > 0) {
          var range = win.getSelection().getRangeAt(0)
          var preCaretRange = range.cloneRange()

          preCaretRange.selectNodeContents(element)
          preCaretRange.setEnd(range.startContainer, range.startOffset)
          start = preCaretRange.toString().length
          preCaretRange.setEnd(range.endContainer, range.endOffset)
          end = preCaretRange.toString().length
      }
  } else if ( (selection = doc.selection) && selection.type !== "Control") {
      var textRange = selection.createRange()
      var preCaretTextRange = doc.body.createTextRange()

      preCaretTextRange.moveToElementText(element)
      preCaretTextRange.setEndPoint("EndToStart", textRange)
      start = preCaretTextRange.text.length
      preCaretTextRange.setEndPoint("EndToEnd", textRange)
      end = preCaretTextRange.text.length
  }
  return { start: start, end: end }
}


/**
 * Service to handle interactions with a RichTextEditor like calling formatting functions on it and
 * checking its undo and redo status.
 */
export class RichTextEditorService{
  
  /** The editor this service is for. This should be set by the editor itself.  */
  _editor = null
  
  constructor({parent,watch}){
    this.parent = parent
    this.watch = watch
    
    /**
     * Updates the parent's undo and redo watcher. This method is called by the 
     */
    this._watchUndoRedo = function(hasUndo,hasRedo){
      if (this.watch && this.watch.undoRedo){
        this.watch.undoRedo(hasUndo,hasRedo)
      }
    }
  }

  load(content){
    this._editor.load(content)
  }
  unmount(){
    this._editor = null
  }

  undo(){
    this._editor.undo()
  }
  redo(){
    this._editor.redo()
  }
  bold(){
    this._editor.formatDoc("bold")
  }
  italic(){
    this._editor.formatDoc("italic")
  }
  underline(){
    this._editor.formatDoc("underline")
  }
  addIndent(){
    this._editor.addIndent()
  }
  zoomIn(){
    this._editor.zoomIn()
  }
  zoomOut(){
    this._editor.zoomOut()
  }
}
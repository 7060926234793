import React from 'react'

/**
 * Circle icon used to viualy indicate what a color will look like
 */
const ColorDot =(props)=> {
  const dotStyle = {
    margin : "2px", minWidth : "20px", minHeight : "20px", borderRadius: "20px", 
      backgroundColor : props.color.color, display : "inline-block"
  }
  return (
    <div style={dotStyle}/>
  )
}

export default ColorDot 
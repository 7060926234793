import React from 'react'

import UiTestPage, {UiTestSection, UiTestRow} from '../UiTestPage';
import TextInput from '../../../platform/components/inputs/text/TextInput';
import NumberInput from '../../components/inputs/number/NumberInput';
import DatePicker from '../../components/pickers/datePicker/DatePicker';

/**
 * Cards page is a demo of the Card component, interactive api documentaton, and a test
 * of the card's behaivor and conformance to the Material Spec.
 */
class InputsPage extends React.Component {
    
  state = {}

  updateTextHandler =(value)=> { this.setState({ text : value }) }
  clearText =()=> { this.setState({ text : null }) }
  updateNumericHandler =(value)=> { this.setState({ numeric : value }) }
  clearNumeric =()=> { this.setState({ numeric : 0.00 }) }

  render(){
    return (
      <UiTestPage>
        
        <UiTestSection name="Text Inputs">
          <UiTestRow name="Normal Input">
            <TextInput
              label="Label"
              placeholder="Placeholder text..."
              value={this.state.text} 
              changed={this.updateTextHandler}/>
          </UiTestRow>
          <UiTestRow name="No Label Input">
            <TextInput
              noLabel
              placeholder="Placeholder text..."
              value={this.state.text} 
              changed={this.updateTextHandler}/>
          </UiTestRow>
        </UiTestSection>
        <UiTestSection name="Validated Input">
          <UiTestRow name="Numeric Input">
            <NumberInput 
              placeholder="A number..."
              label="Number"
              value={this.state.numeric} 
              changed={this.updateNumericHandler}/>
          </UiTestRow>
          <UiTestRow name="Integer Input">
            <NumberInput 
              integer
              label="Integer"
              placeholder="An integer..." value={this.state.integer} 
              changed={value => this.setState({ integer : value }) }/>
          </UiTestRow>
          <UiTestRow name="Date Input">
            <DatePicker
              placeholder="An integer..."
              value={this.state.date} 
              changed={value => this.setState({ date : value }) }/>
          </UiTestRow>
        </UiTestSection>
      </UiTestPage>
    )
  }

}

export default InputsPage
import React from 'react'

import './TextInput.css'

/**
 * An MDI styled text input.
 * @param {id} field The field id, passed back to the caller to identify the field
 * @param {function} blurred Called when the user defocus the input. Passes the event value in 
 * the first param and the event in the second.
 * @param {function} changed Called when the user types into the input with the value in 
 * the first param and the event in the second.
 * @param {function} pressed Called when the user types presses a key. Passes the key event to
 * the passed in function.
 * @param {String} value - **Required** The value of the input.
 * @param {String} label The label value to show over the input.
 * @param {String} placeholder The value to show when the input is empty.
 * @param {String} list List value used by <datalist/> HTML elements to specify auto suggestions.
 * @param {boolean} autoFocus If true, will autofocus the input.
 * @param {boolean} fullWidth If true, will style the input to use full width.
 * @param {boolean} noLabel If true, will not show floating label.
 */
class TextInput extends React.Component {

  state = {
    isFocused : false
  }

  keyPressed =(e)=> {
    if (this.props.pressed) this.props.pressed(e, this.props.field)
  }
  blurred =(e)=> {
    this.setState({isFocused : false})
    if (this.props.blurred) this.props.blurred(e.target.value, e, this.props.field )
  }
  focused =(e)=> {
    this.setState({isFocused : true})
  }
  changed =(e)=> {
    if (this.props.changed) this.props.changed(e.target.value, e, this.props.field )
  }

  label =(label,placeholder,floating) => {
    if (label !== undefined && label !== null && (placeholder === undefined || placeholder === null))
      return label

    if (placeholder !== undefined && placeholder !== null && (label === undefined || label === null))
      return placeholder

    return floating ? label : placeholder
  }

  render(){
    let classes = "text-input"

    const {autofocus, className, list, placeholder, value, fullWidth, noLabel} = this.props
    const {focused, blurred, changed, keyPressed} = this
    const {isFocused} = this.state

    if (className) classes += ` ${className}`
    if (fullWidth) classes += " full-width"
    
    const labelFloating = isFocused || value || value === "0" || value === 0
    const label = this.label(this.props.label,placeholder,labelFloating)
    const labelClasses = `text-input__label${labelFloating?" floating":""}`

    const containerClasses = "input-container" + (noLabel ? "" : " has-label")

    return (
      <div className={containerClasses}>
        { !noLabel && <div className={labelClasses}>{label}</div> }
        <input
          autoComplete= { this.props.autoComplete || Date.now() }
          list= { list }
          className={ classes }
          placeholder={ noLabel && (placeholder || "") }
          onFocus={ focused }
          onBlur={ blurred }
          onChange={ changed }
          onKeyPress={ keyPressed }
          autoFocus={ autofocus }
          value={(value === undefined || value === null) ? "" : value }/>
      </div>
    )
  }
}

export default TextInput
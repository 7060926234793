import React from 'react'

import './FlatButton.css'
import TextButton from './TextButton'

const FlatButton =(props)=> {
  let styles = {}
  let classes = "flat-button"
  if (props.color) styles.color = props.color
  if (props.disabled){ 
    styles.opacity = ".26"
    classes+=" disabled" 
  }
  return (<TextButton notBackground buttonStyles={styles} buttonClass={classes} {...props}/>)
}

export default FlatButton
class ClickOutsideService {
  
  static init() {
    if (!window.clickOutsideService){
      window.clickOutsideService = new ClickOutsideService()
    }
    return window.clickOutsideService
  }

  constructor(){
    if(!window.ClickOutsideServiceListener){
      window.ClickOutsideServiceListener = window.addEventListener('click', e => {
        this.OnClick(e)
      });
      this.registrations = {};
      this._registerKey = 0;
    }
  }

  OnClick =(e)=> {
    Object.keys(this.registrations).forEach( registered => {
      if (this.registrations[registered].active &&
        this.clickedOutside(e,this.registrations[registered])){
        this.registrations[registered].callback(e);
      }
    })
  }

  clickedOutside =(e,registered)=> {
    if (registered && registered.element && registered.element !== e.target && 
      !registered.element.contains(e.target) &&
    !this.clickedOutsideDontClose(e) ) {
        registered.callback(e);
    }
  }

  clickedOutsideDontClose =(e)=> {
    let target = e.target
    if (target.classList.contains("dont-close-menu")) return true
    while (target.parentNode){
      if (target.classList.contains("dont-close-menu")) return true
      target = target.parentNode
    }
    return false
  }

  /**
   * Registers an element and calls the passed callback when the user clicks outside of
   * the element. Make sure to unregister the element when its no longer needed.
   * 
   * @param {*} element HTML element
   * @param {*} callback
   * @return the index of the clickedOutside handler
   */
  static register=(element,callback)=>{
    ClickOutsideService.init()
    const registrationKey = ClickOutsideService._registerKey
    ClickOutsideService.registrations[registrationKey] = {
      element : element,
      callback : callback
    }
    return registrationKey;
  }

  static setActive=(registerKey)=>{
    ClickOutsideService.registrations[registerKey].active = true
  }

  static setInactive=(registerKey)=>{
    ClickOutsideService.registrations[registerKey].active = false
  }

  static unregister=(registration)=>{
    if (window.clickOutsideService.registrations){
      delete window.clickOutsideService.registrations[registration];
    }
  }

  static get registrations(){
    return window.clickOutsideService.registrations
  }
  static get _registerKey(){
    return window.clickOutsideService._registerKey++;
  }
}

export default ClickOutsideService
import React from 'react'
import MultiAddItem from '../../../../base/widgets/multiAddItem/MultiAddItem';
import FlatButton from '../../../../../platform/components/buttons/textButton/FlatButton';
import ViewsList from '../../../../base/view/list/collection/ViewsList';

import {storage} from 'firebase'

import { Transaction, Model, Collection, newKey, databaseRef } from '../../../../../TransactaFire/TransactaFire';
import User from '../../../../User/User';

import FilePicker from '../../../../../platform/components/file/picker/FilePicker';
import Card from '../../../../../platform/components/cards/Card';
import Avatar from '../../../../../platform/components/avatar/Avatar';
import { BodyTextBold, CaptionText } from '../../../../../platform/components/typography/Typography';
import FieldIterator from '../../../../base/widgets/multiFieldWidgets/fieldIterator/FieldIterator';

import './Profile.css'
import DataFire from '../../../../../TransactaFire/DataFire';

class Profile extends ViewsList {
  state = {
    subview : "profile"
  }

  contactSchema = {
    contacts : {
      type : "orderedCollection",
      name : "contacts",
    }
  }

  viewNode = `${User.uid}/profile`

  componentDidMount(){
    this.mounted = true
    this.viewRef = databaseRef(`${User.uid}/profile`)

    this.viewRef.on('value', snapshot => {
      if (this.mounted && typeof snapshot.val() === "object"){
        let snapshotState = {...snapshot.val()}
        this.setState({...snapshotState})
      }
    })

    this.contactsRef = databaseRef(`${User.uid}/contacts`)
    DataFire.bindRef(this,this.contactsRef,this.contactSchema)
  }

  get footer(){
    return (
      // ng-if="vm.UserProfile.nameChanged || vm.UserProfile.emailChanged">
      <div class="whiteframe-1dp" style={{zIndex: 10}}>
        <md-divider/>
        <div layout="row" >
          <span flex></span>
          <FlatButton primary clicked={this.saveNameAndEmail}>
            Change
          </FlatButton>
          <span flex></span>
          <FlatButton primary clicked={this.cancel}>
            Cancel
          </FlatButton>
          <span flex></span>
        </div>
      </div>
    )
  }
  
  infoFields = [ "name", "company", "companyRole", "birthday" ]

  saveInfo =(info)=> {
    const transaction = new Transaction()

    this.infoFields.filter( field => info[field] !== undefined)
      .forEach( field => this.editProp(field, info[field], transaction, "profile_") )

    transaction.commit()
  }

  editProp =(prop,value,transaction,prefix="") => {
    const profile = transaction.add(new Model(this.viewRef,this.state))
    profile.change(prop,value)
    this.changeContactField(prop,value,transaction,prefix)
  }

  /**
   * @todo The avatar should be validated as a image, if not an error message shown.
   * @todo Show a preview of the avatar and allow the user to change the image's zoom
   * and position.
   * @todo When the user confirms the image selection, process the image into an
   * image the size of the avatar and save the processed image into fitebase storage.
   * @todo Increment the image number each time so that the browser cache doesn't 
   * prevent an update.
   */
  changedAvatar = async(files) => {
    const transaction = new Transaction()
    const profile = transaction.add(new Model(this.viewRef,this.state))

    const ref = storage().ref(`${User.uid}/avatar`)
    const uploadTask = ref.put(files[0])
    await uploadTask
    const avatarUrl = await ref.getDownloadURL()

    profile.change("avatarUrl",avatarUrl)
    this.changeContactField("avatarUrl",avatarUrl,transaction)
    
    transaction.commit()
  }

  /**
   * Gets an array of contact keys from state
   */
  getContactKeys =()=> {
    if (!this.state.contacts) return []
    return Object.keys(this.state.contacts)
  }

  /**
   * Handles getting the model for a contact key
   */
  contactKeyToModel(contact,transaction){
    const contactUid = contact.replace("contact_","")
    return transaction.add(Model.newAtPath(`${contactUid}/contacts/contact_${User.uid}`))
  }

  /**
   * Fans out a profile change to the contacts
   */
  changeContactField =(field,value,transaction,prefix = "")=> {
    this.getContactKeys().forEach( contact => {
      const contactModel = this.contactKeyToModel(contact,transaction)
        contactModel.change(prefix + field,value)
    })
  }

  /**
   * Adds a MultiAddItem to the user's profile and updates and contacts made from the user with the MultiAddItem.
   * 
   * The Profile/Contact's MultiAddItems are not orderable as we need to be able to add/remove them from multiple
   * sources and the current sorting design doesn't support two sources of item order.
   */
  addItem =(item,type,node)=> {
    const transaction = new Transaction()
    const model = transaction.add(new Model(this.viewRef,this.state))
    const contacts = this.getContactKeys()
    const key = newKey(`${type}_`)

    model.change(`${node}/${key}`,1)
    model.change(key, item)

    contacts.forEach( contact => {
      const contactModel = this.contactKeyToModel(contact,transaction)
      this.addContactItem(item, node, key, contactModel)
    })

    return transaction.commit()
  }

  /**
   * Modify the contact's multiItemField field
   */
  addContactItem =(item,node,key,model)=> {
    model.change(`${node}/${key}`, 1 )
    model.change(key, { ...item, profile : true })
  }

  /**
   * Adds a MultiAddItem to the user's profile and updates and contacts made from the user with the MultiAddItem.
   */
  editItem =(item)=> {
    const transaction = new Transaction()
    const model = transaction.add(new Model(this.viewRef,this.state))
    const contacts = this.getContactKeys()
    const key = item.id

    model.change(key, item)

    contacts.forEach( contact => {
      const contactModel = this.contactKeyToModel(contact,transaction)
      this.editContactItem(item,contactModel)
    })

    return transaction.commit()
  }

  /**
   * Modify the contact's multiItemField field
   */
  editContactItem =(item,model)=> {
    model.change(item.id, { ...item, profile : true })
  }

  deleteItem =(item,type,node)=> {
    const transaction = new Transaction()
    const model = transaction.add(new Model(this.viewRef,this.state))
    const contacts = this.getContactKeys()
    const key = item.id

    model.change(`${node}/${key}`, null)
    model.change(key, null)

    contacts.forEach( contact => {
      const contactModel = this.contactKeyToModel(contact,transaction)
      this.deleteContactItem(key,node,contactModel)
    })

    return transaction.commit()
  }

  /**
   * Modify the contact's multiItemField field
   */
  deleteContactItem =(key,node,model)=> {
    model.change(`${node}/${key}`, null)
    model.change(key, null)
  }

  addPhone =(phone)=> {
    this.addItem(phone,"phone","phones")
  }
  addEmail =(email)=> {
    this.addItem(email,"email","emails")
  }
  addLocation =(location)=> {
    this.addItem(location,"location","locations")
  }

  changeName =(name)=> {
    this.editProp("name",name)
  }

  deletePhone =(phone)=> {
    this.deleteItem(phone,"phone","phones")
  }
  deleteEmail =(email)=> {
    this.deleteItem(email,"email","emails")
  }
  deleteLocation =(location)=> {
    this.deleteItem(location,"location","locations")
  }

  itemName = "Personal profile"

  get avatarSummary(){
    return (
      <div className="row flex">
        <Avatar style={{margin:"0 16px 0 0"}} src={this.state.avatarUrl}/>
        <div style={{alignSelf: "center"}}>{User.userName}</div>
      </div>
    )
  }

  get avatarEdit(){
    return (<FilePicker changed={this.changedAvatar} style={{margin:"0 0px 0px -46px"}}>
      <div style={{padding:"8px 24px"}}>
        {/** @todo edit email */}
        <Card className="avatar-picker">
          <div style={{display:"flex",flexDirection:"row", padding:"16px 24px"}}>
            <Avatar style={{marginRight:"16px"}} src={this.state.avatarUrl}/>
            <div style={{display:"flex",flexDirection:"column"}}>
              <BodyTextBold>Avatar photo</BodyTextBold>
              <CaptionText>Click to change</CaptionText>
            </div>
          </div>
        </Card>
      </div>
    </FilePicker>)
  }

  get screenBody(){

    return(
      <div style={{height:"100%",overflowY:"auto"}}>
        <FieldIterator
          parent={this.state}
          icon="person"
          label="Profile"
          saveItem={this.saveInfo}
          paddingLeft="38"
          items={[ "name", "avatar", "company", "companyRole", "birthday" ]}
          summaryLines={[
            { custom : true, content : this.avatarSummary },
            { section : true, 
                left : { half : true, prop : "company", label : "Company", hideIfEmpty : true },
                right : { half : true, prop : "companyRole", label :"Company Role", hideIfEmpty : true },
            },
            { prop : "birthday", label :"Birthday", hideIfEmpty : true }
          ]}
          editLines={[
            { flex : true, prop : "name", label :"Name", type: "input", placeholder : "Your display name" },
            { custom : true, content : this.avatarEdit },
            { section : true, 
              left : { half : true, prop : "company", label :"Company", type: "input" },
              right : { half : true, prop : "companyRole", label :"Company Role", type: "input" },
            },
            { prop : "birthday", label :"Birthday" }
          ]}/>

        <MultiAddItem 
          items={this.state.phones}
          parent={this.state}
          itemButton={ {clicked : this.deletePhone, icon : 'cancel'} }
          icon="phone"
          label="Phone numbers"
          saveItem={this.editItem}
          addItem={this.addPhone}
          addLabel="Add phone"
          emptyMessage="Phone numbers shown to your contacts."
          lines={[
            { prop : "number", label :"Phone number" },
            { prop : "label", label :"Label for number" }
          ]}
        />
        <MultiAddItem 
          items={this.state.emails}
          parent={this.state}
          itemButton={ {clicked : this.deleteEmail, icon : 'cancel'} }
          icon="email"
          label="Email addresses"
          saveItem={this.editItem}
          addItem={this.addEmail}
          addLabel="Add email"
          emptyMessage="Emails addresses shown to your contacts."
          lines={[
            { prop : "email", label :"Email address" },
            { prop : "label", label :"Label for email address" }
          ]}
        />
        <MultiAddItem 
          items={this.state.locations}
          parent={this.state}
          itemButton={ {clicked : this.deleteLocation, icon : 'cancel'} }
          icon="place"
          label="Locations"
          saveItem={this.editItem}
          addItem={this.addLocation}
          addLabel="Add locations"
          emptyMessage="Your locations shown to your contacts."
          lines={[
            { prop : "address", label :"Location address" },
            { prop : "label", label :"Label for Location" }
          ]}
        />
      </div>
    )
  }
}

export default Profile
import React from 'react'
import FlatButton from '../../../platform/components/buttons/textButton/FlatButton';

import Item from '../../../TransactaFire/Item';
import { Transaction, Model, Collection } from '../../../TransactaFire/TransactaFire';
import User from '../../User/User';
import Routing from '../../routing/Routing';

class ContactLandingPage extends React.Component {
  state = {
    status : "loading"
  }

  componentDidMount(){
    this.mounted = true
    let params = Routing.getRouterParam(this)
      this.key = params.key

    this.shareable = new Item(this, this.key, "contactShare")
    this.shareable.loaded.then( user => {
      this.setState({status : "loaded", shareItem : user})
    })
  }

  acceptShareable = async ()=> {
    let transaction = new Transaction()
    let promises = []
    const otherUid = this.state.shareItem.uid

    const otherUserContacts = transaction.add(new Collection.newAtPath(`${otherUid}/contacts`,"contacts","contact"))
    const userContacts = transaction.add(new Collection.newAtPath(`${User.uid}/contacts`,"contacts","contact"))

    const otherUserProfile = await Model.newAtPath(`${otherUid}/profile`).loaded
    const userProfile = User.profile

    promises.push(otherUserContacts.addToCollection({...userProfile, uid : User.uid}, null, `contact_${User.uid}`))
    promises.push(userContacts.addToCollection({...otherUserProfile, uid : otherUid},null,`contact_${otherUid}`))

    await Promise.all(promises)
    transaction.commit().then( ()=> 
      this.props.history.replace(`/contact/contact_${otherUid}`)
    )
  }

  declineShareable=()=>{
    this.props.history.push(User.lastRoute)
  }

  getSharingMessage=({name})=>`${name} has invited you to become a contact.`

  get loading(){
    return (
      <h3 style={{width: "100%"}}> Loading... </h3>
    )
  }
  get loadFailed(){
    return (
      <div layout="column" style={{width: "100%"}}>
        <h3>Error</h3>
        Did not find the shared object or encountered an error.
      </div>
    )
  }
  get loaded(){
    return (
      <div layout="column" style={{padding: "16px", backgroundColor: "white" }} className="md-whiteframe-1dp">
        <div>{this.getSharingMessage(this.state.shareItem)}</div>
        <div style={{ display: "flex", flexDirection: "row"}} >
          <FlatButton primary clicked={this.acceptShareable}>Accept</FlatButton>
          <FlatButton accent clicked={this.declineShareable}>Decline</FlatButton>
        </div>
      </div>
    )
  }

  render(){ 
  return (
    <div style={{padding: "64px", backgroundColor: "#f5f5f5", flex : "1"}}>
      {this.state.status === "loaded" && this.loaded }
      {this.state.status === "loading" && this.loading }
      {this.state.status === "loadFailed" && this.loadFailed }
    </div>
  )}
}

export default ContactLandingPage
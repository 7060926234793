import React from 'react'
import SideNavigationService from './SideNavigationService';
import ClickOutsideService from '../../services/clicked-outside/ClickOutsideService';

import './SideNavigation.css'

class SideNavigationDrawer extends React.Component {
  mounted = false
  state = {
    shown : false,
  }

  componentDidMount(){
    this.clickedOutsideId = ClickOutsideService.register(this.refs.drawer,
      e=>this.clickedOutside(e))
    SideNavigationService.bindNavigation(this)
    this.mounted = true
  }
  
  componentWillUnmount =()=> {
    this.mounted = false
    ClickOutsideService.unregister(this.clickedOutsideId)
  }

  clickedOutside =(e)=> {
    SideNavigationService.hideDrawer()
  }

  render(){
    let classes = "side-navigation"
    if (this.state.shown) classes += " shown"
    
    return(
      <div ref="drawer" className={classes} tabIndex="-1">
        {this.props.children}
      </div>
    )
  }
}
export default SideNavigationDrawer
export { SideNavigationService }
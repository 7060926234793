import React from 'react'

import UiTestPage, {UiTestSection, UiTestRow, UiTestRowItem} from '../UiTestPage';
import Badge from '../../components/badges/Badge';

class BadgesPage extends React.Component {

  render(){
    return (
      <UiTestPage>
        <UiTestSection name="Badges">
          <UiTestRow name="Normal Badges">
            <UiTestRowItem name="Badge">
              <Badge>3</Badge>
            </UiTestRowItem>
            <UiTestRowItem name="Primary">
              <Badge primary>?</Badge>
            </UiTestRowItem>
            <UiTestRowItem name="Accent">
              <Badge accent>-</Badge>
            </UiTestRowItem>
            <UiTestRowItem name="Warn">
              <Badge warn>!</Badge>
            </UiTestRowItem>
            <UiTestRowItem name="Colored">
              <Badge color="#4caF50">Y</Badge>
            </UiTestRowItem>
            <UiTestRowItem name="Icon">
              <Badge icon="alarm">Alarm</Badge>
              </UiTestRowItem>
            <UiTestRowItem name="Icon">
              <Badge warn icon="warning">Warning</Badge>
            </UiTestRowItem>
            <UiTestRowItem name="Icon Character">
              <Badge>♥</Badge>
            </UiTestRowItem>
          </UiTestRow>

          <UiTestRow name="Badges On Item">
            <UiTestRowItem name="Badge on Chip" failed>
              <Badge>♥</Badge>
            </UiTestRowItem>
            <UiTestRowItem name="Badge on Input" failed>
              <Badge>♥</Badge>
            </UiTestRowItem>
            <UiTestRowItem name="Badge on Div" failed>
              <Badge>♥</Badge>
            </UiTestRowItem>
            <UiTestRowItem name="Badge on Span" failed>
              <Badge>♥</Badge>
            </UiTestRowItem>
            <UiTestRowItem name="Badge on Avatar" failed>
              <Badge>♥</Badge>
            </UiTestRowItem>
          </UiTestRow>
        </UiTestSection>
      </UiTestPage>
    )
  }
}
export default BadgesPage
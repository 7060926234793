export default class Shareable {

  static itemExists(id,state){
    return state[id] !== null && state[id] !== undefined
  } 

  static itemHidden(id,state){
    return state.hidden[id]
  } 

  static itemInCurrentFolder(id,state){
    return state[id].parent ?
      state.folderId === state[id].parent :
      state.folderId === "base"
  }

  static filterShareables(objects,state){
    return Object.keys(objects).filter( id => 
      Shareable.itemExists(id,state) &&
      !Shareable.itemHidden(id,state) &&
      Shareable.itemInCurrentFolder(id,state)
    )
  } 

  static sortItems(items,objects){
    return items.sort( (a,b) => objects[a] - objects[b] )
  }
}
// Import FirebaseAuth and firebase.
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import User from '../User/User';

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'popup', /** @todo test if popup signin flow works better than redirect flow - especially on mobile */
  signInSuccessUrl: User.loginRoute, /** @todo Redirect to the user's lastRoute/deeplink */

  // Support google and email login.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ]
};

export default class SignInScreen extends React.Component {

  componentDidMount(){
    User.loginRoute = this.props.location
  }

  render() {
    return (
      <div>
        <h1>PlanReach</h1>
        <p>Please sign-in:</p>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      </div>
    );
  }
}
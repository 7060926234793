import React from 'react'

import TextInput from '../../../../../platform/components/inputs/text/TextInput';
import ColorPicker from '../../../../../platform/components/pickers/colorPicker/ColorPicker';
import BoardColors from '../../BoardColors';
import ClickOutsideService from '../../../../../platform/services/clicked-outside/ClickOutsideService';
import TextArea from '../../../../../platform/components/inputs/textarea/TextArea';
import NumberInput from '../../../../../platform/components/inputs/number/NumberInput';
import DatePicker from '../../../../../platform/components/pickers/datePicker/DatePicker';
import { numberDateFormat } from '../../../../../GlobalUtils/DateAndTime';
import IconButton from '../../../../../platform/components/buttons/iconButton/IconButton';

import './Card.css';

class BoardCard extends React.Component {
  
  /**
   * @property {String} view - Which view is active.
   * - `summary`
   * - `expanded`
   */
  state = {
    view : "summary",
  }

  componentDidUpdate (previousProps){
    if (this.props.focus && !previousProps.focus){
      this.focusInput()
    }
    if (this.props.deselect && !previousProps.deselect){
      this.deselectCard()
    }
  }

  componentDidMount(){
    this.mounted = true
    this.refs.card.clickOutsideId = ClickOutsideService.register(
      this.refs.card,(e)=>this.clickedOutside(e))
  }
  
  deselectCard(){
    if (this.state.view === "expanded") this.collapseCard()
  }

  focusInput(){
    if (!this.mounted) return

    let inputs = this.refs.card.getElementsByTagName('INPUT')
      inputs[inputs.length-1].focus()

    if (this.state.view !== "expanded") this.expandCard()
  }

  componentWillUnmount(){
    this.mounted = false
    ClickOutsideService.unregister(this.refs.card.clickOutsideId)
  }

  clickedOutside =(e)=> {
    if (this.state.view === "summary") return;
    this.collapseCard()
  }

  colors = BoardColors.colors;

  expandCard =()=> {
    if (!this.mounted) return
    ClickOutsideService.setActive(this.refs.card.clickOutsideId)
    if (this.state.view === "summary"){
      this.setState({ view : "expanded" })
    }
  }

  collapseCard =()=> {
    if (!this.mounted) return
    this.setState({ view : "summary" })
    ClickOutsideService.setInactive(this.refs.card.clickOutsideId)
  }

  changeField =(value,e,field)=> {
    this.props.changed(value,field,this.props.id)
  }
  saveField =(value,e,field)=> {
    this.props.save(value,field,this.props.id)
  }

  changeColor =(value)=> {
    this.props.save(value,"color",this.props.id)
  }
  changeDueDate =(value)=> {
    if (value instanceof Date) value = numberDateFormat(value)
    this.props.changed(value,"due",this.props.id)
  }

  blurDueDate =(value)=> {
    if (value instanceof Date) value = numberDateFormat(value)
    this.props.save(value,"due",this.props.id)
  }
  showDate =()=> {
    ClickOutsideService.setInactive(this.refs.card.clickOutsideId)
  }
  closeDate =(date)=> {
    if (date instanceof Date) this.blurDueDate(date)
    setTimeout( ()=> ClickOutsideService.setActive(this.refs.card.clickOutsideId), 50 )
  }

  /**
   * If the user presses enter while editing the title, the item is saved and the next
   * item is added.
   */
  nextItem =(event)=> {
    if (event.key === 'Enter') {
      const { lane, id } = this.props
      this.blurName(this.props.card.name)
      this.props.nextItem(lane,id)
    }
  }

  removeCard=()=>{
    const { id, removeCard } = this.props
    removeCard(id)
  }

  reorderCard=()=>{
    const { id, reorderCard } = this.props
    reorderCard(id)
  }

  copyCard=()=>{
    const { id, copyCard } = this.props
    copyCard(id)
  }

  render(){
    let cardClasses = "board-card"

    return (
      <div className={cardClasses}
        ref="card"
        key={this.props.id}
        onClick={()=>this.expandCard()}>
        <div>
          <div 
            className="colors-dark-background" 
            style={{display : "flex", flexDirection: "row", 
            backgroundColor : this.colors[this.props.card.color || 0].color}}>
            <TextInput 
              noLabel
              field="name"
              placeholder="Card Name"
              pressed={ this.nextItem}
              value={this.props.card.name}
              blurred={this.saveField}
              changed={this.changeField}/>
          </div>
          <div style={{display : this.state.view  !== "summary" ? "block" : "none"}}>
            <div className="board-card__body">
              <ColorPicker
                colors={this.colors}
                placeholder="Card Color"
                value={this.props.card.color}
                changed={this.changeColor}/>
              <NumberInput
                fullWidth
                field="hours"
                placeholder="Hours"
                value={this.props.card.hours}
                blurred={this.saveField}
                changed={this.changeField}/>
              <DatePicker
                placeholder="Due"
                showPanel={this.showDate}
                closePanel={this.closeDate}
                value={this.props.card.due}
                blurred={this.blurDueDate}
                changed={this.changeDueDate}/>
              <TextArea
                placeholder="Description"
                field="description"
                value={this.props.card.description}
                blurred={this.saveField}
                changed={this.changeField}/>
            </div>
            <div className="board-card__footer">
              <IconButton icon="delete" clicked={this.removeCard} />
              <IconButton icon="reorder" clicked={this.reorderCard}/>
              <IconButton icon="attach_file" clicked={this.copyCard}/>
            </div>
          </div>
        </div>
        {
          this.ReorderingOverlay
        }
      </div>
    )
  }

  get ReorderingOverlay(){
    if (this.props.reorderStatus === false) return null

    let cardReorderingclasses = "board-card-reordering-area"
    if (this.props.reorderStatus === "target") cardReorderingclasses += " target"

    return (
      <div className={cardReorderingclasses} onClick={this.props.endReorder}/>
    )
  }
}

export default BoardCard
import React from 'react'

import UiTestPage, {UiTestSection} from '../UiTestPage';
import MultiAddItem from '../../../PlanReach/base/widgets/multiAddItem/MultiAddItem';

/**
 * Multi Add Item page is a demo of the MultiAddItemPage component,
 * interactive api documentaton, and a test of the widget's behaivor.
 */
class MultiAddItemPage extends React.Component {
  
  state = {
    phones : { phone_1 : 0 },
    phone_1 : {
      number : "123",
      label : "phone label"
    }
  }

  addPhone =(phone)=> {
    let phoneKey = `phone_${(new Date()).getTime()}`
    let count = Object.keys(this.state.phones).length

    this.setState({
      "phones" : {...this.state.phones, [phoneKey] : count},
      [phoneKey] : phone
    })
  }
  editPhone =(phone)=> {
    this.setState({[phone.id] : {...phone} })
  }
  deletePhone =(phone)=> {
    let phones = {...this.state.phones}
    let count = phones[phone.id]
    Object.keys(phones).forEach( key => {
      if (phones[key] > count ) phones[key]--
    })
    phones[phone.id] = undefined
    this.setState({[phone.id] : undefined, phones : phones}, ()=> {
      console.log(this.state)
    })
  }
  render(){
    return (
      <UiTestPage>
        <UiTestSection name="Multi Add Item">
          <div> List and edit widget. </div>
          
          <MultiAddItem 
            items={this.state.phones}
            parent={this.state}
            itemButton={
              {clicked : this.deletePhone, icon : 'cancel'}
            }
            icon="phone"
            label="Phone Numbers"
            saveItem={this.editPhone}
            addItem={this.addPhone}
            addLabel="Add phone"
            emptyMessage="No phone numbers found. Some features require a phone number."
            lines={[
              { prop : "number", label :"Phone number" },
              { prop : "label", label :"Label for number" }
            ]}
          />
            
      </UiTestSection>
    </UiTestPage>)
  }
}

export default MultiAddItemPage
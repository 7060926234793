import React from 'react'

const LargeArea =(props)=> {
  return (
    <div className="side-navigation__large-area">
      {props.children}
    </div>
  )
}

export default LargeArea
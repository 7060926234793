import React from 'react'

import './MessageParser.css'
import { NodeParser } from './NodeParsers';

/**
 * MessageParser takes a single **Message** formatted string as a children prop and converts the message 
 * into a series of components.
 */
const MessageParser =(props)=> {
  const nodes = getMessageNodes(props.children)
  return nodes.map(renderNode)
}

const getMessageNodes=(message)=>{
  const document = (new DOMParser()).parseFromString(message, "application/xml")
  if (document.children[0].nodeName === "html"){
    return [{type : "pre",value : message}]
  }
  const childNodes = document.children[0].childNodes
  return NodeParser(childNodes)
}

const renderNode =(node,index)=> {
  const {type, children, value} = node
  let content = value
  if (children && children.length) content = Array.from(children).map(renderNode)

  switch(type){
    case "section": return <MessageSection key={index}>{content}</MessageSection>
    case "div": return <MessageDiv key={index}>{content}</MessageDiv>
    case "break": return (<br key={index}/>)
    case "link": return <MessageLink key={index} url={value.url}>{value.label || value.url}</MessageLink>
    case "paragraph": return <MessageParagraph key={index}>{content}</MessageParagraph>
    case "bold": return <MessageBold key={index}>{content}</MessageBold>
    case "italic": return <MessageItalic key={index}>{content}</MessageItalic>
    case "pre": return <MessagePre key={index}>{content}</MessagePre>
    default: return <MessageParagraph key={index}>{content}</MessageParagraph>
  }
}

const MessageSection =(props)=>{
  return <div className="message-section">{props.children}</div>
}
const MessageDiv =(props)=>{
  return <div>{props.children}</div>
}
const MessageParagraph =(props)=>{
  return <span className="message-paragraph">{props.children}</span>
}
const MessageBold =(props)=>{
  return <span className="message-paragraph-bold">{props.children}</span>
}
const MessageItalic =(props)=>{
  return <i className="message-paragraph">{props.children}</i>
}
const MessagePre =(props)=>{
  return <pre>{props.children}</pre>
}
const MessageLink =(props)=>{
  return <a href={props.url}>{props.children}</a>
}

export default MessageParser
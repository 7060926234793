import React from 'react'
import TextInput from '../inputs/text/TextInput';

/**
 * Component that allows typing in a choice or selecting an option.
 * 
 * @prop {boolean} onlyAllowChoices If true, only one of the valid choices are allowed
 * 
 * @todo Add to UI Tests
 * @todo props: readOnly
 * @todo Make the Chooser's Datalist use a unique id to ensure it works even when there are other datalists
 */
export default class Chooser extends React.Component {
  
  state = {
    current : "",
    view : false
  }

  componentDidMount(){
    this.mounted=true
  }

  addNew=()=> this.setState({view : "editting"})

  changed=(value)=>{
    this.setState({current : value})
  }
  blurred=()=>{
    if (this.state.current && this.props.blurred){
      if (!this.props.onlyAllowChoices || this.valueInChoices(this.state.current)){
        this.props.blurred(this.valueToChoiceValue(this.state.current))
      }
    } 
    this.setState({
      current : "",
      view : "summary"
    })
  }

  valueInChoices(value){
    return Object.values(this.props.choices).find( choice => this.compareValueToChoice(choice,value) )
  }

  compareValueToChoice(choice,value){
    if (typeof choice === "object") return choice.displayName === value
    return choice === value
  }

  valueToChoiceValue(value){
    let choice = this.valueInChoices(value)
    if (typeof choice === "object") return choice.value
    return choice
  }

  choice=(choice,key)=>{
    if (typeof choice === "object" && choice.displayName !== undefined && choice.value  !== undefined ){
      return <option value={choice.displayName} key={key} />
    }
    return <option value={choice} key={choice} />
  }

  /**
   * HTML Datalist that controls the auto select options
   */
  datalist =()=> {
    if (!this.props.choices) return

    let choices = Object.entries(this.props.choices).map( entry => 
      this.choice(entry[1],entry[0])
    )

    return (
      <datalist id="Chooser">
        {choices}
      </datalist>
    )
  }
  
  render(){
    return (<>
      <div onClick={this.addNew}> 
        {
          this.state.view === "summary"
          ? <TextInput value={this.state.current} changed={this.changed} blurred={this.blurred} placeholder={this.props.placeholder}
              list="Chooser" autofocus/>
          : <div className="input-container">{this.props.label} : {this.props.value}</div>
        }
      </div>
      { this.datalist() }
    </>)
  }
}
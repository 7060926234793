import React from 'react'

import UiTestPage, {UiTestSection, UiTestRowItem} from '../UiTestPage';

import FlatButton from '../../../platform/components/buttons/textButton/FlatButton'
import Card, {CardTitle, CardContent, CardActions} from "../../components/cards/Card"

/**
 * Cards page is a demo of the Card component, interactive api documentaton, and a test
 * of the card's behaivor and conformance to the Material Spec.
 */
const CardsPage =()=> {
  return (
    <UiTestPage>
      <UiTestSection name="Cards">
        <div> Self-contained pieces of paper with data. </div>
        <UiTestRowItem name="Wide">
        <Card wide>
          <CardTitle primary>
            <h2>Welcome</h2>
          </CardTitle>
          <CardContent>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Mauris sagittis pellentesque lacus eleifend lacinia...
          </CardContent>
          <CardActions>
            {/* class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect" */}
            <FlatButton primary>Get Started</FlatButton>
          </CardActions>
          {/* <Menu>
            //class="mdl-button mdl-button--icon mdl-js-button mdl-js-ripple-effect"
            <IconButton icon="share"/>
          </Menu> */}
        </Card>
      </UiTestRowItem>
    </UiTestSection>
  </UiTestPage>)
}

export default CardsPage
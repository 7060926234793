import React from 'react'
import CheckBox from '../../../../../platform/components/inputs/checkBox/CheckBox';
import { ClassList } from '../../../../../platform/delegates/ClassNameDelegate';

/**
 * Used to display a FieldIterator field in Summary Mode
 *
 * @prop {string} type - The type of field to display. Valid options are 'text' or 'checkbox'.
 * @prop {any} value - Value to use for the field.
 * @prop {string} prop - The property to pull value from. Used only if value isn't.
 * @prop {Object} parent - The object to get the values from. Usually the parent state.
 * @prop {String} label - Label for the field. If omitted, prop will be used instead.
 * @prop {string} emptyValue - Value to show when the value is empty
 * @prop {boolean | function} noLabel - If true, the label won't be shown.
 * @prop {boolean | function} visible - If true, the SummaryField won't be shown.
 * @prop {boolean} hideIfEmpty - If true, the SummaryField won't be shown if its empty.
 * 
 * @todo Doesn't update when values change
 * @todo Add a prop to not show when value is blank
 * @todo Handle when the value sent is 0, right now that will resolve to empty
 */
const SummaryField=({type = "text", value, prop, parent, label, emptyValue, noLabel, visible, hideIfEmpty, flex, half, custom, content})=>{
  if (visible === false) return null
  if (typeof visible === "function" && !visible()) return null
  
  let displayValue = getDisplayValue(value,parent,prop)
  if (checkHideIfEmpty(displayValue,hideIfEmpty)) return null

  else if (displayValue !== 0 && !displayValue){
    displayValue = emptyValue || "none"
  }

  const classes = ["column"]
  if (flex || half) classes.push("flex")

  switch(type){
    case "checkbox": { return(
      <CheckBox readonly value={parent[prop]}>
          {noLabel !== true && displayValue }
      </CheckBox>
    )}
    case "custom" : { return(
      <div>{content}</div>
    )}
    case "text":
    default : 
    { return(
      <div className={ClassList(classes)}>
        {noLabel !== true && <div className="typography-body-light">{label || prop}</div>}
        <div className="typography-body">{displayValue}</div>
      </div>
    )}
  }
}

function getDisplayValue(value,parent,prop){
  if (typeof value === "function")
    return value(parent,prop)

  return parent !== undefined
    ? parent[prop]
    : undefined
}

function checkHideIfEmpty(value,hideIfEmpty){
  return hideIfEmpty && value !== 0 && !value
}

export default SummaryField
class UndoHistory {

  /**
   * @todo Document
   * 
   * @param {*} state 
   * @param {*} value 
   * @param {*} updateFunction This does nothing
   */
  constructor(state,value,updateFunction){
  
    this.history = []
    this.currentHistory = null
  
    this.init(value)

    this._MAX_HISTORY = 50
    this.hasUndo = false
    this.hasRedo = false
    this.updateFunction = updateFunction
  }

  init(value){
    this.history.push({
      value : value,
      caret : value 
        ? { start : String(value).length, end : String(value).length }
        : { start : 0, end : 0 }
    });
    this.currentHistory = value
  }

  change(value,caret){
    if (this.history.length >= this._maxHistory){
      this.history.shift()
    }
    else if (this.currentHistory < this.history.length){
      this.history.splice(this.currentHistory+1,this.history.length-this.currentHistory)
    }
  
    this.history.push({value : value, caret : caret});
    this.currentHistory = this.history.length - 1;
    this.hasUndo = true;
    this.hasRedo = false;
  }

  undo(){
    if (this.currentHistory < 1){ 
      throw "Cannot undo when there are no UndoHistory events to undo."
    }
    this.currentHistory --;
    this.hasUndo = this.currentHistory > 0;
    this.hasRedo = true;

    return this.history[this.currentHistory]
  }

  redo(){
    if (this.currentHistory + 1 > this.history.length){
      throw "Cannot redo when there are no UndoHistory events to redo."
    }
    this.currentHistory ++;
    this.hasUndo = true;
    this.hasRedo = this.currentHistory < this.history.length - 1;

    return this.history[this.currentHistory]
  }
}

export default UndoHistory
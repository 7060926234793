import { databaseRef } from './TransactaFire';

/**
 * Item pointing to a node in firebase
 */
class Item {
  constructor(parent,path,node){
    this._parent = parent
    this._path = path
    this._node = node

    this.loaded = this.attachRef()
  }

  get ref(){
    if (!this._ref) this._ref = databaseRef(this._path)
    return this._ref
  }

  /**
   * Destroys the Item and turns off its ref listener.
   */
  destroy(){
    this.ref.off()
  }

  /**
   * Attaches the Firebase ref to the component with an on value
   * watcher. Whenever the ref changes the state will be updated.
   *
   * @param {*} ref Firebase ref pointing to the data.
   * @param {*} node Name of the node for the ref - e.g ref("user/bars")
   * would have a node of bars.
   */
  attachRef(){
    return new Promise( (resolve,reject) => {
      this.ref.on('value', snapshot => {
        if (!this._parent.mounted) reject()

        let snapshotState = { ...snapshot.val()}
        this._parent.setState({[this._node] : {...snapshotState}})
        resolve(snapshotState)
      })
    })
  }

}
export default Item
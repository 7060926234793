import React from 'react'
import './NotePreview.css'

const NotePreview =(props)=> {
  return (
    <div className="note-preview">
      {props.content}
    </div>)
}

export default NotePreview
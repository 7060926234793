import { userToString, stringToUser } from "../../../../../platform/components/chooser/MultiContactChooser";
import User from "../../../../User/User";
import { Transaction, newKey, Collection, Model, databaseRef } from "../../../../../TransactaFire/TransactaFire";
import { usersToArray } from "../../../../features/messages/views/new/NewMessage";

const aOrAn =(item)=> "aeiouy".includes(item.substring(0,1).toLowerCase()) ? "an" : "a"
const capitalize =a=> a.charAt(0).toUpperCase()+a.substring(1);

const bodyTemplate =(userName,itemType,itemName,url)=>
`<div>
<section>
  <b>${userName}</b> would like to share ${aOrAn(itemType)} ${capitalize(itemType)} called "<b>${itemName}</b>" with you.
</section>
<section>
  Use this <link url="/${url}">Link</link> to accept the ${capitalize(itemType)}.
</section>
</div>`

const subjectTemplate =(userName,itemType,itemName)=>
`${userName} wants to share ${aOrAn(itemType)} ${capitalize(itemType)} called "${itemName}" with you.`

export default class ShareMessage{

  static sendMessage =async(message)=> {
    let transaction = new Transaction()
    let promises = []

    const recipients = message.recipients

    usersToArray(recipients).forEach( recipient => {
      const uid = stringToUser(recipient).uid

      if (uid !== User.uid){
        const key = newKey("message_")
  
        let recipientMessages = transaction.add(new Collection( databaseRef(`${uid}/messages`), "messages", "message_") )
          promises.push( recipientMessages.addToCollection({...message, unread : true, isRecipient: true }, null, key) )

        let recipientUnreadMessagesCount = transaction.add(new Model( databaseRef(`${uid}/data/unreadMessages`), null, true))
        promises.push(recipientUnreadMessagesCount.loaded.then( count => 
          recipientUnreadMessagesCount.set( isNaN(count) ? 1 : ++count ) 
        ))

        let recipientUnreadMessages = transaction.add(new Model( databaseRef(`${uid}/messages/unreadMessages`) ))
          recipientUnreadMessages.change(key,1)
      }
    })

    await Promise.all(promises) 
    transaction.commit()
  }


  static createMessage(itemName,itemType,recipients,senderName,senderId,url){
    return {
      sender : userToString({name : senderName, uid : senderId}),
      subject : subjectTemplate(senderName,itemType,itemName),
      recipients : recipients,
      body : bodyTemplate(senderName,itemType,itemName,url),
      summary : "Use this message to accept the request.",
      created : (new Date()).getTime(),
      noReply : true
    }
  }
}
import React from 'react'

import User from '../../../../User/User';
import { Transaction, Model, Collection, databaseRef } from '../../../../../TransactaFire/TransactaFire';
import UndoableActions from '../../../../undoable/UndoableActions';
import { ToastService } from '../../../../../platform/components/toast/Toast';
import UndoToastButton from '../../../../../platform/components/toast/buttons/undo/UndoToastButton';

import Messages, { MessageSummary } from '../../../messages/views/list/Messages';
import { Header, Title } from '../../../../base/screen/AppScreen';
import NavigationButton from '../../../../../platform/components/sideNavigation/SideNavigationButton';

class FeedbackMessages extends Messages {
  mounted = false

  get viewsRef(){
    if (!this._viewsRef) this._viewsRef = databaseRef(`feedback/messages`)
    return this._viewsRef
  }

  viewNode = "messages"
  itemNode = "feedback"
  lastRoute = "feedbackMessages"

  /**
   * Handles the following query params on page load:
   * * `removeMessage : messageId` - removes the message with the matching id
   * 
   * @override
   */
  handleParams(params){
    let removeMessage = params.get('removeMessage')
      if (removeMessage) this.removeMessage(removeMessage)
  }

  readMessage = async(messageId) =>{
    const unreadMessagesRef = new Model( databaseRef("feedback/unreadMessages"), null, true)
    const unreadMessages = await unreadMessagesRef.loaded
    
    if (unreadMessages && unreadMessages[messageId] !== undefined){
      const transaction = new Transaction()
      const unreadMessageModel = transaction.add(new Model(databaseRef("feedback/unreadMessages").child(messageId)))
      const unreadMessagesModel = transaction.add(new Model(User.dataRef.child("unreadFeedback")))
      
      var count = unreadMessages ? Object.keys(unreadMessages).length-1 : 0

      unreadMessageModel.remove()
      unreadMessagesModel.set(count)
      transaction.commit()
    }
  }

  getItem(messageId){
    return MessageSummary({
      ...this.state[messageId], id : messageId, sref : this.handleLink, formatDate : this.formatDate, 
      unread : this.state.unreadMessages && this.state.unreadMessages[messageId] !== undefined
    })
  }

  /**
   * Removes a message.
   * Waits for viewRef to load into state so that the message can be hidden.
   */
  removeMessage = async(messageId) => {
    this.props.history.replace("/feedbackMessages")
    const message = await this.getMessage(messageId)

    if (!message || !message.subject) return null
    await this.viewsRef.$loaded

    this.hideMessage(messageId)
    this.showRemoveMessage(message,messageId)
  }

  /**
   * Shows an Undo Toast that unhides the message if pressed and deletes the message otherwise.
   */
  showRemoveMessage(message,messageId){
    const act = UndoableActions.addAction(
       this.deleteMessage, 
       this.showMessage, 
       messageId
    )
    const action =()=> UndoableActions.action(act)
    const undo =()=> UndoableActions.undo(act)

    ToastService.showToast(
      `Feedback Message "${message.subject}" was removed`,
      <UndoToastButton action={undo}/>,
      3500
    ).then( action )
  }
  
  /**
   * Deletes a message
   */
  deleteMessage = async (messageId) => {
    let transaction = new Transaction()
    let messages = transaction.add(new Collection(this.viewsRef, this.viewNode, "feedback_"))

    await messages.deleteItems([messageId])
    transaction.commit()
  }

  get fabArea(){
    return null
  }

  get header(){
    return (<>
      <Header primary>
        <NavigationButton/>
        <Title value="User Feedback"/>
      </Header>
    </>)
  }

}

export default FeedbackMessages
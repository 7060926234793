import React from 'react'
import Panel from '../../../popups/popup/panel/Panel';

import './DatePopup.css'
import { shortDays, fullMonths, fullYear, FirstDayInMonth, DaysInMonth } from '../../../../../GlobalUtils/DateAndTime';
import IconButton from '../../../buttons/iconButton/IconButton';

const datePropToDate =(dateProp)=> dateProp instanceof Date ? dateProp : new Date(dateProp)

/**
 * Material spec date picker popup.
 * 
 * @param {String || Date} value
 * @param {Function} update Function called when ok is clicked. Returns the picker's date.
 * @param {Function} close Function called to close the picker.
 * 
 * https://medium.com/@alxsanborn/material-ui-date-picker-and-time-picker-930df28dc1d7
 * https://material-ui.com/demos/pickers/
 *  
 * @todo make change from one month to another use an animated scroll
 * @todo make a mechanism to quickly change year
 * @todo document methods
 */
class DatePickerPopup extends React.Component {
  
  state = {
    date : datePropToDate(this.props.value),
    month : datePropToDate(this.props.value).getMonth() || (new Date()).getMonth(),
    selectedMonth : datePropToDate(this.props.value).getMonth(),
    year : fullYear(datePropToDate(this.props.value).getYear()) || fullYear((new Date()).getYear()),
    selectedYear : fullYear(datePropToDate(this.props.value).getYear()),
    selectedDay : datePropToDate(this.props.value).getDate(),
  }

  closePopup=()=>{
    const { selectedDay, selectedMonth, selectedYear } = this.state
    const date = new Date(selectedYear, selectedMonth, selectedDay)

    this.props.update && this.props.update(date)
    if (this.props.close) this.props.close(date)
  }
  

  selectDay =(day)=> {
    this.setState({
      selectedDay : day,
      selectedMonth : this.state.month,
      selectedYear : this.state.year,
    })
  }
  monthLeft =()=> {
    var {month, year} = this.state
    if ( month > 0) return this.setState({month : --month})
    this.setState({month : 11, year : --year})
  }
  monthRight =()=> {
    var {month, year} = this.state
    if ( month < 11) return this.setState({month : ++month})
    this.setState({month : 0, year : ++year})
  }

  get daysOfWeekHeader(){
    return(
      <div className="day-header">
        { shortDays.map( (day,i) => <div className="day-header__day" key={i}>{day}</div> ) }
      </div>
    ) 
  }

  get monthHeader(){
    const {month, year} = this.state

    return (
      <div className="month-header">
        <IconButton icon="keyboard_arrow_left" clicked={this.monthLeft}/>
        <div className="month-header__month">
          {fullMonths[month]} {year}
        </div>
        <IconButton icon="keyboard_arrow_right" clicked={this.monthRight}/>
      </div>
    )
  }

  get weeks(){
    const {selectDay} = this
    const {month,selectedMonth,year,selectedYear} = this.state

    const selectedDay = (month === selectedMonth && year === selectedYear) ? this.state.selectedDay : -1
    const start = FirstDayInMonth(month+1,year)
    const numberOfDays = DaysInMonth(month+1,year)

    const weeks = []
      weeks.push( Week(selectDay,start,1,numberOfDays,selectedDay) )

    var i = 8-start
    while ( i <= numberOfDays){
      weeks.push( Week(selectDay,0,i,numberOfDays,selectedDay) )
      i+=7
    }

    return weeks
  }

  render(){
    return (
      <div className="popup-panel-container">
        <Panel close={this.closePopup}>
          {this.monthHeader}
          {this.daysOfWeekHeader}
          {this.weeks}
        </Panel>
      </div>
    )
  }
}

const Week =(selectDay,start,firstDay,last,selected)=>{
  let daysInWeek = []
  for( var i=0; i < 7; i++){
    if (start && i < start){
      daysInWeek.push("")
    }
    else if (last && i + firstDay > last){
      daysInWeek.push("")
    } 
    else {
      daysInWeek.push(i+firstDay-start)
    }
  }

  return (
    <div className="date-panel__week" key={firstDay}>
      { daysInWeek.map( (day,i) => 
        selected === day 
          ? <div className="day--selected" key={i}>{day}</div>
          : day === "" ? <div className="day--empty" key={i}></div>
          : <div className="day" onClick={()=>selectDay(day)} key={i}>{day}</div>
      ) }
    </div>
  )
}

export default DatePickerPopup
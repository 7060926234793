import React from 'react'

import './TextArea.css'
import TextInput from '../text/TextInput';

/**
 * An MDI styled text area input.
 * 
 * @param {function} blurred Called when the user defocus the input. Passes the event value in 
 * the first param and the event in the second.
 * @param {function} changed Called when the user types into the input with the value in 
 * the first param and the event in the second.
 * @param {function} pressed Called when the user types presses a key. Passes the key event to
 * the passed in function.
 * @param {String} value - **Required** The value of the input.
 * @param {String} label The label value to show over the input.
 * @param {String} placeholder The value to show when the input is empty.
 * @param {String} list List value used by <datalist/> HTML elements to specify auto suggestions.
 * @param {boolean} autoFocus If true, will autofocus the input.
 * @param {boolean} fullWidth If true, will style the input to use full width.
 * @param {boolean} noLabel If true, will not show floating label.
 * 
 * @todo implement data list functionality
 */
class TextArea extends TextInput {
  
  render(){
    let classes = "text-input text-area no-click-border"
    let style = {}

    const {autofocus, className, list, placeholder, value, fullWidth, noLabel} = this.props
    const {focused, blurred, changed, keyPressed} = this
    const {isFocused} = this.state
    
    if (this.props.style) style = this.props.style
    if (className) classes += ` ${className}`
    if (fullWidth) classes += " full-width"

    const labelFloating = isFocused || value || value === "0" || value === 0
    const label = this.label(this.props.label,placeholder,labelFloating)
    const labelClasses = `text-input__label${labelFloating?" floating":""}`

    const containerClasses = "input-container" + (noLabel ? "" : " has-label")

    return (
      <div className={containerClasses}>
        { !noLabel && <div className={labelClasses}>{label}</div> }
        <textarea
          autoFocus={ autofocus }
          value={(value === undefined || value === null) ? "" : value }
          className={ classes }
          style={ style }
          placeholder={ noLabel && (placeholder || "") }
          onFocus={ focused }
          onBlur={ blurred }
          onChange={ changed }
          onKeyPress={ keyPressed }
        />
      </div>
    )
  }

}

export default TextArea
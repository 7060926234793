import React from 'react'

import { PopupSection } from "../../../../../platform/components/popups/popup/Popup";
import Avatar from "../../../../../platform/components/avatar/Avatar";

/**
 * Choose Contact Section
 * @param {Array[Contact]} contacts 
 */
export class ChooseContactSection extends React.Component {
  state={
    chosenContacts : {}
  }

  clickedContact=(contact)=>{
    const chosenContacts = this.getChosenContacts(contact)
    this.setState({ chosenContacts : chosenContacts })
    this.props.updateChosenContacts && this.props.updateChosenContacts(chosenContacts)
  }

  getChosenContacts(contact){
    return { ...this.state.chosenContacts, 
      [contact] : this.state.chosenContacts[contact] ? false : true }
  }

  render(){
    const contacts = getContactChoices(this.props.contacts,this.clickedContact,this.state.chosenContacts)
    return(
      <PopupSection flex scrollY>
        {contacts}
      </PopupSection>
    )
  }
}

/**
 * Gets an array of ContactChoice components to choose from.
 * @param {*} contactsCollection 
 * @param {*} action 
 * @param {*} selected 
 */
const getContactChoices =(contactsCollection,action,selected)=> {
  let usedContacts = {}
  if (!contactsCollection.contacts) return null

  return Object.keys(contactsCollection.contacts)
    .filter( id => !contactsCollection[id].recordOnly && usedContacts[id] !== true )
    .map( id => 
      ContactChoice({
        id: id,
        ...contactsCollection[id],
        action : action,
        selected : selected[id]
      })
    )
}

/**
 * ContactChoice Component
 * 
 * @param {Contact} props - Should have the following props: id, avatarUrl, name, 
 * action, selected
 */
const ContactChoice =({id,avatarUrl,name,action,selected})=> {
  return (
    <div key={id}
      className="contact-summary"
      onClick={()=>action(id)}>
      <div className="contact-summary__avatar">
        <Avatar src={avatarUrl} selected={selected}/>
      </div>
      <div className="contact-summary__content">
        <div>{name}</div>
      </div>
    </div>)
}
import React from 'react'

import Card, { CardActions } from '../../../../../platform/components/cards/Card';
import TextInput from '../../../../../platform/components/inputs/text/TextInput';
import Menu, { MenuItem } from '../../../../../platform/components/menu/Menu';
import IconButton from '../../../../../platform/components/buttons/iconButton/IconButton';

import './FeatureViewFolder.css'
import { CaptionText } from '../../../../../platform/components/typography/Typography';

const FeatureViewFolder =(props)=> {
  let classes = "shareable-view-folder"

  if (props.reorderStatus === "target"){
    classes += " reorder-target"
  } else if (props.reorderStatus === "reordering"){
    classes += " reordering"
  }

  return (
    <div className={classes}>
      <Card>
        <CardActions>
          <IconButton icon="folder" primary clicked={props.sref}/>
          <TextInput value={props.folder.name} changed={props.changeName} blurred={props.saveName} noLabel/>
          <Menu left dotsVertical>
            <MenuItem clicked={props.reorder}>Reorder</MenuItem>
            <MenuItem clicked={props.delete}>Delete</MenuItem>
          </Menu>
        </CardActions>
        <div style={{marginLeft:"14px",height:"20px",marginTop:"-16px"}}>
          <CaptionText>{`${countItems(props.folder.items)} Items`}</CaptionText>
        </div>
        {
          !props.reorderStatus ? null :
          <div className="feature-view-reordering-area" onClick={props.endReorder}/> 
        }
    </Card>
  </div>
  )
}

const countItems =(items)=> items ? Object.keys(items).length : 0

export default FeatureViewFolder
import React from 'react'

import UiTestPage, {UiTestSection, UiTestRowItem} from '../UiTestPage';

import FlatButton from '../../../platform/components/buttons/textButton/FlatButton'

import {Screen, Body, FabArea} from '../../../PlanReach/base/screen/AppScreen'
import Toast, {ToastService} from '../../components/toast/Toast';
import Fab from '../../components/buttons/FAB/FAB';

/**
 * Cards page is a demo of the Card component, interactive api documentaton, and a test
 * of the card's behaivor and conformance to the Material Spec.
 */
class SnackbarsPage extends React.Component {
  showToast=()=>{
    ToastService.showToast("Hello friends")
  }

  render(){
    return (
      <UiTestPage>
        <UiTestSection name="Snackbars">
          <div>
            Snackbars are popup from the bottom of the screen to show info, confirmation, or a choice. 
          </div>
          <UiTestRowItem name="Message toast">
            <div style={{border : "solid rgba(0,0,0,.35) 1px", margin: "8px"}}>
              <Screen>
                <Body>
                  <div style={{margin : "8px"}}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Mauris sagittis pellentesque lacus eleifend lacinia...
                  </div>
                </Body>
                <FabArea>
                  <Fab clicked={this.showToast} accent icon="add"/>
                </FabArea>
                <Toast>
                  <FlatButton>Accept</FlatButton>
                  <FlatButton>Undo</FlatButton>
                </Toast>
              </Screen>
            </div>
          </UiTestRowItem>
      </UiTestSection>
    </UiTestPage>)
  }
}

export default SnackbarsPage
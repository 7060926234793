/**
 * Merging snapshot changes from Firebase into react state is painful. This service allows react components to
 * use a schema to specify which parts of state come from Firebase and to automaticall null removed data when
 * it was removed in Firebase.
 */
export default class Snapshot {
    
    /**
     * Whenever the snapshot happens, pass the state and the snapshot into this method, along with the schema,
     * to mutate the snapshot so it can be used in setState.
     * 
     * @param {Object} previous Should be the current component state.
     * @param {Object} snapshotState Value returned by Firebase on value method. This will be mutated.
     * @param {Object} schema The data schema for the component.
     * 
     * @todo Make fully recursive
     * 
     * @public
     * @static
     */
    static purgeRemovedItems(previousState,snapshotState,schema){
        if (!schema) return

        Object.keys(schema).forEach( field => {
            if (schema[field].type === "field") 
                Snapshot.purgeRemovedField(previousState,snapshotState,schema[field].name)
            else if (schema[field].type === "collection"){
                Snapshot.purgeRemovedFromCollection(previousState,snapshotState,schema[field].name)
            }
            else if (schema[field].type === "orderedCollection"){
                Snapshot.purgeRemovedFromCollectionAndFields(previousState,snapshotState,schema[field].name)
            }
        })
        return snapshotState
    }

    /**
     * Returns true if the property exists in previous but was removed in the updated
     * @private
     */
    static valueWasRemove(previous,updated,field){
        return (previous[field] !== undefined && previous[field] !== null) &&
          (updated[field] === undefined || updated[field] === null)
      }
    
    /**
     * Nulls the field if it was removed from updated
     * @private
     */
    static purgeRemovedField(previous,updated,field){
        if (Snapshot.valueWasRemove(previous,updated,field)){
            updated[field] = null
        }
        return updated[field]
    }
    
    /**
     * Nulls all of the items in the field if it was removed from updated. If the field was removed, the entire 
     * field is nulled.
     * @private
     * @todo Add ability to ignore certain items
     */
    static purgeRemovedFromCollection(previous,updated,field){
        if (Snapshot.valueWasRemove(previous,updated,field))
            return updated[field] = {}

        Object.keys(previous[field]).forEach( key => {
            if (Snapshot.valueWasRemove(previous[field], updated[field], key)) 
                updated[field][key] = null
        })

        return updated[field]
    }

    /**
     * Nulls out removed collections items and the field they point to. If the field was removed, the entire field
     * is nulled out, as are each item in the collection.
     * @todo Add ability to ignore certain items
     * @private
     */
    static purgeRemovedFromCollectionAndFields(previous,updated,field){
        if (Snapshot.valueWasRemove(previous,updated,field)){
            
            let children = {...updated[field]}
            Object.keys(children).filter( id => children[id] !== null && children[id] !== undefined)
                .forEach( id => updated[id] = null)

            return updated[field] = {}
        }

        if (!previous[field]){
            return updated[field]
        }

        Object.keys(previous[field]).forEach( key => {
            if (Snapshot.valueWasRemove(previous[field], updated[field], key)){
                
                updated[field][key] = null
                updated[key] = null
            }
        })

        return updated[field]
    }
}
import React from 'react'
import MultiAddItem from '../../../../base/widgets/multiAddItem/MultiAddItem'
import ViewDetail from '../../../../base/view/list/collection/ViewDetail'

import { Transaction, Model, Collection, newKey, databaseRef } from '../../../../../TransactaFire/TransactaFire'
import User from '../../../../User/User'
import { Header, Title, BackButton, EditableTitle } from '../../../../base/screen/AppScreen'
import FieldIterator from '../../../../base/widgets/multiFieldWidgets/fieldIterator/FieldIterator';
import Menu, { MenuItem } from '../../../../../platform/components/menu/Menu';

class Contact extends ViewDetail {
  
  lastRoute = "contacts"

  componentDidMount(){
    this.contactId = this.getReactRouterParam().contactId;
    const params = new URLSearchParams(this.props.location.search.slice(1))
    this.handleParams(params)

    this.viewNode = `${User.uid}/contacts`
    this.viewUrl = "contacts"
    this.mounted = true
    this.contactsRef = databaseRef(`${User.uid}/contacts`)

    this.viewRef = databaseRef(`${User.uid}/contacts/${this.contactId}`)

    this.viewRef.on('value', snapshot => {
      if (this.mounted && typeof snapshot.val() === "object"){
        let snapshotState = {...snapshot.val()}
        this.setState({...snapshotState})
      }
    })
  }

  handleParams(params){
    let newContact = params.get('newContact')
    if (newContact==="recordOnly"){
      this.setState({newContact : true})
      this.recordOnly = true
    }
  }
  removeContact =()=> {
    if (!this.mounted) return
    this.setState({redirect: `/contacts?removeContact=${this.contactId}`})
  }

  hasBeenSaved =()=> {
    return !this.state.newContact
  }

  addPhone =(phone)=> {
    this.addItem(phone,"phone","phones")
  }
  addEmail =(email)=> {
    this.addItem(email,"email","emails")
  }
  addLocation =(location)=> {
    this.addItem(location,"location","locations")
  }

  addItem =(item,type,node)=> {
    const transaction = new Transaction()
    const model = transaction.add(new Model(this.viewRef,this.state))
    const key = newKey(`${type}_`)

    model.change(`${node}/${key}`, 2)
    model.change(key, item)
    transaction.commit()
  }
  editProp =(prop,value) => {
    if (value instanceof Object)
      this.viewRef.child(prop).update(value)
    else
      this.viewRef.child(prop).set(value)
  }
  editItem =(item)=> {
    this.viewRef.child(item.id).update(item)
  }
  changeName =(name)=> {
    this.editProp("name",name)
  }
  deletePhone =(phone)=> {
    this.deleteItem(phone,"phone","phones")
  }
  deleteEmail =(email)=> {
    this.deleteItem(email,"email","emails")
  }
  deleteLocation =(location)=> {
    this.deleteItem(location,"location","locations")
  }
  deleteItem =(item,type,node)=> {
    const transaction = new Transaction()
    const model = transaction.add(new Model(this.viewRef,this.state))
    const key = item.id

    model.change(`${node}/${key}`, null)
    model.change(key, null)
    transaction.commit()
  }

  /**
   * Temporarily changes the contact's name
   */
  changeContactName =(value)=> {
    this.setState({name : value})
  }

  /**
   * Save the contact's name. If the contact is new, the contact is committed for the first time and added to the 
   * user's contact views collection.
   */
  saveContactName = async value =>{
    if (value !== 0 && !value) return
    this.setState({newContact : false})
    if (await this.isNew()) return this.saveNewContact(value)

    let transaction = new Transaction()

    let contact = transaction.add(Model.newAtPath(`${User.uid}/contacts/${this.contactId}`))
      contact.change("name",value)

    return transaction.commit()
  }
  
  /**
   * Tests if a contact is new by checking if its already save in firebase
   */
  async isNew(){
    let snapshot = await this.viewRef.once("value")
    let value = snapshot.val();
    return value === null || value.name === null || value.name === undefined
  }

  /**
   * Saves a new contact
   */
  async saveNewContact(name){
    let transaction = new Transaction()
    let contacts = transaction.add(new Collection(this.contactsRef,"contacts","contact_"))

    let contact = {
      name : name,
      recordOnly : this.recordOnly
    }

    await contacts.addToCollection(contact, null, this.contactId)
    transaction.commit()
  }

  infoFields = [ "nickname", "useNickname", "company", "companyRole", "birthday" ]

  /**
   * Saves the info fields. Passed into the Contact Info FieldIterator.
   */
  saveInfo =(info)=> {
    const transaction = new Transaction()
    const infoModel = transaction.add(new Model(this.viewRef))

    this.infoFields.forEach( field => {
      if (info[field] !== null && info[field] !== undefined)
      infoModel.change(field, info[field])
    })
    transaction.commit()
  }
  nicknameSummaryDisplayName =()=> {
    if(!this.state.nickname) return null
    if(!this.state.useNickname) return this.state.nickname
    return `"${this.state.nickname}" replacing "${this.state.name}"`
  }
  nicknameVisible =()=> {
    return !(this.recordOnly || this.state.recordOnly)
  }
  combineProfileFieldWithField =(field)=> {
    var contactField = this.state[field]
    var profileField = this.state[`profile_${field}`]

    if (profileField === contactField) return contactField
    if (!profileField && contactField) return contactField
    if (profileField && !contactField) return profileField

    return (profileField || profileField === 0 || profileField === "0" )
      ? `${contactField} (${profileField})`
      : contactField
  }
  companyDisplayName =()=> this.combineProfileFieldWithField("company")
  companyRoleDisplayName =()=> this.combineProfileFieldWithField("companyRole")
  birthdayDisplayName =()=> this.combineProfileFieldWithField("birthday")

  /**
   * Only used for MultiItem
   */
  isProfileItem=(item)=>{
    return !item.profile
  }

  get screenBody(){
  return (
    <div className="scrollable-column">
      <FieldIterator
        available={this.hasBeenSaved}
        parent={this.state}
        icon="person"
        label="Info"
        saveItem={this.saveInfo}
        paddingLeft="38"
        items={this.infoFields}
        summaryLines={[
          { value : this.nicknameSummaryDisplayName, label : "Nickname", visible : this.nicknameVisible, hideIfEmpty : true },
          { section : true, 
              left : { half : true, value : this.companyDisplayName, label : "Company", hideIfEmpty : true},
              right : { half : true, value : this.companyRoleDisplayName, label :"Company Role", hideIfEmpty : true},
          },
          { value : this.birthdayDisplayName, label :"Birthday", hideIfEmpty : true }
        ]}
        editLines={[
          { section : true, 
            visible : this.nicknameVisible,
            left : { flex : true, prop : "nickname", label :"Nickname", type: "input" },
            right : { prop : "useNickname", label :"Nickname", type: "checkbox" },
          },
          { section : true, 
            left : { half : true, prop : "company", label :"Company", type: "input" },
            right : { half : true, prop : "companyRole", label :"Company Role", type: "input" },
          },
          { prop : "birthday", label :"Birthday" }
        ]}/>
        <MultiAddItem
          available={this.hasBeenSaved}
          items={this.state.phones}
          parent={this.state}
          itemButton={ {clicked : this.deletePhone, icon : 'cancel'} }
          icon="phone"
          label="Phone numbers"
          itemAvailable={this.isProfileItem}
          saveItem={this.editItem}
          addItem={this.addPhone}
          addLabel="Add phone"
          emptyMessage="Phone numbers shown to your contacts."
          lines={[
            { prop : "number", label :"Phone number" },
            { prop : "label", label :"Label for number" }
          ]}
        />
        <MultiAddItem
          available={this.hasBeenSaved}
          items={this.state.emails}
          parent={this.state}
          itemButton={ {clicked : this.deleteEmail, icon : 'cancel'} }
          icon="email"
          label="Email addresses"
          itemAvailable={this.isProfileItem}
          saveItem={this.editItem}
          addItem={this.addEmail}
          addLabel="Add email"
          emptyMessage="Emails addresses shown to your contacts."
          lines={[
            { prop : "email", label :"Email address" },
            { prop : "label", label :"Label for email address" }
          ]}
        />
        <MultiAddItem
          available={this.hasBeenSaved}
          items={this.state.locations}
          parent={this.state}
          itemButton={ {clicked : this.deleteLocation, icon : 'cancel'} }
          icon="place"
          label="Locations"
          itemAvailable={this.isProfileItem}
          saveItem={this.editItem}
          addItem={this.addLocation}
          addLabel="Add locations"
          emptyMessage="Your locations shown to your contacts."
          lines={[
            { prop : "address", label :"Location address" },
            { prop : "label", label :"Label for Location" }
          ]}
        />
      </div>
    )
  }

  get header(){
    return (<Header primary>
      <BackButton clicked={this.handleBackLink}/>
      { this.recordOnly 
        ? <EditableTitle value={this.state.name} changed={this.changeContactName} blurred={this.saveContactName} placeholder="Contact name"/>
        : <Title value={this.state.name}/>
      }
      <Menu left>
        <MenuItem clicked={this.removeContact}>Remove Contact</MenuItem>
      </Menu>
    </Header>)
  }
}

export default Contact
/* Date and Time Functions */

export const shortDays = ["Su","Mo","Tu","We","Th","Fr", "Sa"]
export const mediumDays = ["Sun","Mon","Tue","Wed","Thu","Fri", "Sat"]
export const shortMonths = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
export const fullMonths = ["January","February","March","April","May","June","July","August","September","October","November","December"]

export const fullYear =(year)=> parseInt(year) + 1900

const padZero =(m)=> (m < 10 ? "0" : "" ) + m
const amOrPm =(date)=> date.getHours()>=12 ? 'pm' : 'am'

/**
 * Formats a date based on how much time has elapsed. Informal
 * format mimicing google's usage.
 */
export const elapsedTimeFormat =(aDate, aDate2 = (new Date()).getTime())=> {
  let date = (aDate instanceof Date) ? aDate : new Date(aDate)
  let timeSinceNow = aDate2 - date.getTime()

  if (timeSinceNow < 60000) return `${Math.floor(timeSinceNow/1000)}s`
  if (timeSinceNow < 3600000) return `${Math.floor(timeSinceNow/60000)}m`
  if (timeSinceNow < 86400000) return `${Math.floor(timeSinceNow/3600000)}h`
  if (timeSinceNow < 604800000) return mediumDays[date.getDay()]

  let month = shortMonths[date.getMonth()]
  return `${month} ${date.getDate()}`
}

/**
 * Formats a date similar to google's informal format.
 */
export const informalTimeFormat =(aDate, aDate2 = (new Date()).getTime())=> {
  const date = (aDate instanceof Date) ? aDate : new Date(aDate)
  const timeSinceNow = aDate2 - date.getTime()
  
  const hours = date.getHours() % 12
  let month = shortMonths[date.getMonth()]

  if (timeSinceNow < 43200000) return `${hours ? hours : 12}:${padZero(date.getMinutes())} ${amOrPm(date)}`
  if (timeSinceNow < 86400000) return `${mediumDays[date.getDay()]} ${hours ? hours : 12} ${amOrPm(date)}`
  if (timeSinceNow < 604800000) return mediumDays[date.getDay()]
  return `${month} ${date.getDate()}`
}

/**
 * Short date format - "`Sun`, `Mar` `2017`".
 */
export const shortDateFormat =(aDate)=> {
  const date = (aDate instanceof Date) ? aDate : new Date(aDate)
  return `${mediumDays[date.getDay()]}, ${shortMonths[date.getMonth()]} ${date.getDate()}`
}

/**
 * Number date format - "`11`/`02`/`2017`".
 */
export const numberDateFormat =(aDate)=> {
  const date = (aDate instanceof Date) ? aDate : new Date(aDate)
  return `${padZero(date.getMonth()+1)}/${padZero(date.getDate())}/${date.getFullYear()}`
}

/**
 * Short time format - "`Sun`, `Mar` `2017` at `10`:`43` `am`".
 */
export const shortTimeFormat =(aDate)=> {
  const date = (aDate instanceof Date) ? aDate : new Date(aDate)

  const hours = date.getHours() % 12
  let month = shortMonths[date.getMonth()]

  return `${mediumDays[date.getDay()]}, ${month} ${date.getDate()} at ${hours ? hours : 12}:${padZero(date.getMinutes())} ${amOrPm(date)}`
}

/**
 * DaysInMonth
 * 
 * @param {*} month with 1 as January and 12 as Decmber
 * @param {*} y Year, used to handle leap years
 * 
 * This is the boolean + binary days in month approach created by TrueBlueAussie
 */
export const DaysInMonth=(month, year)=> (month === 2) ? (!((year % 4) || (!(year % 100) && (year % 400))) ? 29 : 28) : 30 + ((month + (month >> 3)) & 1);

/**
 * Determines the day of week for the first day of a month in a particular year.
 * 
 * @param {*} month with 1 as January and 12 as Decmber
 * @param {*} year, used to handle leap years
 * 
 * This is the boolean + binary days in month approach created by TrueBlueAussie
 */
export const FirstDayInMonth =(month, year)=> (new Date(year + "-" + (month) + "-01")).getUTCDay()
/**
 * Service for invoking toast/snackbar messages.
 * 
 * Only one snackbar/toast area can exist at a time. Creating a new
 * toast/snackbar will unregister the old one.
 */
export default class ToastService {
  
  static index = 0
  state = {
    shown : false
  }

  /**
   * Shows a toast or snackbar for the current toast area.
   * @param {*} message Message text to show.
   * @param {*} actions Buttons to be shown on the toast.
   * @param {*} durration How long to keep the toast up. Defaults to 3 seconds.
   * 
   * @returns Promise that resolves when toast expires. That promise has an index
   * corresponding to the toast it goes to.
   */
  static showToast(message, actions, durration = 3000){
    let index = ToastService.index++
    let promise = new Promise( (res,rej) => {
      if (!this.toast || !this.toast.mounted) return rej(); // Should throw error, or at least warn
      if (actions) this.toast.setActions(actions, res);
      this.toast.setState({ shown : index, message : message})
      this.toastCountdown(durration, res, index)
    })

    promise.index = index
    return promise
  }
  
  static toastCountdown( durration = 3000, res, index ){
    if (durration === false || durration < 0 ) return;
    setTimeout(() => {
      res()
      this.hideToast(index)
    }, durration);
  }

  static hideToast(index){
    if (!this.toast || !this.toast.mounted || 
      (index !== undefined && this.toast.state.shown !== index)  ) return;
    this.toast.setState({ shown : false })
  }

  /**
   * Handles binding the current screen's toast/snackbar to the ToastService
   * @param {*} toast React Toast component
   */
  static bindToast(toast){
    this.toast = toast;
  }
}
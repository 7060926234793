import React from 'react'
import './Avatar.css'

class Avatar extends React.Component {
  state = { loaded : false }
  
  get bodyCheckedAvatar(){
    return (<div className="avatar-placeholder-anchor">

    </div>)
  }

  get bodyAvatarIcon(){
    return (<svg
      className="avatar-placeholder"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ariahidden="true"
      focusable="false"
      id="svg8"
      version="1.1"
      viewBox="0 0 33.866666 33.866668"
      height="128" width="128">
      <g transform="translate(0,-263.13332)" id="layer1">
        <g id="g3806">
          <rect style={{fill:this.backgroundColor,fillOpacity:"1",strokeWidth:"0.26458335"}} id="rect3796" width="33.866669" height="33.866669" x="-4.4408921e-16" y="263.13333"/>
          <circle style={{fill:this.foregroundColor,fillOpacity:"1",strokeWidth:"0.45641622"}} id="path3737" cx="16.933332" cy="276.23019" r="6.3499999"/>
          <path style={{fill:this.foregroundColor,fillOpacity:"1",strokeWidth:"1.97656679"}} d="M 64,81.189453 A 39.999998,18.905501 0 0 0 24.009766,100 H 24 V 100.0937 128 h 80 V 100.09375 100 h -0.008 A 39.999998,18.905501 0 0 0 64,81.189453 Z" transform="matrix(0.26458334,0,0,0.26458334,0,263.13332)" id="path3737-3"/>
          <path style={{fill:this.foregroundColor,fillOpacity:"1",strokeWidth:"1.97656679"}} d="M 30.823959,8.5274651 11.773927,27.577532 3.0427081,18.846314 5.2811017,16.607893 11.773927,23.084924 28.585614,6.2891351 Z" transform="matrix(0.26458334,0,0,0.26458334,0,263.13332)" id="path3737-5"/>
        </g>
      </g>
    </svg>)
  }

  get bodyAvatarIconChecked(){
    return (<svg
      className="avatar-placeholder"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ariahidden="true"
      focusable="false"
      id="svg8"
      version="1.1"
      viewBox="0 0 33.866666 33.866668"
      height="128" width="128">
      <g transform="translate(0,-263.13332)" id="layer1">
        <g id="g3806">
          <rect style={{fill:this.backgroundColor,fillOpacity:"1",strokeWidth:"0.26458335"}} id="rect3796" width="33.866669" height="33.866669" x="-4.4408921e-16" y="263.13333"/>
          <circle style={{fill:this.foregroundColor,fillOpacity:"1",strokeWidth:"0.45641622"}} id="path3737" cx="16.933332" cy="276.23019" r="6.3499999"/>
          <path style={{fill:this.foregroundColor,fillOpacity:"1",strokeWidth:"1.97656679"}} d="M 64,81.189453 A 39.999998,18.905501 0 0 0 24.009766,100 H 24 V 100.0937 128 h 80 V 100.09375 100 h -0.008 A 39.999998,18.905501 0 0 0 64,81.189453 Z" transform="matrix(0.26458334,0,0,0.26458334,0,263.13332)" id="path3737-3"/>
        </g>
      </g>
      <path style={{fill:"#ffffff",strokeWidth:"1.32291651"}} d="M 28.508854,9.9284432 12.633828,25.803498 5.3578127,18.527483 7.2231407,16.662132 12.633828,22.059658 26.643567,8.0631688 Z" id="path32"/>
    </svg>)
  }

  get imageAvatarChecked(){
    return (<svg
      className="avatar-placeholder-over"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ariahidden="true"
      focusable="false"
      id="svg8"
      version="1.1"
      viewBox="0 0 33.866666 33.866668"
      height="40" width="40">
      <rect style={{fill:"#00000042",strokeWidth:"0.26458335"}} id="rect3796" width="40" height="40" x="0" y="0"/>
      <path style={{fill:"#ffffffde", strokeWidth:"1.32291651"}} d="M 28.508854,9.9284432 12.633828,25.803498 5.3578127,18.527483 7.2231407,16.662132 12.633828,22.059658 26.643567,8.0631688 Z" id="path32"/>
    </svg>)
  }
  
  get backgroundColor(){
    if (this.props.backgroundColor) return this.props.backgroundColor
    if (this.props.gray) return '#cfcfcf'
    return '#a0c3ff'
  }

  get foregroundColor(){
    if (this.props.foregroundColor) return this.props.foregroundColor
    if (this.props.gray) return '#919191'
    return '#4374e0'
  }

  render(){

    return (
      <div className="avatar" style={this.props.style}>
        <div className="avatar-placeholder-anchor">
        { !this.state.loaded && (
          !this.props.selected
            ? this.bodyAvatarIcon
            : this.bodyAvatarIconChecked
        )}
        { this.state.loaded && this.props.selected && (
            this.imageAvatarChecked
        )}
        </div>
    <img className="avatar-image"
      onLoad={()=>this.setState({loaded:true})}
      alt=""
      src={this.props.src}/>
    </div>
  )}
}

export default Avatar
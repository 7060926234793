import React from 'react'

import PopupScrim from '../../../popups/PopupScrim'
import Panel from '../../../popups/popup/panel/Panel'

import MinuteCircle from './MinuteCircle'
import HourCircle from './HourCircle'

import FlatButton from '../../../buttons/textButton/FlatButton'

import './TimePopup.css'
import '../../../../colors/colors.css'

/**
 * Material spec time picker popup.
 * 
 * @param {Function} update Function called when ok is clicked with the picker's time passed as a 
 * parameter. The time will be passed as a Date object or Timestring 
 * @param {Function} close Function called to close the picker.
 * @param {String} time The time in H:MM format.
 * 
 * https://medium.com/@alxsanborn/material-ui-date-picker-and-time-picker-930df28dc1d7
 * https://material-ui.com/demos/pickers/
 *  
 * @todo Clock Face should pad t/l/r 32px, bottom 8px 
 * @todo When the screen scrolls, the minute picker fails
 * @todo should leverage and extend popup, right now it copypastas 
 * 
 * @version 3
 *   @todo invert the minute numbers when its only partialy highlited 
 *   @todo Reactive clock face size
 *   @todo 2 Animate time arm when changing selected 
 */
class TimePickerPopup extends React.Component {
  
  state = {
    time : this.props.time,
    view : "hours",
    hour : parseInt(this.props.time.split(" ")[0].split(":")[0]),
    minutes : parseInt(this.props.time.split(" ")[0].split(":")[1]),
    am : (val => val !== "pm")(this.props.time.split(" ")[1]) 
  }

  views = {
    hours : "hours",
    minutes : "minutes"
  }

  padMinutes =(minutes)=> {
    return minutes < 10 ? "0" + minutes : minutes
  }
  formatTime =(hour,minutes,am)=> {
    return `${hour}:${this.padMinutes(minutes)} ${am === true ? "am":"pm"}`
  }
  changeHourMinuteView =(view)=> {
    this.setState({view : view})
  }
  changeAmPmHandler =(am)=> {
    this.setState( {am : am, time : this.formatTime(this.state.hour, this.state.minutes, am)} )
  }
  changeHourHandler =(hour)=> {
    this.setState({
      hour : hour,
      time : this.formatTime(hour,this.state.minutes,this.state.am)
    })
  }
  changeMinutesHandler =(minute,event)=> {
    let minutes = Math.round(this.getMinuteAngle(event.clientX,event.clientY)/6)
    if (minutes === 60) minutes = 0;

    this.setState({
      minutes : minutes,
      time : this.formatTime(this.state.hour,minutes,this.state.am)
    })
  }

  getMinuteAngle =(eventX,eventY)=> {
    let centerX = this.clockFaceEl.offsetLeft + this.clockFaceEl.offsetWidth/2
    let centerY = this.clockFaceEl.offsetTop + this.clockFaceEl.offsetHeight/2
    let angle = Math.atan2(eventY - centerY, eventX - centerX) * 180 / Math.PI;
    return (angle + 450) % 360
  }

  getLineAngle =(sx,sy,ex,ey)=> {
    return  Math.atan2(sy - ey, sx - ex) * 180 / Math.PI;
  }

  getClockContents = (state)=> {
    if (state.view === "hours"){
      return this.getHourButtons(256,38,this.state.hour)
    } else if (state.view === "minutes"){
      return this.getMinuteButtons(256,38,this.state.minutes)
    }
  }
  
  getMinuteButtons =(diameter,padding,selected)=>{
    let minuteButtons = [];
    this.getMinutePositions(diameter,padding,selected).forEach( position => {
      let minuteButton = (
        <MinuteCircle 
          x={position.x} y={position.y}
          number={position.number}
          clicked={this.changeMinutesHandler}
          subMinute={position.number % 5 !== 0}
          selected={position.number===selected}/>
      )
      minuteButtons.push(minuteButton)
    })
    minuteButtons.unshift(this.getClockHand())
    return minuteButtons
  }

  getMinutePositions =(diameter,padding,selected)=>{
    return this.getTimePositions(parseFloat(diameter),parseFloat(padding),selected,60,15,0)
  }

  getHourButtons =(diameter,padding,selected)=>{
    let hourButtons = [];
    this.getHoursPositions(diameter,padding,selected).forEach( position => {
      let hourButton = (
        <HourCircle 
          x={position.x}
          y={position.y}
          number={position.number}
          clicked={this.changeHourHandler}
          selected={position.number===selected}/>
      )
      hourButtons.push(hourButton)
    })
    hourButtons.unshift(this.getClockHand())
    return hourButtons
  }

  getHoursPositions =(diameter,padding,selected)=>{
    return this.getTimePositions(parseFloat(diameter),parseFloat(padding),selected,12)
  }

  getTimePositions =(diameter,padding,selected,itemCount=12,offset=2,add=1)=> {
    let positions = []
    for(var i = 0; i < itemCount; i++) {
      let x = diameter/2 + (diameter-padding) * Math.cos(2 * Math.PI * i / itemCount)/2;
      let y = diameter/2 + (diameter-padding) * Math.sin(2 * Math.PI * i / itemCount)/2;
      let count = i + offset < itemCount ? i + offset : i + offset - itemCount;
      let number = count+add

      if (number === selected) this.hand = {
        x : x, y : y, angle : this.getLineAngle(diameter/2,diameter/2,x,y,)
      }
      positions.push({ number : number, x : x, y : y})
    }
    return positions;
  }

  getClockHand =()=>{
    return (
      <div className="clock-circle-hand">
        <svg height="256" width="256">
          <line x1={this.hand.x} y1={this.hand.y} x2="128" y2="128"
            style={{stroke:"#607d8b",fill:"#607d8b",strokeWidth:"2"}} />
        </svg>
      </div>
    )
  }

  render(){
    let hourClass = this.state.view === "hours" ? "clock-time-selected" : "clock-time"
    let minuteClass = this.state.view === "minutes" ? "clock-time-selected" : "clock-time"
    let amClass = this.state.am === true ? "clock-time-selected" : "clock-time"
    let pmClass = this.state.am !== true ? "clock-time-selected" : "clock-time"

    return (
      <div>
        <PopupScrim clickedOutside={null}>
          <Panel>
            <div className="time-popup-header colors-fill-primary whiteframe-1dp">
              <div style={{flex:1, display:"inline-block", textAlign : "right"}}
              className={hourClass}
                onClick={() => this.changeHourMinuteView("hours")}>
                  {this.state.hour}
              </div>
              <div className="clock-time">:</div>
              <div className="clock-right-container">
                <div className={minuteClass}
                  onClick={() => this.changeHourMinuteView("minutes")}>
                  {this.padMinutes(this.state.minutes)}
                </div>
                <div className="clock-am-pm-container">
                  <FlatButton 
                    className={amClass}
                    clicked={()=>this.changeAmPmHandler(true)}>AM</FlatButton>
                  <FlatButton
                    className={pmClass}
                    clicked={()=>this.changeAmPmHandler(false)}>PM</FlatButton>
                </div>
              </div>
            </div>
            <div className="body">
              <div className="clock-circle-holder"> 
                <div className="clock-circle" 
                  ref={el => this.clockFaceEl = el}>
                  <div className="clock-circle-dot"/>
                  {this.getClockContents(this.state)}
                </div>
              </div>
            </div>
            <div className="whiteframe-1dp" style={{padding:"8px",display:"flex"}}>
              <div style={{flex:1}}/>
              <FlatButton primary 
                clicked={()=> (this.props.update && this.props.update(this.state.time)) ||
                  (this.props.close && this.props.close())}>
                Ok
              </FlatButton>
              <FlatButton clicked={()=>this.props.close && this.props.close()}>
                Cancel
              </FlatButton>
            </div>
          </Panel>
        </PopupScrim>
      </div>
    )
  }
}

export default TimePickerPopup;
import React from 'react'

import RaisedButton from '../../../../../platform/components/buttons/textButton/RaisedButton'
import DetailScreen from '../../../../base/view/detail/DetailScreen'
import Divider from '../../../../../platform/components/dividers/Divider'
import DataFire from '../../../../../TransactaFire/DataFire'
import User from '../../../../User/User';

import MultiContactChooser from '../../../../../platform/components/chooser/MultiContactChooser';

import { BackButton, Title } from '../../../../base/screen/AppScreen';
import { ToastService } from '../../../../../platform/components/toast/Toast';
import { informalTimeFormat } from '../../../../../GlobalUtils/DateAndTime';

import IconButton from '../../../../../platform/components/buttons/iconButton/IconButton';
import Menu, { MenuItem } from '../../../../../platform/components/menu/Menu';
import { Transaction, Model, databaseRef } from '../../../../../TransactaFire/TransactaFire';

import './Message.css'
import { QuoteEntries } from '../../quotes/QuoteMessage';
import MessageParser from '../../parser/MessageParser';

class Message extends DetailScreen {

  shareableNode = "message"
  viewNode = "messages"

  componentDidMount(){
    super.componentDidMount()

    this.getContent()
    this.contactsRef = databaseRef(`${User.uid}/contacts`)
    DataFire.bindRef(this,this.contactsRef,this.contactSchema)
  }

  getContent(){
    databaseRef(`${User.uid}/messages/${this.shareableId}`).once(
      "value", snapshot => {
        return new Promise( (resolve, reject) => {
          if (!snapshot.val()) reject("Couldn't load message")

          let value = snapshot.val()
          this.setState({...value})
        })
      }
    )
  }

  contactSchema = {
    contacts : {
      type : "orderedCollection",
      name : "contacts",
    }
  }

  state = { }

  /**
   * Opens the newMessage screen specifying this message to respond to
   */
  respondToMessage =()=> {
    this.props.history.push(`/newMessage?respond=${this.shareableId}`)
  }

  /**
   * Similar to responding - 
   */
  forwardMessage = () => {
    this.props.history.push(`/newMessage?forward=${this.shareableId}`)
  }

  markMessageUnread =async()=> {
    const transaction = new Transaction()
    const unreadMessagesCount = transaction.add(new Model(User.dataRef.child("unreadMessages")))
    const unreadMessageModel = transaction.add(new Model(databaseRef(`${User.uid}/messages`).child("unreadMessages"),null,true))

    const unreadMessages = await unreadMessageModel.loaded
    var count = unreadMessages ? Object.keys(unreadMessages).length : 0
    if (!unreadMessages || !unreadMessages[this.shareableId]){
      count ++
      unreadMessageModel.change(this.shareableId,count)
    }

    unreadMessagesCount.set(count)

    transaction.commit()
    ToastService.showToast(`Marked "${this.state.subject}" unread`)
  }

  removeMessage =()=> {
    if (!this.mounted) return
    this.props.history.replace(`/messages?removeMessage=${this.shareableId}`)
  }

  recipientsToArray=(recipients)=>{
    if (!this.state.recipients) return []
    return this.state.recipients.split(",").map( a => a.trim)
  }

  get screenBody(){
    return (
    <div className="flex column">
      { this.recipients }
      <Divider/>
      <div style={{padding : "0px 16px"}}>
        <MessageParser>{this.state.body}</MessageParser>
      </div>
      { QuoteEntries(this.state.quotes) }
    </div>
    )
  }

  get recipients(){
    const recipients = this.state.recipients
    return (
      <div><MultiContactChooser readOnly label="To" value={recipients}/></div>
    )
  }

  get screenHeader(){
    return (<>
      <BackButton clicked={this.handleBackLink}/>
      <Title value={this.state.subject}/>
      <div className="message-header__time typography-body">
        { informalTimeFormat(this.state.created)}
      </div>
      <IconButton clicked={this.respondToMessage} icon="reply"/>
      <Menu>
        <MenuItem clicked={this.forwardMessage}>Forward</MenuItem>
        <MenuItem clicked={this.markMessageUnread}>Mark Unread</MenuItem>
        <MenuItem clicked={this.removeMessage}>Delete</MenuItem>
      </Menu>
    </>)
  }

  get footer(){
    return (
      <div className="column">
        <Divider/>
        <div className="rightItems">
          <RaisedButton primary clicked={this.sendMessage}>Send</RaisedButton>
        </div>
      </div>
    )
  }

  changeNoteName =(value)=> {
    this.setState({name : value})
  }
}

export default Message
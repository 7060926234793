import React from 'react'

import UiTestPage, {UiTestSection, UiTestRow, UiTestRowItem} from '../UiTestPage';
import CheckBox from '../../components/inputs/checkBox/CheckBox';

/**
 * Cards page is a demo of the Card component, interactive api documentaton, and a test
 * of the card's behaivor and conformance to the Material Spec.
 */
class CheckBoxPage extends React.Component {

  state={
    checkBox0 : true,
    checkBox1 : false,
    checkBox2 : true,
    checkBox3 : true
  }

  changeItemField=(value,field)=>{
    this.setState({[field] : value})
  }

  render(){
    return (
      <UiTestPage>
        
        <UiTestSection name="Toggle Controls">

          <UiTestRow name="Check Boxes">
            <UiTestRowItem name="Check Box">
              <div>
                <CheckBox value={this.state.checkBox0} 
                  changed={value=>this.changeItemField(value,"checkBox0")}>
                  Check Box
                </CheckBox>
              </div>
            </UiTestRowItem>
            <UiTestRowItem name="Readonly">
              <CheckBox value={this.state.checkBox1}
                readonly
                changed={value=>this.changeItemField(value,"checkBox1")}>
                Check Box
              </CheckBox>
            </UiTestRowItem>
            <UiTestRowItem name="Readonly Checked">
              <CheckBox value={this.state.checkBox2}
                readonly
                changed={value=>this.changeItemField(value,"checkBox1")}>
                Check Box
              </CheckBox>
            </UiTestRowItem>
            <UiTestRowItem name="Disabled">
              <CheckBox value={this.state.checkBox1}
                disabled
                changed={value=>this.changeItemField(value,"checkBox2")}>
                Check Box
              </CheckBox>
            </UiTestRowItem>
            <UiTestRowItem name="Disabled Checked">
              <CheckBox value={this.state.checkBox2}
                disabled
                changed={value=>this.changeItemField(value,"checkBox2")}>
                Check Box
              </CheckBox>
            </UiTestRowItem>
            <UiTestRowItem name="Primary">
              <CheckBox
                primary
                value={this.state.checkBox3}
                changed={value=>this.changeItemField(value,"checkBox3")}>
                Check Box
              </CheckBox>
            </UiTestRowItem>
            <UiTestRowItem name="Accent">
              <CheckBox
                accent
                value={this.state.checkBox3}
                changed={value=>this.changeItemField(value,"checkBox3")}>
                Check Box
              </CheckBox>
            </UiTestRowItem>
            <UiTestRowItem name="Warn">
              <CheckBox
                warn
                value={this.state.checkBox3}
                changed={value=>this.changeItemField(value,"checkBox3")}>
                Check Box
              </CheckBox>
            </UiTestRowItem>
            <UiTestRowItem name="Colored">
              <CheckBox
                color="#9c27b0"
                value={this.state.checkBox3}
                changed={value=>this.changeItemField(value,"checkBox3")}>
                Check Box
              </CheckBox>
            </UiTestRowItem>
          </UiTestRow>

          <UiTestRow name="Switch" dark>
          </UiTestRow>

          <UiTestRow name="Radio Button" dark>
          </UiTestRow>
        </UiTestSection>
      </UiTestPage>
    )
  }
}
export default CheckBoxPage
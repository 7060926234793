import React from 'react'

import IconButton from '../buttons/iconButton/IconButton';
import MenuBase from './MenuBase';

import './Menu.css'
import './MenuItem.css'
import { ColorsDelegate } from '../../delegates/ColorsDelegate';
import { ClassList } from '../../delegates/ClassNameDelegate';

/**
 * @property {boolean} dotsVertical - if set uses the vertical dots icon
 * @property {boolean} dotsHorizontal - if set uses the horizontal dots icon
 */
class Menu extends MenuBase {

  static menuItemHeight = 48
  static menuTopAndBottomPadding = 16

  get menuHeight(){
    if (!this.props.children) return Menu.menuTopAndBottomPadding*2
    if (!this.props.children.length) return Menu.menuItemHeight + Menu.menuTopAndBottomPadding*2
    return this.props.children.length * Menu.menuItemHeight + Menu.menuTopAndBottomPadding*2
  }
  getMenu(){
    let icon = "menu"
    if (this.props.icon){
      icon = this.props.icon
    } else if (this.props.dots || this.props.dotsVertical){
      icon = "more_vert"
    } else if (this.props.dotsHorizontal) (
      icon = "more_horiz"
    )

    return <IconButton icon={icon} clicked={()=>this.showMenuHandler()}/>
  }
}

const MenuItem =(props)=> {
  let styles = { color : "black" }
  let classes= ["menu-item"]
  
  if (props.selected) classes.push("selected")
  if (props.flex) classes.push("flex")
  if (props.disabled){
    styles.opacity = ".26"
    classes.push("disabled")
  }

  const menuItemClicked =(...args)=> {
    if (props.closeMenu !== false) props.hideMenu();
    if (props.clicked) props.args ? props.clicked(props.args) : props.clicked();
  }

  ColorsDelegate(props,classes,styles,{notBackground:true})
  return (
    <div className={ClassList(classes)} style={styles}
      onClick={()=> menuItemClicked() }>
      {props.children}
    </div>
  )
}

export { MenuItem }
export default Menu
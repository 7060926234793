import React from 'react'

import RaisedButton from '../../../../../platform/components/buttons/textButton/RaisedButton'
import DetailScreen from '../../../../base/view/detail/DetailScreen'
import Divider from '../../../../../platform/components/dividers/Divider'
import DataFire from '../../../../../TransactaFire/DataFire'
import User from '../../../../User/User';

import { BackButton, Title } from '../../../../base/screen/AppScreen';
import { ToastService } from '../../../../../platform/components/toast/Toast';
import { informalTimeFormat } from '../../../../../GlobalUtils/DateAndTime';

import IconButton from '../../../../../platform/components/buttons/iconButton/IconButton';
import Menu, { MenuItem } from '../../../../../platform/components/menu/Menu';
import { Transaction, Model, databaseRef } from '../../../../../TransactaFire/TransactaFire';
import { BodyText, BodyTextBold } from '../../../../../platform/components/typography/Typography';

// import './Message.css'

class FeedbackMessage extends DetailScreen {

  shareableNode = "message"
  viewNode = "feedbackMessages"

  componentDidMount(){
    super.componentDidMount()

    this.getContent()
    this.contactsRef = databaseRef(`${User.uid}/contacts`)
    DataFire.bindRef(this,this.contactsRef,this.contactSchema)
  }

  getContent(){
    databaseRef(`feedback/messages/${this.shareableId}`).once(
      "value", snapshot => {
        return new Promise( (resolve, reject) => {
          if (!snapshot.val()) reject("Couldn't load message")

          let value = snapshot.val()
          this.setState({...value})
        })
      }
    )
  }

  contactSchema = {
    contacts : {
      type : "orderedCollection",
      name : "contacts",
    }
  }

  state = { }

  /**
   * @todo implement
   */
  respondToMessage = () => {
    ToastService.showToast("Need to implement")
  }

  /**
   * @todo implement
   */
  forwardMessage = () => {
    ToastService.showToast("Need to implement")
  }

  markMessageUnread =async()=> {
    const transaction = new Transaction()
    const unreadMessagesCount = transaction.add(new Model(User.dataRef.child("unreadMessages")))
    const unreadMessageModel = transaction.add(new Model(databaseRef(`${User.uid}/messages`).child("unreadMessages"),null,true))

    const unreadMessages = await unreadMessageModel.loaded
    var count = unreadMessages ? Object.keys(unreadMessages).length : 0
    if (!unreadMessages || !unreadMessages[this.shareableId]){
      count ++
      unreadMessageModel.change(this.shareableId,count)
    }

    unreadMessagesCount.set(count)

    transaction.commit()
    ToastService.showToast(`Marked "${this.state.subject}" unread`)
  }

  removeMessage =()=> {
    if (!this.mounted) return
    this.props.history.replace(`/feedbackMessages?removeMessage=${this.shareableId}`)
  }

  get screenBody(){
    const { type, body } = this.state
    return (
    <div className="flex column">
      <div style={{padding : "16px"}}>
        <BodyTextBold>Type: </BodyTextBold>
        <BodyText>{type && type.name}</BodyText>
      </div>
      <Divider/>
      <pre style={{padding : "0px 16px"}}>{body}</pre>
    </div>
    )
  }

  get screenHeader(){
    return (<>
      <BackButton clicked={this.handleBackLink}/>
      <Title value={this.state.subject}/>
      <div className="message-header__time typography-body">
        { informalTimeFormat(this.state.created)}
      </div>
      <IconButton icon="reply"/>
      <Menu>
        <MenuItem clicked={this.forwardMessage}>Forward</MenuItem>
        <MenuItem clicked={this.markMessageUnread}>Mark Unread</MenuItem>
        <MenuItem clicked={this.removeMessage}>Delete</MenuItem>
      </Menu>
    </>)
  }

  get footer(){
    return (
      <div className="column">
        <Divider/>
        <div className="rightItems">
          <RaisedButton primary clicked={this.sendMessage}>Send</RaisedButton>
        </div>
      </div>
    )
  }
}

export default FeedbackMessage
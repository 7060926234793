import React from 'react'
import './IconButton.css'
import Icon from '../../icons/Icon'
import { ColorsDelegate } from '../../../delegates/ColorsDelegate'
import { ClassList } from '../../../delegates/ClassNameDelegate'

/**
 * A button with a single MDI icon.
 * 
 * @param {void} primary If true, the button will be style with the app's primary color
 * @param {string} icon Required. The mdi icon to show.
 * @param {string} color Optional color in hex format string. 
 * 
 * @todo ensure className doesn't pass to Icon
 * https://stackoverflow.com/questions/35152522/react-transferring-props-except-one
 */
const IconButton =(props)=> {
  let classes = ["icon-button"]
  if (props.classes) classes.push(...props.classes.split(" "))
  if (props.className) classes.push(...props.className.split(" "))

  let containerClasses = "icon-button-container"
  let styles = {}

  ColorsDelegate(props,classes,styles,{notBackground:true})
  if (props.flex) containerClasses += " flex"

  if (props.disabled) { 
    classes.push("disabled")
    styles.opacity = ".26"
  }

  if (props.small) classes.push("small")

  return (
    <div className={containerClasses}>
      <button className={ClassList(classes)} onClick={()=>props.clicked && props.clicked()}>
        <Icon styles={styles} {...props}/>
      </button>
    </div>
  )
}

export default IconButton
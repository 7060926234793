import React from 'react'

import './PopupScrim.css' 

/**
 * This widget is use
 * @todo support a default close when click outside method
 */
const PopupScrim =(props)=> {
  
  return (<div className="PopupScrim" 
    onClick={(event)=> props.clickedOutside && props.clickedOutside(event)}>
    {props.children}
  </div>)
}

export default PopupScrim;
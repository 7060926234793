import React from 'react'

import TextInput from '../../../../../../platform/components/inputs/text/TextInput';
import CheckBox from '../../../../../../platform/components/inputs/checkBox/CheckBox';

import ClickOutsideService from '../../../../../../platform/services/clicked-outside/ClickOutsideService';

import './QuickListItem.css';

class QuickListItem extends React.Component {

  state = {}

  componentDidMount(){
    this.mounted = true
    this.textInputParent = React.createRef();
    this.ClickedOutside = 
    this.refs.item.clickOutsideId = ClickOutsideService.register(
      this.refs.item,(e)=>this.clickedOutside(e)
    )
    if (this.props.item.$new){
      this.setState({selected : true})
      setTimeout(()=>this.focusInput(),1)      
    }
  }

  componentDidUpdate (previousProps,previousState){
    if (this.props.$new || (this.props.focus && !previousProps.focus)){
      this.focusInput()
    }
  }

  componentWillUnmount(){
    ClickOutsideService.unregister(this.refs.item.clickOutsideId)
    this.mounted = false
  }

  clickedOutside =(e)=> {
    this.deslect(this.props.id)
  }

  /**
   * Focuses the textInput if the focus props was updated to true.  
   * 
   * Note: The checkbox is also an inputs so focusing the second item gets the
   * textInput.
   */
  focusInput(){
    let inputs = this.refs.item.getElementsByTagName('INPUT')
      inputs[inputs.length-1].focus()
    if (!this.state.selected) this.select()
  }

  select =()=> {
    if (!this.mounted) return
    this.setState({ selected : true })
    ClickOutsideService.setActive(this.refs.item.clickOutsideId)
    this.props.selectItem(this.props.id)
    this.props.showSubheader(this.props.id)
  }

  deslect =()=> {
    if (!this.mounted) return
    this.setState({ selected : false })
    this.props.deselectItem(this.props.id)
    ClickOutsideService.setInactive(this.refs.item.clickOutsideId)
    this.props.hideSubheader(this.props.id)
  }

  checkItem =(value)=> {
    this.props.save(value,"checked",this.props.id,this.props.item.$new)
  }
  changeName =(value)=> {
    this.props.changed(value,"name",this.props.id)
  }
  blurName =(value)=> {
    this.props.save(value,"name",this.props.id,this.props.item.$new)
  }
  keyDown =e=> {
    if (e.key === 'Enter') {
      this.props.nextItem(this.props.id)
      this.deslect(this.props.id)
    }
  }

  render(){
    let itemClasses = "quicklist-item"
    let itemReorderingclasses = "quicklist-item-reordering-area"
    if (this.props.reorderStatus === "target") itemReorderingclasses += " target"
    if (this.state.selected || this.props.item.$new) itemClasses += " selected"

    return (<>
      <div className={itemClasses}
        ref={inputParent => this.textInputParent = inputParent}
        key={this.props.id}>
        <div style={{display : "flex", flexDirection: "row"}}>
          <div style={{display : "flex", flexDirection: "row", flex: "1"}}
            ref="item" onClick={()=>this.select()}>
            <CheckBox
              value={this.props.item.checked}
              changed={ this.checkItem } />
            <TextInput
              fullWidth
              noLabel
              placeholder="Item name"
              value={this.props.item.name}
              blurred={ this.blurName }
              changed={ this.changeName } 
              pressed={this.keyDown} />
            {  
              this.props.reorderStatus === false ? null :
              <div className={itemReorderingclasses}
                onClick={this.props.endReorder}/>
            }
          </div>
          <div className="quick-list-item__right-spacer"/>
        </div>
      </div>
      </>
    )
  }
}

export default QuickListItem
import React from 'react'

import ClickOutsideService from "../../services/clicked-outside/ClickOutsideService";
import TextInput from "../inputs/text/TextInput";
import Chip from "../chips/Chip";
import Chooser from "./Chooser";

import './MultiContactChooser.css'

/**
 * @todo Create a single ContactChooser
 * 
 * @prop {boolean} readOnly
 * @prop {boolean} advanceOnEnter If set, when the user presses return the method current choice will "advance" (i.e, the blur method will fire)
 */
export default class MultiContactChooser extends Chooser {
  state = {
    current : "",
    view : "summary",
  }

  componentDidMount(){
    this.mounted = true
    this.refs.chooser.clickOutsideId = ClickOutsideService.register(
      this.refs.chooser,(e)=>this.clickedOutside(e))
  }

  componentWillUnmount(){
    this.mounted = false
    ClickOutsideService.unregister(this.refs.chooser.clickOutsideId)
  }

  clickedOutside=()=>{
    ClickOutsideService.setInactive(this.refs.chooser.clickOutsideId)
    this.setState({view : "summary"})
  }
  editContacts=()=>{
    if (this.props.readOnly) return null

    ClickOutsideService.setActive(this.refs.chooser.clickOutsideId)
    this.setState({view : "editContacts"})
  }

  blurred=()=>{
    if (this.state.current && this.props.blurred){
      if (!this.props.onlyAllowChoices || this.valueInChoices(this.state.current)){
        this.props.blurred(this.valueToChoiceValue(this.state.current))
      }
    } 
    this.setState({
      current : "",
    })
  }

  keyDown=(e)=>{
    if (this.props.advanceOnEnter && e.key === 'Enter') {
      this.blurred()
    }
  }

  /**
   * Calls the parent's remove timeout function. The function also adds a 10 mill delay so that the
   * item being removed doesn't race the clickedOutside service and cause false positives.
   */
  removed=(...args)=>{
    setTimeout(()=> this.props.removed && this.props.removed(...args), 10);
  }

  ContactList =()=> {
    if (typeof this.props.value !== "string" || this.props.value === "") return <span></span>

    return this.props.value.split(",")
      .map( a => a.trim())
      .map( contact => this.ContactChip(contact))
  }

  ContactChip =(contact)=> {
    const user = stringToUser(contact)
    return <Chip id={contact} key={user.uid} removed={this.removed}>{user.name}</Chip>
  }

  get views(){
    switch (this.state.view){

      case "editContacts": 
      { return (
        <div className="column">
          <div className="wrap-row contact-area">
            <div className="multicontactchooser__linelabel">To: </div>
            { this.ContactList() }
            <TextInput fullWidth value={this.state.current} changed={this.changed} blurred={this.blurred} placeholder={this.props.placeholder}
              list="Chooser" autofocus pressed={this.keyDown}/>
          </div>
        </div>
      )}

      case "addContact": 
      { return (
        <TextInput value={this.state.current} changed={this.changed} blurred={this.blurred} placeholder={this.props.placeholder}
          list="Chooser" autofocus/>
      )}
      
      case "summary": default:
      { return (
        <div onClick={this.editContacts} className="multicontactchooser__row">
          <div className="multicontactchooser__linelabel" style={{maxWidth: "40px"}}>{this.props.label}:</div>
          {contactStringToUser(this.props.value)}
        </div>
      )}
    }
  }

  render(){
    return (<div className="column" ref="chooser">
      { this.views }
      { this.datalist() }
    </div>)
  }
}

/**
 * Converts a user string into a user object
 */
export const stringToUser=(str)=> {
  const userSplit = str.replace("]", "").split("[")
  return {
    name: userSplit[0].trim(),
    uid: userSplit[1].trim() 
  }
}

/**
 * Converts a user object into a user string
 */
export const userToString=(user)=> `${user.name} [${user.uid}]`

/**
 * Converts a string of contacts into just their names
 * @note a single regex could probably achieve this and be more effecient - however, I am shit at writing regexes so split->map->reduce we go.
 */
export const contactStringToUser=(str)=> !str ? "" : str.split(",").map( a => a.replace(  /\[.*\]/, ''  )).reduce( (a,b) => `${a.trim()}, ${b.trim()}` )
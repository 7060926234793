

export default class ChangedTimeOutDelay {
  constructor(callback, delayLength = 1000){
    this.changedTimeOut = null
    this.callback = callback
    this.delayLength = delayLength
  }
  
  /**
   * Clears the timeout and prevents the callback from firing. Useful when cleaning up the parent.
   */
  clear(){
    if (this.changedTimeOut || this.changedTimeOut == 0 ) clearTimeout(this.changedTimeOut)
  }

  /**
   * Resets the callback preventing it from firing until the the reset timeout expires.
   * @param  {...any} args Optional args to be passed to the callback
   */
  changed(...args){
    this.clear()
    this.changedTimeOut = setTimeout(()=>this.callback(...args), this.delayLength);
  }

  /**
   * Calls the callback immediately canceling the timeout. Use for things like blurring a delay being watched.
   * @param  {...any} args Optional args to be passed to the callback
   */
  end(...args){
    this.clear()
    this.callback(...args)
  }
}
import React from 'react'
import TimePopup from '../../components/pickers/timePicker/timePopup/TimePopup';

import UiTestPage, {UiTestSection} from '../UiTestPage';
import DatePickerPopup from '../../components/pickers/datePicker/popup/DatePopup';
import Popup, { PopupSubHeader, PopupHeader } from '../../components/popups/popup/Popup';
import { shortDateFormat } from '../../../GlobalUtils/DateAndTime';
import PopupArea, { PopupService } from '../../components/popups/PopupArea';
import RaisedButton from '../../components/buttons/textButton/RaisedButton';

class DialogsPage extends React.Component {

  state = {
    showScrim : false,
    showDateScrim : false,
    time : "9:44",
    date : new Date()
  }

  updateTimeHandler=(time)=>{ this.setState({time : time}) }
  updateDateHandler=(date)=>{ this.setState({date : date}) }

  showScrimHandler=()=>{ this.setState({showScrim : !this.state.showScrim}) }
  hideScrimHandler=()=>{ this.setState({showScrim : false}) }

  showDateScrimHandler=()=>{ this.setState({showDateScrim : !this.state.showDateScrim}) }
  hideDateScrimHandler=()=>{ this.setState({showDateScrim : false}) }

  showPopop=()=>{ 
    this.testPopupId = PopupService.showPopup(this.testPopup)
  }
  showPopopInside=()=>{
    this.testPopupInsideId = PopupService.showPopup(this.testPopupInside)
  }

  hidePopup=()=>{
    PopupService.closePopup()
  }

  render(){
    let dialog = null

    if (this.state.showScrim){
      dialog = (
        <TimePopup 
          time={this.state.time}
          close={this.hideScrimHandler}
          update={this.updateTimeHandler}/>
      )
    } else if (this.state.showDateScrim){
      dialog = (
        <DatePickerPopup
          value={this.state.date}
          close={this.hideDateScrimHandler}
          update={this.updateDateHandler}/>
      )
    }

    return (
      <UiTestPage>
        <UiTestSection name="Dialog Test">
        <div>Current Time: {this.state.time}</div>
        <div>Current Date: {shortDateFormat(this.state.date)}</div>
        <button onClick={()=>this.showScrimHandler()}>Show Time Scrim</button>
        <button onClick={()=>this.showDateScrimHandler()}>Show Date Scrim</button>
        <button onClick={()=>this.showPopop()}>Show Popup</button>
        <button onClick={()=>this.showPopopInside()}>Show Popup Inside</button>
        {dialog}
        </UiTestSection>
        <PopupArea/>
      </UiTestPage>
    )
  }

  testPopup = (
    <Popup clickedOutside={()=>this.hidePopup()}>
      <PopupHeader>Test Popup</PopupHeader>
      <PopupSubHeader>A Subheader</PopupSubHeader>
        <div style={{padding:"16px"}}>
          Some Content
        </div>
        <RaisedButton clicked={this.showPopopInside}>Internal Popup</RaisedButton>
    </Popup>
  )

  testPopupInside = (
    <Popup clickedOutside={()=>this.hidePopup()}>
      <PopupHeader>Popup Inside a Popup</PopupHeader>
      <div style={{padding:"16px"}}>
        More Content
      </div>
    </Popup>
  )

}

export default DialogsPage
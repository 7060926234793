/**
 * This library is used to manipulate *ItemOrder* objects.
 * 
 * The **ItemOrder** patern consists of using a single JavaScript *Object* with a list of keys and values. 
 * 
 * The value represents the order of the keys and should go from **0** to **length-1** with no dupplicates. (*If dupplicates are*
 * *detected, the item and all subsequent items have their values padded.*)
 *  
 * As **React's** *setState* method will not delete keys, key with a value of **null** or **undefined** will be ignored by all of 
 * the **Reordering's** methods.
 * 
 * Although *ItemOrder* is agnostic to how the keys are used, they are designed to support pointing to root level properties in
 * a parent **Object** or **state**.
 */
export default class Reordering {

  /**
   * Sorts an ItemOrder object and removes invalid items (value of **null** or **undefined**)
   * @param {ItemOrder} items - the items to sort.
   * 
   * @return {Array} An array of the keys from the **items** passed in sorted by their value.
   */  
  static sortItems(items){
    return Object.keys(items)
    .filter( a => items[a] !== undefined && items[a] !== null )
    .sort( (a,b) => items[a] - items[b] )
  }

  /**
   * Gets the items before the passed in item. Does not return the item.
   * @param {number} item - the item's id.
   * @param {ItemOrder} items - the items collection.
   * 
   * @return {Array} An array of the keys from the **items** before the item.
   */
  static itemsBefore(item,items){
    if (!Number.isInteger(items[item]))
      return Reordering.sortItems(items)

    return Reordering.sortItems(items)
      .filter( a =>  a !== item && items[a] < items[item])
  }

  /**
   * Gets the items after the passed in item. Does not return the item.
   * @param {number} item - the item's id.
   * @param {ItemOrder} items - the items collection.
   * 
   * @return {Array} An array of the keys from the **items** after the item
   */
  static itemsAfter(item,items){
    if (!Number.isInteger(items[item]))
      return Reordering.sortItems(items)

    return Reordering.sortItems(items)
      .filter( a =>  a !== item && items[a] > items[item])
  }

  /**
   * Moves an item in a collection to the positon of another item.
   * @param {*} item1 the id of the item being moved
   * @param {*} item2 the id of the destination item
   * @param {*} items Key value collection with id as the key and the position as the value.
   * 
   * @todo Support optional param explictly stating whether to be bofore or after the destination
   * (defaults to being before if move backwards and behind if moving forward)
   */
  static reorderItem(item1,item2,items){
    let sorted = Reordering.sortItems(items)
      sorted.forEach( (id,i) => items[id] = i);

    //if (!this.validateReorderItem(position,destination,items)) return null;

    let position = sorted.findIndex( a => a === item1)
    let destination = sorted.findIndex( a => a === item2)
    let insertBefore = position > destination

    sorted.splice(position, 1)
    sorted.splice(insertBefore ? destination : destination, 0, item1)
    sorted.forEach( (id,i) => items[id] = i )
    return items
  }

  /**
   * Moves a range of items in a collection to another position.
   * @param {Array} range an array containing the items being moved in the order they be inserted in
   * @param {Number} destinationId the id of the destination item
   * @param {Object} items key value collection with id as the key and the position as the value.
   * 
   * @todo Support optional param explictly stating whether to be bofore or after the destination
   * (defaults to being before if move backwards and behind if moving forward)
   * 
   * @todo Update to use Reordering.sortItems
   * @todo Test
   */
  static reorderItemRange(range,destinationId,items){
    let startPosition = items[range[0]]
    let length = range.length
    let endPosition = items[range[length-1]]
    let destination = items[destinationId]
    if (!this.validateReorderItemRange(startPosition,endPosition,destination,items)) return null;

    // Moving item down
    if (startPosition < destination){
      Object.keys(items)
        .filter( id => items[id] > endPosition && items[id] <= destination )
        .forEach( id => items[id] -= length)
      range.forEach( id => items[id] += destination - endPosition)

    // Moving item up
    } else {
      Object.keys(items)
        .filter( id => items[id] >= destination && items[id] < startPosition )
        .forEach( id => items[id] += length)
      range.forEach( id => items[id] -= startPosition - destination)
    }
    return items;
  }

  /**
   * Inserts an item into an item collection at a particular index. **This method mutates the items collection passed in.**
   * 
   * @param {String} item the id of the item being moved
   * @param {Number} index the index in the items to be inserted
   * @param {Array} items Key value collection with id as the key and the position as the value.
   * 
   * @todo Support optional param explictly stating whether to be bofore or after the destination
   * (defaults to being before if move backwards and behind if moving forward)
   */
  static insertItem(item,index,items){
    let sorted = Reordering.sortItems(items)
    let  i = 0

    sorted.forEach( id => {
      if (i === index) i++
      items[id] = i++
    })

    items[item] = index
    return items;
  }

 /**
   * Adds an item to the end of the **ItemOrder**.
   * 
   * @param {String} item the id of the item being added
   * @param {ItemOrder} items **ItemOrder** is Key-Value collection with ids as the keys and the positions as the values.
   * 
   * @return items. The mutated **ItemOrder** is returned. Can be chained into other **Reording** methods.
   */
  static appendItem(item,items){
    let sorted = Reordering.sortItems(items)
    sorted.push(item)
    
    sorted.forEach( (id,i) => items[id] = i);
    return items;
  }

  /**
   * Removes an item from the **ItemOrder**.
   * 
   * @param {String} item the id of the item being added
   * @param {ItemOrder} items **ItemOrder** is Key-Value collection with ids as the keys and the positions as the values.
   * 
   * @return items. The mutated **ItemOrder** is returned. Can be chained into other **Reording** methods.
   */
  static removeItem(item,items){
    let sorted = Reordering.sortItems(items)
    let position = sorted.findIndex( a => a === item)
    if (position !== -1){
      sorted.splice(position,1)
    }
    sorted.forEach( (id,i) => items[id] = i);
    delete items[item]
    return items;
  }

  /**
   * Validates that item1 and item2 have valid positions and that items is an array with items
   */
  static validateReorderItem(position,destination,items){
    return (position || position === 0) && (destination || destination === 0) &&
      items && Object.keys(items).length > 1
  }

  /**
   * Validates that start and end positions are valid in relation to the destination and that
   * items is an array with items
   */
  static validateReorderItemRange(startPosition,endPosition,destination,items){
    return (startPosition || startPosition === 0) && 
      (endPosition || endPosition === 0) &&
      (destination || destination === 0) && 
      (items && Object.keys(items).length > 1) &&
      (startPosition > destination || endPosition < destination)
  }

  /**
   * Replaces one item with another. Equivalent to removing item1 and then adding item2 at the 
   * same position.
   * 
   * @param {String} item1 item to be replaced
   * @param {String} item2 item to replace item1 with
   * @param {ItemOrder} items **ItemOrder** is Key-Value collection with ids as the keys and the positions as the values.
   * 
   * @return items. The mutated **ItemOrder** is returned. Can be chained into other **Reording** methods.
   */
  static replaceItem(item1,item2,items){
    let sorted = Reordering.sortItems(items)
    let position = sorted.findIndex( a => a === item1)
    sorted[position] = item2

    sorted.forEach( (id,i) => items[id] = i);
    items[item1] = null
    return items
  }
}

import React from 'react'

import './DatePicker.css'
import IconButton from '../../buttons/iconButton/IconButton';
import DatePickerPopup from './popup/DatePopup';
import DateInput from '../../inputs/date/DateInput';

/**
 * @todo Document
 */
class DatePicker extends React.Component {

  state = {
    showPopup : false
  }

  showDatePopup =()=> {
    const { showPanel } = this.props

    showPanel && showPanel()
    this.setState({showPopup:true})
  }

  closeDatePopup =(date)=> {
    const { closePanel, blurred, changed } = this.props

    if (closePanel) closePanel(date)
    if (blurred) blurred(date)
    if (changed) changed(date)

    this.setState({showPopup:false})
  } 

  render () {
    return (<>
      <div className="date-picker">
        <DateInput {...this.props}/>
        <IconButton icon="event" clicked={this.showDatePopup}/>
      </div>
      { this.state.showPopup &&
        <DatePickerPopup
          value={this.state.date}
          close={this.closeDatePopup}
          update={this.updateDateHandler}/>
      }
    </>)
  }

}

export default DatePicker;
import React from 'react'

import ShareableViews from '../../../../base/view/list/shareable/ShareableViews'
import QuickListPreview from './preview/QuickListPreview'

class QuickLists extends ShareableViews {
  mounted = false

  shareablePrefix = "quickList"
  viewNode = "quickLists"
  shareableNode = "quickList"
  shareableName = "Quick Lists"
  pluralName = "QuickLists"
  newName = "New Quick List"

  lastRoute = "quickLists"

  preview(props){
    return <QuickListPreview {...props}/>
  }
}

export default QuickLists
import React from 'react'

import MenuBase from '../../menu/MenuBase'
import TriangleDown from '../../triangleDown/TriangleDown'
import { MenuItem } from '../../menu/Menu'

import './SelectPicker.css'

/**
 * @param {Object} value
 * @param {Collection} items 
 * @param {String} placeholder
 * @param {function} changed
 */
class SelectPicker extends MenuBase {

  static menuItemHeight = 48
  static menuTopAndBottomPadding = 16

  get choices(){
    return this.props.items
  }

  getMenuContents(){
    return this.choices.map( this.createMenuItem )    
  }

  createMenuItem = (item) => {
    return (
      <MenuItem key={item.id}
        selected={this.props.value === item.id}
        hideMenu={this.hideMenuHandler}
        clicked={()=>this.selectChangeHandler(item.id)}>
          {item.name}
      </MenuItem>)
  }

  selectChangeHandler =(selectedValue)=>{
    this.setState({showMenu : false})
    if (this.props.changed) this.props.changed(selectedValue,this.props.field)
  }

  getMenu(){
    let width = this.menuWidth

    if (!this.props.items || this.props.items[this.props.value] === undefined){
      return (
        <div className="select-picker-menu-item" style={{minWidth: `${width}px`}}>
          <span className="placeholder-text">{this.props.placeholder ? 
            this.props.placeholder : "Choose a value"}</span>
          { this.props.readonly ? null : <TriangleDown/> }
        </div>
      )
    } else {
      let item = this.props.items[this.props.value];
      return (
        <div key={item.id} className="select-picker-menu-item" style={{minWidth: `${width}px`}}>
          <span className="text">{item.name}</span>
          { this.props.readonly ? null : <TriangleDown/> }
        </div>
      )
    }
  }
}

export default SelectPicker;
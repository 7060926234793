import React from 'react'
import './QuickListPreview.css'
import CheckMark from '../../../../../../platform/components/inputs/checkBox/CheckMark'

const QuickListPreview =(props)=> {
  if (!props) return null

  const items = Object.values(props).map( (item,i) => {
    const {name,isSection} = item
    let className = isSection ? "quick-list-preview__section" : "quick-list-preview__item" 
    
    return (
      <div className={className} key={i}>
        { checkBox(item) }
        { name }
      </div>)
  })

  return (
    <div className="quick-list-preview">
      {items}
    </div>)
}

const checkBox =({checked,isSection})=> {
  if (isSection) return null
  if (!checked) return <div className="quick-list-preview__checkbox"/>
  return <div className="quick-list-preview__checkbox--checked"><CheckMark color="white"/></div>
}

export default QuickListPreview
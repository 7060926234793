import React from 'react'

import { DisplayText1, DisplayText2, DisplayText3, DisplayText4, HeadlineText, TitleText,
  SubtitleText, SubtitleText2, BodyText, BodyTextBold, CaptionText, BodyTextLight } 
  from '../../../platform/components/typography/Typography'
import UiTestPage, {UiTestSection, UiTestRow} from '../UiTestPage';

/**
 * Cards page is a demo of the Card component, interactive api documentaton, and a test
 * of the card's behaivor and conformance to the Material Spec.
 */
class PickersPage extends React.Component {

  render(){
    return (
      <UiTestPage>

        <UiTestSection name="Display Styles">
          <UiTestRow name="Display 4">
            <DisplayText4>Very Big Text</DisplayText4>
          </UiTestRow >
          <UiTestRow name="Display 3">
            <DisplayText3>Realy Big Text</DisplayText3>
          </UiTestRow>
          <UiTestRow name="Display 2">
            <DisplayText2>Pretty Big Text</DisplayText2>
          </UiTestRow>
          <UiTestRow name="Display 1">
            <DisplayText1>Big Text</DisplayText1>
          </UiTestRow>
        </UiTestSection>

        <UiTestSection name="Title Styles">
          <UiTestRow name="Headline">
            <HeadlineText>Headline</HeadlineText>
          </UiTestRow>
          <UiTestRow name="Title">
            <TitleText>Title</TitleText>
          </UiTestRow>
          <UiTestRow name="Subtitle">
            <SubtitleText>Subtitle</SubtitleText>
          </UiTestRow>
          <UiTestRow name="Subtitle 2">
            <SubtitleText2>Subtitle Bold</SubtitleText2>
          </UiTestRow>
        </UiTestSection>

        <UiTestSection name="Body Styles">
          <UiTestRow name="Body Text">
            <BodyText>Normal Text</BodyText>
          </UiTestRow>
          <UiTestRow name="Body Text Bold">
            <BodyTextBold>Bold Text</BodyTextBold>
          </UiTestRow>
          <UiTestRow name="Body Text Light">
            <BodyTextLight>Bold Text</BodyTextLight>
          </UiTestRow>
          <UiTestRow name="Caption Text">
            <CaptionText>Caption</CaptionText>
          </UiTestRow>
        </UiTestSection>

      </UiTestPage>
    )
  }

}

export default PickersPage
import React from 'react'
import Card, { CardContent, CardActions } from '../../../../../../platform/components/cards/Card';
import TextInput from '../../../../../../platform/components/inputs/text/TextInput';
import Menu, { MenuItem } from '../../../../../../platform/components/menu/Menu';

import './FeatureView.css'
import User from '../../../../../User/User';
// import Preview from '../preview/Preview';

const FeatureView =(props)=> {
  let classes = "shareable-view"

  const {owner} = props.feature

  if (props.reorderStatus === "target"){
    classes += " reorder-target"
  } else if (props.reorderStatus === "reordering"){
    classes += " reordering"
  }

  return (
    <div className={classes}>
      <Card>
        <CardContent>
          <div className="shareable-view-preview" onClick={props.sref}>
            {props.preview || null}
          </div>
        </CardContent>
        <CardActions>
          <TextInput value={props.feature.name} changed={props.changeName} blurred={props.saveName} noLabel/>
          <Menu left dots>
            {menuItems(props,owner)}
          </Menu>
        </CardActions>
        {
          !props.reorderStatus ? null :
          <div className="feature-view-reordering-area"
            onClick={props.endReorder}/> 
        }
      </Card>
    </div>
  )
}

function menuItems(props,owner){
  let items = []
    items.push(<MenuItem key="copy">Copy</MenuItem>)
    items.push(<MenuItem key="reorder" clicked={props.reorder}>Reorder</MenuItem>)
    items.push(<MenuItem key="sharing" clicked={props.showShareItemPopup}>Sharing</MenuItem>)
    if (props.feature.parent){
      items.push(<MenuItem key="moveUp" clicked={props.moveUpFolder}>Move Up</MenuItem>)
    }
    else if (props.hasFolders){
      items.push(<MenuItem key="changeFolder" clicked={props.moveToFolder}>Change Folder</MenuItem>)
    }
    if (owner === User.uid){
      items.push(<MenuItem key="delete" clicked={props.delete}>Delete</MenuItem>)
    }
    else {
      items.push(<MenuItem key="unshare" clicked={props.unshare}>Unshare</MenuItem>)
    }

  return items
}

export default FeatureView
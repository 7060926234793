import React from 'react'

import './UiTestPage.css'

const Page =(props)=> {
  return (
    <div className="uiTestPage whiteframe-1dp">
      {props.children}
    </div>
  )
}
const UiTestSection = (props)=> {
  return (
    <div className="UiTestSection">
      <div className="UiTestSectionHeader">{props.name}</div>
      {props.children}
    </div>
  )
}
const UiTestRow = (props)=> {
  let classes = "UiTestRow"
  if (props.dark) classes += " colors-dark-background"
  return (
    <div>
      <div className="UiTestRowName">{props.name}</div>
      <div className={classes}>
        {props.children}
      </div>
    </div>
  )
}
const UiTestRowItem = (props)=> {
  return (
    <div className="UiTestRowItem">
      <div className="UiTestRowItemContent">
        {props.children}
      </div>
      <div className={`UiTestRowItemName${props.failed ? " failed" : ""}`}>{props.name}</div>
    </div>
  )
}

export default Page
export {UiTestSection, UiTestRow, UiTestRowItem}
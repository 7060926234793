import React from 'react'
import { Screen, Header, Body, FabArea, BackButton, EditableTitle } from '../../../screen/AppScreen';
import Fab from '../../../../../platform/components/buttons/FAB/FAB';

import { Redirect } from 'react-router';
import Reordering from '../../../../reordering/Reordering';

import * as firebase from 'firebase'
import { ToastService } from '../../../../../platform/components/toast/Toast';
import FlatButton from '../../../../../platform/components/buttons/textButton/FlatButton';
import { databaseRef } from '../../../../../TransactaFire/TransactaFire';


/**
 * @todo Document
 * @todo This class has a LOT of CopyPasta - need to cleanup and fix
 * 
 * @todo get rid of the manual data fanouts
 */
class ViewDetail extends React.Component {
  mounted = false
  state = {
    views : [],
    folders : [],
    reorderingItem : false,
    showMoveToFolderPopup : false,
  }

  itemsRef = databaseRef()

  get viewsRef(){
    if (!this._viewsRef) this._viewsRef = databaseRef(this.viewNode)
    return this._viewsRef
  }

  getReactRouterParam(){
    const { match: { params } } = this.props;
    return params
  }

  /**
   * Marks the component as unmounted to prevent memory leak from 
   * any async operations.
   * 
   * @todo Remove reordering
   */
  componentWillUnmount =()=> {
    this.mounted = false
  }

  /**
   * Pass component updates to method to check for folder state
   * changes.
   */
  componentDidUpdate(prevProps,prevState) {
    this.updateFolderOnStateChange(prevProps,prevState)
  }

  /**
   * Marks the component as mounted.
   */
  componentDidMount(){
    this.mounted = true
    this.attachViewRef()
    this.updateFolder()
  }

  /**
   * Attaches the Firebase ref to the component with an on value
   * watcher. Whenever the ref changes the state will be updated.
   */
  attachViewRef(){
    this.viewsRef.on('value', snapshot => {
      if (this.mounted && typeof snapshot.val() === "object"){

        let snapshotState = {...snapshot.val()}
        if (snapshotState[this.viewNode]){
          this.setState({...snapshotState})
        } else {
          this.setState({[this.viewNode] : []})
        }
      }
    })
  }

  /**
   * Using a redirect to change the folder causes problems, so instead
   * the state is handled by naivgation directly. This method is used
   * to handle whenever the user navigates within the 
   */
  updateFolderOnStateChange(prevProps,prevState) {
    if (!prevState.folderId) return this.updateFolder()
    if (prevProps.match.params.folderId !== this.props.match.params.folderId)
      this.updateFolder()
  }

  /**
   * Saves the folder url param to state, defaulting to base if its null
   */
  updateFolder(){
    let folderId = this.getReactRouterParam() || "base"
    this.setState({folderId : folderId})
  }

  /**
   * Creates a new item of the type specified by the child extending
   * this class.
   * 
   * The item gets stored in a type-prefixed GUID. The creator gets a
   * view of the item which is stored in their list of items of
   * that type.
   */
  newItem =()=> {
    const viewsKey = this.itemPrefix + "_" + this.itemsRef.push().key
    let count = this.state[this.viewNode] ? Object.keys(this.state[this.viewNode]).length : 0

    let update = {
      [this.viewsRef.fullkey()+"/"+viewsKey] : 
      { link : viewsKey, name : this.newName },
      [this.itemsRef.fullkey()+"/"+viewsKey] : 
      { name : this.newName },
      [this.viewsRef.fullkey()+"/"+this.viewNode+"/"+viewsKey] :
      count
    }
    firebase.database().ref().update(update)
  }

  
  /**
   * Marks a view to be reordered.
   */
  startReorderView =(id)=> {
    if (!this.mounted) return
    this.setState({
      reorderingItem : id,
      reorderingFolder : false
    })
  }

  /**
   * Ends reordering. If a different view was clicked, the view is
   * reordered to it.
   */
  endReorderView =(id)=> {
    if (!this.mounted) return
    if (id && id !== this.state.reorderingItem){
      let views = {...this.state[this.viewNode]}
      Reordering.reorderItem(this.state.reorderingItem, id, views)
      this.itemsRef.child(this.viewNode+"/"+this.viewNode).update(views)
    }
    this.setState({ reorderingItem : false})
  }

  /**
   * Marks a folder to be reordered.
   */
  reorderFolder =(id)=> {
    if (!this.mounted) return
    this.setState({
      reorderingItem : false,
      reorderingFolder : id
    })
  }

  /**
   * Ends reordering. If a different folder was clicked, the view is
   * reordered to it.
   */
  endReorderFolder =(id)=> {
    if (!this.mounted) return
    if (id && id !== this.state.reorderingFolder){
      let folders = {...this.state.folders}
      Reordering.reorderItem(this.state.reorderingFolder, id, folders)
      this.viewsRef.child("folders").update(folders)
    }
    this.setState({ reorderingFolder : false})
  }

  /**
   * Removes the view and shows an undo toast. If the toast is not clicked,
   * the view is deleted.
   */
  removeView =(viewId)=> {
    this.hideItem(viewId)
    ToastService.showToast(this.state[viewId].name + " removed", 
      this.undoRemoveItemButton(viewId)).then( () => 
      this.state[viewId].$hidden && this.deleteView(viewId) 
    )
  }

  /**
   * Deletes a view view
   * @todo Convert to using transaction
   */
  deleteView =(id)=> {
    let position = this.state[this.viewNode][id]
    
    let itemOrder = Object.keys(this.state[this.viewNode]).reduce( ( obj, key ) => {
      if (key === id){
        obj[key] = null
      }
      else {
        obj[key] = this.state[this.viewNode][key]
        if (obj[key] > position) obj[key]--
      }
      return obj

    }, {})

    let update = {
      [this.viewsRef.fullkey()+"/"+id] : null,
      [this.itemsRef.fullkey()+"/"+id] : null,
      [this.itemsRef.fullkey()+"/"+this.viewNode+"/"+this.viewNode+"/"] : itemOrder
    }
    firebase.database().ref().update(update)
  }

  /**
   * Marks an item to be hidden.
   */
  hideItem =(id)=> {
    let view = {...this.state[id]}
    view.$hidden = true
    this.setState({[id] : view})
  }

  /**
   * Marks an item to be visible.
   */
  showItem =(id)=> {
    let item = {...this.state[id]}
    item.$hidden = false
    this.setState({[id] : item})
  }

  /**
   * Undo button shown in remove item toast
   */
  undoRemoveItemButton = viewId => (
    <FlatButton accent clicked={()=>this.showItem(viewId)}>Undo</FlatButton>
  )

  /**
   * Saves a name change to an item
   */
  saveNameChange =(id,value)=> {
    let update = {
      [this.viewsRef.child(id).child("name").fullkey()] : value,
      [this.itemsRef.child(id).child("name").fullkey()] : value,
    }
    firebase.database().ref().update(update)
  }

  /**
   * @todo implement
   */
  changeName =(id,value)=> {
    // let o = {...this.state[id], name : value}
    // this.setState({ [id] : o })
    this.saveNameChange(id,value)
  }

  /**
   * Moves view to a item
   */
  handleLink =(id)=> {
    if (!this.mounted) return
    this.setState({redirect: '/'+this.itemNode+'/'+id})
  }

  /**
   * @todo Document
   */
  getItemViews =()=> {
    return Object.keys(this.state[this.viewNode])
      .filter( a => this.state[a].parent ? 
        this.state.folderId === this.state[a].parent :
        this.state.folderId === "base" )
      .sort( (a,b) => this.state[this.viewNode][a] - this.state[this.viewNode][b] )
      .map( (viewId,index) => {
      
      if (this.state[viewId].$hidden){
        return null
      } 

      let reorderStatus = false;
      if (this.state.reorderingItem){
        reorderStatus = this.state.reorderingItem === viewId ? "reordering" : "target"
      }

      return this.getItem(viewId,reorderStatus)
    }, this)
  }

  /**
   * @todo update view refs
   */
  changeItemName =(value)=> {
    this.viewRef.child("name").set(value)
  }

  handleBackLink =()=> {
    if (!this.mounted) return
    const { history } = this.props;

    if (history.from === this.viewNode){
      history.goBack()
    } else {
      history.replace(`/${this.viewUrl}`)
    }
  }

  get header(){
    return (<Header primary>
      <BackButton clicked={this.handleBackLink}/>
      <EditableTitle value={this.state.name} changed={(value)=>this.changeItemName(value)}/>
    </Header>)
  }

  render(){
    let styles = {}
    if (this.scrollY) styles.overflowY = "true"
    
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }
    return (
      <Screen>
        {this.header}
        <search-box-seperate/>
        <Body background="#f5f5f5" style={styles}>
          {this.screenBody}
        </Body>
        <FabArea bottom right>
          { this.fab ? <Fab icon="add" accent clicked={this.newItem} /> : null }
        </FabArea>
      </Screen>
    )
  }
}

export default ViewDetail
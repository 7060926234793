import React from 'react'

import Panel from './panel/Panel'
import PopupScrim from '../PopupScrim'
import { ClassList } from '../../../delegates/ClassNameDelegate';
import { PopupService } from '../PopupArea';

import './Popup.css'

class Popup extends React.Component {

  /**
   * 
   */
  clickedOutsideHandler=(event)=>{
    if (this.props.clickedOutside)
      this.props.clickedOutside(event)
    if (this.props.clickedOutsideCloses || this.props.clickedOutsideCloses === undefined )
      this.closePopup()
  }

  closePopup=()=>{
    if (this.props.close) return this.props.close()
    if (this.props.close === undefined) PopupService.closePopup()
  }

  get body(){
    let classes = []
    let styles = {}
    this.panelWidth(classes,styles)

    return <Panel className={ClassList(classes)}>{this.props.children}</Panel>
  }

  panelWidth(classes,styles){
    const {width} = this.props
    switch(width){
      default: classes.push("standard-width")
    }
  }

  render(){
    return (
      <div style={this.popupStyle}>
        <PopupScrim clickedOutside={this.clickedOutsideHandler}>
          {this.body}
        </PopupScrim>
      </div>
    )
  }
}

const PopupHeader =(props)=> {
  return (
    <div className="popup-header typography-title">
      {props.children}
    </div>
  )
}

/**
 * Header for a popup.
 * #### Props
 * * right {*boolean*} - pads all items in the footer so they are on the
 *   right side
 */
const PopupSubHeader =(props)=> {
  return (
    <div className="popup-sub-header typography-subtitle2">
      {props.right && <div style={{flex : "1"}}/>}
      {props.children}
    </div>
  )
}

const PopupSection =(props)=> {
  let style = {}
  if (props.flex) style.flex = "1"
  if (props.scrollY) style.overflowY = "auto"

  return (
    <div className="popup-section"
      style={style}
      onClick={()=>props.clicked && props.clicked()}>
      {props.children}
    </div>
  )
}

/**
 * Footer for a popup.
 * #### Props
 * * right {*boolean*} - pads all items in the footer so they are on the
 *   right side
 */
const PopupFooter =(props)=> {
  return (
    <div className="popup-footer typography-title">
      {props.right && <div style={{flex : "1"}}/>}
      {props.children}
    </div>
  )
}

export default Popup
export {PopupHeader, PopupSubHeader, PopupSection, PopupFooter}
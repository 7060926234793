import React from 'react'

import DetailScreen from '../../../base/view/detail/DetailScreen';
import Reordering from '../../../reordering/Reordering';
import { Model, Transaction, newKey } from '../../../../TransactaFire/TransactaFire';

import {BackButton, EditableTitle, FabArea} from '../../../base/screen/AppScreen'
import FAB from '../../../../platform/components/buttons/FAB/FAB'
import FlatButton from '../../../../platform/components/buttons/textButton/FlatButton';

import { ToastService } from '../../../../platform/components/toast/Toast';
import UndoToastButton from '../../../../platform/components/toast/buttons/undo/UndoToastButton';
import UndoableActions from '../../../undoable/UndoableActions';

import Palette from './palette/Palette';
import Lane from './lane/Lane';
import BoardCard from './card/Card';

import './Board.css'

class Board extends DetailScreen {

  state = {
    hidden : {},
    lanes : [],
    cards : [],
    name : "",
    reordering : false,
    paletteColor : 0
  }

  shareablePrefix = "board"
  viewNode = "boards"
  shareableNode = "board"
  shareableName = "Board"

  createPreview =(board,transaction)=> {
    const preview = this.mapPreview(board)

    Object.keys(this.state.readers).forEach( reader => {
      let view = transaction.add( Model.newAtPath(`${reader}/${this.viewNode}/${this.shareableId}`) )
        view.change("preview",preview)
    })
  }

  mapPreview =(board)=> {
    let lanes = Object.keys(board.lanes)
      .filter( l => board.lanes[l] || board.lanes[l] === 0)
      .sort( (a,b) => board.lanes[a] - board.lanes[b] )
      .map( laneId => {
        let cards = this.getPreviewCards(board[laneId].cards, board)
        let lane = { name : board[laneId].name }
        if (cards && cards.length) {
          lane.cards = {...cards}
        } else {
          lane.cards = null
        }
      return lane
    })

    return { lanes : (lanes.length ? {...lanes} : null) }
  }

  getPreviewCards =(cards,board)=> {
    if (!cards) return null

    return Object.keys(cards)
    .filter( c => cards[c] || cards[c] === 0)
    .sort( (a,b) => cards[a] - cards[b] )
    .map( cardId => ({
        name : board[cardId].name,
        color : board[cardId].color || 0
      })
    )
  }

  addCard =(laneId)=> {
    const transaction = new Transaction()
    const board = transaction.add(new Model(this.shareableRef,{...this.state}))

    let lane = {...this.state[laneId]}

    let cardKey = "card_"+this.shareableRef.push().key
    let count = lane.cards ? Object.keys(lane.cards).length : 0
    
    if (!count) lane.cards = {}
    let card = {
      name:"New Card",
      color: this.state.paletteColor
    }
    lane.cards[cardKey] = count

    board.change(cardKey, card)
    board.change(laneId, lane)
    
    this.createPreview(board._data, transaction)
    transaction.commit()

    setTimeout( ()=> this.setState({ focused : cardKey, deselected : null }), 50 )
    setTimeout( ()=> this.setState({ focused : null }), 70 )
  }

  copyCard = async(cardId) => {
    const transaction = new Transaction()
    const board = transaction.add(new Model(this.shareableRef,{...this.state}))

    const laneId = this.getCardLane(cardId)
    const lane = this.state[laneId]
    if (!lane || lane.cards[cardId] === undefined) return // figure this out

    const cardBeingCopied = this.state[cardId]
    const cardKey = newKey("card_")
    const card = { ...cardBeingCopied, name : `${cardBeingCopied.name} copy` }

    board.change(`${laneId}/cards`, Reordering.insertItem(cardKey, lane.cards[cardId]+1, lane.cards))
    board.change(cardKey, card)
    this.createPreview(board._data, transaction)

    await transaction.commit()

    this.setState({ focused : cardKey, deselected : cardId })
    setTimeout( ()=> this.setState({ focused : null, deselected : null }), 50 )
  }

  /**
   * Get the id of the lane the card is in
   */
  getCardLane =(cardId)=> {
    return Object.keys(this.state.lanes).find( laneId => 
      this.state[laneId].cards && this.state[laneId].cards[cardId] !== undefined
    , this)
  }

  /**
   * Get the position of a card in a lane 
   */
  getCardPosition(cardId,lane){
    if (!lane.cards) return 0
    return lane.cards[cardId]
  }

  addLane =()=> {
    const transaction = new Transaction()
    const board = transaction.add(new Model(this.shareableRef,{...this.state}))

    const laneKey = "lane_"+this.shareableRef.push().key
    let count = this.state.lanes ? Object.keys(this.state.lanes).length : 0
    
    let newLane = {
      name : "New Lane",
    }
    let lanes = {...this.state.lanes}
    lanes[laneKey] = count

    board.change(laneKey, newLane)
    board.change("lanes", lanes)
    this.createPreview(board._data, transaction)
    transaction.commit()
  }

  /**
   * Marks a lane to be reordered.
   */
  reorderLane =(laneId)=> {
    if (!this.mounted) return
    this.setState({ 
      reorderingLane : laneId
    })
  }

  /**
   * Ends the lane reordering process. If no lane is passed in the reordering is simply
   * cancelled. Otherwise the lane currently being reorders is moved to the lane passed
   * as an argument.
   */
  endReorderLane =(laneId)=> {
    if (!this.mounted) return

    if (laneId){
      const transaction = new Transaction()
      const board = transaction.add(new Model(this.shareableRef,{...this.state}))

      const lanes = {...this.state.lanes}
      Reordering.reorderItem(this.state.reorderingLane,laneId,lanes)

      board.change("lanes",lanes)
      this.createPreview(board._data, transaction)
      transaction.commit()
    }

    this.setState({ reorderingLane : false})
  }

  /**
   * Marks a card to be reordered.
   */
  reorderCard =(cardId)=> {
    if (!this.mounted) return
    this.setState({
      reorderingCard : cardId
    })
  }

  /**
   * Ends the card reordering process. If no destination is passed in the reordering is simply
   * cancelled.
   * If a lane is passed in, the card is moved to the end of that desination lane.
   * If a card is passed in, the card will be moved to in front of that card.
   */
  endReorderCard =(destinationId)=> {
    if (!this.mounted) return
    if (destinationId === undefined || destinationId === this.state.reorderingCard) 
      return this.setState({ reorderingCard : null })

    const transaction = new Transaction()
    const board = transaction.add(new Model(this.shareableRef,{...this.state}))

    let type = this.boardItemType(destinationId)
    let cardId = this.state.reorderingCard
    let currentLaneId = this.getCardLane(cardId)

    if (type === "lane"){
      this.endReorderCardToLane(board,destinationId,currentLaneId,cardId)
    }
    else if(type === "card"){
      this.endReorderCardToCard(board,destinationId,currentLaneId,cardId)
    }
    this.setState( {reorderingCard : null} )
    this.createPreview(board._data, transaction)
    transaction.commit()
  }

  /**
   * Reorders a card to another lane
   */
  endReorderCardToLane =(board,destinationId,currentLaneId,cardId)=> {
    let destinationLane = {...this.state[destinationId]}
    board.change(currentLaneId, this.removeCardFromLane(cardId,currentLaneId))
    board.change(destinationId, this.appendCardToLane(cardId,destinationLane))
  }

  /**
   * Reorders a card to another card's position
   */
  endReorderCardToCard =(board,destinationId,currentLaneId,cardId)=> {
    let destinationLaneId = this.getCardLane(destinationId)

    if (currentLaneId === destinationLaneId){
      let currentLane = {...this.state[currentLaneId]}
      currentLane.cards = Reordering.reorderItem(cardId,destinationId,currentLane.cards)
      board.change(currentLaneId, currentLane)
    }
    else {
      let destinationLane = {...this.state[destinationLaneId]}
      let position = this.getCardPosition(destinationId,destinationLane.cards)
      board.change(currentLaneId, this.removeCardFromLane(cardId,currentLaneId))
      destinationLane.cards = Reordering.insertItem(cardId, position, destinationLane.cards)
      board.change(destinationLaneId, destinationLane)
    }
  }

  /**
   * Tests if the item is a card or a lane
   */
  boardItemType =(id)=> {
    if (!id) return null
    if (id.substr(0,"lane_".length) === "lane_") return "lane"
    if (id.substr(0,"card_".length) === "card_") return "card"
    return undefined
  }

  /**
   * Removes a card from a lane.
   * @return the updated lane
   */
  removeCardFromLane =(cardId,laneId) => {
    let lane = {...this.state[laneId]}
    let position = lane.cards[cardId]
    lane.cards[cardId] = null

    Object.keys(lane.cards).forEach( cardId => {
      if (lane.cards[cardId] > position)
      lane.cards[cardId]--
    })

    return lane
  }

  /**
   * Appends a card to a lane
   * @return the updated lane
   */
  appendCardToLane =(cardId,lane) => {
    let position = lane.cards ? Object.keys(lane.cards).length : 0
    if (position === 0) lane.cards = {}
    lane.cards[cardId] = position
    return lane
  }

  /**
   * Marks a lane to be deleted and shows a toast to undo the delete.
   */
  removeLane =(laneId)=> {
    const lane = this.state[laneId]
      if (!lane) return null

    this.hideLane(laneId)
    this.showRemoveLaneToast(lane,laneId)
  }

  /**
   * Shows a toast that, if clicked, will reshow the lane. If not, the lane is deleted.
   */
  showRemoveLaneToast =(lane,laneId)=> {
    const act = UndoableActions.addAction(
      this.deleteLane,
      this.showLane,
      laneId
    )

    const action =()=> UndoableActions.action(act)
    const undo =()=> UndoableActions.undo(act)

    ToastService.showToast( `Lane "${lane.name}" was removed`, <UndoToastButton action={undo}/>, 3000 )
      .then( action )
  }

  /**
   * Deletes a lane
   */
  deleteLane =(laneId)=> {
    const transaction = new Transaction()
    const board = transaction.add(new Model(this.shareableRef,{...this.state}))

    let cards = this.state[laneId].cards
    let lanes = {...this.state.lanes}
    let position = lanes[laneId]

    Object.keys(lanes).forEach(laneKey => {
      if (laneKey === laneId){
        lanes[laneKey] = null
      }
      else if (lanes[laneKey]>position){
        lanes[laneKey]--
      } 
    })

    board.change(laneId, null)
    board.change("lanes", lanes)
    if (cards)
      Object.keys(cards).map( cardId => board.change(cardId, null) )

    this.createPreview(board._data, transaction)
    transaction.commit()
  }

  hideLane =(laneId)=> {
    if (!this.mounted) return
    this.setState({ hidden : {...this.state.hidden, [laneId] : true } })
  }

  showLane =(laneId)=> {
    ToastService.hideToast()

    if (!this.mounted) return
    this.setState({ hidden : {...this.state.hidden, [laneId] : false } })
  }

  undoRemoveLaneButton = laneId => (
    <FlatButton accent clicked={()=>this.showLane(laneId)}>Undo</FlatButton>
  )

  /**
   * Marks a lane to be deleted and shows the use an undo toast.
   */
  removeCard =(cardId)=> {
    const card = this.state[cardId]
      if (!card) return null

    this.hideCard(cardId)
    this.showRemoveCardToast(card,cardId)
  }
  
  /**
   * Shows an Undo Toast that unhides the card if pressed and deletes the card otherwise.
   */
  showRemoveCardToast(card,cardId){
    const act = UndoableActions.addAction(
      this.deleteCard, 
      this.showCard, 
      cardId
    )

    const action =()=> UndoableActions.action(act)
    const undo =()=> UndoableActions.undo(act)

    ToastService.showToast( `Card "${card.name}" was removed`, <UndoToastButton action={undo}/>, 3000 )
      .then( action )
  }

  /**
   * Deletes a card
   */
  deleteCard =(cardId)=> {
    const transaction = new Transaction()
    const board = transaction.add(new Model(this.shareableRef,{...this.state}))

    let parentId = Object.keys(this.state.lanes).find(
      laneId => ( this.state[laneId].cards &&
        this.state[laneId].cards[cardId] !== undefined )
    )
    
    let parent = {...this.state[parentId]}
    let position = parent.cards[cardId]

    Object.keys(parent.cards).forEach(cardKey => {
      if (cardKey === cardId){
        parent.cards[cardKey] = null
      }
      else if (parent.cards[cardKey] > position){
        parent.cards[cardKey]--
      }
    })

    board.change(parentId,parent)
    board.change(cardId,null)
    this.createPreview(board._data, transaction)
    transaction.commit()
  }

  hideCard =(cardId)=> {
    if (!this.mounted) return
    this.setState({ hidden : {...this.state.hidden, [cardId] : true } })
  }

  /**
   * @todo make sure the toast being closed is for the lane being shown
   */
  showCard =(cardId)=> {
    ToastService.hideToast()

    if (!this.mounted) return
    this.setState({ hidden : {...this.state.hidden, [cardId] : false } })
  }

  undoRemoveCardButton = cardId => (
    <FlatButton accent clicked={()=>this.showCard(cardId)}>Undo</FlatButton>
  )

  emptyItemsMessageHeader =()=> "Empty Board"
  emptyItemsMessageBody =()=> "Add a lane with the button below"

  getLanes(items){
    if (!items.length) return this.emptyItemsMessage
    let reorderLanes = (items.length>1)

    return items
    .filter( a => a !== null && a !== undefined && !this.state.hidden[a] )
    .map( laneId => {
      let reorderStatus = false
      let endReorder = null

      if (this.state.reorderingLane){
        reorderStatus = this.state.reorderingLane === laneId ? "reordering" : "target"
      } else if (this.state.reorderingCard){
        reorderStatus = "cardTarget"
      }

      if (reorderStatus === "target")
        endReorder = ()=>this.endReorderLane(laneId)
      if (reorderStatus === "reordering")
        endReorder = ()=>this.endReorderLane()
      if (reorderStatus === "cardTarget")
        endReorder = ()=>this.endReorderCard(laneId)

      return <Lane
        reorderStatus={reorderStatus}
        key={laneId}
        boardRef={this.shareableRef}
        name={this.state[laneId].name}
        cards={this.getCards(laneId)}
        id={laneId}
        canReorder={reorderLanes}
        changeLaneField={this.changeLaneField}
        saveLaneField={this.saveLaneField}
        removeLane={()=>this.removeLane(laneId)}
        reorderLane={()=>this.reorderLane(laneId)}
        endReorder={endReorder}
        addCard={()=>this.addCard(laneId)}
      />
    }, this)
  }

  getCards=(laneId)=>{
    if (!this.state[laneId].cards) return null
    
    const {focused, deselected} = this.state

    return Object.keys(this.state[laneId].cards)
    .filter( a => a !== null && a !== undefined && !this.state.hidden[a] )
    .sort( (a,b) => this.state[laneId].cards[a] - this.state[laneId].cards[b])
    .map( cardId => {
      let card = this.state[cardId]

      let reorderStatus = false
      if (this.state.reorderingCard) reorderStatus = 
        this.state.reorderingCard === cardId ? "reordering" : "target"

      return (
        <BoardCard
          reorderStatus={reorderStatus}
          key={cardId}
          card={card}
          focus={focused === cardId}
          deselect={deselected === cardId}
          id={cardId}
          lane={laneId}
          boardRef={this.shareableRef}
          save={(value,field,card)=>this.saveCardField(value,field,card)}
          changed={(value,field,card)=>this.changeCardField(value,field,card)}
          removeCard={this.removeCard}
          reorderCard={this.reorderCard}
          copyCard={this.copyCard}
          endReorder={()=>this.endReorderCard( 
            this.state.reorderingCard !== cardId ? cardId : undefined
          )}
          nextItem={this.nextItem}
          />
      )
    }, this)
  }

  changeLaneName =(laneId,value)=> {
    if (!this.mounted) return
    let laneCopy = {...this.state[laneId]}
    laneCopy.name = value
    this.setState({[laneId] : laneCopy})
  }

  changePaletteColor =(value)=> {
    this.setState({paletteColor : value})
  } 

  changeLaneField =(value,field,laneId)=> {
    let lane = {...this.state[laneId]}
    lane[field] = value
    this.setState({[laneId] : lane})
  }

  saveLaneField =(value,field,laneId)=> {
    const transaction = new Transaction()
    const board = transaction.add(new Model(this.shareableRef,{...this.state}))

    let lane = {...this.state[laneId]}
      lane[field] = value
      board.change(laneId,lane)
    
    this.createPreview(board._data, transaction)
    transaction.commit()
  }

  changeCardField=(value,field,cardId)=>{
    if (!this.mounted) return

    let card = {...this.state[cardId]}
    card[field] = value
    this.setState({[cardId] : card})
  }

  saveCardField=(value,field,cardId)=>{
    let transaction = new Transaction()
    let board = transaction.add(new Model(this.shareableRef,{...this.state}))

    let card = {...this.state[cardId]}
      card[field] = value
      board.change(cardId,card)

    this.createPreview(board._data, transaction)
    transaction.commit()
  }

  /**
   * If there are items after the current item, advances to the next. 
   * Otherwise it will create a new item.
   */
  nextItem =(laneId,cardId)=> {
    const lane = this.state[laneId]
    const cards = Object.keys(lane.cards)
      .filter( a => lane.cards[a] !== null && lane.cards[a] !== undefined )
      .sort( (a,b)=> lane.cards[a] - lane.cards[b])

    const position = cards.findIndex( a => a === cardId)
    const nextId = cards.find( (a,i) => i > position)

    if (nextId !== undefined){
      this.setState({ focused : nextId, deselected : cardId})
      setTimeout( ()=> this.setState({ focused : null, deselected : null }), 10 )
    } else {
      this.setState({ deselected : cardId })
      this.addCard(laneId)
    }
  }

  get screenHeader(){
    return (<>
      <BackButton clicked={this.handleBackLink}/>
      <EditableTitle value={this.state.name} changed={(value)=>this.changeShareableName(value)}/>
      <Palette changed={this.changePaletteColor} value={this.state.paletteColor}/>
    </>)
  }

  get screenBody(){
    const lanes = Object.keys(this.state.lanes)
      .filter( a => a !== null && a !== undefined && !this.state.hidden[a] )
      .sort( (a,b) => this.state.lanes[a] - this.state.lanes[b])

    return (<>
      <div className="feature-boards-lanes">
        {this.getLanes(lanes)}
        { lanes.length ? <div className="feature-boards-lanes-pad"/> : null}
      </div>
    </>)
  }

  get screenFabs(){
    return (
      <FabArea bottom right>
        { this.state.reorderingLane
          ? (<FAB icon="close" clicked={()=>this.endReorderLane()} />)
          : (<FAB icon="add" accent clicked={()=>this.addLane()} />)
        }
      </FabArea>)
  }
}

export default Board
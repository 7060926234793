import React from 'react'

import Popup, { PopupHeader, PopupSection, PopupFooter } from '../../../../../platform/components/popups/popup/Popup';
import { TitleText, BodyText, BodyTextBold } from '../../../../../platform/components/typography/Typography';
import FlatButton from '../../../../../platform/components/buttons/textButton/FlatButton';
import Chip from '../../../../../platform/components/chips/Chip';
import Item from '../../../../../TransactaFire/Item';
import CopyButton from '../../../../../platform/components/buttons/copyButton/CopyButton';
import ShareMessage from './ShareMessage';
import User from '../../../../User/User';
import { userToString } from '../../../../../platform/components/chooser/MultiContactChooser';
import { ChooseContactSection } from '../../../../features/contacts/choose/popup/ChooseContactSection';
import { PopupService } from '../../../../../platform/components/popups/PopupArea';

/**
 * Popup that shows all of the sharing options for a ShareItem.
 * 
 * ##### Views
 * **overview** - Shows basic information about the ShareItem.  
 * **choseShareMethod** - Lists the different sharing methods.
 * **chooseContacts** - Shows a list of contacts the user can choose. The chosen
 * contacts will recieve a message inviting them to share the item.
 * **shareLink** - Shows a url link that other user can use to share the item. 
 */
class ShareItemPopup extends React.Component {
  mounted = false

  state = {
    view : "overview"
  }

  componentDidMount(){
    this.mounted = true
    this.ref = new Item(this,`${this.props.id}`,this.props.type)
  }

  componentWillUnmount(){
    this.mounted = false
    this.ref.destroy()
  }

  /**
   * @property List of all users for the ShareItem
   */
  get users(){
    if (!this.state[this.props.type]) return null
    const readers = this.state[this.props.type].readers
    return readers && Object.keys(readers).map( key => ({ id : key, ...this.state[this.props.type].users[key] }))
  }

  /**
   * @property Owner user for the ShareItem
   */
  get owner(){
    if (!this.users) return null
    return this.users.find( a => a.id === this.ownerUid)
  }

  get ownerUid(){ return this.state[this.props.type] && this.state[this.props.type].owner }

  /**
   * @property List of all non owner users for the ShareItem
   */
  get nonOwners(){
    if (!this.users) return null
    return this.users.filter( a => a.id !== this.ownerUid)
  }

  /**
   * Creates a Chip for the owner 
   */
  get ownerChip(){
    let owner = this.owner
    if (!owner) return (<div>Nobody</div>)
    return <Chip key={owner.id}>{owner.name}</Chip> 
  }

  /**
   * Creates a list of Chips for the users 
   */
  get sharedUserChips(){
    let users = this.nonOwners

    if (!users) return (<div>Nobody</div>)

    return users.map( user => {
      return <Chip key={user.id}>{user.name}</Chip> 
    })
  }

  get viewItemOverview(){
    return (<>
      <PopupHeader>{this.props.item.name}</PopupHeader>
      <div>
      <PopupSection flex>
        <TitleText>Owner</TitleText>
        <div className="row"> { this.ownerChip } </div>
        <TitleText>Shared with</TitleText>
        <div className="row"> { this.sharedUserChips } </div>
      </PopupSection>
      </div>
      <PopupFooter right>
        <FlatButton primary clicked={()=>this.mounted && this.setState({view:"choseShareMethod"})}>
          Share
        </FlatButton>
        <FlatButton primary clicked={PopupService.closePopup}>
          Close
        </FlatButton>
      </PopupFooter>
    </>)
  }

  get viewSharingMethods(){
    return (<>
      <PopupHeader>Sharing Method</PopupHeader>
      <div>
        <PopupSection clicked={()=>this.mounted && this.setState({view : "chooseContacts"})}>
          <TitleText>Choose contacts</TitleText>
          <BodyText>
            Choose one or more contacts to share this {this.props.type} with.
          </BodyText>
        </PopupSection>
        <PopupSection clicked={()=> {
          this.mounted && this.setState({view : "shareLink"})
          this.props.itemShareLink(this.props.id).then( 
            url => this.mounted && this.setState({shareUrl : url}) )
        }}>
          <TitleText>Share link</TitleText>
          <BodyText>
            Creates a link people can use to get this {this.props.type}. If they aren't
            already a PlanReach user, they will be able to sign up.
          </BodyText> 
        </PopupSection>
        <PopupFooter right>
          <FlatButton primary clicked={()=>this.mounted && this.setState({view:"overview"})}>
            Back
          </FlatButton>
        </PopupFooter>
      </div>
    </>)
  }

  get viewShareLink(){
    return (<>
      <PopupHeader>Share Link</PopupHeader>
      <div>
        <PopupSection flex>
          <TitleText>Invite Link</TitleText>
          <BodyText>Use this link to share this {this.props.type} with other people.
            Anyone with this link can view and edit it after loging into PlanReach.</BodyText>
          <div style={{borderWidth: "4px 2px 2px 2px", marginTop: "16px", borderStyle: "solid",
            borderColor: "rgba(0,0,0,0.12)", padding: "8px", }}>
            <textarea id="itemToShare" readOnly rows="4"
              style={{width: "100%", border: "none",
              resize: "none"}} value={`localhost:3000/${this.state.shareUrl}`}/>
          </div>
        </PopupSection>
        <PopupFooter>
          <CopyButton element="itemToShare"/>
          <div className="flex"/>
          <FlatButton primary clicked={()=>this.mounted && this.setState({view:"choseShareMethod"})}>
            Back
          </FlatButton>
        </PopupFooter>
      </div>
    </>)
  }

  updateChosenContacts=(chosenContacts)=>{
    this.setState({ chosenContacts : chosenContacts })
  }

  /**
   * Creates a share message for the chosen contacts
   */
  shareWithChosen=()=>{
    const chosenContacts = Object.keys(this.state.chosenContacts)
      .map(this.getContactUserString).toString()

    var out = ShareMessage.createMessage(this.props.item.name, this.props.type,
      chosenContacts, User.userName, User.uid, this.state.shareUrl)
    
    ShareMessage.sendMessage(out)
  }

  getContactUserString=(uid)=>userToString({
    name : this.props.contacts[uid].name,
    uid : this.props.contacts[uid].uid
  })

  /**
   * @todo implement
   */
  get viewChooseContact(){
    return (
      <>
        <PopupHeader>Choose Contacts</PopupHeader>
        <div>
          <PopupSection>
            <p style={{margin:"0"}}>
              <BodyText>Choosen contacts will recieve a message inviting them to share </BodyText>
              <BodyTextBold>{this.props.item.name}</BodyTextBold>
              <BodyText>.</BodyText>
            </p>
          </PopupSection>
          <ChooseContactSection contacts={this.props.contacts} updateChosenContacts={this.updateChosenContacts}/>
        </div>
        {/* Search box */}
        <PopupFooter>
          <FlatButton primary clicked={this.shareWithChosen}>
            Share
          </FlatButton>
          <div className="flex"/>
          <FlatButton primary clicked={()=>this.mounted && this.setState({view:"choseShareMethod"})}>
            Back
          </FlatButton>
        </PopupFooter>
      </>
    )
  }
  
  /** Can't do email yet */
  get viewShareEmail(){
    return (<>
      <PopupHeader>Share Email</PopupHeader>
    </>)
  }

  render(){
    return(
      <Popup>
        { ( !this.state.view || this.state.view === "overview" ) &&
          this.viewItemOverview }
        { this.state.view === "choseShareMethod" && 
          this.viewSharingMethods }
        { this.state.view === "chooseContacts" && 
          this.viewChooseContact }
        { this.state.view === "shareLink" &&
          this.viewShareLink }
      </Popup>
    )
  }
}

export default ShareItemPopup
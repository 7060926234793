import React from 'react'

import RaisedButton from '../../../../../platform/components/buttons/textButton/RaisedButton'
import DetailScreen from '../../../../base/view/detail/DetailScreen'
import Divider from '../../../../../platform/components/dividers/Divider'
import DataFire from '../../../../../TransactaFire/DataFire'
import User from '../../../../User/User';

import MultiContactChooser, { stringToUser, userToString } from '../../../../../platform/components/chooser/MultiContactChooser';

import './NewMessage.css'
import { BackButton, EditableTitle } from '../../../../base/screen/AppScreen';
import { ToastService } from '../../../../../platform/components/toast/Toast';
import { Transaction, Collection, Model, newKey, databaseRef } from '../../../../../TransactaFire/TransactaFire';
import { QuoteEntries } from '../../quotes/QuoteMessage';
import IconButton from '../../../../../platform/components/buttons/iconButton/IconButton';

import RichTextEditor, { RichTextEditorService } from '../../../../../platform/texteditor/RichTextEditor';

class NewMessage extends DetailScreen {

  componentDidMount(){
    super.componentDidMount()

    this.contactsRef = databaseRef(`${User.uid}/contacts`)
    DataFire.bindRef(this,this.contactsRef,this.contactSchema).then(this.loaded)
  }

  componentWillUnmount(){
    this.mounted = false
    this.editorService.unmount()
  }

  contactSchema = {
    contacts : {
      type : "orderedCollection",
      name : "contacts",
    }
  }

  state = {
    content : ""
  }

  /**
   * Editor specific functionality
   */
  editorService = new RichTextEditorService({
    parent : this,
    watch : {
      undoRedo : (hasUndo,hasRedo) => this.setState({ canUndo: hasUndo, canRedo: hasRedo })
    }
  })

  loaded=()=>{
    this.editorService.load(this.state.content)
  }

  shareablePrefix = "message"
  viewNode = "messages"
  shareableNode = "message"
  shareableName = "Messages"

  /**
   * Handles the following query params on page load:
   * * `respond : messageId` - respond to the message with the matching id
   * @override
   */
  handleParams(params){
    let respondToMessage = params.get('respond')
      if (respondToMessage) this.respondToMessage(respondToMessage)
  }

  /**
   * Removes the "Re: " portion of a subject
   */
  removeRe(str){
    if (str.substring(0,4) === "Re: ") return str.substring(4)
    return str
  }

  /**
   * Allows a user to respond to a message in the same way an email can be responded to.
   */
  async respondToMessage(messageId){
    const parentRef = databaseRef(`${User.uid}/messages/${messageId}`)
    const parentMessage = await parentRef.once('value')

    const {subject,sender,recipients,body,created,quotes} = parentMessage.val()
    const self = userToString({name : User.userName, uid : User.uid})

    let to = removeDuplicates([ ...usersToArray(recipients), ...[sender]].filter( user => user !== self ))

    const quote = {
      from: stringToUser(sender).name,
      sent: created,
      to: recipients,
      subject: subject,
      body: body
    }

    this.setState({
      subject : `Re: ${this.removeRe(subject)}`,
      quotes : quotes ? [...quotes, quote] : [quote],
      recipients : to.join()
    })
  }

  /**
   * Editor callback to update the state's content.
   */
  updateStateContent =(event)=> {
    if (event && event.target){
      this.setState({ content : event.target.innerHTML})
    }
  }

  validateMessage(){
    const { recipients, subject } = this.state
    let error = ""

    if (!subject) error += "Messages must have a subject."
    if (!recipients) error += (error ? "\n" : "") + "Messages must have at least one recipient."

    return error
  }

  contentToSummary=(content)=>{
    let ret = content.replace(/<style([\s\S]*?)<\/style>/gi,'')
      .replace(/<script([\s\S]*?)<\/script>/gi, '')
      .replace(/<\/div>/ig, '\n ')
      .replace(/<\/li>/ig, '\n ')
      .replace(/<li>/ig, ' * ')
      .replace(/<\/ul>/ig, '\n ')
      .replace(/<\/p>/ig, '\n ')
      .replace(/<br\s*[\/]?>/gi, "\n ")
      .replace(/<[^>]+>/ig, '')

      // Reversing escaped characters
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&")

    return ret
  }

  sendMessage = async() => {
    let validation = this.validateMessage()
    if (validation) return ToastService.showToast(validation)

    let transaction = new Transaction()
    let promises = []
    let sendToSelf = false
    const { recipients, subject, content, quotes } = this.state

    let message = {
      sender : userToString({name : User.userName, uid : User.uid}),
      subject : subject,
      recipients : recipients,
      body : `<div>${content}</div>`,
      summary : this.contentToSummary(content), // sanatize to a string
      quotes : quotes ? quotes : null,
      created : (new Date()).getTime()
    }

    usersToArray(recipients)
    .forEach( recipient => {
      const uid = stringToUser(recipient).uid
      if (uid !== User.uid){
        const key = newKey("message_")
  
        let recipientMessages = transaction.add(new Collection( databaseRef(`${uid}/messages`), "messages", "message_") )
          promises.push( recipientMessages.addToCollection({...message, unread : true, isRecipient: true }, null, key) )

        let recipientUnreadMessagesCount = transaction.add(new Model( databaseRef(`${uid}/data/unreadMessages`), null, true))
        promises.push(recipientUnreadMessagesCount.loaded.then( count => 
          recipientUnreadMessagesCount.set( isNaN(count) ? 1 : ++count ) 
        ))

        let recipientUnreadMessages = transaction.add(new Model( databaseRef(`${uid}/messages/unreadMessages`) ))
          recipientUnreadMessages.change(key,1)
      } else {
        sendToSelf = true
      }
    })

    let senderMessages = transaction.add(new Collection( databaseRef(`${User.uid}/messages`), "messages", "message_") )
      promises.push( senderMessages.addToCollection({...message, isSender: true, isRecipient: sendToSelf ? true : null}) )

    await Promise.all(promises) 
    transaction.commit()
    
    this.props.history.replace(`/messages`)
    ToastService.showToast("Message Sent")
  }

  /**
   * Converts the recipients from a comma seperated string into an array
   */
  usersToArray=(user)=>{
    if (!user) return []
    return user.split(",").map( a => a.trim())
  }

  /** 
   * @todo blind carbon copy (Bcc)
   * @todo carbon copy (Cc)
   */
  updateRecipients =(value)=> {
    let contacts = {}
    let recipientString = ""
    let firstContact = true

    if (this.state.recipients){
      this.state.recipients.split(",").forEach( contact => contacts[contact.trim()] = contact.trim() )
    }
    contacts[value] = value.trim()

    Object.values(contacts).forEach( contact => {
      recipientString += (firstContact ? "" : ", ") + contact
      firstContact = false
    })
    this.setState({ recipients : recipientString })
  }

  removeRecipient=(contact)=>{
    this.setState({
      recipients : usersToArray(this.state.recipients)
        .filter( a => a !== contact )
        .map( (a,i) => 
          (i === 0 ? "" : " ") + a 
        )
      .toString()
    })
  }

  updateSubject =(value)=> {
    this.setState({ subject : value})
  }

  updateBody =(value)=> {
    this.setState({ body : value})
  }

  get screenBody(){
    return (
    <div className="flex column">
      <div className="screen-sub-header">
        {this.screenSubHeader}
      </div>
      { this.recipients }
      <Divider/>
      <RichTextEditor
        service={this.editorService}
        onInput={this.updateStateContent}/>
      { QuoteEntries(this.state.quotes) }
      { this.footer }
    </div>
    )
  }

  /**
   * Recipients are stored in a comma seperated String like a recipient list in emails. Each recipient is a user id.
   * 
   * @todo Non contact users can be recipients. Need to figure out the security of allowing them to continue being responded
   * to in chain while preventing them from being copied to a new message.
   * 
   * @todo Do I need blind carbon copy (Bcc)?
   * @todo Do I need carbon copy (Cc)?
   */
  get recipients(){
    const recipients = this.state.recipients
    let usedContacts = {}
    let contacts = []
      
    if (recipients){
      recipients.split(",").forEach( a => usedContacts[a.trim()] = true)
    }

    if (this.state.contacts){
      contacts = Object.keys(this.state.contacts)
      .filter( a => !this.state[a].recordOnly )
      .map( contactId => ({
        displayName : this.state[contactId].name,
        value : userToString(this.state[contactId])
      }) )
    }

    contacts.push({
      displayName : "self",
      value : userToString({name : User.userName, uid : User.uid})
    })

    contacts = contacts.filter( a => {
      return !usedContacts[a.value]
    })

    return (
      <div>
        <MultiContactChooser
          onlyAllowChoices
          label="To"
          placeholder="Recipient"
          choices={contacts}
          value={recipients}
          advanceOnEnter
          blurred={this.updateRecipients}
          removed={this.removeRecipient}
        />
      </div>
    )
  }

  get screenHeader(){
    return (<>
      <BackButton clicked={this.handleBackLink}/>
      <EditableTitle value={this.state.subject} placeholder="Subject" changed={this.updateSubject}/>
    </>)
  }

  get screenSubHeader(){
    return (<>
      <IconButton icon="format_indent_increase" clicked={()=>this.editorService.addIndent()}/>
      <IconButton icon="format_bold" title="Bold" clicked={()=>this.editorService.bold()}/>
      <IconButton icon="format_italic" title="Italic" clicked={()=>this.editorService.italic()}/>
      <IconButton icon="format_underline" title="Underline" clicked={()=>this.editorService.underline()}/>
      <div style={{flex:"1"}}/>
      <IconButton icon="undo"
        clicked={()=>this.editorService.undo()}
        disabled={!this.state.canUndo}/>
      <IconButton icon="redo"
        clicked={()=>this.editorService.redo()}
        disabled={!this.state.canRedo}/>

      <IconButton icon="zoom_in" clicked={()=>this.editorService.zoomIn()}/>
      <IconButton icon="zoom_out" clicked={()=>this.editorService.zoomOut()}/>
    </>)
  }

  get footer(){
    return (
      <div className="column">
        <Divider/>
        <div className="rightItems">
          <RaisedButton primary clicked={this.sendMessage}>Send</RaisedButton>
        </div>
      </div>
    )
  }
}

//https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
const removeDuplicates =(array)=> {
  if (!(array || array instanceof Array)) return array
  return [...new Set(array)]
}

/**
* Converts the recipients from a comma seperated string into an array
*/
export const usersToArray=(user)=>{
  if (!user) return []
  return user.split(",").map( a => a.trim())
}

export default NewMessage
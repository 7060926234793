/**
 * Service that handles "ticking" components that update every so many mills
 */
export default class Ticker {
  static tickers = []
  static tickerIndex = 0
  
  static secondInterval = setInterval(
    ()=> Ticker.secondTick(),
    1000
  )

  static secondTick(){
    Ticker.tickers.forEach( a => a.callback(a.id) )
  }
  static registerTicker(callback){
    Ticker.tickers.push({
      id : Ticker.tickerIndex++,
      callback : callback
    })
  }
  static unregisterTicker(id){
    let ticker = Ticker.tickers.find( a => a.id === id)
    Ticker.tickers.splice(ticker,1)
  }

}
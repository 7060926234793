import React from 'react'
import Icon from '../../../platform/components/icons/Icon';
import FlatButton from '../../../platform/components/buttons/textButton/FlatButton';

import Item from '../../../TransactaFire/Item';
import { Transaction, Model } from '../../../TransactaFire/TransactaFire';
import User from '../../User/User';
import Routing from '../../routing/Routing';

/**
 * Landing page used by a User to accept a shared item.
 */
class ShareItemPage extends React.Component {
  mounted = false

  state = {
    status : "loading"
  }

  componentDidMount(){
    this.mounted = true
    let params = Routing.getRouterParam(this)
      this.type = params.type
      this.key =  params.key

    this.shareable = new Item(this, this.key, "shareItem")
    this.shareable.loaded.then( snapshot => {
      this.setState({status : "loaded"}) 
    })
  }

  componentWillUnmount(){ 
    this.mounted = false
  }

  /**
   * Called when the User accepts the Shareable. The item is added to the User
   * and the User is added to the item.
   */
  acceptShareable = async() => {
    let transaction  = new Transaction()

    const user = transaction.add(Model.newAtPath(User.uid))
    const item = transaction.add(Model.newAtPath(this.state.shareItem.id))
    const itemData = await item.loaded

    item.change(`readers/${User.uid}`,1)
    item.change(`writers/${User.uid}`,1)
    item.change(`users/${User.uid}`,{
      ...User.summary,
      permission : this.state.shareItem.permission
    })

    const view = {
      link : this.state.shareItem.id, 
      name : this.state.shareItem.name,
      owner : itemData.owner
      /* , Preview */
    }

    user.addToCollection(this.state.shareItem.type, this.state.shareItem.collection, view, this.state.shareItem.id )

    transaction.commit().then( ()=> 
      this.props.history.push(`/${this.state.shareItem.type}/${this.state.shareItem.id}`)
    )
  }

  /**
   * If the user declines the shareable, exits out of the landing page.
   */
  declineShareable=()=>{
    this.props.history.push(User.lastRoute)
  }


  get viewLoading(){
    return (
      <h3 style={{width: "100%"}}> Loading... </h3>
    )
  }
  get viewFailed(){
    return (
      <div layout="column" style={{width: "100%"}}>
        <h3>Error</h3>
        Did not find the shared object or encountered an error.
      </div>
    )
  }
  get viewLoaded(){
    return (
      <div layout="column" style={{padding: "16px", backgroundColor: "white" }}
        className="md-whiteframe-1dp">
        <div>{`You have been invited to ${this.state.shareItem.permission}
          the following ${this.state.shareItem.type}:`}
        </div>
        <div style={{padding: "0px", margin: "0px", display: "flex", flexDirection: "row", 
          alignItems : "center"}}>
          <Icon icon="format_list_bulleted"/>
          <span className="sidePanelItem md-body-2" style={{lineHeight: "48px", marginLeft : "16px"}}>
            {this.state.shareItem.name}
          </span>
          <span style={{flex:"1"}}/>
        </div>
        <div style={{ display: "flex", flexDirection: "row"}} >
          <FlatButton primary clicked={this.acceptShareable}>Accept</FlatButton>
          <FlatButton accent clicked={this.declineShareable}>Decline</FlatButton>
        </div>
      </div>
    )
  }

  render(){ 
  return (
    <div style={{padding: "64px", backgroundColor: "#f5f5f5", flex : "1"}}>
      {this.state.status === "loading" && this.viewLoading }
      {this.state.status === "loaded" && this.viewLoaded }
      {this.state.status === "loadFailed" && this.viewFailed }
    </div>
  )}
}

export default ShareItemPage
import React from 'react'

import IconButton from '../../../platform/components/buttons/iconButton/IconButton'
import FlatButton from '../../../platform/components/buttons/textButton/FlatButton'
import RaisedButton from '../../../platform/components/buttons/textButton/RaisedButton'
import FAB from '../../../platform/components/buttons/FAB/FAB'
import UiTestPage, {UiTestSection, UiTestRow, UiTestRowItem} from '../UiTestPage';
import CopyButton from '../../components/buttons/copyButton/CopyButton';

/**
 * Cards page is a demo of the Card component, interactive api documentaton, and a test
 * of the card's behaivor and conformance to the Material Spec.
 */
class ButtonsPage extends React.Component {

  render(){
    return (
      <UiTestPage>
        
        <UiTestSection name="Text Buttons">

          <UiTestRow name="Flat Buttons">
            <UiTestRowItem name="Flat Button">
              <FlatButton>Press Me</FlatButton>
            </UiTestRowItem>
            <UiTestRowItem name="Disabled">
              <FlatButton disabled={true}>Press Me</FlatButton>
            </UiTestRowItem>
            <UiTestRowItem name="Primary">
              <FlatButton primary>Press Me</FlatButton>
            </UiTestRowItem>
            <UiTestRowItem name="Accent">
              <FlatButton accent>Press Me</FlatButton>
            </UiTestRowItem>
            <UiTestRowItem name="Warn">
              <FlatButton warn>Press Me</FlatButton>
            </UiTestRowItem>
            <UiTestRowItem name="Colored">
              <FlatButton color="#9c27b0">Press Me</FlatButton>
            </UiTestRowItem>
            <UiTestRowItem name="Icon">
              <FlatButton icon="face">Press Me</FlatButton>
            </UiTestRowItem>
          </UiTestRow>

          <UiTestRow name="Flat Buttons Dark" dark>
            <UiTestRowItem name="Flat Button">
              <FlatButton>Press Me</FlatButton>
            </UiTestRowItem>
            <UiTestRowItem name="Disabled">
              <FlatButton disabled={true}>Press Me</FlatButton>
            </UiTestRowItem>
            <UiTestRowItem name="Primary">
              <FlatButton primary>Press Me</FlatButton>
            </UiTestRowItem>
            <UiTestRowItem name="Accent">
              <FlatButton accent>Press Me</FlatButton>
            </UiTestRowItem>
            <UiTestRowItem name="Warn">
              <FlatButton warn>Press Me</FlatButton>
            </UiTestRowItem>
            <UiTestRowItem name="Colored">
              <FlatButton color="#9c27b0">Press Me</FlatButton>
            </UiTestRowItem>
            <UiTestRowItem name="Icon">
              <FlatButton icon="face">Press Me</FlatButton>
            </UiTestRowItem>
          </UiTestRow>

          <UiTestRow name="Raised Buttons">
            <UiTestRowItem name="Raised Button">
              <RaisedButton>Press Me</RaisedButton>
            </UiTestRowItem>
            <UiTestRowItem name="Disabled">
              <RaisedButton disabled={true}>Press Me</RaisedButton>
            </UiTestRowItem>
            <UiTestRowItem name="Primary">
              <RaisedButton primary>Press Me</RaisedButton>
            </UiTestRowItem>
            <UiTestRowItem name="Accent">
              <RaisedButton accent>Press Me</RaisedButton>
            </UiTestRowItem>
            <UiTestRowItem name="Warn">
              <RaisedButton warn>Press Me</RaisedButton>
            </UiTestRowItem>
            <UiTestRowItem name="Colored">
              <RaisedButton color="#9c27b0">Press Me</RaisedButton>
            </UiTestRowItem>
            <UiTestRowItem name="Dark Colored">
              <RaisedButton darkColor="#9c27b0">Press Me</RaisedButton>
            </UiTestRowItem>
            <UiTestRowItem name="Icon">
              <RaisedButton icon="favorite" primary>Press Me</RaisedButton>
            </UiTestRowItem>
          </UiTestRow>
        </UiTestSection>
        
        <UiTestSection name="Floating Action Buttons">
          <UiTestRow name="Plain FABs">
            <UiTestRowItem name="FAB">
              <FAB icon="add"/>
            </UiTestRowItem>
            <UiTestRowItem name="Colored">
              <FAB color="#9c27b0" icon="add"/>
            </UiTestRowItem>
            <UiTestRowItem name="Primary">
              <FAB primary icon="add"/>
            </UiTestRowItem>
            <UiTestRowItem name="Accent">
              <FAB accent icon="add"/>
            </UiTestRowItem>
            <UiTestRowItem name="Warn">
              <FAB warn icon="add"/>
            </UiTestRowItem>
            <UiTestRowItem name="Disabled">
              <FAB disabled={true} icon="add"/>
            </UiTestRowItem>
          </UiTestRow>

          <UiTestRow name="Special Floating Action Buttons">
            <UiTestRowItem name="Mini FAB">
              <FAB mini icon="add"/>
            </UiTestRowItem>
            <UiTestRowItem name="Mini Primary">
              <FAB mini primary icon="add"/>
            </UiTestRowItem>
            <UiTestRowItem name="Mini Accent">
              <FAB mini accent icon="add"/>
            </UiTestRowItem>
            <UiTestRowItem name="Mini Warn">
              <FAB mini warn icon="add"/>
            </UiTestRowItem>
          </UiTestRow>
        </UiTestSection>

        <UiTestSection name="Icon Buttons">
          <UiTestRow>
            <UiTestRowItem name="Icon Button">
              <IconButton icon="face"/>
            </UiTestRowItem>
            <UiTestRowItem name="Primary">
              <IconButton primary icon="face"/>
            </UiTestRowItem>
            <UiTestRowItem name="Accent">
              <IconButton accent icon="face"/>
            </UiTestRowItem>
            <UiTestRowItem name="Warn">
              <IconButton warn icon="face"/>
            </UiTestRowItem>
            <UiTestRowItem name="Colored">
              <IconButton color="#9c27b0" icon="face"/>
            </UiTestRowItem>
            <UiTestRowItem name="Disabled">
              <IconButton disabled icon="face"/>
            </UiTestRowItem>
            <UiTestRowItem name="Small Icon Button">
              <IconButton small icon="face"/>
            </UiTestRowItem>
          </UiTestRow>
        </UiTestSection>

        <UiTestSection name="Special Buttons">
          <UiTestRow>
            <UiTestRowItem name="Copy Button (Only works on Inputs)">
              <div style={{display:"flex",flexDirection:"column"}}>
                <textarea id="textToCopy" readOnly wrap="hard" style={{resize:"none"}}
                  value="The quick brown fox jumps over the lazy dog"/>
                <CopyButton element="textToCopy"/>
              </div>
            </UiTestRowItem>
          </UiTestRow>
        </UiTestSection>

        <UiTestSection name="Not Supported Yet">
          <ul>
            <li>Unelevated, Outlined Text Buttons</li>
            <li>Contained (Full Width) Text Buttons</li>
            <li>Trailing Icon Text Buttons</li>
            <li>Expanded Fab</li>
            <li>Dense Text Button</li>
            <li>Toggle Buttons</li>
          </ul>
        </UiTestSection>
      </UiTestPage>
    )
  }
}
export default ButtonsPage
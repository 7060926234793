/**
 * Brian Utility Functions
 * @todo Create tests
 */

 /**
  * Javascript's truthy coercion has `0 == false`, for **truthier** *0* coerces to *true* and all other values
  * are handled as *truthy* or *falsey*.
  * 
  * @todo document truthier args 2
  */
export const truthier =(a,b)=> {
  if (typeof b === "object") return b[a] === 0 || !!b[a]
  return a === 0 || !!a
} 

/**
 * Returns the first value that is **truthier** or returns `undefined`.
 * @param  {...any} args
 * @return {value | undefined} a `value` from `args` or `undefined`
 */
export const truthierFirst =(...args)=> {
  return args.find( a => truthier(a))
}

/**
  * Javascript's falsey coercion has `0 == false`, for **falsier** *0* coerces to *false* and all other values
  * are handled as *truthy* or *falsey*.
  */
export const falsier =a=> {
  return !truthier(a)
}

/**
 * Gets the last element of the array
 * @param {*} array 
 */
export const arrayLast =(array)=> array[array.length - 1]

/**
 * Takes a property that could be either a value or function. If it is a function, executes it and return its value, 
 * otherwise it just returns its value.
 * 
 * @param {prop} prop to resolve.
 * @param  {...any} args any arguments to pass to the prop when it resolves.
 * @return The result of executing the prop if it is a function or the prop itself if it is not.
 */
export function resolveToValue(prop, ...args){
  if (typeof prop === "function") return prop(...args)
  return prop
}
// ^-- Possible Cross Site Vulnerability
import React from 'react'

/**
 * @todo Document
 * @todo mutliple should be a param
 * @todo Make button stylable
 */
export default class FilePicker extends React.Component {

  changedFile(){
    this.props.changed && this.props.changed(this.refs.fileInput.files)
  }

  render(){
    return (<div style={this.props.style}>
      { this.props.children ? 
        (<>
          <label>
            {this.props.children}
            <input type="file" ref="fileInput" name="files[]" multiple
              onChange={()=>this.changedFile()} style={{
                position:"absolute",top: "-1000000px"}}/>
          </label>
        </>)
      : (
        <input type="file" ref="fileInput" name="files[]" multiple 
            onChange={()=>this.changedFile()} />
        )        
      }
    </div>
    )
  }

  /**
   * Static helper for reading files
   * @param {*} file 
   * @param {*} type 
   */
  static fileReader(file,type){
    return new Promise( (resolve,reject)=> {
      let reader = new FileReader()
      reader.onload =()=> { resolve(reader.result) }

      switch(type){
        case "text" : 
          reader.readAsText(file)
          break
        default : 
          reader.readAsText(file)
      }
    })

  }
}
import React from 'react'

import './Card.css'
import { ClassList, ClassNameDelegate } from '../../delegates/ClassNameDelegate';

const Card =(props)=>{
  const classes = ["card"]
  ClassNameDelegate(props,classes)
  return (
    <div className={ClassList(classes)} style={props.style}>
      {props.children}
    </div>
  )
}
const CardTitle =(props)=>{
  let classes = "card-title"
  if (props.primary) classes+=" colors-fill-primary"
  return (
    <div className={classes}>
      {props.children}
    </div>
  )
}

const CardContent =(props)=>{
  return (
    <div className="card-content">
      {props.children}
    </div>
  )
}

const CardActions =(props)=>{
  return (
    <div className="card-actions">
      {props.children}
    </div>
  )
}

export {CardTitle, CardContent, CardActions}
export default Card
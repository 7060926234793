/**
 * Delegate that handles adding color properties to a component
 * @param {*} props 
 * @param {*} classes 
 * @param {*} styles
 * @param {*} modifiers - List of modifiers that change how the delegate works.
 *   - `notBackground` - Will apply the color to the css "color" property instead of "background-color"
 * 
 * @prop {String} color - If present, the color is used for the switch.
 * @prop {boolean} primary - If present, will use the primary color.
 * @prop {boolean} accent - If present, will use the accent color.
 * @prop {boolean} warn - If present, will use the warn color.
 */
export const ColorsDelegate=(props,classes,styles,modifiers)=>{
  if (modifiers && modifiers.notBackground){
    if (props.color) styles.color = props.color
    if (props.darkColor) styles.color = props.darkColor
  }
  else {
    if (props.color) styles.backgroundColor = props.color
    if (props.darkColor) styles.backgroundColor = props.darkColor
  }
 
  if (props.primary) classes.push("primary")
  if (props.accent) classes.push("accent")
  if (props.warn) classes.push("warn")
}
import React from 'react'
import { shortTimeFormat } from '../../../../GlobalUtils/DateAndTime';
import { contactStringToUser } from '../../../../platform/components/chooser/MultiContactChooser';
import ExpansionPanel from '../../../../platform/components/expansionPanel/ExpansionPanel';
import MessageParser from '../parser/MessageParser';

export const QuoteEntries =(quotes)=> {
  if (quotes === undefined) return null
  return (
    <ExpansionPanel summary="Quoted Message">
      {quotes.map( (quote,i) => QuoteEntry(quote,i) )}
    </ExpansionPanel>
  )
}

export const QuoteEntry =(quote, i)=> {
  const {from,sent,to,subject,body} = quote

  return (
    <div key={i}>
      <div className="message__quote-header">
        <div className="row"><b>From</b>{from}</div>
        <div className="row"><b>Sent</b>{shortTimeFormat(sent)}</div>
        <div className="row"><b>To</b>{contactStringToUser(to)}</div>
        <div className="row"><b>Subject</b>{subject}</div>
      </div>
      <div className="message__quoted-text">
        <MessageParser>
          {body}
        </MessageParser>
      </div>
    </div>
  )
}
/**
 * Library used to handle undoable actions.
 */
export default class UndoableActions {
  
  static index = 0
  static undoableActions = {}

  /**
   * Adds an action that can be undone or canceled. This allows a fire and 
   * forget aproach to taking some future action unless another action intervenes.
   * 
   * @param {function} action Called when the Undoable happens. 
   * @param {function} undo Called when the Undoable is undone.
   * @param {*} params - Parameter(s) to be passed to the Undoable's methods. 
   * @param {function} cancel - Optional method to call when the Undoable is canceled.
   * This method should be used like like a "final" method as it gets called wether
   * the Undoable's action or undo method is called.
   * 
   * @returns actionId - the id of the undoableAction
   * 
   * @todo Make this return a Undoable with an id instead of just the id
   */
  static addAction(action, undo, params, cancel){
    const actionId = UndoableActions.index +=1

    UndoableActions[actionId] = {
      action : action,
      undo : undo,
      params : params,
      cancel : cancel
    }

    return actionId
  }

  /**
   * Executes the undoable's action unless it has been canceled. By default, the
   * Undoable is canceled after action.
   */
  static action(actionId, afterAction = "cancel"){
    const undoableAction = UndoableActions[actionId]
    if (!undoableAction) return

    if (undoableAction.action)
      undoableAction.action(undoableAction.params)
    if (afterAction === "cancel")
      UndoableActions.cancel(actionId)
  }

  /**
   * Undoes an Undoable. By default, the Undoable is canceled after undo.
   */
  static undo(actionId, afterUndo = "cancel"){
    const undoableAction = UndoableActions[actionId]
    if (!undoableAction) return

    if (undoableAction.undo)
      undoableAction.undo(undoableAction.params)
    if (afterUndo === "cancel")
      UndoableActions.cancel(actionId)
  }

  /**
   * Cancels the action so that it can't be called. 
   */
  static cancel(actionId){
    const undoableAction = UndoableActions[actionId]
    if (!undoableAction) return

    delete UndoableActions[actionId]
    if (undoableAction.cancel)
      undoableAction.cancel(undoableAction.params)
  }

}
import React from 'react'
import SideNavigationDrawer, { SideNavigationService } from '../../../platform/components/sideNavigation/SideNavigationDrawer'
import Icon from '../../../platform/components/icons/Icon'

import { Link } from 'react-router-dom'
import { Header } from '../../base/screen/AppScreen'
import LargeArea from '../../../platform/components/sideNavigation/LargeArea'
import Avatar from '../../../platform/components/avatar/Avatar'
import FlatButton from '../../../platform/components/buttons/textButton/FlatButton'
import User from '../../User/User';
import { Route, withRouter } from 'react-router-dom'
import MessageIndicator from '../messages/navigation/MessageIndicator';

/**
 * The SideNavigationDrawer for PlanReach
 */
class AppNavigation extends React.Component {
  state = {}
  mounted = false

  /**
   * Marks the component as mounted.
   */
  componentDidMount(){
    this.mounted = true
  }
  componentWillUnmount =()=> {
    this.mounted = false
  }

  /**
   * Moves view to a item
   */
  handleLink =(url)=> {
    if (!this.mounted) return
    this.props.history.push(url)
    SideNavigationService.hideDrawer()
  }

  UiItems = [
    {link: "/uiTest/badges", label : "Badges"},
    {link: "/uiTest/buttons", label : "Buttons"},
    {link: "/uiTest/cards", label : "Cards"},
    {link: "/uiTest/checkBox", label : "Check Boxes"},
    {link: "/uiTest/chips", label : "Chips"},
    {link: "/uiTest/dialogs", label : "Dialogs"},
    {link: "/uiTest/fileUpload", label : "File Upload"},
    {link: "/uiTest/inputs", label : "Inputs"},
    {link: "/uiTest/menus", label : "Menus"},
    {link: "/uiTest/messageParser", label : "Message Parser"},
    {link: "/uiTest/multiAddItemPage", label : "Multi Add Items"},
    {link: "/uiTest/pickers", label : "Pickers"},
    {link: "/uiTest/toasts", label : "Toasts and Snackbars"},
    {link: "/uiTest/typography", label : "Typography"},
    {link: "/uiTest/navigationPage", label : "Navigation Page"},
  ]

  planReachFeatures = [
    { link: "/contacts", icon : "group", name : "Contacts", },
    { link: "/messages", icon : "email", name : "Messages", right : this.messageIndicator},
    { link: "/chats", icon : "chats", name : "Chats" },
    { link: "/boards", icon : "view_column", name : "Boards" },
    { link: "/notes", icon : "subject", name : "Notes" },
    { link: "/quicklists", icon : "list", name : "QuickLists" },
    { link: "/feedback", icon : "feedback", name : "Feedback" },
    { link: "/feedbackMessages", icon : "live_help", name : "Feedback",  right : this.feedbackIndicator, visible: this.showFeedbackMessages },
    { link: "/uiTest/buttons", icon : "palette", name: "UI Tests"},
  ]

  get messageIndicator(){
    return <MessageIndicator/>
  }
  get feedbackIndicator(){
    return <MessageIndicator messageSource="unreadFeedback"/>
  }

  showFeedbackMessages(){
    return !!(User.data && User.data.flags && User.data.flags.feedbackMessages)
  }

  getUiItems(){
    return this.UiItems.map( (uiItem,i) => AppNavigationItem({...uiItem, key: i}))
  }

  getFeatureItems(){
    return this.planReachFeatures.map( (featureItem,index) => 
      this.getFeatureItem({...featureItem, id: index}))
  }

  getFeatureItem({name,icon,link,id,right,visible}){
    if (typeof visible === "function" && !visible()) return null

    return (
    <div className="side-navigation__list-item" key={id} onClick={()=>this.handleLink(link)}>
      <Icon primary icon={icon}/>
      <div>{name}</div>
      <div className="right">{right}</div>
    </div>)
  }

  render(){
    return (
      <SideNavigationDrawer>
        <Header primary/>
        <div className="side-navigation__list">
          <LargeArea>
            <div className="row flex" onClick={()=>this.handleLink("profile")}>
              <Avatar src={User.avatarUrl}/>
              <div style={{alignSelf: "center"}}>{User.userName}</div>
            </div>
            <FlatButton primary clicked={User.logout}>Logout</FlatButton>
          </LargeArea>
          { this.getFeatureItems() }
          <Route path="/uiTest" component={()=>this.getUiItems()}/>
        </div>
      </SideNavigationDrawer>
    )
  }
}

const AppNavigationItem =(props)=> {
  return (<div className="side-navigation__list__item" key={props.key}>
    <Link to={props.link}><Icon primary icon={props.icon}/>{props.label}</Link>
  </div>)
} 

export default withRouter(AppNavigation)
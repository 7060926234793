import React from 'react'

import { SubtitleText, BodyText } from '../../../../../platform/components/typography/Typography';
import Icon from '../../../../../platform/components/icons/Icon';
import FlatButton from '../../../../../platform/components/buttons/textButton/FlatButton';
import IconButton from '../../../../../platform/components/buttons/iconButton/IconButton';
import EditField from '../fieldWidgets/EditField';
import SummaryField from '../fieldWidgets/SummaryField';
import { resolveToValue } from '../../../../../GlobalUtils/BrianUtils';

/**
 * This widget shows an edit and a summary view of a list of items.
 * 
 * @prop {function} available - Optional function that determines if the item can add/edit items.
 * @todo Document
 */
class FieldIterator extends React.Component{
  
  state = { mode : "summary" }

  /**
   * When this resolves to false, the edit button and edit view will be unavailable
   */
  get available(){
    if (this.props.available === undefined || this.props.available === null || this.props.available === "" ||
      this.props.available === true) return true;

    if (typeof this.props.available === "function") return this.props.available()
    return this.props.available
  }

  /**
   * Determines which view the item is in.
   */
  get currentView(){
    if (!this.available) return this.summaryView
    switch(this.state.mode){
      case "summary" : return this.summaryView
      case "edit" : return this.editView
      default : return this.summaryView
    }
  }
  get currentButtons(){
    if (!this.available) return null
    switch(this.state.mode){
      case "edit" : return this.saveAndCancelButton
      default : return null
    }
  }

  /**
   * Summary Mode
   */
  summaryMode=()=>{
    this.setState({mode : "summary"})
  }

  get summaryView(){
    let style = {}
    
    if (this.props.paddingLeft) style.paddingLeft = this.props.paddingLeft+"px"
    return ( 
      <div style={style}>
        {this.summaryLines()}
      </div>
    )
  }
  summaryLines=()=> {
    if (this.props.summaryLines){
      return this.props.summaryLines.map( (line,i) =>
        this.summaryLine(line, i, this.props.parent)
      )
    } else if (!this.props.items){
      return this.summaryList()
    }
    return null
  }
  summaryLine=({section, left, center, right, visible = true, custom, content, ...line}, index, parent)=>{
    if (!resolveToValue(visible)) return null

    if (section) return (
      <div key={index} className="flexbox-row margin-8" >
        {left && <SummaryField {...left} parent={parent} key="left"/>}
        {center && <SummaryField {...center} parent={parent} key="middle"/>}
        {right && <SummaryField {...right} parent={parent} key="right"/>}
      </div>
    )
    if (custom) return <div>{content}</div>

    return (
      <div key={index} className="flexbox-row margin-8">
        <SummaryField {...line} parent={parent}/>
      </div>
    )
  }
  summaryList=()=> {
    return this.props.items.map( itemId => this.summaryItemView(itemId) )
  }
  summaryItemView=(lineId)=> {
    const label = lineId
    const value = this.props.parent[lineId]
    
    return (
      <div className="multi-add-item_summary" key={lineId}>
        <div className="flex column">
          <span style={{lineHeight: "24px"}} className="typography-body-light">
            {label} </span>
          <span style={{lineHeight: "24px"}} className="typography-body">
            {value} </span>
        </div>
      </div>
    )
  }
  emptySummary =()=> {
    return (
    <div style={{marginLeft: "38px"}}>
      <BodyText>{this.props.emptyMessage || "No items"}</BodyText>
    </div>)
  }

  /**
   * Edit Mode
   */
  editMode=()=>{
    let props = {}
    this.props.items.forEach( itemId => 
      props[itemId] = this.props.parent[itemId]
    )
    this.setState({
      mode : "edit",
      ...props
    })
  }
  saveItem=()=>{
    let values = {}
    this.props.items.forEach( itemId => 
      values[itemId] = this.state[itemId]
    )
    this.props.saveItem(values)
    this.summaryMode()
  }
  get editView(){
    let style = {}
    
    if (this.props.paddingLeft) style.paddingLeft = this.props.paddingLeft+"px"
    return ( 
      <div style={style}>
        {this.editLines()}
      </div>
    )
  }
  editLines=()=> {
    if (!this.props.editLines) return null
    return this.props.editLines.map( (line,i) =>
      this.editLine(line,i)
    )
  }
  editLine=({section, left, center, right, visible = true, custom, content, ...line}, index)=>{
    if (!resolveToValue(visible)) return null

    if (section) return (
      <div key={index} className="flexbox-row">
        {left && <EditField {...left} updateProp={this.updateProp} parent={this.state} key={`${index}_left`}/>}
        {center && <EditField {...center} updateProp={this.updateProp} parent={this.state} key={`${index}_middle`}/>}
        {right && <EditField {...right} updateProp={this.updateProp} parent={this.state} key={`${index}_right`}/>}
      </div>
    )
    if (custom) return <div key={`${index}_custom`}>{content}</div>
    return (
      <div key={index} className="flexbox-row">
        <EditField {...line} updateProp={this.updateProp} parent={this.state}/>
      </div>
    )
  }

  get saveAndCancelButton(){
    return (<div className="multi-add-item__actions">
      <FlatButton primary clicked={this.saveItem}>Save</FlatButton>
      <span className="flex"/>
      <FlatButton primary clicked={this.summaryMode}>Cancel</FlatButton>
    </div>)
  }

  /**
   * Modifies a line in an item
   */
  updateProp=(prop,value)=>{
    this.setState({
      [prop] : value
    })
  }

  render(){
    return (
      <div className="multi-add-item">
        <div className="row">
          <Icon icon={this.props.icon} color="rgba(0,0,0,.54)" />
          <div style={{lineHeight: "48px", flex : "1", paddingLeft : "14px"}}>
            <SubtitleText>{this.props.label}</SubtitleText>
          </div>
        { this.available && 
          <IconButton clicked={()=>this.editMode()} icon="edit"/> 
        }
        </div>
        <div className="column flex">
          { this.currentView }
        </div>
        <div className="column flex">
          { this.currentButtons }
        </div>
      </div>
    ) 
  }
}

export default FieldIterator
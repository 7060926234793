import React from 'react'

import './QuickList.css'

import { Transaction, Model, Collection, newKey } from '../../../../../TransactaFire/TransactaFire';
import { ToastService } from '../../../../../platform/components/toast/Toast';

import {BackButton, EditableTitle, FabArea} from '../../../../base/screen/AppScreen'
import FlatButton from '../../../../../platform/components/buttons/textButton/FlatButton';
import IconButton from '../../../../../platform/components/buttons/iconButton/IconButton';
import DetailScreen from '../../../../base/view/detail/DetailScreen';
import QuickListItem from './item/QuickListItem';
import Fab from '../../../../../platform/components/buttons/FAB/FAB';
import Menu, { MenuItem } from '../../../../../platform/components/menu/Menu';
import Divider from '../../../../../platform/components/dividers/Divider';
import QuickListSection from './section/QuickListSection';
import UndoableActions from '../../../../undoable/UndoableActions';
import Reordering from '../../../../reordering/Reordering';

/**
 * @todo Undoable check all
 * @todo Undoable uncheck all
 */
class QuickList extends DetailScreen {

  schema = {
    items : { 
      type : "orderedCollection",
      name : "items"
    }
  }

  addingItem = false

  state = {
    items : {},
    name : "",
    showSubheader : false,
    newItem : null,
    hidden : {},
    reorderingItem : false,
    inReorderingSection : false,
    selectedItem : false
  }

  shareablePrefix = "quickList"
  viewNode = "quickLists"
  shareableNode = "quickList"
  shareableName = "QuickList"

  shareableRef = null
  shareableViewRef = null

  /**
   * Shows the subheader for an item or
   */
  showSubheader = (id,type) => {
    if (!this.mounted || id === "newItem") return
    this.setState({showSubheader: id, subheaderType : type})
  }

  hideSubheader = id => {
    if (!this.mounted) return
    if (!id || this.state.showSubheader === id) // prevents clicking a new item from triggering
      this.setState({showSubheader: false })
  }

  getPreview(value, field, itemId, transaction){
    if (!field || !itemId) return null

    const data = {...this.state}
      data[itemId][field] = value

    const previewItems = Object.keys(data.items || {})
      .filter( a => data[a] || data[a] === 0 )
      .sort( (a,b) => data.items[a] - data.items[b])

    this.createPreview(previewItems, data, transaction)
  }

  /**
   * Creates the View Preview
   * @param {Array} items 
   */
  createPreview=(items,data,transaction)=>{
    const preview = !items ? null : this.mapPreview(items,data)

    Object.keys(this.state.readers).forEach( reader => {
      let view = transaction.add( Model.newAtPath(`${reader}/${this.viewNode}/${this.shareableId}`) )
        view.change("preview",preview)
    })
  }

  /**
   * @param {Array} items Sorted array of item ids
   * @param {*} data 
   */
  mapPreview(items,data){
    let previewItems = {}
    items.forEach( (a,i) =>
      previewItems["item_"+i] = this.previewItem(a,data)
    )
    return previewItems
  }

  /**
   * Creates an items on the preview
   */
  previewItem =(itemId,data)=> {
    if (!data[itemId]) return null

    const {name,checked,isSection} = data[itemId]
    return {
      name : name,
      checked : checked || null,
      isSection : isSection || null
    }
  }

  /**
   * Returns a list of items in a section.
   *
   * @param {id} sectionId Id of the section. 
   * @param {boolean} returnSelf If true, returned items will include the section.
   * Defaults to true.  
   */
  getItemsInSection=(sectionId, returnSelf = true)=>{
    if (!sectionId || !this.state[sectionId]) return null
    let items = Object.keys(this.state.items)
      .filter( a => this.state.items[a] || this.state.items[a] === 0)
      .sort( (a,b) => this.state.items[a] - this.state.items[b] )
    
    let position = this.state.items[sectionId]
    
    let nextSection = items.filter( a => this.state[a])
      .find( a => this.state[a].isSection && this.state.items[a] > position)

    let endPosition = nextSection ? this.state.items[nextSection] :
      Object.keys(this.state.items).length

    return items.filter( a => a !== "newItem" && 
    ( (returnSelf && a === sectionId) ||
      (this.state.items[a] > position && this.state.items[a] < endPosition) )
    )
  }

  getQuickListView =()=> {
    return this.shareableViewRef
  }

  /**
   * Called when the user clicks the addItem FAB
   */
  addItemFab =()=> {
    this.addItem()
    this.addingItem = true
  }

  /**
   * Creates a new QuickList item
   */
  addItem =()=> {
    if (!this.state.selectedItem || this.state.items === null ||this.state.items === undefined)
      return this.setState(this.addItemAtEnd())
    
    let length = Object.keys(this.state.items).length
      if (!length) return this.setState(this.addItemAtEnd())
    
    let position = this.state.items[this.state.selectedItem] + (this.addingItem ? 1 : 0)
      return this.addItemAtPosition(position)
  }

  addItemAtEnd =()=> {
    let items = {...this.state.items}
    Reordering.appendItem("newItem",items)

    return {
      hidden : {
        ...this.state.hidden,
        newItem : false
      },
      items : items,
      newItem : {
        checked : false,
        name : null,
        $new : true,
      }
    }
  }

  addItemAtPosition =(position)=> {
    let items = Reordering.insertItem("newItem",position,{...this.state.items})

    this.setState({
      hidden : { ...this.state.hidden, newItem : false },
      items : items,
      newItem : { checked : false, name : null, $new : true, $position : position },
      selectedItem : false
    })
  }

  /**
   * Overriding snapshotChange to handle newItem position
   * @override
   */
  snapshotChanged(previousState,snapshotState){
    this.newItemPositionOnSnapshotChange(previousState,snapshotState)
  }

  /**
   * Handles snapshot changes when there is a newItem
   * @todo Describe the desired behaivor
   */
  newItemPositionOnSnapshotChange(previousState,snapshotState){
    if (!this.isAddingNewItem(previousState,snapshotState)) return

       // There is an issue when external reorders happen...not sure how it should work, let alone how to handle
    let itemsBefore = Reordering.itemsBefore("newItem",previousState.items)
      .filter( a => snapshotState.items[a] !== null && snapshotState.items[a] !== undefined)
      .sort( (a,b) => snapshotState.items[a] - snapshotState.items[b] )

    let itemsAfter = Reordering.itemsAfter("newItem",previousState.items)
      .filter( a => snapshotState.items[a] !== null && snapshotState.items[a] !== undefined)
      .sort( (a,b) => snapshotState.items[a] - snapshotState.items[b] )

    if (itemsBefore.length){
      Reordering.insertItem("newItem", snapshotState.items[itemsBefore.pop()]+1, snapshotState.items)
    } 
    else if (itemsAfter.length){
      Reordering.insertItem("newItem", snapshotState.items[itemsAfter[0]], snapshotState.items)
    }
    else {
      Reordering.appendItem("newItem", snapshotState.items)
    }
    
    snapshotState.newItem = previousState.newItem
  }

  isAddingNewItem(...states){
    if (!this.addingItem) return false

    const stateHasNewItem = state => 
      state.newItem && state.items && state.newItem !== null && state.newItem !== undefined

    states.forEach( state => { if (!stateHasNewItem(state)) return false })
    return true
  }

  /**
   * Duplicates a QuickList item
   */
  duplicateItem =(itemId)=> {
    let key = newKey("item_")
    let transaction = new Transaction()

    let itemModel = transaction.add(Model.newAtPath(`${this.shareableId}/${key}`))
      itemModel.set({...this.state[itemId]})

    let itemsModel = transaction.add(Model.newAtPath(`${this.shareableId}/items`))
      itemsModel.set( Reordering.insertItem(key, this.state.items[itemId] + 1, {...this.state.items}) )

    transaction.commit()
    this.setState({showSubheader : false })
    this.addingItem = false
  }

  /**
   * Adds a QuickList item
   */
  saveNewItem =()=> {
    const key = newKey("item_")

    const transaction = new Transaction()
      const itemModel = transaction.add(Model.newAtPath(`${this.shareableId}/${key}`))
      const itemsModel = transaction.add(Model.newAtPath(`${this.shareableId}/items`))

    const item = this.formatItem(this.state["newItem"])
      itemModel.set(item)

    const items = Reordering.replaceItem("newItem",key, {...this.state.items})
      items["newItem"] = null

    itemsModel.set(items)

    let sortedItems = Object.keys(items)
      .filter( a => items[a] !== null)
      .sort( (a,b) => items[a] - items[b])

    let previewData = { [key] : item }
    Object.keys(items)
      .filter( a => items[a] !== null && this.state[a] )
      .forEach( a => previewData[a] = this.state[a] )

    this.createPreview(sortedItems,previewData,transaction)
    return new Promise( (resolve, reject) => {
      this.setState({
        selectedItem : key,
        items : items,
        [key] : {...item},
        newItem : null,
      }, ()=> resolve(transaction.commit()))
    })
  }

  formatItem(item){
    let formattedItem = {}
    Object.keys(item).forEach( key => {
      if (key.charAt(0) !== "$") formattedItem[key] = item[key] 
    })
    return formattedItem
  }

  /**
   * Adds a section to the QuickList.
   * @todo Make add at location
   */
  addSection = async()=> {
    
    let transaction = new Transaction()
    let items = transaction.add( new Collection(this.shareableRef,"items") )

    let section = { name : "New section", isSection : true }
    const addedSection = await items.addToCollection(section, "section_")

    let sortedItems = Object.keys(this.state.items)
      .filter( a => this.state.items[a] !== null)
      .sort( (a,b) => this.state.items[a] - this.state.items[b])

    sortedItems.push(addedSection.key)

    const stateWithAddedSection = {...this.state, [addedSection.key] : section}
    this.createPreview(sortedItems, stateWithAddedSection, transaction)

    transaction.commit().then( ()=> {
      if (this.mounted){
        this.addingItem = true
        this.mounted && this.setState({selectedItem : addedSection.key})
      } 
    })
  }

  /**
   * Checks all items
   * @todo Add todo toast
   */
  checkAll =()=> {
    let transaction = new Transaction()
    let quickList = transaction.add( new Model(this.shareableRef,this.state,false) )
    let data = {...this.state}
    
    Object.keys(this.state.items)
      .filter( a => this.state[a] !== null && this.state[a] !== undefined && a !== "newItem")
      .forEach( id => {
        if (!quickList._data[id].isSection && !quickList._data[id].checked){
          quickList.change(`${id}/checked`,true)
          data[id].checked = true
        }
      })

    const sortedItems = Object.keys(this.state.items)
      .filter( a => ( this.state[a] || this.state[a] === 0 ) )
      .sort( (a,b) => this.state.items[a] - this.state.items[b])

    this.createPreview(sortedItems, data, transaction)

    transaction.commit()
    this.setState({showSubheader : false})
    this.addingItem = false
  }

  /**
   * Unchecks all items
   * @todo Add todo toast
   */
  uncheckAll =()=> {
    let transaction = new Transaction()
    let quickList = transaction.add( new Model(this.shareableRef,this.state,false) )
    let data = {...this.state}
    
    Object.keys(quickList._data.items)
      .filter( a => this.state[a] !== null && this.state[a] !== undefined && a !== "newItem")
      .forEach( id => {
        if (!quickList._data[id].isSection && quickList._data[id].checked){
          quickList.change(`${id}/checked`,false)
          data[id].checked = false
        }
      })

    const sortedItems = Object.keys(this.state.items)
      .filter( a => ( this.state[a] || this.state[a] === 0 ) )
      .sort( (a,b) => this.state.items[a] - this.state.items[b])

    this.createPreview(sortedItems, data, transaction)

    this.setState({showSubheader : false})
    transaction.commit()
  }

  removeChecked =()=> {
    let removed = Object.keys(this.state.items).filter( id => 
      this.state[id] && this.state[id].checked
    )

    this.hideItems(removed)
    
    let action = UndoableActions.addAction(
      this.deleteItems, this.showItems, {items : removed}
    )

    ToastService.showToast("Checked items removed", 
      this.undoRemoveItemsButton(
        ()=>UndoableActions.undo(action)
      )).then( () => UndoableActions.action(action) )

    this.setState({showSubheader : false})
    this.addingItem = false
  }

  removeAll =()=> {
    let removed = Object.keys(this.state.items)
    this.hideItems(removed)
    
    let action = UndoableActions.addAction(
      this.deleteItems, this.showItems, {items : removed}
    )

    ToastService.showToast("All items cleared", 
      this.undoRemoveItemsButton(
        ()=>UndoableActions.undo(action)
      )).then( () => UndoableActions.action(action) )

    this.setState({showSubheader : false})
    this.addingItem = false
  }

  /**
   * Deletes an array of items.
   * 
   * Resets the subheader, selected item, and addingItem properties then calls deleteItemsTransaction
   * witht the items to be deleted.
   * 
   * @param {Object} params - Object passed into this method. The param's **items** will be deleted.
   */
  deleteItems =(params)=> {
    if (!params) return
    if (!(params.items instanceof Array) || !params.items.length) 
      return this.deleteItem(params)

    if (params.items.find( itemId => itemId === this.state.showSubheader) !== undefined){
      this.mounted && this.setState({showSubheader : false}) 
    }
    if (params.items.find( itemId => itemId === this.state.selectedItem) !== undefined){
      this.mounted && this.setState({selectedItem : false})
    }
    this.addingItem = false

    const deleted = params.items.filter( itemId => this.state[itemId])
    this.deleteItemsTransaction(deleted)
  }

  /**
   * Handles the actual transaction to delete the items
   * @param {Array} deleted - The items to delete.
   */
  deleteItemsTransaction = async(deleted) => {
    const transaction = new Transaction()
    const collection = transaction.add( new Collection(this.shareableRef,"items") )
    await collection.deleteItems(deleted)

    const deletedKeys = deleted.reduce( (previousValue, currentVal) =>
      { previousValue[currentVal] = true; return previousValue },
    {} );

    const notDeleted =(a)=> !deletedKeys[a]
    const previewItems = Object.keys(this.state.items)
      .filter( a => ( this.state[a] || this.state[a] === 0 ) && notDeleted(a) )
      .sort( (a,b) => this.state.items[a] - this.state.items[b])

    this.createPreview(previewItems, this.state, transaction)
    transaction.commit() 
  }

  /**
   * Get the id of the section the item is in
   */
  getItemSection =(itemId)=> {
    return Object.keys(this.state.sections).find( sectionId => this.state[sectionId].items &&
      this.state[sectionId].items[itemId] !== undefined
    , this)
  }

  /**
   * Get the position of a item in a section 
   */
  getItemPosition(itemId,section){
    if (!section.items) return 0
    return section.items[itemId]
  }

  /**
   * Selects the item, which results in its background changing and it's id being stored for other actions.
   */
  selectItem =(itemId)=> {
    if (!this.mounted) return

    this.setState({ selectedItem : itemId })
    if (itemId !== "newItem") this.addingItem = false
  }

  /**
   * Deselects the item, which resets its background and makes sure it isn't used in other actions.
   */
  deselectItem =(itemId)=> {
    if (!this.mounted || this.state.selectedItem !== itemId) return
    
    this.setState({ selectedItem : false })
    if (itemId !== "newItem") this.addingItem = false
  }

  /**
   * Marks a item to be reordered.
   */
  reorderItem =(itemId)=> {
    if (!this.mounted) return
    this.setState({ 
      showSubheader : itemId,
      reorderingItem : itemId
    })
    this.addingItem = false
  }

  reorderSection =(sectionId)=> {
    if (!this.mounted) return
    
    let itemsInSection = {}
    this.getItemsInSection(sectionId,false)
      .forEach( a => itemsInSection[a] = true)

    this.setState({
      showSubheader : sectionId,
      reorderingItem : sectionId,
      inReorderingSection : itemsInSection
    })
    this.addingItem = false
  }

  /**
   * Ends the item reordering process. If no destination is passed in the reordering is simply
   * cancelled, otherwise the Collection's reorders method is used the results are committed.
   */
  endReorderItem = async(destinationId) => {
    if (!this.mounted) return
    if (destinationId === undefined || destinationId === this.state.reorderingItem){
      return this.setState({
        reorderingItem : false,
        inReorderingSection : false
      })
    }

    let transaction = new Transaction()
    let items = transaction.add(new Collection(this.shareableRef,"items"))
    let itemId = this.state.reorderingItem
    let reorderedItems = null

    this.setState({
      reorderingItem : false,
      inReorderingSection : false
    })
    this.addingItem = false

    if (this.state[itemId].isSection){
      reorderedItems = await items.reorderItemRange(this.getItemsInSection(itemId), destinationId)
    } else {
      reorderedItems = await items.reorderItems(itemId, destinationId)
    }

    const sortedItems = Object.keys(reorderedItems)
      .filter( a => ( this.state[a] || this.state[a] === 0 ) )
      .sort( (a,b) => reorderedItems[a] - reorderedItems[b])

    this.createPreview(sortedItems, this.state, transaction)
    transaction.commit()
  }

  /**
   * If there are items after the current item, advances to the next. 
   * Otherwise it will create a new item.
   */
  nextItem =(id)=> {
    let position = this.state.items[id]
    let next = Object.keys(this.state.items)
      .filter( a => this.state.items[a] )
      .sort( (a,b)=> this.state.items[a] - this.state.items[b])
      .find( a => this.state.items[a] > position)

    if (next !== undefined){
      this.setState({
        focus:next,
        [next]: {...this.state[next], selected : true},
        selectedItem : next
      })

      setTimeout(()=>this.setState({
        [id]: {...this.state[id], selected : false},
        focus:false
      }))

    } else {
      if (id === "newItem"){
        this.saveNewItemField(this.state.newItem.name,"name","newItem").then(()=> {
          this.setState(this.addItemAtEnd())
          this.addingItem = true
        })
      }
      else {
        this.setState(this.addItemAtEnd())
        this.addingItem = true
      }
    }
    
  }

  /**
   * Tests if the item is a item or a section
   */
  quickListItemType =(id)=> {
    if (!id) return null
    if (id.substr(0,"section_".length) === "section_") return "section"
    if (id.substr(0,"item_".length) === "item_") return "item"
    return undefined
  }

  /**
   * Removes a item from a section.
   * @return the updated section
   */
  removeItemFromSection =(itemId,sectionId) => {
    let section = {...this.state[sectionId]}
    let position = section.items[itemId]
    section.items[itemId] = null

    Object.keys(section.items).forEach( itemId => {
      if (section.items[itemId] > position)
      section.items[itemId]--
    })

    return section
  }

  /**
   * Appends a item to a section
   * @return the updated section
   */
  appendItemToSection =(itemId,section) => {
    let position = section.items ? Object.keys(section.items).length : 0
    if (position === 0) section.items = {}
    section.items[itemId] = position
    return section
  }

  /**
   * Marks a section to be deleted and shows the use an undo toast. 
   */
  removeSection =(sectionId)=> {
    if (this.state.showSubheader === sectionId){
      this.hideSubheader()
    }
    let removed = this.getItemsInSection(sectionId,true)

    this.hideItems(removed)
    let action = UndoableActions.addAction(
      this.deleteSection, this.showItems, {sectionId : sectionId, items : removed}
    )

    var promise = ToastService.showToast(
      this.state[sectionId].name + " removed", 
      this.undoRemoveSectionButton(()=>UndoableActions.undo(action))
    )
    promise.then( () => UndoableActions.action(action) )
  }

  /**
   * Deletes a section
   */
  deleteSection = async({sectionId}) => {
    const deleted = this.getItemsInSection(sectionId,true)
    this.deleteItems({ items : deleted})
  }

  undoRemoveItem =(action)=> {
    this.showSection(this.undoableActions[action].item)
  }

  undoRemoveSectionButton = action => (
    <FlatButton accent clicked={action}>Undo</FlatButton>
  )
  undoRemoveItemsButton = action => (
    <FlatButton accent clicked={action}>Undo</FlatButton>
  )

  get hasCheckedItems(){
    return Object.keys(this.state.items).filter( id => {
      return this.state[id] !== undefined && this.state[id] !== null && !this.state[id].isSection && this.state[id].checked
    }).length > 0
  } 

  get hasUncheckedItems(){
    return Object.keys(this.state.items).filter( id => {
      return this.state[id] !== undefined && this.state[id] !== null && !this.state[id].isSection && !this.state[id].checked
    }).length > 0
  }

  /**
   * Marks a section to be deleted and shows the use an undo toast. 
   * 
   * @todo When state changes, the hidden property gets overriden showing
   * the removed items. When the timer runs out, they aren't hidden so they
   * aren't deleted. Move the hidden from the items into its own state property
   * so that firebase doesn't clear it.
   */
  removeItem =(itemId)=> {
    if (this.state.showSubheader === itemId){
      this.hideSubheader()
    }
    this.hideItem(itemId)
    
    let action = UndoableActions.addAction(
      this.deleteItem, this.showItem, {itemId : itemId}
    )

    var promise = ToastService.showToast(
      this.state[itemId].name + " removed", 
      this.undoRemoveSectionButton(()=>UndoableActions.undo(action))
    )
    promise.then( () => UndoableActions.action(action) )
  }

  /**
   * Deletes an item
   * 
   * @todo Use Collection for itemsModel, and deleteItems to remove
   */
  deleteItem = ({itemId})=> {
    let transaction = new Transaction()
    
    let itemsModel = transaction.add(new Model(this.shareableRef.child("items")))
    let items = Reordering.removeItem("newItem",{...this.state.items})
    Reordering.removeItem(itemId,items)
    itemsModel.set(items)

    let itemModel = transaction.add(new Model(this.shareableRef.child(itemId)))
      itemModel.set(null)

    const previewItems = Object.keys(this.state.items)
      .filter( a => ( this.state[a] || this.state[a] === 0 ) &&  a !== itemId )
      .sort( (a,b) => this.state.items[a] - this.state.items[b])

    this.createPreview(previewItems, this.state, transaction)
    transaction.commit()
    if (this.mounted) this.setState({selectedItem : false})
  }

  hideItem =(itemId)=> {
    if (!this.mounted) return
    let item = { ...this.state[itemId] }
    if (!item) return
    
    let hidden = {...this.state.hidden, [itemId] : true}
    this.setState({ hidden : hidden })
  }

  hideItems =(items)=> {
    if (!this.mounted || !items || !items.length) return
    let hidden = {...this.state.hidden}

    items.forEach( itemId => hidden[itemId] = true )
    this.setState({ hidden : hidden})
  }

  /**
   * @todo make sure the toast being closed is for the section being shown
   */
  showItem =(params)=> {
    if (!this.mounted || !params.itemId) return
    let hidden = {...this.state.hidden, [params.itemId] : false}
    this.setState({ hidden : hidden })
    ToastService.hideToast()
  }

  showItems =(params)=> {
    if (!this.mounted || !params) return
    if (!params.items || !params.items.length) return this.showItem(params)
    
    let hidden = {...this.state.hidden}

    params.items.forEach( itemId => hidden[itemId] = false )
    this.setState({hidden : hidden})
    ToastService.hideToast()
  }

  undoRemoveItemButton = itemId => (
    <FlatButton accent clicked={()=>this.undoRemoveItem(itemId)}>Undo</FlatButton>
  )

  defaultSection(){
    return {
      id : null,
      name : "test"
    }
  }

  getItems=()=>{
    return Object.keys(this.state.items)
    .filter( a => this.state[a] !== undefined && this.state[a] !== null)
    .sort( (a,b) => this.state.items[a] - this.state.items[b])
    .map( itemId => {
      let item = this.state[itemId]
      if (this.state.hidden[itemId] === true) return null

      let reorderStatus = false
      if (this.state.reorderingItem !== false) reorderStatus = 
        this.state.reorderingItem === itemId ? "reordering" : "target"
      
      if (this.state.inReorderingSection !== false && 
        this.state.inReorderingSection[itemId])
        reorderStatus = "reordering"

      const focus = this.state.focus === itemId
      return item.isSection ? (
        <QuickListSection
          reorderStatus={reorderStatus}
          key={itemId}
          item={item}
          id={itemId}
          quickListRef={this.shareableRef}
          save={this.saveItemField}
          changed={this.changeItemField}
          showSubheader={this.showSubheader}
          hideSubheader={this.hideSubheader}
          nextItem={this.nextItem}
          focus={focus}
          selectItem={this.selectItem}
          deselectItem={this.deselectItem}
          endReorder={()=>this.endReorderItem( 
            this.state.reorderingItem !== itemId ? itemId : undefined
          )}
        />
      ) : (
        <QuickListItem
          reorderStatus={reorderStatus}
          key={itemId}
          item={item}
          id={itemId}
          quickListRef={this.shareableRef}
          save={this.saveItemField}
          changed={this.changeItemField}
          showSubheader={this.showSubheader}
          hideSubheader={this.hideSubheader}
          nextItem={this.nextItem}
          focus={focus}
          selectItem={this.selectItem}
          deselectItem={this.deselectItem}
          endReorder={()=>this.endReorderItem( 
            this.state.reorderingItem !== itemId ? itemId : undefined
          )}
        />
      )
    }, this)
  }

  changeItemField=(value,field,itemId)=>{
    if (!this.mounted) return

    let item = {...this.state[itemId]}
    item[field] = value
    this.setState({[itemId] : item})
  }

  saveItemField=async(value,field,itemId)=>{
    if (itemId === "newItem") return this.saveNewItemField(value,field,itemId)

    let transaction = new Transaction()
    let quickList = transaction.add(new Model(this.shareableRef,{...this.state}))

    quickList.change(`${itemId}/${field}`,value)
    this.getPreview(value, field, itemId, transaction)
    transaction.commit()
    this.addingItem = false
  }

  saveNewItemField=(value,field,itemId)=>{
    
    if (field === "checked" || value === null || value === "" ){
      this.removeNewItem(itemId)
      this.endAddingItem()
      return Promise.resolve()
    }
    
    let saved = this.saveNewItem(itemId)
    
    saved.then( ()=> {
      setTimeout( ()=> { if (this.state.items && (this.state.items[itemId] === undefined || this.state.items[itemId] === null))
        this.endAddingItem()
      } , 300)
    })
    
    return saved
  }

  removeNewItem=()=>{
    let items = Reordering.removeItem("newItem",{...this.state.items})
    items.newItem = null

    this.setState({
      newItem : null,
      items: items
    })
  }

  endAddingItem(){
    this.addingItem = false
    this.setState({selectedItem : false})
  }

  //-----------------------------------

  get menuContents(){
    var contents = []
    var i = 0
    contents.push(<MenuItem key={i++} clicked={this.addSection}>Add Section</MenuItem>)
    this.hasUncheckedItems && contents.push(<MenuItem key={i++} clicked={this.checkAll}>Check All</MenuItem>)
    this.hasCheckedItems && contents.push(<MenuItem key={i++} clicked={this.uncheckAll}>Uncheck All</MenuItem>)
    contents.push(<Divider key={i++} />)
    this.hasCheckedItems && contents.push(<MenuItem key={i++} clicked={this.removeChecked}>Delete Checked</MenuItem>)
    contents.push(<MenuItem key={i++} clicked={this.removeAll}>Clear All</MenuItem>)
    return contents
  }

  get screenHeader(){
    return (<>
      <BackButton clicked={this.handleBackLink}/>
      <EditableTitle value={this.state.name} changed={(value)=>this.changeShareableName(value)}/>
      <Menu left>
        { this.menuContents }
      </Menu>
    </>)
  }

  get screenSubHeader(){
    let isSection = this.state.showSubheader && this.state[this.state.showSubheader].isSection 

    if (isSection){
      return (
        <div style={{display:"flex", flexDirection: "row", width: "100%"}}>
          <IconButton icon="content_copy" flex clicked={()=>this.duplicateItem(this.state.showSubheader)} />
          <IconButton icon="reorder" flex  clicked={()=>this.reorderSection(this.state.showSubheader)}/>
          <IconButton icon="delete" flex clicked={()=>this.removeSection(this.state.showSubheader)} />
        </div> )
    } else {
      return (
        <div style={{display:"flex", flexDirection: "row", width: "100%"}}>
          <IconButton icon="content_copy" flex clicked={()=>this.duplicateItem(this.state.showSubheader)} />
          <IconButton icon="reorder" flex  clicked={()=>this.reorderItem(this.state.showSubheader)}/>
          <IconButton icon="delete" flex clicked={()=>this.removeItem(this.state.showSubheader)} />
        </div> )
    }
  }

  get screenBodyStyle(){
    return {backgroundColor: "var(--color-gray-100)",
    overflowY: "auto"}
  }

  get screenBody(){
    let bodyStyle = "feature-quick-list-items"
    let paddingStyle="feature-quick-list-items-pad"
    if (this.state.showSubheader) bodyStyle += " show-subheader"
    if (this.state.showSubheader) paddingStyle += " show-subheader"

    return (
      <div className={bodyStyle}>
        {this.getItems()}
        <div className={paddingStyle}/>
      </div>
    )
  }

  get screenFabs(){
    return (
      <FabArea bottom right>
        { this.state.reorderingSection
          ? (<Fab icon="close" clicked={()=>this.endReorderItem()} />)
          : (<Fab icon="add" accent clicked={()=>this.addItemFab()} />)
        }
      </FabArea>)
  }
}

export default QuickList
import React from 'react'

import SelectPicker from '../../../platform/components/pickers/selectPicker/SelectPicker'
import ColorPicker from '../../../platform/components/pickers/colorPicker/ColorPicker'
import UiTestPage, {UiTestSection, UiTestRow} from '../UiTestPage';
import MaterialColors from '../../colors/MaterialColors';

/**
 * Cards page is a demo of the Card component, interactive api documentaton, and a test
 * of the card's behaivor and conformance to the Material Spec.
 */
class PickersPage extends React.Component {
    
  state = {
    colors : MaterialColors.primaryColors,
    items : [
      {name : "Item 1", id: 0}, {name : "Item 2", id: 1}, {name : "Item 3", id: 2},
    ],
    color : 2,
    selected : 1,
  }
  updateColorHandler =(color)=> { this.setState({ color : color }) }
  updateSelectedHandler =(value)=> { this.setState({ selected : value }) }


  render(){
    return (
      <UiTestPage>
        <UiTestSection name="Select">
          <div>Selected: {this.state.selected}</div>
          <UiTestRow name="Normal Picker">
            <SelectPicker 
              value={this.state.selected}
              changed={this.updateSelectedHandler}
              items={this.state.items} />
          </UiTestRow>
          <UiTestRow name="Read Only Picker">
            <SelectPicker 
              readonly
              value={this.state.selected}
              changed={this.updateSelectedHandler}
              items={this.state.items} />
          </UiTestRow>
          <UiTestRow name="Flex Picker">
            <SelectPicker
              flex 
              value={this.state.selected}
              changed={this.updateSelectedHandler}
              items={this.state.items} />
          </UiTestRow>
        </UiTestSection>

        <UiTestSection name="Color">
          <div>Color: {this.state.color}</div>
          <UiTestRow name="Color Picker">
            <ColorPicker 
              value={this.state.color}
              changed={this.updateColorHandler}
              colors={this.state.colors} />
          </UiTestRow>
          <UiTestRow name="Readonly Color Picker">
            <ColorPicker
              readonly={true}
              value={this.state.color}
              changed={this.updateColorHandler}
              colors={this.state.colors} />
          </UiTestRow>
        </UiTestSection>
      </UiTestPage>
    )
  }

}

export default PickersPage
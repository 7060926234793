import '../text/TextInput.css'
import TextInput from '../text/TextInput';

/**
 * An MDI styled text input.
 * 
 * @todo Implement the underline: normal|selected|none prop
 * @param {void} primary If true, the button will be style with the app's primary color
 * 
 * @param {function} changed Called when the user types into the input with the value in 
 * the first param and the event in the second.
 * @param {String} value The value of the input.
 */
class NumberInput extends TextInput {
  validateNumeric =(oldValue, value)=> {
    if (value === ".") return "0."
    if (isNaN(value)) return oldValue
    return value
  }

  validateInteger =(oldValue, value)=> {
    if (value === ".") return "0."
    if (isNaN(value)) return oldValue
    return Number(value).toFixed(0)
  }

  validate =(oldValue, newValue) => {
    if (this.props.integer) return this.validateInteger(oldValue, newValue)
    return this.validateNumeric(oldValue, newValue)
  }

  changed =(e)=> {
    const {changed, value} = this.props
    if (changed) changed( this.validate(value,e.target.value), e, this.props.field )
  }

  // let classes = `text-input ${ props.className }`
  // if (props.fullWidth) classes += " full-width"
  
}

export default NumberInput
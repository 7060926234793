import React from 'react'

import UiTestPage, {UiTestSection} from '../UiTestPage';
import MessageParser from '../../../PlanReach/features/messages/parser/MessageParser';
import Card from '../../components/cards/Card';

/**
 * Message Parser page is a demo of the MessageParser component,
 * interactive api documentaton, and a test of the widget's behaivor.
 */
class MessageParserPage extends React.Component {

  render(){
    return (
      <UiTestPage>
        <Card>
          <pre>{message}</pre>
        </Card>
        <UiTestSection name="Message Parser">
          <MessageParser>{message}</MessageParser>
        </UiTestSection>
      </UiTestPage>)
  }
}

const message = `<div>
  <section>
    <b>Brian Benson</b> would like to share a Board called "<b>Test Board</b>" with you.
  </section>
  <section>
    Use this <link url="/deeplink/landing/board/shareItemLink_-LtwBks-cv1_PbxpNwPV">Link</link> to accept the Board.
  </section>
</div>`

export default MessageParserPage
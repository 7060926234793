class MaterialColors {

  static primaryColors = [
    {id:0, name:"red", color : "#F44336"},
    {id:1, name:"pink", color : "#E91E63"},
    {id:2, name:"purple", color : "#9C27B0"},
    {id:3, name:"deep purple", color : "#673AB7"},
    {id:4, name:"indigo", color : "#3F51B5"},
    {id:5, name:"blue", color : "#2196F3"},
    {id:6, name:"light blue", color : "#03A9F4"},
    {id:7, name:"cyan", color : "#00BCD4"},
    {id:8, name:"teal", color : "#009688"},
    {id:9, name:"green", color : "#4CAF50"},
    {id:10, name:"light green", color : "#8BC34A"},
    {id:11, name:"lime", color : "#CDDC39"},
    {id:12, name:"yellow", color : "#FFEB3B"},
    {id:13, name:"amber", color : "#FFC107"},
    {id:14, name:"orange", color : "#FF9800"},
    {id:15, name:"deep orange", color : "#FF5722"},
    {id:17, name:"brown", color : "#795548"},
    {id:18, name:"gray", color : "#9E9E9E"},
    {id:19, name:"blue gray", color : "#607D8B"},
  ]
}

export default MaterialColors
import React from 'react'
// import sanatizeHtml from 

import Divider from '../../../../../platform/components/dividers/Divider'
import User from '../../../../User/User';

import { ToastService } from '../../../../../platform/components/toast/Toast';
import { Transaction, Collection, Model, newKey, databaseRef }  from '../../../../../TransactaFire/TransactaFire';
import SelectPicker from '../../../../../platform/components/pickers/selectPicker/SelectPicker';


import NewMessage from '../../../messages/views/new/NewMessage';

class AppFeedback extends NewMessage {

  validateFeedback(){
    const { type, subject } = this.state
    let error = ""

    if (!subject) error += "The feedback must have a subject."
    if (type===undefined) error += (error ? "\n" : "") + "You must choose a feedback type."

    return error
  }

  sendMessage = async() => {
    let validation = this.validateFeedback()
    if (validation) return ToastService.showToast(validation)

    let transaction = new Transaction()
    const { type, subject, content } = this.state
    const typeText = this.types[type]

    let feedback = {
      sender : { name : User.userName, uid : User.uid },
      subject : subject,
      type : typeText,
      body : content,
      summary : content,
      created : (new Date()).getTime()
    }

    const key = newKey("message_")

    let feedbackCollection = transaction.add(new Collection( databaseRef(`feedback/messages`), "messages", "message_") )
      await feedbackCollection.addToCollection(feedback,null,key)

    let feedbackUnreadMessages = transaction.add(new Model( databaseRef(`feedback/unreadMessages`) ))
      feedbackUnreadMessages.change(key,1)

    let unreadFeedbackCounter = transaction.add(new Model( databaseRef(`ZrctDMhhY8dAtkzRz74xroWW5qn1/data/unreadFeedback`), null, true))
      const unreadCount = await unreadFeedbackCounter.loaded
      unreadFeedbackCounter.set(Number.isInteger(unreadCount) ? unreadCount+1 : 1)

    transaction.commit()
    this.props.history.goBack()
    ToastService.showToast("Thank you for your feedback!")
  }

  updateType =(value)=> {
    this.setState({type : value})
  }

  get screenBody(){
    return (
    <div className="flex column">
      { this.type }
      <Divider/>
      { this.editor }
      { this.footer }
    </div>
    )
  }

  get type(){
    return (
      <div>
        <SelectPicker
          items={this.types}
          placeholder="Type of feedback..."
          value={this.state.type}
          changed={this.updateType}
        />
      </div>
    )
  }

  types = [
    {name : "Bug", id: 0},
    {name : "Support", id: 1},
    {name : "Enhancement", id: 2},
    {name : "Feature", id: 3},
    {name : "Idea", id: 4},
    {name : "Contact Request", id: 5},
    {name : "Other", id: 6},
  ]
}

export default AppFeedback
import React from 'react'
import FlatButton from '../textButton/FlatButton'

/**
 * @property {String} element - Id of the element to copy when button is clicked
 * 
 * @todo Allow different types of button
 * @todo Support different text with "Copy" as default
 */
class CopyButton extends React.Component {

  /**
   * Copies the content of the passed in element
   */
  copyLink=()=>{
    const element = document.getElementById(this.props.element)
    if (!element) return console.warn(`Element with id "${this.props.element}" not found.`)

    element.select();
    document.execCommand('Copy');
    this.props.clicked && this.props.clicked()
  }

  render(){
    return (
      <FlatButton primary clicked={this.copyLink} {...this.props}>
        Copy Link
      </FlatButton>
    )
  }

}

export default CopyButton
import * as firebase from 'firebase'
import { databaseRef } from '../../TransactaFire/TransactaFire';

class User {
  constructor(app){
    User.app = app
  }

  static dataWatchers = []

  /** @todo */
  static logout(){
    delete User.loginRoute
    User.app.userRef.off()
    User.closeProfile()
    User.app.setState({authUser: false, profile : null })
    firebase.auth().signOut()
  }
  
  /**
   * Logs the user in
   */
  static login =(user)=> {
    if (!user) return User.app.setState({userLoaded:true})

    User.uid = user.uid
    User.app.userRef = databaseRef(`${User.uid}`)

    User.app.userRef.on('value', snapshot => {
      if (typeof snapshot.val() === "object"){
        User.app.setState({"authUser": {...snapshot.val()} })
        User.initProfile(user.uid)
        User.app.setState({userLoaded:true})
      }
    })
  }

  /**
   * Loads the user's profile
   */
  static initProfile =(uid)=> {
    User.app.profileRef = databaseRef(`${uid}/profile`)
    User.app.dataRef = databaseRef(`${uid}/data`)

    User.app.profileRef.on('value', snapshot => {
      User.app.setState({profile: {...snapshot.val()} })
    })
    User.app.dataRef.on('value', snapshot => {
      User.app.setState(
        {data: {...snapshot.val()} },
        User.updateDataWatchers
      )
    })
  }

  static closeProfile =()=> {
    delete User.uid

    User.app.profileRef.off()
    User.app.profileRef = null

    User.app.dataRef.off()
    User.app.dataRef = null
  }

  /**
   * Adds a watcher to the User's firebase Data node
   * @todo Add a remove watcher
   */
  static watchDataChanges=( watcher )=>{
    const index = User.dataWatchers.push(watcher)
    User.dataWatchers[index-1](User.data)
  }
  /**
   * Called when the User's Data node changes. Broadcasts the change to all the watchers.
   */
  static updateDataWatchers(){
    User.dataWatchers.forEach( watcher => watcher(User.data))
  }

  static get profileRef(){
    return User.app.profileRef
  }
  static get profile(){
    return User.app.state.profile
  }
  static get dataRef(){
    return User.app.dataRef
  }
  static get data(){
    return User.app.state.data
  }
  static get userName(){
    return User.profile ? User.profile.name : ""
  }
  static get avatarUrl(){
    return User.profile ? User.profile.avatarUrl : ""
  }
  static unreadMessages=()=>{
    return User.data && User.data.unreadMessages
  }

  /**
   * Gets a summary of the user to store in shareables 
   */
  static get summary(){
    let summary = {
      name : User.userName,
    }
    if (User.avatarUrl !== "" && User.avatarUrl !== undefined) 
      summary.avatarUrl = User.avatarUrl

    return summary
  }

  static get lastRoute(){
    return User.data && `/${User.data.lastRoute}`
  }

  static updateRoute(lastRoute){
    databaseRef(`${User.uid}/lastRoute`).set(lastRoute)
  }
}

export default User
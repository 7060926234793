import React from 'react'

/**
 * If the TimeCircle is selected, then a 0 z-index highlight is drawn behind the 
 * clickable area.
 * @param {*} props 
 */
const HourCircle =(props)=> {
  let classes = props.selected
    ? "time-circle noselect colors-fill-primary"
    : "time-circle noselect"

  let content = null;

  if (!props.subMinute){
    content = (<div className="time-circle-number">{props.number}</div>)
  }
  else if (props.selected){
    content = (<div className="time-circle-dot"/>)
  }
  else {
    content = (<div className="time-circle-dot-hidden"/>)
  }

  return (
    <div
      className={classes} 
      onClick={() => props.clicked(props.number)}
      style={{ 
        left: (props.x -16) +"px",
        top: (props.y -16) +"px",}}>
      {content}
    </div>
  )
}

export default HourCircle
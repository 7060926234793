import React from 'react'

import * as firebase from 'firebase'
import ViewsList from '../../../../base/view/list/collection/ViewsList';
import Avatar from '../../../../../platform/components/avatar/Avatar';

import { FabArea, Header, Title } from '../../../../base/screen/AppScreen';
import Fab from '../../../../../platform/components/buttons/FAB/FAB';
import NavigationButton from '../../../../../platform/components/sideNavigation/SideNavigationButton';

import User from '../../../../User/User';
import { Transaction, Model, Collection, newKey, databaseRef } from '../../../../../TransactaFire/TransactaFire';

import "./Chats.css"
import { elapsedTimeFormat } from '../../../../../GlobalUtils/DateAndTime';
import { ToastService } from '../../../../../platform/components/toast/Toast';
import UndoableActions from '../../../../undoable/UndoableActions';
import UndoToastButton from '../../../../../platform/components/toast/buttons/undo/UndoToastButton';
import { PopupService } from '../../../../../platform/components/popups/PopupArea';
import ChooseContactPopup from '../../../contacts/choose/popup/ChooseContactPopup';
import Ticker from '../../../../../DateAndTime/Ticker';

class Chats extends ViewsList {
  mounted = false
  static hidden = {}

  state = {
    chats : {},
    reorderingItem : false,
    hidden : Chats.hidden
  }

  shareablePrefix = "chat"
  viewNode = "chats"
  itemNode = "chat"
  shareableNode = "chat"
  shareableName = "Chats"
  newName = "New Chat"

  lastRoute = "chats"

  emptyItemsMessageHeader =()=> "No Chat Threads"
  emptyItemsMessageBody =()=> "Use the button below to start one"

  openNewChatPopup=()=>{
    if (!this.mounted) return
    PopupService.showPopup(this.NewChatPopup())
  }

  /**
   * Handles the following query params on page load:
   * * `action : actionName` - action to take on entry
   *   * **addChat** - goes straight to add chat popup
   * 
   * * `removeChat : chatId` - removes the chat with the matching id
   * @override
   */
  handleParams(params){
    let action = params.get('action')
    if (action==="addChat") this.openNewChatPopup()

    let removeChat = params.get('removeChat')
    if (removeChat) this.removeChat(removeChat)
  }

  /**
   * Creates a new item of the type specified by the child extending
   * this class.
   * 
   * The item gets stored in a type-prefixed GUID. The creator gets a
   * view of the item which is stored in their list of items of
   * that type.
   */
  addChat = async(contactId) => {
    const transaction = new Transaction()
    const now = Date.now()

    const contact = {...this.state[contactId] }
    const chatKey = this.chatsKey([User.uid,contact.uid])
    
    const chatModel = transaction.add(Model.newAtPath(`${chatKey}`,))
    const chatData = await chatModel.loaded

    if (chatModel._hasData){
      this.rejoinUserToChat(chatModel, transaction, chatKey, now, contact, chatData)
    }
    else {
      this.newChat(chatModel, transaction, chatKey, now, contact)
    }

    await transaction.commit()
    PopupService.closePopup()

    Chats.hidden[chatKey] = null
    this.setState({hidden : Chats.hidden})
  }

  chatsKey =(users)=> {
    const orderedChats = users.sort()
    return `chat_${orderedChats.reduce( (a,b) => `${a}-${b}`)}`
  }

  getChatName(user){
    return user.name
  } 

  newChat =(chatModel, transaction, chatKey, now, contact)=> {
    const users = { 
      [contact.uid] : contact.name,
      [User.uid] : User.name
    }

    this.addUserToChat( transaction, chatKey, User, contact, users, now)
    this.addUserToChat( transaction, chatKey, contact, User, users, now)
    chatModel.set({
      created : now,
      users : users
    })
  }

  addUserToChat =(transaction, chatKey, user, otherUser, users, now)=> {
    let chatViews = transaction.add(Model.newAtPath(`${user.uid}/chats`))

    chatViews.change(`chats/${chatKey}`,now)
    chatViews.change(`${chatKey}`, {
      lastDate : now,
      name : this.getChatName(otherUser),
      users : users
    })
  }

  rejoinUserToChat =(chat, transaction, chatKey, now, contact, chatData) => {
    let chatViews = transaction.add(Model.newAtPath(`${User.uid}/chats`))
    chat.change(`removedUsers/${User.uid}`, null)

    const messageKey = newKey("message_")
    const message = {
      created : now,
      sender : "system",
      text : `${User.userName} has rejoined the chat`
    }
    chat.change(messageKey, message)
    chat.change(`messages/${messageKey}`, now)

    chatViews.change(`chats/${chatKey}`, now)
    chatViews.change(`${chatKey}`, {
      name : this.getChatName(contact),
      lastText : chatData.lastText !== undefined ? chatData.lastText : null,
      lastDate : now,
      users : chatData.users
    })
  }

  /**
   * Shows an Undo Toast that unhides the chat if pressed and deletes the chat otherwise.
   */
  showRemoveChat(chat,chatId){
    const act = UndoableActions.addAction(
       this.deleteChat, 
       this.showChat, 
       chatId
    )
    const action =()=> UndoableActions.action(act)
    const undo =()=> UndoableActions.undo(act)

    ToastService.showToast(
      `Chat with "${chat.name}" was removed`,
      <UndoToastButton action={undo}/>,
      3500
    ).then( action )
  }

  /**
   * Deletes a chat for the user. If there are no users left, the entire chat is deleted.
   */
  deleteChat = async (chatId) => {
    const transaction = new Transaction()

    const chatCollection = transaction.add(new Collection(this.viewsRef, this.viewNode, "chat_"))
    const chat = transaction.add(new Model.newAtPath(`${chatId}`))

    const { users, removedUsers, messages } = await chat.loaded
    const usersRemaining = Object.keys(users).filter( a => (!removedUsers || !removedUsers[a]) && (a !== User.uid) )

    if (usersRemaining.length){
      const messageKey = newKey("message_")
      let messagesUpdate = messages ? {...messages} : {}
      const count = Object.keys(messagesUpdate).length

      const message = {
        created : Date.now(),
        sender : "system",
        text : `${User.userName} has left the chat`
      }
      messagesUpdate[messageKey] = count

      chat.change(messageKey, message)
      chat.change("messages", messagesUpdate)
      chat.change(`removedUsers/${User.uid}`, 1)
    }
    else {
      chat.remove()
    }

    await chatCollection.deleteItems([chatId])
    transaction.commit()
  }

  removeChat = async(chatId) => {
    this.props.history.replace("/chats")
    
    await this.viewsRef.$loaded
    
    const chat = this.state[chatId]
    if (!chat) return null

    this.hideChat(chatId)
    this.showRemoveChat(chat,chatId)
  }

  /**
   * Hides a chat from view
   */
  hideChat =(chatId)=> {
    Chats.hidden = {...Chats.hidden, [chatId] : true}
    this.setState({ hidden : {...this.state.hidden, [chatId] : true} })
  }

  /**
   * Unhides a chat from view
   */
  showChat =(chatId)=> {
    Chats.hidden = {...Chats.hidden, [chatId] : false}
    this.setState({ hidden : {...this.state.hidden, [chatId] : false } })
    ToastService.hideToast()
  }
  
  chatsRef = databaseRef(`${User.uid}/chats`)
  contactsRef = databaseRef(`${User.uid}/contacts`)

  /**
   * Marks the component as mounted.
   */
  componentDidMount(){
    super.componentDidMount()
    this.attachRef(this.contactsRef,"contacts")
  }

  /**
   * Takes the user directly to the new contacts popup
   */
  addContact=()=> {
    this.props.history.push('chats?action=addChat')
    
    // If you push contacts?action=addContact, two history events are added
    this.props.history.push('contacts')
    this.props.history.replace('contacts?action=addContact')
  }

  /**
   * Popup where a user can choose a user to create a chat on
   * 
   * @todo Design group chat
   */
  NewChatPopup =()=> {
    if (!this.state.contacts || Object.keys(this.state.contacts).length <1)
      return null

    const AddContact = (
      <Fab icon="person_add" primary clicked={this.addContact} />
    )

    return <ChooseContactPopup
      contacts={this.getContactChoices()}
      fab={AddContact}
    />
  }

  /**
   * @todo Abstract out to Contacts
   */
  getContactChoices =()=> {
    const usedContacts = this.contactsWithChats()
    if (!this.state.contacts) return null

    return Object.keys(this.state.contacts)
      .filter( id => !this.state[id].recordOnly && usedContacts[id] !== true )
      .map( id => ContactChoice({
        ...this.state[id], id: id, addChat : this.addChat
      })
    )
  }

  contactsWithChats =()=> {
    let usedContacts = {}

    if (this.state.chats) {
      Object.keys(this.state.chats).forEach( chatId => 
        Object.keys(this.state[chatId].users)
          .forEach( uid => usedContacts[`contact_${uid}`] = true )
      )
    }

    return usedContacts
  }

  getItem(itemId){
    const chat = {...this.state[itemId]}
    return <Chat { ...chat} avatarUrl={this.getAvatarForChat(chat)} id={itemId} sref={this.handleLink} key={itemId}/>
  }

  getAvatarForChat({users}){
    if (!users) return null
    const uid = `contact_${Object.keys(users).find( a => a !== User.uid)}`
    const user = this.state[uid]

    return user && user.avatarUrl
  }

  /**
   * Chats are sorted oldest to newwest 
   */
  sortItems =(a,b) => (this.state[this.viewNode][b] - this.state[this.viewNode][a])

  get header() {
    return (
      <Header primary>
        <NavigationButton/>
        <Title value="Chats"/>
      </Header>
    )
  }

  get fabArea(){
    return (<FabArea bottom right>
      <Fab icon="add" accent clicked={this.openNewChatPopup} />
    </FabArea>)
  }
}

class Chat extends React.Component {
  state={
    date: ""
  }

  componentDidMount(){
    this.ticker = Ticker.registerTicker(this.tick)
    this.tick()
  }
  componentWillUnmount(){
    Ticker.unregisterTicker(this.ticker)
  }
  tick =()=> {
    this.setState({date:elapsedTimeFormat(this.props.lastDate)})
  }

  render(){
    const {id, sref, name, lastText, avatarUrl} = this.props
    const clicked =()=> sref(id)

    return (
      <div className="chats-list__chat" onClick={clicked} key={id}>
        <div className="chats-list__chat__avatar">
          <Avatar src={avatarUrl}/>
        </div>
        <div className="chats-list__chat__content">
          <div>{name}</div>
          { lastText
            ? <div className="typography-body alpha-87">{lastText}</div>
            : <div className="typography-body-light"><i>No messages yet...</i></div>
          }
        </div>
        <div className="chats-list__chat__when typography-body">
          {this.state.date}
        </div>
      </div>
    )
  }
}

const ContactChoice =(props)=> {

  const addChat =()=> props.addChat(props.id)

  return (<div 
    className="contact-summary"
    key={props.id}
    onClick={addChat}>
    <div className="contact-summary__avatar">
      <Avatar src={props.avatarUrl}/>
    </div>
    <div className="contact-summary__content">
      <div>{props.name}</div>
    </div>
  </div>)
}

export default Chats
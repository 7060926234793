import React from 'react'

import DetailScreen from '../../../../base/view/detail/DetailScreen';
import IconButton from '../../../../../platform/components/buttons/iconButton/IconButton';

import { Model, Transaction } from '../../../../../TransactaFire/TransactaFire';
import RichTextEditor, { RichTextEditorService } from '../../../../../platform/texteditor/RichTextEditor';

import './NoteDetail.css'

class NoteDetail extends DetailScreen {

  /**
   * Text stored in "content"
   */
  state = {
    canUndo : false,
    canRedo : false,
    showSubheader : true
  }

  shareablePrefix = "note"
  viewNode = "notes"
  shareableNode = "note"
  shareableName = "Notes"

  /**
   * Editor specific functionality
   */
  editorService = new RichTextEditorService({
    parent : this,
    watch : {
      undoRedo : (hasUndo,hasRedo)=> {
        this.saveText()
        this.setState({ canUndo: hasUndo, canRedo: hasRedo })
      }
    }
  })

  componentWillUnmount =()=> {
    this.mounted = false
    this.saveText()
  }

  loaded =(state)=> {
    console.log(this.state.content)
    let text = this.state.content
    if (text === undefined || text === null){
      text = ""
    }

    this.editorService.load(this.state.content)
    this.$oldName = state.name;
    this.$oldText = state.text;
  }

  /**
   * Creates the View Preview and fans it to each to each user
   * @param {Array} items 
   */
  createPreview=(content,transaction)=>{
    const preview = {content : contentToSummary(content)}

    Object.keys(this.state.readers).forEach( reader => {
      let view = transaction.add( Model.newAtPath(`${reader}/${this.viewNode}/${this.shareableId}`) )
      view.change("preview",preview)
    })
  }

  /**
   * Editor callback that saves the note's text.
   */
  saveText=()=> {
    const text = this.state.content

    if (/*text === null || text === undefined ||*/
      JSON.stringify(text) === JSON.stringify(this.$oldContent)) return;

    this.$oldContent = text;

    const transaction = new Transaction()
    const note = transaction.add(new Model(this.shareableRef))
    note.change("content", text || null)

    this.createPreview(text, transaction)
    transaction.commit()
  }

  /**
   * Editor callback to update the state's content.
   */
  updateStateContent =(event)=> {
    if (event && event.target){
      this.setState({ content : event.target.innerHTML})
    }
  }

  /** 
   * Save the current state.
   * @todo Show a visual indicator that a change was made and by whom
   */
  get screenSubHeader(){
    return (<>
      <IconButton icon="format_indent_increase" clicked={()=>this.editorService.addIndent()}/>
      <IconButton icon="format_bold" title="Bold" clicked={()=>this.editorService.bold()}/>
      <IconButton icon="format_italic" title="Italic" clicked={()=>this.editorService.italic()}/>
      <IconButton icon="format_underline" title="Underline" clicked={()=>this.editorService.underline()}/>
      <div style={{flex:"1"}}/>
      <IconButton icon="undo" 
        clicked={()=>this.editorService.undo()}
        disabled={!this.state.canUndo}/>
      <IconButton icon="redo" 
        clicked={()=>this.editorService.redo()}
        disabled={!this.state.canRedo}/>

      <IconButton icon="zoom_in" clicked={()=>this.editorService.zoomIn()}/>
      <IconButton icon="zoom_out" clicked={()=>this.editorService.zoomOut()}/>
    </>)
  }

  get screenBody(){
    return (<>
      <div style={{height: "48px"}}/>
      <RichTextEditor
        service={this.editorService}
        onInput={this.updateStateContent}/>
    </>)
  }

  changeNoteName =(value)=> {
    this.setState({name : value})
  }
}

export default NoteDetail

function contentToSummary(content){
  return content.replace(/<style([\s\S]*?)<\/style>/gi,'')
    .replace(/<script([\s\S]*?)<\/script>/gi, '')
    .replace(/<\/div>/ig, '\n ')
    .replace(/<\/li>/ig, '\n ')
    .replace(/<li>/ig, ' * ')
    .replace(/<\/ul>/ig, '\n ')
    .replace(/<\/p>/ig, '\n ')
    .replace(/<br\s*[\/]?>/gi, "\n ")
    .replace(/<[^>]+>/ig, '')

    // Reversing escaped characters
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
}
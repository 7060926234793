import { arrayLast } from "../../../GlobalUtils/BrianUtils";
import PopupArea from "./PopupArea";

/**
 * Service for opening popups.
 * 
 * Popups are on a stack, only one shown at a time in a last in first out manner. If one popup opens
 * after another, then the most recent popup will be shown and after it is closed, the previous popup is reshown.
 */
export default class PopupService {
  
  static popups = []

  /**
   * Shows a popup in the popup area.
   * @param {*} popup Popup to show.
   * @param {*} durration How long to keep the popup. If false or negative, the popup will not automatically close. Defaults to not closing.
   * 
   * @returns Promise that resolves when popup is closed. That promise has an **index**
   * corresponding to the popup it goes to.
   */
  static showPopup(popup, durration = false){

    PopupService.popups.push(popup)

    let promise = new Promise( (res,rej) => {
      if (!PopupService.popupArea || !PopupService.popupArea.mounted) return rej(); // Should throw error, or at least warn
      PopupService.popupArea.setState({ shown : true, popup : popup})
      PopupService.popupCountdown(durration, res)
    })

    return promise
  }

  static popupCountdown( durration, res ){
    if (durration === false || durration < 0 ) return;

    setTimeout(() => {
      res()
      PopupService.closePopup()
    }, durration);
  }

  /**
   * Closes the open popup. If another popup is bellow it, that popup will be shown.
   * @todo support closing specific popups by some kind of id
   */
  static closePopup(){
    if (!PopupService.popupArea || !PopupService.popupArea.mounted) return
    if (!PopupService.popups.length) return

    PopupService.popups.pop()

    if (!PopupService.popups.length){
      PopupService.popupArea.setState({ shown : false, popup : null })
    } else {
      PopupService.popupArea.setState({ popup : arrayLast(PopupService.popups) })
    }
  }

  /**
   * Handles binding the current screen's PopupArea to the PopupService
   * @param {PopupArea} popupArea React PopupArea component
   */
  static bindPopupArea(popupArea){
    PopupService.popupArea = popupArea
  }

  /**
   * Removes binding of the current screen's PopupArea from the PopupService
   * @param {PopupArea} popupArea React PopupArea component
   */
  static unbindPopupArea(popupArea){
    if (PopupService.popupArea === popupArea)
      PopupService.popupArea = null
  }
}
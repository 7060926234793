import React from 'react'
import ToastService from './ToastService';

import './Toast.css'

class Toast extends React.Component {
  mounted = false
  state = {
    message : "",
    shown : false,
  }

  componentDidMount(){
    this.mounted = true
    ToastService.bindToast(this)
  }
  componentWillUnmount(){
    this.mounted = false
  }

  setActions =(actions, res)=> {
    this.actions = actions
    this.res = res
  }

  render(){
    let classes = "toast colors-dark-background"
    let items = (this.props.items) ? {...this.props.children} : null

    if (this.actions) items = { ...items, ...this.actions }
    if (this.state.shown !== false) classes += " shown"
    
    return(
      <div className={classes} tabIndex="-1">
        <div style={{flex: "1"}}>
          {this.state.message}
        </div>
        <div className="toast__actions">
          {this.state.shown !== false ? items : null}
        </div>
      </div>
    )
  }
}
export default Toast
export { ToastService }
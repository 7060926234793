import React from 'react'

import './ColorPicker.css'

import ColorDot from './colorDot/ColorDot'
import TriangleDown from '../../triangleDown/TriangleDown'
import SelectPicker from '../selectPicker/SelectPicker';
import { MenuItem } from '../../menu/Menu';

/**
 * @param {Collection<Color>} colors 
 * @param {String} placeholder
 * @param {Object} model
 * @param {function} changed
 * 
 * @todo handle when no color passed in
 */
class ColorPicker extends SelectPicker {

  static menuItemHeight = 48
  static menuTopAndBottomPadding = 16

  get choices(){
    return this.props.colors
  }

  createMenuItem = (item) => {
    return (
      <MenuItem 
        key={item.id}
        selected={this.props.value === item.id}
        hideMenu={this.hideMenuHandler}
        clicked={()=>this.selectChangeHandler(item.id)}>
          <ColorDot color={item}/>
          <span className="text">{item.name}</span>
      </MenuItem>)
  }

  getMenu(){
    let width = this.menuWidth

    if (!this.props.colors || this.props.colors[this.props.value] === undefined){
      return (
        <div className="color-picker-menu-item" style={{minWidth: `${width}px`}}>
          <span className="placeholder-text">{this.props.placeholder ? 
            this.props.placeholder : "Choose a color"}</span>
          { this.props.readonly ? null : <TriangleDown/> }
        </div>
      )
    } else {
      let color = this.props.colors[this.props.value];
      return (
        <div key={color.id} className="color-picker-menu-item" style={{minWidth: `${width}px`}}>
          <ColorDot color={color}/>
          <span className="text">{color.name}</span>
          { this.props.readonly ? null : <TriangleDown/> }
        </div>
      )
    }
  }

}

export default ColorPicker;
import React, { Component } from 'react';

import './App.css';
import Radium from 'radium'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import UiTestPages from './platform/ui-tests/UiTestPages'
import NoteDetail from './PlanReach/features/notes/views/detail/NoteDetail';

import * as firebase from 'firebase'
import User from './PlanReach/User/User'

import Board from './PlanReach/features/boards/detail/Board';
import Boards from './PlanReach/features/boards/list/Boards';
import Notes from './PlanReach/features/notes/list/Notes';
import Chats from './PlanReach/features/chats/views/list/Chats';
import ChatDetail from './PlanReach/features/chats/views/detail/ChatDetail';
import Contacts from './PlanReach/features/contacts/views/list/Contacts';
import Contact from './PlanReach/features/contacts/views/detail/Contact';
import Messages from './PlanReach/features/messages/views/list/Messages'
import NewMessage from './PlanReach/features/messages/views/new/NewMessage';
import Message from './PlanReach/features/messages/views/detail/Message';
import Profile from './PlanReach/features/profile/views/profile/Profile';

import ShareItemPage  from './PlanReach/sharing/landing/ShareItemPage';
import { Screen, Header } from './PlanReach/base/screen/AppScreen';
import Toast from './platform/components/toast/Toast';
import AppNavigation from './PlanReach/features/navigation/AppNavigation';
import QuickLists from './PlanReach/features/quicklists/views/list/QuickLists';
import QuickList from './PlanReach/features/quicklists/views/detail/QuicklList';
import AppFeedback from './PlanReach/features/feedback/views/create/AppFeedback';
import FeedbackMessages from './PlanReach/features/feedback/views/list/FeedbackMessages';
import FeedbackMessage from './PlanReach/features/feedback/views/detail/FeedbackMessage';
import SignInScreen from './PlanReach/auth/SignInScreen';
import ContactLandingPage from './PlanReach/sharing/landing/ContactLandingPage';

// Initialize Firebase
var config = {
  apiKey: "AIzaSyCCTzR0eHkxmkUwitZW6jbvff-5tt02B-4",
  authDomain: "chorequestapp.firebaseapp.com",
  databaseURL: "https://chorequestapp.firebaseio.com",
  storageBucket: "chorequestapp.appspot.com",
  messagingSenderId: "995537752909"
};

const firebaseApp = firebase.initializeApp(config);

class App extends Component {
  
  login =user=> {
    if (!this.User) this.User = new User(this)
    User.login(user)
  }
  
  state = {
    isSignedIn : undefined,
    userLoaded : false,
  }
  
  /**
   * @inheritDoc
   */
  componentDidMount() {
    User.app = this

    this.unregisterAuthObserver = firebaseApp.auth().onAuthStateChanged((user) => {
      this.setState({isSignedIn: !!user});
      this.login(user)
    });
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    return (
      <div className="App">
      { this.state.userLoaded !== true
        ? <div>Loading...</div> 
        : this.state.isSignedIn === false
        ? <SignInScreen/>
      : <BrowserRouter>
          <div className="AppContent">
            <Screen>
              <Switch>
                <Route path="/signedIn" component={SignedIn}/>
                {/* UI Tests */}
                <Route path="/uiTest" component={UiTestPages}/>
                {/* PlanReach Features */}
                <Route path="/boards/:folderId?" component={Boards} />
                <Route path="/board/:board" component={Board} />
                <Route path="/notes/:folderId?" component={Notes} />
                <Route path="/note/:note" component={NoteDetail} />
                <Route path="/quickLists/:folderId?" exact component={QuickLists} />
                <Route path="/quickList/:quickList" exact component={QuickList} />
                <Route path="/chats/:folderId?" component={Chats} />
                <Route path="/chat/:chat" component={ChatDetail} />
                <Route path="/contacts" exact component={Contacts} />
                <Route path="/contact/:contactId" exact component={Contact} />
                <Route path="/messages/:mode?" component={Messages} />
                <Route path="/newMessage" exact component={NewMessage} />
                <Route path="/message/:message" exact component={Message} />
                <Route path="/feedback" exact component={AppFeedback} />
                <Route path="/feedbackMessages" component={FeedbackMessages} />
                <Route path="/feedback/:message" exact component={FeedbackMessage} />
                <Route path="/profile" exact component={Profile} />
                <Route path="/deeplink/contactShare/:key" exact component={ContactLandingPage} />
                <Route path="/deeplink/landing/:type/:key" exact component={ShareItemPage} />
                {/* Default Route */}
                <Route component={SignedIn}/> 
              </Switch>
              {/* Screen Elements */}
              <Toast/>
              <AppNavigation/>
            </Screen>
          </div>
        </BrowserRouter>
      }
      </div>
    );
  }
}

class SignedIn extends React.Component {
  state = {
    loading : "loading..."
  }
  componentDidMount(){
    this.handleRoute()
  }

  handleRoute(){
    const history = this.props.history
    const lastRoute = User.data.lastRoute

    switch (lastRoute){
      case "boards" : return history.replace("/boards")
      case "chats" : return history.replace("/chats")
      case "contacts" : return history.replace("/contacts")
      case "messages" : return history.replace("/messages")
      case "notes" : return history.replace("/notes")
      case "quickLists" : return history.replace("/quickLists")
      default : return history.replace("/home")
    }
  }

  render(){return (
    <Screen>
      <Header primary/>
      <div>{this.state.loading}</div>
    </Screen>
  )}
}

export default Radium(App);
import React from 'react'

import {Screen, Header, SubHeader, Body, EditableTitle} from '../../screen/AppScreen'
import { Redirect } from 'react-router';

import Routing from '../../../routing/Routing'
import { BackButton } from '../../screen/AppScreen';
import Snapshot from '../../../reordering/Snapshot';
import { Transaction, Model, databaseRef } from '../../../../TransactaFire/TransactaFire';
import User from '../../../User/User';

class DetailScreen extends React.Component {
  mounted = false

  state = {
    name : "",
    showSubheader : false,
    paletteColor : 0
  }

  componentWillUnmount =()=> {
    this.mounted = false
    this.hasLoaded = false
  }

  shareableRef = null
  shareableViewRef = null

  componentDidMount(){
    
    this.mounted = true

    const params = Routing.getRouterParam(this)
      this.shareableId = params[this.shareableNode]
    const queryParams = new URLSearchParams(this.props.location.search.slice(1))
        if (this.handleParams) this.handleParams(queryParams)

    if (this.shareableId) this.shareableRef = databaseRef(this.shareableId)
    if (this.shareableId) this.shareableViewRef = databaseRef(`${User.uid}/${this.viewNode}/${this.shareableId}`)

    if (this.shareableRef) this.shareableRef.on('value', snapshot => {
      
      if (this.mounted && typeof snapshot.val() === "object"){
        console.log("componentDidMount")
        let previousState = {...this.state}
        let snapshotState = {...snapshot.val()}

        Snapshot.purgeRemovedItems(previousState,snapshotState,this.schema)
        this.snapshotChanged(previousState,snapshotState)
        this.setState({...snapshotState})
        
        if (!this.hasLoaded){
          this.hasLoaded = true
          this.loaded({...snapshotState})
        } 
      }
    })
  }

  /**
   * Overwrite this method if you need to handle when firebase has snapshot changes
   */
  snapshotChanged(previousState,snapshotState){ }

  componentWillUnmount(){
    this.shareableRef.off()
  }

  handleBackLink =()=> {
    if (!this.mounted) return
    const { history } = this.props;

    if (history.from === this.viewNode){
      history.goBack()
    } else {
      history.replace(`/${this.viewNode}`)
    }
  }

  createPreview =(shareable)=> {
    return null
  }
 
  /**
   * Updates the name of the shareable and all of its views.
   */
  changeShareableName =(value)=> {
    let transaction = new Transaction()
    let readers = this.state.readers

    Object.keys(readers).forEach( reader => {
      let view = transaction.add( Model.newAtPath(`${reader}/${this.viewNode}/${this.shareableId}`) )
        view.change("name",value)
    })

    let shareable = transaction.add( Model.newAtPath(this.shareableId) )
      shareable.change("name", value)

    return transaction.commit()
  }

  emptyItemsMessageHeader =()=> "No Items"
  emptyItemsMessageBody =()=> "Add one with the button below"

  get emptyItemsMessage(){
    return (
      <div className="empty-item-views-message">
        <div className="empty-item-views-message__header">{this.emptyItemsMessageHeader()}</div>
        <div className="empty-item-views-message__body">{this.emptyItemsMessageBody()}</div>
      </div>
    )
  }

  get screenHeader(){
    return (<>
      <BackButton clicked={this.handleBackLink}/>
      <EditableTitle value={this.state.name} changed={(value)=>this.changeShareableName(value)}/>
    </>)
  }

  /**
   * Override this with a <FabArea> cotaining all of the fabs
   * for the view.
   */
  get screenFabs(){
    return null
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }
    return (
      <Screen>
        <Header primary>{this.screenHeader}</Header>
        <SubHeader show={this.state.showSubheader}>
          {this.screenSubHeader}
        </SubHeader>
        <Body style={this.screenBodyStyle}>{this.screenBody}</Body>
        {this.screenFabs}
        {this.screenFooter}
      </Screen>
    )
  }
}

export default DetailScreen
/**
 * Iterates over the CSS classes in the Component's className prop and adds them all
 * to the classes array.
 * @param {*} props 
 * @param {*} classes 
 */
export const ClassNameDelegate=(props,classes)=>{
  if (!props.className) return []
  props.className.split(" ").forEach( className => classes.push(className.trim()))
}

/**
 * Takes an array of CSS classes and reduces it to a CSS class string
 */
export const ClassList=(classes)=> {
  if (!classes || !classes.length) return ""
  return classes.reduce( (a,b) => a + " " + b.trim() )
}
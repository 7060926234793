import React from 'react'

import ClickOutsideService from '../../services/clicked-outside/ClickOutsideService';
import ScrollingService from '../../../PlanReach/scrolling/ScrollingService';
class MenuBase extends React.Component {

  mounted = false
  state = {
    showMenu : false
  }

  get items() {
    if (!this.props.children) return []
    if (!this.props.children.length) return [this.props.children]
    return this.props.children
  }

  get menuHeight() {
    let contentsRect = this.refs.menuContentSize.getBoundingClientRect();
    let largest = 48
    if (contentsRect.height) largest = contentsRect.height

    return largest
  }

  get menuWidth() {
    let largest = 96
    if (!this.refs || !this.refs.menuContentSize) return largest

    let contentsRect = this.refs.menuContentSize.getBoundingClientRect();
    if (contentsRect.width) largest = contentsRect.width
    return largest
  }

  /** Override this to adjust the menu's overall style */
  get menuStyleTweaks() {
    return null
  }

  /** Override this to add classes to the menu */
  get additionalMenuClasses() {
    return ""
  }

  componentDidMount =()=> {
    this.refs.menuContents.clickOutsideId = ClickOutsideService.register(this.refs.menuContents,
      e=>this.clickedOutside(e))

    this.refs.menu.scrollingId = ScrollingService.register(this.refs.menu,
      e=>this.scrollDetected(e))

    this.mounted = true
  }

  componentWillUnmount =()=> {
    this.mounted = false
    ClickOutsideService.unregister(this.refs.menuContents.clickOutsideId)
    ScrollingService.unregister(this.refs.menu.scrollingId)
  }

  clickedOutside =(e)=> {
    this.hideMenuHandler()
  }

  scrollDetected =()=> {
    this.hideMenuHandler()
  }

  hideMenuHandler =()=> {
    if (!this.mounted) return
    this.mounted && this.setState({ showMenu : false })
    ClickOutsideService.setInactive(this.refs.menuContents.clickOutsideId)
    ScrollingService.stopWatch(this.refs.menu.scrollingId)
  }

  showMenuHandler =()=>{
    if (!this.mounted) return
    let rect = this.refs.menu.getBoundingClientRect();

    let hieghtAndDirection = this.getMenuHeightAndDirection(rect)
    let verticalScroll = hieghtAndDirection.height < this.menuHeight
    let widthAndDirection = this.getMenuHorizontalDirection(rect,verticalScroll)

    this.setState({
      showMenu : true,
      minWidth : this.refs.menu.clientWidth,
      position_x : rect.left,
      position_y : rect.top,
      ...hieghtAndDirection,
      ...widthAndDirection
    })
    setTimeout(() => {
      ClickOutsideService.setActive(this.refs.menuContents.clickOutsideId)
      ScrollingService.watch(this.refs.menu.scrollingId)
    }, 50);
  }

  /**
   * Determines if the menu should open above or bellow, makes sure the menu doesn't go out of bounds,
   * and offsets it based on the container's location.
   * 
   * @todo Don't hard code menuHeight
   * @todo Don't hard code headerHeight
   * @todo Test this with vertical screen scrolling 
   * @todo Allow up or down direction prefrence rather than alwaysprefering down.
   */
  getMenuHeightAndDirection =(rect)=> {
    const headerHieght = 56
    const scrollHeight = headerHieght

    const viewHeight = document.documentElement.clientHeight - scrollHeight
    //document.documentElement.scrollHeight - document.documentElement.clientHeight
    const viewTop = scrollHeight 
    const viewBottom = viewTop + viewHeight

    let bellow = viewBottom - scrollHeight - this.menuHeight - rect.bottom
    let above = rect.top - this.menuHeight - viewTop
    
    if (bellow - 16 > 0){
      return {
         direction : "bellow",
         offset : rect.bottom,
         height : Math.min(this.menuHeight, viewBottom - rect.bottom - 16)
      }
    } else if (bellow > above) {
      return {
        direction : "bellow",
        offset : rect.bottom,
        height : viewBottom - rect.bottom - 16 
      }
    } else {
      return {
        offset : viewBottom - rect.top,
        direction : "above",
        height : rect.top - viewTop - 16
      }
    }
  }

  /**
   * Determines if the menu should open left or right of menu, makes sure the menu doesn't
   * go out of bounds, and offsets it based on the container's location.
   */
  getMenuHorizontalDirection =(rect,verticalScroll)=> {
    const viewWidth = document.documentElement.clientWidth

    let menuWidth = this.menuWidth
    if(verticalScroll) menuWidth+= 17

    let left = rect.left - menuWidth
    let right = viewWidth - rect.right - menuWidth
    
    if (!this.props.left && right -16 > 0) return { 
      menuWidth : menuWidth,
      horizontalDirection : null }
    if (this.props.left && left -16 > 0) return {
      menuWidth : menuWidth,
      horizontalDirection : "left",
      horizontalOffset : rect.right - menuWidth,
      width : menuWidth
    }
    if (left > right) {
      let width = Math.min(left - 16, menuWidth)
      return {
        menuWidth : menuWidth,
        horizontalDirection : "left",
        horizontalOffset : rect.right - width,
        width : width
      }
    }
    else return {
      menuWidth : menuWidth,
      horizontalDirection : "right",
      horizontalOffset : rect.left,
      width : Math.min(right - 16, menuWidth)
    }
  }

  getMenu(){
    return this.menuSelection(this.props.value);
  }

  getMenuContents(){
    return React.Children.map(this.props.children, child =>
      React.cloneElement(child, { hideMenu: this.hideMenuHandler })
    );
  }

  get menuContainerClasses(){
    return "menu "
  }

  render () {
    let menuClasses = this.menuContainerClasses + this.additionalMenuClasses
    
    if (this.props.left) menuClasses += " menu-left"
    if (this.state.showMenu) menuClasses += " show-menu"
    let menuContentClasses = this.state.showMenu ? "whiteframe-3dp" : "hidden"
    let menuStyle = this.state.showMenu ? {zIndex: "100"} : {}

    if (this.menuStyleTweaks) this.menuStyleTweaks.forEach( tweak => {
      menuStyle[tweak.property] = tweak.value
    });

    let contentStyle = {
      position : this.state.showMenu ? "fixed" : "static",
      minWidth : this.state.minWidth,
      overflow : "auto",
      zIndex : 1
    }
    if (this.state.showMenu){
      if (this.state.direction === "above"){
        contentStyle.maxHeight = this.state.height + "px"
        contentStyle.bottom = (this.state.offset) + "px"
      }
      if (this.state.direction === "bellow"){
        contentStyle.maxHeight = this.state.height + "px"
        contentStyle.top = (this.state.offset) + "px"
      }
      if (this.state.horizontalDirection){
        contentStyle.minWidth = this.state.width + "px"
        if (this.state.horizontalDirection === "left")
          contentStyle.left = (this.state.horizontalOffset) + "px"
        if (this.state.horizontalDirection === "right")
          contentStyle.left = (this.state.horizontalOffset) + "px"  
        contentStyle.left = (this.state.horizontalOffset) + "px"  
          contentStyle.left = (this.state.horizontalOffset) + "px"  
      }
    }

    return (
      <div className={menuClasses} ref="menu" style={menuStyle}>
        <div
          onClick={ !this.props.readonly ? this.showMenuHandler : undefined }>
          {this.getMenu()}
        </div>
        <div style={contentStyle} className={menuContentClasses}>
          <div className="menu-contents">
            <div ref="menuContents">
              {this.getMenuContents()}
            </div>
          </div>
        </div>
        <div ref="menuContentSize" className="menu-sizing">
          {this.getMenuContents()}
        </div>
      </div>
    )
  }

}

export default MenuBase;
import React from 'react'
import { Header, Body, FabArea, Title } from '../../../screen/AppScreen';
import Fab from '../../../../../platform/components/buttons/FAB/FAB';

import { Redirect } from 'react-router';
import Reordering from '../../../../reordering/Reordering';

import * as firebase from 'firebase'
import { ToastService } from '../../../../../platform/components/toast/Toast';
import FlatButton from '../../../../../platform/components/buttons/textButton/FlatButton';
import NavigationButton from '../../../../../platform/components/sideNavigation/SideNavigationButton';

import User from '../../../../User/User';

import './ViewList.css'
import PopupArea from '../../../../../platform/components/popups/PopupArea';
import { databaseRef } from '../../../../../TransactaFire/TransactaFire';

/**
 * @todo get rid of the manual data fanouts
 */
class ViewsList extends React.Component {
  mounted = false
  state = {
    views : [],
    reorderingItem : false,
    showMoveToFolderPopup : false,
  }

  // itemsRef = databaseRef()

  get viewsRef(){
    if (!this._viewsRef) 
      this._viewsRef = databaseRef(`${User.uid}/${this.viewNode}`)

    // if (!this._viewsRef) this._viewsRef = databaseRef(this.viewNode)
    return this._viewsRef
  }

  /**
   * folderId?
   */
  getReactRouterParam(param = "folderId"){
    return this.props.match.params[param]
  }

  /**
   * Marks the component as unmounted to prevent memory leak from 
   * any async operations.
   */
  componentWillUnmount =()=> {
    this.mounted = false
  }

  // /**
  //  * Pass component updates to method to check for folder state
  //  * changes.
  //  */
  // componentDidUpdate(prevProps,prevState) {
  //   this.updateFolderOnStateChange(prevProps,prevState)
  // }

  /**
   * Marks the component as mounted.
   */
  componentDidMount(){
    this.mounted = true
    const queryParams = new URLSearchParams(this.props.location.search.slice(1))
      if (this.handleParams) this.handleParams(queryParams)
    this.attachViewRef()
    this.updateLastRoute()
  }

  /**
   * Handles pointing the user at the last route
   */
  updateLastRoute(){
    if (this.lastRoute) User.updateRoute(this.lastRoute)
  }

  /**
   * If you want to use query params, override this method
   */
  handleParams(){
    return null
  }

  /**
   * Attaches the Firebase ref to the component with an on value
   * watcher. Whenever the ref changes the state will be updated.
   *
   * @param {*} ref Firebase ref pointing to the data.
   * @param {*} node Name of the node for the ref - e.g ref("user/bars")
   * would have a node of bars.
   */
  attachRef(ref,node){
    ref.$loaded = new Promise( (resolve,reject) => {
      ref.on('value', snapshot => {
        if (this.mounted && typeof snapshot.val() === "object"){

          let snapshotState = {...snapshot.val()}
          if (snapshotState[node]){
            this.setState({...snapshotState})
          } else {
            this.setState({[node] : {}})
          }
          resolve(snapshotState)
        }
        reject()
      })
    })
  }

  /**
   * Attaches the Firebase ref to the component with an on value
   * watcher. Whenever the ref changes the state will be updated.
   */
  attachViewRef(){
    this.attachRef(this.viewsRef,this.viewNode)
  }

  /**
   * Creates a new item of the type specified by the child extending
   * this class.
   * 
   * The item gets stored in a type-prefixed GUID. The creator gets a
   * view of the item which is stored in their list of items of
   * that type.
   */
  newItem =()=> {
    const viewsKey = this.itemPrefix + "_" + this.itemsRef.push().key
    let count = this.state[this.viewNode] ? Object.keys(this.state[this.viewNode]).length : 0

    let update = {
      [this.viewsRef.fullkey()+"/"+viewsKey] : 
      { link : viewsKey, name : this.newName },
      [this.itemsRef.fullkey()+"/"+viewsKey] : 
      { name : this.newName },
      [this.viewsRef.fullkey()+"/"+this.viewNode+"/"+viewsKey] :
      count
    }
    firebase.database().ref().update(update)
  }

  
  /**
   * Marks a view to be reordered.
   */
  startReorderView =(id)=> {
    if (!this.mounted) return
    this.setState({
      reorderingItem : id,
      reorderingFolder : false
    })
  }

  /**
   * Ends reordering. If a different view was clicked, the view is
   * reordered to it.
   */
  endReorderView =(id)=> {
    if (!this.mounted) return
    if (id && id !== this.state.reorderingItem){
      let views = {...this.state[this.viewNode]}
      Reordering.reorderItem(this.state.reorderingItem, id, views)
      this.itemsRef.child(this.viewNode+"/"+this.viewNode).update(views)
    }
    this.setState({ reorderingItem : false})
  }

  /**
   * Marks a folder to be reordered.
   */
  reorderFolder =(id)=> {
    if (!this.mounted) return
    this.setState({
      reorderingItem : false,
      reorderingFolder : id
    })
  }

  /**
   * Ends reordering. If a different folder was clicked, the view is
   * reordered to it.
   */
  endReorderFolder =(id)=> {
    if (!this.mounted) return
    if (id && id !== this.state.reorderingFolder){
      let folders = {...this.state.folders}
      Reordering.reorderItem(this.state.reorderingFolder, id, folders)
      this.viewsRef.child("folders").update(folders)
    }
    this.setState({ reorderingFolder : false})
  }

  /**
   * Removes the view and shows an undo toast. If the toast is not clicked,
   * the view is deleted.
   */
  removeView =(viewId)=> {
    this.hideItem(viewId)
    ToastService.showToast(this.state[viewId].name + " removed", 
      this.undoRemoveItemButton(viewId)).then( () => 
      this.state[viewId].$hidden && this.deleteView(viewId) 
    )
  }

  /**
   * Deletes a view view
   * @todo Convert to using transaction
   */
  deleteView =(id)=> {
    let position = this.state[this.viewNode][id]
    
    let itemOrder = Object.keys(this.state[this.viewNode]).reduce( ( obj, key ) => {
      if (key === id){
        obj[key] = null
      }
      else {
        obj[key] = this.state[this.viewNode][key]
        if (obj[key] > position) obj[key]--
      }
      return obj

    }, {})

    let update = {
      [this.viewsRef.fullkey()+"/"+id] : null,
      [this.itemsRef.fullkey()+"/"+id] : null,
      [this.itemsRef.fullkey()+"/"+this.viewNode+"/"+this.viewNode+"/"] : itemOrder
    }
    firebase.database().ref().update(update)
  }

  /**
   * Marks an item to be hidden.
   */
  hideItem =(id)=> {
    let view = {...this.state[id]}
    view.$hidden = true
    this.setState({[id] : view})
  }

  /**
   * Marks an item to be visible.
   */
  showItem =(id)=> {
    let item = {...this.state[id]}
    item.$hidden = false
    this.setState({[id] : item})
  }

  /**
   * Undo button shown in remove item toast
   */
  undoRemoveItemButton = viewId => (
    <FlatButton accent clicked={()=>this.showItem(viewId)}>Undo</FlatButton>
  )

  /**
   * Saves a name change to an item
   */
  saveNameChange =(id,value)=> {
    let update = {
      [this.viewsRef.child(id).child("name").fullkey()] : value,
      [this.itemsRef.child(id).child("name").fullkey()] : value,
    }
    firebase.database().ref().update(update)
  }

  /**
   * @todo implement
   */
  changeName =(id,value)=> {
    // let o = {...this.state[id], name : value}
    // this.setState({ [id] : o })
    this.saveNameChange(id,value)
  }

  /**
   * Moves view to a item
   */
  handleLink =(id)=> {
    if (!this.mounted) return
    this.props.history.push(`/${this.itemNode}/${id}`)
  }

  /**
   * This method determines how the items are ordered and can be overrode to change the order.
   */
  sortItems =(a,b) => (this.state[this.viewNode][a] - this.state[this.viewNode][b])

  /**
   * This method determines how the items are filtered.
   */
  filterViewItems =(a)=>( a !== null && a !== undefined )

  get screenBody(){
    return (<div className="item-views-list">
      {this.getItemViews()}
    </div>)
  }
  
  emptyItemsMessageHeader =()=> "No Items"
  emptyItemsMessageBody =()=> "Use the button to add one"

  get emptyItemsMessage(){
    return (
      <div className="empty-item-views-message">
        <div className="empty-item-views-message__header">{this.emptyItemsMessageHeader()}</div>
        <div className="empty-item-views-message__body">{this.emptyItemsMessageBody()}</div>
      </div>
    )
  }

  getItemViews =()=> {
    if (!this.state[this.viewNode]) return this.emptyItemsMessage
    const hidden = this.state.hidden || {}

    const items = Object.keys(this.state[this.viewNode])
      .filter( a => !hidden[a] )
      .filter( this.filterViewItems )
      .sort( this.sortItems )

    if (items.length < 1) return this.emptyItemsMessage

    return items
      .map( (id,index) => {
        if (this.state[id].$hidden) return null 
        let reorderStatus = false;
        if (this.state.reorderingItem)
          reorderStatus = this.state.reorderingItem === id ? "reordering" : "target"

        return this.getItem(id, reorderStatus)
      }, this)
  }

  get header(){
    return (
      <Header primary>
        <NavigationButton/>
        <Title value={ this.itemName }/>
      </Header>
    )
  }

  get fabArea(){
    return (<FabArea bottom right>
      { this.fab ? <Fab icon="add" accent clicked={this.newItem} /> : null }
    </FabArea>)
  }

  render(){
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }

    let bodyStyles = {}
    let bodyBackgroundColor = this.bodyBackgroundColor || "#f5f5f5"
    if (this.scrollY!==false) bodyStyles.overflowY = "true"
    
    return (
      <>
        {this.header}
        <search-box-seperate/>
        <Body background={bodyBackgroundColor} style={bodyStyles}>
          {this.screenBody}
        </Body>
        {this.fabArea}
        <PopupArea/>
      </>
    )
  }
}

export default ViewsList
import React from 'react'

import './RaisedButton.css'
import TextButton from './TextButton'

const RaisedButton =(props)=> {
  let styles = {}
  if (props.color) styles.backgroundColor = props.color
  if (props.darkColor) {
    styles.backgroundColor = props.darkColor
    styles.color = "white"
  }
  if (props.disabled){
    styles.color = styles.color === "white" ? "rgba(255,255,255,0.24)" : "rgba(0,0,0,0.24)"
  }
  return (<TextButton buttonStyles={styles} buttonClass="raised-button" {...props}/>)
}

export default RaisedButton
import React from 'react'

const CheckMark =(props)=> {
  const style = {
    msTransform: "rotate(360deg)",
    WebkitTransform: "rotate(360deg)",
    transform: "rotate(360deg)"
  }
  const color = props.color || "#000000"

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ariahidden="true"
      focusable="false"
      style={style}
      viewBox="0 0 24 24">
      <path d="M1.73,12.91 8.1,19.28 22.79,4.59" fill="none" stroke={color} strokeWidth="3.12px"/>
      &nbsp;
    </svg>
  )
}

export default CheckMark